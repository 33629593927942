const templateIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 54 45"
			fill="none"
		>
			<path
				d="M18 45H0V0H54V45H18ZM4.5 40.5H13.5V13.275H4.5V40.5ZM18 40.5H49.5V13.5H18V40.5ZM13.5 9H49.5V4.5H4.5V9H13.5Z"
				fill={color}
			/>
		</svg>
	);
};
export default templateIcon;
