import React from "react";
import "./OverlaySectionHeader.css";

type OverlaySectionHeaderProps = {
	children: string;
	icon?: React.ReactElement;
	subtitle?: string;
};

const OverlaySectionHeader: React.FC<OverlaySectionHeaderProps> = ({
	children,
	...props
}) => {
	return (
		<div className="OverlaySectionHeader-container">
			<div className="OverlaySectionHeader">
				{props.icon ? (
					<div className="OverlaySectionHeader-icon">{props.icon}</div>
				) : null}

				<h2>{children}</h2>
			</div>
			{props.subtitle ? <p>{props.subtitle}</p> : null}
		</div>
	);
};
export default OverlaySectionHeader;
