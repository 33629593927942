import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./ZoomCustomizer.css";
import CSlider from "@gigauser/common/src/ui/video/components/CSlider/CSlider";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { ZoomEdit } from "@giga-user-fern/api/types/api/resources/video";
import {
	batchUpdateZoom,
	deleteAllZooms,
	deleteZoom,
	selectVideo,
	updateZoom,
} from "@gigauser/common/src/redux/slices/guideSlice";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import { setActiveZoom } from "@gigauser/common/src/redux/slices/platformUiSlice";
import { round10 } from "../../../../../../../features/export/ExportVideo/ExportVideo";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";
import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import AdvancedSettings from "@gigauser/common/src/ui/video/components/AdvancedSettings/AdvancedSettings";
import { ZOOM_TRANSITION_TIME } from "@gigauser/common/src/core/canvas/CanvasPlayer/CanvasPlayer";
import { Tooltip } from "@chakra-ui/react";
import transcriptIcon from "@gigauser/common/src/assets/svgs/videoeditor/zoomIcon";
import tickIcon from "@gigauser/common/src/assets/svgs/tickIcon";
import CSection from "../../layout/CSection/CSection";

type ZoomCustomizerProps = {};

const ZoomCustomizer: React.FC<ZoomCustomizerProps> = () => {
	const activeZoomId = useAppSelector(
		(state) => state.platformUi.value.activeZoom,
	);
	const zooms = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.videoEdits?.zooms,
	);
	const video = useAppSelector(selectVideo);
	const videoEdits = video?.videoEdits;
	const dispatch = useAppDispatch();

	const cp = useContext(CanvasPlayerContext);

	const canvasRef = useRef<HTMLCanvasElement>(null);

	var activeZoom: ZoomEdit | null = null;
	if (zooms) {
		activeZoom = zooms.find((zoom) => zoom.id === activeZoomId) || null;
	}

	const setTimelineToZoom = (zoom?: ZoomEdit) => {
		var zoomToGo = zoom || activeZoom;
		if (!zoomToGo) return;

		const time = (zoomToGo.startTime + zoomToGo.endTime) / 2;
		cp.hardsetCurrentTime(cp.getAdjustedTime(time), true);
	};

	const onUpdateZoomFactor = (newFactor: number) => {
		if (!activeZoom) return;
		const updatedZoom: ZoomEdit = { ...activeZoom, zoomFactor: newFactor };
		dispatch(updateZoom(updatedZoom));
		setTimelineToZoom();
	};

	const onUpdateZoomTransitionTime = (time: number) => {
		if (!activeZoom) return;

		const updatedZoom: ZoomEdit = {
			...activeZoom,
			transitionTime: Math.min(
				time,
				(activeZoom.endTime - activeZoom.startTime) / 2,
			),
		};
		dispatch(updateZoom(updatedZoom));
		setTimelineToZoom();
	};

	const onDeleteZoom = () => {
		if (!activeZoom) return;
		dispatch(deleteZoom(activeZoom));
	};

	const onDeleteAll = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: trashIcon("white"),
					title: "Delete All Zooms ",
				},
				component: (
					<div className="gigauser-deletepopup">
						<p>Are you sure you would like to delete all zooms? </p>

						<div className="gigauser-delete-buttons">
							<Cutton
								onClick={() => {
									dispatch(deleteAllZooms());
									dispatch(closeOverlay());
								}}
								leftIcon={trashIcon("white")}
							>
								Delete
							</Cutton>
							<Cutton
								rank="secondary"
								onClick={() => {
									dispatch(closeOverlay());
								}}
							>
								Cancel
							</Cutton>
						</div>
					</div>
				),
			}),
		);
	};

	const handleDefaultClick = () => {
		if (!zooms) return;
		const newZooms = returnUpdatedZooms(transitionTime);
		dispatch(
			openOverlay({
				heading: {
					icon: transcriptIcon("white"),
					title: "Update All Zooms ",
				},
				component: (
					<div className="gigauser-deletepopup">
						<p>Are you sure you would like to update all zooms? </p>

						{newZooms?.notUpdatedZooms.length ? (
							<>
								<p>
									These zooms will not be updated since they are <br />
									too short for the given transition time
								</p>
								<div className="Zooms-List Popup-Zooms-List">
									{[...(newZooms?.notUpdatedZooms || [])]
										.sort((a, b) => a.startTime - b.startTime)
										.map((zoom) => (
											<div
												className="zoom-item-container"
												onDoubleClick={() => {
													setTimelineToZoom(zoom);
												}}
												onClick={() => {
													setTimelineToZoom(zoom);
													dispatch(setActiveZoom(zoom.id));
												}}
											>
												{`${zoom.zoomFactor}x zoom at ${round10(cp.getAdjustedTime(zoom.startTime))}s`}
											</div>
										))}
								</div>
							</>
						) : null}

						<div className="gigauser-delete-buttons">
							<Cutton
								onClick={() => {
									dispatch(
										batchUpdateZoom({
											updatedZooms: newZooms?.updatedZooms || [],
											deletedZooms: [],
										}),
									);
									dispatch(closeOverlay());
								}}
								leftIcon={tickIcon("white")}
							>
								Apply
							</Cutton>
							<Cutton
								rank="secondary"
								onClick={() => {
									dispatch(closeOverlay());
								}}
							>
								Cancel
							</Cutton>
						</div>
					</div>
				),
			}),
		);
	};

	const returnUpdatedZooms = (transitionTime: number) => {
		if (!zooms) return;

		const updatedZooms: ZoomEdit[] = [];
		const notUpdatedZooms: ZoomEdit[] = [];
		zooms.map((zoom) => {
			if (zoom.endTime - zoom.startTime >= transitionTime * 2) {
				updatedZooms.push({
					...zoom,
					transitionTime: transitionTime,
				});
			} else if (zoom.endTime - zoom.startTime + 0.1 >= transitionTime * 2) {
				updatedZooms.push({
					...zoom,
					transitionTime: (zoom.endTime - zoom.startTime) / 2,
				});
			} else {
				notUpdatedZooms.push(zoom);
			}
		});

		return { updatedZooms, notUpdatedZooms };
	};

	const onMouseDown = () => {
		document.addEventListener("mouseup", finishDragging);
		document.addEventListener("mousemove", updateZoomCenter);
	};

	const updateZoomCenter = (e: any) => {
		if (!activeZoom) return;

		const clientX = e.clientX;
		const clientY = e.clientY;

		const rect = canvasRef.current?.getBoundingClientRect();
		if (!rect) return;

		var offsetX = Math.max(clientX - rect.left, 0); //x position within the element.
		var offsetY = Math.max(clientY - rect.top, 0);

		const updatedZoomCenter = {
			x: Math.min(offsetX / rect.width, 1),
			y: Math.min(offsetY / rect.height, 1),
		};

		const updatedZoom: ZoomEdit = {
			...activeZoom,
			zoomCenter: updatedZoomCenter,
		};

		dispatch(updateZoom(updatedZoom));
		setTimelineToZoom();
	};

	const finishDragging = () => {
		document.removeEventListener("mouseup", finishDragging);
		document.removeEventListener("mousemove", updateZoomCenter);
	};

	useEffect(() => {
		return () => {
			finishDragging();
		};
	}, []);

	if (activeZoom && videoEdits && cp.vidRef?.current) {
		const zoomCenter = activeZoom.zoomCenter;

		var width = 0;
		var height = 0;

		if (cp.vidRef?.current) {
			width = cp.vidRef.current.videoWidth;
			height = cp.vidRef.current.videoHeight;
		}

		var transitionTime = activeZoom.transitionTime || ZOOM_TRANSITION_TIME;

		return (
			<div className="ZoomCustomizer">
				<CSection>
					<div
						className="ZoomCustomizer-esc"
						onClick={() => {
							dispatch(setActiveZoom(null));
						}}
					>
						← Back
					</div>

					<div className="ZoomCustomizerDelete">
						<div className="C-label">Delete Zoom</div>

						<Icon className="zoom-delete" onClick={onDeleteZoom}>
							{trashIcon("#db5461")}
						</Icon>
					</div>

					<CSlider
						label="Zoom level"
						unit="x"
						value={activeZoom.zoomFactor}
						setValue={onUpdateZoomFactor}
						min={1}
						max={4}
					/>

					<div className="C-label">Zoom Center</div>

					<div className="ZoomCenterAdjuster">
						<div
							className="ZoomCenter-canvas-container"
							onClick={updateZoomCenter}
						>
							<canvas
								width={width}
								height={height}
								ref={canvasRef}
								className="ZoomCenterAdjuster-canvas"
							></canvas>

							<div
								className="ZoomCenterThumb"
								onMouseDown={onMouseDown}
								style={{
									left: `${zoomCenter.x * 100}%`,
									top: `${zoomCenter.y * 100}%`,
								}}
							>
								.
							</div>
						</div>
					</div>

					<AdvancedSettings>
						<CSlider
							disabled={false}
							label="Transition time"
							unit="s"
							value={transitionTime}
							setValue={onUpdateZoomTransitionTime}
							min={0}
							max={4}
						/>
						<div className="Set-default-container">
							<Tooltip
								placement="right"
								borderRadius={6}
								bg="#303030"
								className="editor-button-tooltip"
								color={"white"}
								label={"All zooms will use this transition time"}
							>
								<div
									className="set-default-message"
									onClick={handleDefaultClick}
								>
									Apply to all zooms
								</div>
							</Tooltip>
						</div>
					</AdvancedSettings>

					{/* <CSection label='Timing' icon={clockIcon}  >

                <CSlider value={activeZoom.startTime} setValue={onUpdateZoomStart} label="Start time" unit='s' 
                    min={0} max={getTotalDuration(videoEdits, cp.vidRef)}
                />
                <CSlider value={activeZoom.endTime - activeZoom.startTime} setValue={onUpdateZoomDuration} label="Duration" unit='s' 
                    min={2} max={getTotalDuration(videoEdits, cp.vidRef)}
                />

            </CSection> */}
				</CSection>
			</div>
		);
	} else
		return (
			<CSection>
				<div className="ZoomCustomizer">
					{zooms?.length ? (
						<>
							<div className="ZoomCustomizerDelete">
								<div className="C-label">Delete All Zooms</div>

								<Icon className="zoom-delete" onClick={onDeleteAll}>
									{trashIcon("#db5461")}
								</Icon>
							</div>
							<hr />
						</>
					) : null}

					<div>
						<IBox size="s">Click in the bottom timeline to add a zoom.</IBox>

						<img
							src={
								"https://clueso-public-assets.s3.ap-south-1.amazonaws.com/web_assets/new_zoom.gif"
							}
							className="new_zoom_img"
						></img>
					</div>

					{zooms?.length ? (
						<div className="Zooms-List">
							<hr />

							{[...zooms]
								.sort((a, b) => a.startTime - b.startTime)
								.map((zoom) => (
									<div
										className="zoom-item-container"
										onDoubleClick={() => {
											setTimelineToZoom(zoom);
										}}
										onClick={() => {
											setTimelineToZoom(zoom);
											dispatch(setActiveZoom(zoom.id));
										}}
									>
										{`${zoom.zoomFactor}x zoom at ${round10(cp.getAdjustedTime(zoom.startTime))}s`}
									</div>
								))}
						</div>
					) : null}
				</div>
			</CSection>
		);
};
export default ZoomCustomizer;
