import React from "react";
import "./FileUpload.css";
import Icon from "../../../Icon/Icon";
import plusIcon from "../../../../assets/svgs/plusIcon";
import musicIcon from "../../../../assets/svgs/videoeditor/musicIcon";
import { Size } from "../../../../types/sizes";

type FileUploadProps = {
	fileTypes?: string[];
	file?: File | null;
	icon?: (color: string) => JSX.Element;
	onChange: (file: File) => void;
	children?: React.ReactNode;
	size?: Size;
};

const FileUpload: React.FC<FileUploadProps> = (props) => {
	const onPickFile = () => {
		const fileInput = document.createElement("input");
		if (props.fileTypes) fileInput.accept = props.fileTypes.join(", "); // Accept only .mp3 and .wav files
		fileInput.type = "file";

		// Attach a change event listener to the file input element
		fileInput.addEventListener("change", async (event) => {
			const input = event.target as HTMLInputElement;
			if (input.files && input.files[0]) {
				// Resolve the promise with the selected Blob
				const blob = input.files[0];
				props.onChange(blob);
			}
		});

		// Trigger the click event on the file input to open the file picker
		fileInput.click();
	};

	return (
		<div onClick={onPickFile}>
			{props.children ? (
				props.children
			) : (
				<div className={`FileUpload ${props.size}`}>
					<Icon className="FileUpload-Icon">
						{" "}
						{!props.file
							? plusIcon("white")
							: props.icon
								? props.icon("white")
								: plusIcon("white")}{" "}
					</Icon>

					<div className="FileUpload-name">
						{!props.file
							? `Upload ${props.fileTypes?.join(" or ")}`
							: props.file.name}
					</div>
				</div>
			)}
		</div>
	);
};
export default FileUpload;
