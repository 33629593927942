import React, { useState } from "react";
import "./RectangleCustomizer.css";
import CSection from "../../../layout/CSection/CSection";
import { ElementEdit } from "@giga-user-fern/api/types/api/resources/video";
import { useAppDispatch } from "@gigauser/common/src/redux";
import { updateElement } from "@gigauser/common/src/redux/slices/guideSlice";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { ColorPicker } from "antd";
import paintbucketIcon from "@gigauser/common/src/assets/svgs/screenshot/paintbucketIcon";
import rectangleIcon from "@gigauser/common/src/assets/svgs/screenshot/rectangleIcon";
import strokeWidthIcon from "@gigauser/common/src/assets/svgs/screenshot/strokeWidthIcon";
import Clickout from "@gigauser/common/src/layouts/Clickout/Clickout";
import penIcon from "@gigauser/common/src/assets/svgs/screenshot/penIcon";
import elementsIcon from "@gigauser/common/src/assets/svgs/videoeditor/elementsIcon";

type RectangleCustomizerProps = {
	element: ElementEdit;
};

const RectangleCustomizer: React.FC<RectangleCustomizerProps> = ({
	element,
}) => {
	const dispatch = useAppDispatch();
	const [showPopup, setShowPopup] = useState(false);

	const strokes = [4, 8, 12, 16, 20];
	const shapeData = element.shapedata || null;

	// const onUpdateFillColor = (hex: string) => {
	//     if (!shapeData) return;

	//     const newElement: ElementEdit = {
	//         ...element,
	//         shapedata: { ...shapeData, backgroundColor: hex },
	//     };
	//     dispatch(updateElement(newElement));
	// };

	const onUpdateStrokeColor = (hex: string) => {
		if (!shapeData) return;

		localStorage.setItem("RectangleCustomizerStrokeColor", hex);

		const newElement: ElementEdit = {
			...element,
			shapedata: { ...shapeData, strokeColor: hex },
		};
		dispatch(updateElement(newElement));
	};

	const toggleShowPopup = () => {
		setShowPopup(!showPopup);
	};

	const onUpdateStrokeWidth = (width: number) => {
		if (!shapeData) return;

		localStorage.setItem("RectangleCustomizerStrokeWidth", width.toString());

		const newElement: ElementEdit = {
			...element,
			shapedata: { ...shapeData, strokeWidth: width },
		};
		dispatch(updateElement(newElement));
	};

	const iconColor = "#c2c1c1";

	return (
		<div className="rectangle-customizer">
			<CSection label="Design" icon={elementsIcon}>
				<div className="rectangle-customizer-options">
					<div className="rectangle-customizer-options-container rectangle-customizer-color">
						<ColorPicker
							value={shapeData?.strokeColor}
							onChange={(_, hex) => onUpdateStrokeColor(hex)}
						>
							<Icon
								className="rectangle-customizer-color-icon"
								style={{
									borderColor: shapeData?.strokeColor || "transparent",
								}}
							>
								{penIcon(iconColor)}
							</Icon>
						</ColorPicker>
					</div>

					<div className="rectangle-customizer-options-container rectangle-customizer-popup-icon-container">
						<Icon
							onClick={toggleShowPopup}
							hoverTip="Stroke Width"
							className="rectangle-customizer-icon borderless"
						>
							{strokeWidthIcon(iconColor)}
						</Icon>

						{showPopup ? (
							<div className="rectangle-customizer-popup fillPopup">
								<Clickout closeFunction={() => setShowPopup(false)}>
									<div className="rectangle-customizer-stroke-width-picker">
										{strokes.map((sw) => (
											<div
												className={`rectangle-customizer-stroke-width-option ${
													sw === shapeData?.strokeWidth
														? "active-sw"
														: "inactive-sw"
												}`}
												onClick={() => onUpdateStrokeWidth(sw)}
											>
												{sw} px
											</div>
										))}
									</div>
								</Clickout>
							</div>
						) : null}
					</div>
				</div>
			</CSection>
		</div>
	);
};
export default RectangleCustomizer;
