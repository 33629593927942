const i = (color = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.9168 3.25C10.2565 3.25012 8.65926 3.88553 7.45261 5.0259C6.24596 6.16627 5.52142 7.72515 5.42762 9.38275C4.12857 9.91034 3.0511 10.8696 2.37678 12.0989C1.70246 13.3282 1.47252 14.7524 1.72571 16.1314C1.9789 17.5105 2.69974 18.7601 3.76675 19.6697C4.83376 20.5793 6.18171 21.0933 7.58345 21.125L18.4168 21.1261C19.7485 21.0969 21.0339 20.631 22.0764 19.8018C23.1189 18.9726 23.861 17.8247 24.1891 16.5337C24.5172 15.2427 24.4132 13.8797 23.8931 12.6534C23.373 11.4271 22.4654 10.405 21.3093 9.7435L20.1024 10.9503C21.0472 11.3727 21.8162 12.1097 22.2782 13.0358C22.7401 13.9618 22.8664 15.0195 22.6355 16.0283C22.4047 17.0371 21.8309 17.9345 21.0121 18.5674C20.1934 19.2004 19.1804 19.5296 18.1459 19.4989L7.85429 19.5V19.4978C6.71096 19.5313 5.59833 19.1251 4.74552 18.3628C3.89271 17.6006 3.36466 16.5403 3.27011 15.4004C3.17556 14.2605 3.5217 13.1277 4.23727 12.2354C4.95283 11.343 5.98332 10.759 7.11654 10.6037C6.90539 9.42898 7.13252 8.21765 7.75486 7.19923C8.3772 6.18082 9.35152 5.4261 10.4932 5.07809C11.6348 4.73008 12.8645 4.81296 13.9491 5.31104C15.0337 5.80912 15.8979 6.68778 16.3779 7.7805L17.5869 6.57042C17.0222 5.56299 16.1991 4.72428 15.2025 4.14067C14.2059 3.55705 13.0717 3.24961 11.9168 3.25Z"
				fill={color}
			/>
			<path
				d="M12.5227 15.5455L12.6023 13.358L10.7528 14.5312L10.2756 13.696L12.2244 12.6818L10.2756 11.6676L10.7528 10.8324L12.6023 12.0057L12.5227 9.81818H13.4773L13.3977 12.0057L15.2472 10.8324L15.7244 11.6676L13.7756 12.6818L15.7244 13.696L15.2472 14.5312L13.3977 13.358L13.4773 15.5455H12.5227Z"
				fill={color}
			/>
		</svg>
	);
};
export default i;
