import React, { Children } from "react";
import "./CSection.css";
import downArrowheadIcon from "@gigauser/common/src/assets/svgs/downArrowheadIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";

type CSectionProps = {
	icon?: (color: string) => JSX.Element;
	label?: string;
	resetFunction?: () => void;
	disabled?: boolean;
	children: React.ReactNode[] | React.ReactNode;
	noMarginTop?: boolean;
	noPadding?: boolean;
	subsection?: boolean;
	defaultCollapsed?: boolean;
};

const CSection: React.FC<CSectionProps> = (props) => {
	const [expanded, setExpanded] = React.useState(
		props.defaultCollapsed ? false : true,
	);

	return (
		<div
			className={`CSection 
            ${props.disabled ? "disabled-CSection" : "enabled-CSection"}
            ${props.noPadding ? "nopadding" : ""}
        `}
		>
			{props.label && (
				<div
					className={`CSectionHeader ${props.noMarginTop ? "nomargin" : "margin"}`}
					onDoubleClick={() => {
						setExpanded(!expanded);
					}}
				>
					<div className="CSectionHeader-left">
						{props.icon ? (
							<div className="CSectionHeader-icon">{props.icon("#b7b7b7")}</div>
						) : null}
						{props.label ? (
							<div className="CSectionHeader-label">{props.label}</div>
						) : null}
					</div>

					{props.resetFunction ? (
						<div className="CSectionHeader-right">Reset</div>
					) : null}

					<div
						className={`CSectionHeader-right `}
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						<Icon
							className={`CSection-dropdown ${expanded ? "expanded" : "collapsed"}`}
						>
							{downArrowheadIcon("hsl(217, 19%, 79%)")}
						</Icon>
					</div>
				</div>
			)}

			<div
				className={`CSection-sectioncontainer 
                ${props.noPadding ? "nopadding" : ""}
                ${props.subsection ? "subsection" : ""}
            `}
				style={{
					display: expanded ? "block" : "none",
				}}
			>
				{Children.map(props.children, (child: any) => {
					var disabled = false;
					if (React.isValidElement(child)) {
						try {
							if ((child.props as any).disabled) disabled = true;
						} catch {}
					}

					return (
						<div
							className={`CSection-subsection ${disabled ? "disabled-CSection" : ""}`}
						>
							{child}
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default CSection;
