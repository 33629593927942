const downloadIcon = (color: string = "black") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M8.25 12.85L3.87 8.47C3.72782 8.33752 3.53978 8.2654 3.34548 8.26882C3.15118 8.27225 2.96579 8.35097 2.82838 8.48838C2.69097 8.62579 2.61225 8.81118 2.60883 9.00548C2.6054 9.19978 2.67752 9.38783 2.81 9.53L7.76 14.48C8.45 15.16 9.56 15.16 10.24 14.48L15.19 9.53C15.2637 9.46134 15.3228 9.37854 15.3638 9.28654C15.4048 9.19454 15.4268 9.09523 15.4286 8.99452C15.4304 8.89382 15.4118 8.79379 15.3741 8.7004C15.3364 8.60701 15.2803 8.52218 15.209 8.45096C15.1378 8.37974 15.053 8.3236 14.9596 8.28588C14.8662 8.24816 14.7662 8.22963 14.6655 8.23141C14.5648 8.23318 14.4655 8.25523 14.3735 8.29622C14.2815 8.33721 14.1987 8.39631 14.13 8.47L9.75 12.85V1.25C9.75 1.05109 9.67098 0.860322 9.53033 0.71967C9.38968 0.579018 9.19891 0.5 9 0.5C8.80109 0.5 8.61032 0.579018 8.46967 0.71967C8.32902 0.860322 8.25 1.05109 8.25 1.25V12.85ZM0.75 16.25H17.25C17.4489 16.25 17.6397 16.329 17.7803 16.4697C17.921 16.6103 18 16.8011 18 17C18 17.1989 17.921 17.3897 17.7803 17.5303C17.6397 17.671 17.4489 17.75 17.25 17.75H0.75C0.551088 17.75 0.360322 17.671 0.21967 17.5303C0.0790175 17.3897 0 17.1989 0 17C0 16.8011 0.0790175 16.6103 0.21967 16.4697C0.360322 16.329 0.551088 16.25 0.75 16.25Z"
				fill={color}
			/>
		</svg>
	);
};

export default downloadIcon;
