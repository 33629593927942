import React from "react";
import "./iBox.css";
import infoIcon from "../../../assets/svgs/infoIcon";
import { Size } from "../../../types/sizes";

type IBoxProps = {
	children: string | React.ReactNode;
	type?: "success";
	size?: Size;
};

const IBox: React.FC<IBoxProps> = ({ children, ...props }) => {
	const color = props.type == "success" ? "#3a9434" : "#87711D";

	return (
		<div className={`iBox ${props.type} ${props.size}`}>
			<div className="infoIcon">{infoIcon(color)}</div>

			<div className="iBox-text">{children}</div>
		</div>
	);
};
export default IBox;
