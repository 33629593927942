import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import { calculateMediaDuration } from "@gigauser/common/src/core/canvas/canvasUtils/videoUtils";
import { useAppSelector } from "@gigauser/common/src/redux";
import { selectVideo } from "@gigauser/common/src/redux/slices/guideSlice";
import React, { useContext, useEffect, useRef } from "react";

type PreviewOriginalVideoProps = {};

const PreviewOriginalVideo: React.FC<PreviewOriginalVideoProps> = () => {
	const cp = useContext(CanvasPlayerContext);
	const ogVidRef = useRef<HTMLVideoElement>(null);

	const video = useAppSelector(selectVideo);

	useEffect(() => {
		if (ogVidRef.current) {
			calculateMediaDuration(ogVidRef.current);
		}
	}, []);

	return (
		<div className="PreviewOriginalVideo">
			<video
				ref={ogVidRef}
				preload="metadata"
				controls
				className={`gigauser-preview-original-video`}
				src={video?.originalSrc}
				onError={(e: any) => {
					console.log("error in preview original video: ", e);
				}}
				crossOrigin="anonymous"
				// onPlay={handlePlay}
				// onPause={handlePause}
			></video>
		</div>
	);
};
export default PreviewOriginalVideo;
