import React, { useContext } from "react";

import "./NavbarMenu.css";
import useCollectionNavigate from "@gigauser/common/src/redux/hooks/FileHooks/useCollectionNavigate";
import { EnvironmentContext } from "@gigauser/common/src/contexts";

import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	PlatformHomePage,
	setHomePage,
} from "@gigauser/common/src/redux/slices/platformUiSlice";
import home from "@gigauser/common/src/assets/icons/home.svg";
import trash from "@gigauser/common/src/assets/icons/trash.svg";
import chart from "@gigauser/common/src/assets/icons/chart.png";
import users from "@gigauser/common/src/assets/icons/users.svg";
import eye from "@gigauser/common/src/assets/icons/eye.svg";
import settings from "@gigauser/common/src/assets/icons/settings-cog.svg";
import usage_meter from "@gigauser/common/src/assets/icons/usage-meter.svg";
import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin";
import { saver } from "@gigauser/common/src/network/saver";
import { rootCollection } from "@gigauser/common/src/types/files";

type NavbarMenuProps = {};

const NavbarMenu: React.FC<NavbarMenuProps> = () => {
	const env = useContext(EnvironmentContext);
	const navigate = useCollectionNavigate(env);

	var navbarMenuArray: {
		label: string;
		icon: string;
		id: PlatformHomePage;
		handler?: () => void;
	}[] = [
		{
			label: "Home",
			icon: home,
			id: "home",
			handler: () => {
				navigate(rootCollection);
			},
		},
		// {
		//     label: 'Analytics', icon: chart, id: 'anal'
		// }
	];

	const betaFeaturesAccessible = useSuperAdmin();

	if (betaFeaturesAccessible("bin")) {
		//Seeing deleted guides is only possible for clueso internal team.
		navbarMenuArray.push({
			label: "Trash",
			icon: trash,
			id: "bin",
		});
	}

	if (!saver.organization?.trial) {
		navbarMenuArray.push({
			label: "Analytics",
			icon: chart,
			id: "anal",
		});
	}
	// if (saver.user?.role == "admin") {
	navbarMenuArray.push({
		label: "Creators",
		icon: users,
		id: "admin",
	});
	// }
	if (saver.user?.role == "admin") {
		navbarMenuArray.push({
			label: "Usage",
			icon: usage_meter,
			id: "usage",
		});
		if (betaFeaturesAccessible("helpcenterappearance")) {
			navbarMenuArray.push({
				label: "Help Center Settings",
				icon: eye,
				id: "help-center-config",
			});
		}
	}

	navbarMenuArray.push({
		label: "Settings",
		icon: settings,
		id: "settings",
	});

	const menu = navbarMenuArray;
	const dispatch = useAppDispatch();

	const homePage = useAppSelector((state) => state.platformUi.value.homePage);

	return (
		<div className="NavbarMenu laptop">
			{menu.map((menuItem) => {
				return (
					<div
						className={`NavbarMenuItem laptop ${homePage === menuItem.id ? "active" : ""}`}
						key={menuItem.label}
						onClick={() => {
							dispatch(setHomePage(menuItem.id));
							if (menuItem.handler) {
								menuItem.handler();
							}
							//TODO: Put this line back
							// navigate(menuItem.label==="My Library" ? myLibrary: sharedFiles)
						}}
					>
						<img src={menuItem.icon} className="NavbarMenuItem-icon"></img>
						<span className="NavbarMenuItem-icon-label">{menuItem.label}</span>
					</div>
				);
			})}
		</div>
	);
};
export default NavbarMenu;
