import { useAuth0 } from "@auth0/auth0-react";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import React from "react";
import Logo from "../assets/logos/Logo.svg";
import Splash from "../ui/Loading/Splash/Splash";
import "./Auth.css";
import logger from "../utils/logger";
import { useAuthInfo, useHostedPageUrls } from "@propelauth/react";

type SignInProps = {
	method?: "redirect" | "popup";
	message?: string;
};

const SignIn: React.FC<SignInProps> = (props) => {
	const { getLoginPageUrl, getSignupPageUrl, getAccountPageUrl } =
		useHostedPageUrls();

	// These pages are all hosted by PropelAuth on our domain
	const openAccountPage = () => window.open(getAccountPageUrl());
	const openSignupPage = () => window.open(getSignupPageUrl());
	const openLoginPage = () => window.open(getLoginPageUrl());

	const openPopup = () => {
		const popup = window.open(
			"",
			"auth0:authorize:popup",
			"left=100,top=100,width=400,height=600,resizable,scrollbars=yes,status=1",
		);
		if (!popup) {
			throw new Error("Could not open popup");
		}

		return new Promise((resolve) => setTimeout(() => resolve(popup), 200));
	};

	const onSignIn = async () => {
		logger.debug("Trying to sign in: ");
		//in dev mode do something here.
		if (props.method === "redirect") {
			//loginWithRedirect();
		} else {
			openLoginPage();
		}
	};

	return (
		<div className="SignIn">
			<div className="SignIn-header">
				<img src={Logo} className="AuthLogo"></img>
			</div>

			<button className="SignIn-button" onClick={onSignIn}>
				Sign In
			</button>
		</div>
	);
};
export default SignIn;
