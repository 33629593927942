import { useAppSelector } from "@gigauser/common/src/redux";
import logger from "../../../utils/logger";
import { Collection } from "../../../core/types/collections";
import { selectSaver } from "../../slices/backendSlice";

const useGetPath: () => (
	objectID: string,
) => Promise<[Collection, ...Collection[]] | false> = () => {
	const saver = useAppSelector(selectSaver);

	return async (objectID: string) => {
		logger.debug("getPath objectID: ", objectID);

		try {
			// const resp = await API.graphql({
			//     query: getPath,
			//     variables: {input:{
			//         objectID: objectID
			//     }}
			// }) as any

			// logger.debug("the getPath response is: ", resp)

			// const sections = resp.data.getPath
			// return sections

			const resp = await saver.getPath(objectID);
			logger.debug("resp useGetPath: ", resp);

			return false;
		} catch (e) {
			logger.error("Error getting path: ", e);
			return false;
		}
	};
};

export default useGetPath;
