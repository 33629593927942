import React, { useState } from "react";
import Icon from "../../Icon/Icon";
import { Size } from "../../../types/sizes";
import "./Menu.css";
import MenuElement from "./MenuElement";
import logger from "../../../utils/logger";
import downArrowheadIcon from "../../../assets/svgs/downArrowheadIcon";

export type DisplayMenu = {
	menu: DisplayMenuItem[];
	spacing?: Size;
	size?: Size;
	direction?: MenuDirection;
	closeDisplayMenu?: () => void;
};

export type MenuDirection = "ltr" | "rtl";

export type DisplayMenuItem = {
	label?: string;
	hoverTip?: string;
	value?: string;
	toggleStates?: {
		left: ToggleOption;
		right: ToggleOption;
		active?: boolean; //true if right
	};
	directComponent?: React.ReactNode;
	submenu?: DisplayMenuItem[];
	description?: string;
	href?: string;
	closeMenuOnClick?: boolean; //true if menu should close on click
} & (
	| {
			icon?: JSX.Element;
			handler?:
				| ((event: React.MouseEvent<HTMLDivElement>) => void)
				| ((event: React.MouseEvent<HTMLDivElement>) => Promise<boolean>);
			type?: "warning" | "toggle" | "separator" | "header" | null;
			loading?: boolean;
	  }
	| {
			icon?: string;
			type: "file";
			handler?: () => void;
	  }
);

type ToggleOption = {
	icon: string;
	handler: () => void;
};

const Menu: React.FC<DisplayMenu> = ({
	menu,
	spacing = "n",
	size = "n",
	closeDisplayMenu,
	...props
}) => {
	const direction = props.direction || "ltr";

	return (
		<div className={`Menu ${size}`}>
			{/* <div className='triangleElement'>&#9650;</div> */}
			<ul className="list">
				{menu.map((MenuItem) =>
					MenuItem.directComponent != null ? (
						<div>{MenuItem.directComponent}</div>
					) : (
						<div className={`li ${spacing}`}>
							{
								//Menu Element
								MenuItem.type == "warning" ||
								MenuItem.type == "toggle" ||
								MenuItem.type == null ? (
									<MenuElement
										MenuItem={MenuItem}
										direction={direction}
										closeDisplayMenu={closeDisplayMenu}
									></MenuElement>
								) : null
							}

							{
								//separator line
								MenuItem.type == "separator" ? (
									<hr className="separator" />
								) : null
							}

							{
								//Header
								MenuItem.type == "header" ? (
									<div className="header">{MenuItem.label?.toUpperCase()}</div>
								) : null
							}

							{MenuItem.type === "file" ? (
								<label>
									<div className="dropdownElement">
										<div className="clickable">
											<Icon className="Menu-icon">{MenuItem.icon}</Icon>

											<div className="label">{MenuItem.label}</div>
											<input
												onChange={(e) => {
													logger.debug(e);

													MenuItem.handler!();
												}}
												type="file"
												accept="application/pdf"
												style={{ display: "none" }}
											></input>
										</div>
									</div>
								</label>
							) : null}
						</div>
					),
				)}
			</ul>
		</div>
	);
};

export default Menu;
