const timerIcon = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M9 16.5C12.728 16.5 15.75 13.478 15.75 9.75C15.75 6.02208 12.728 3 9 3C5.27208 3 2.25 6.02208 2.25 9.75C2.25 13.478 5.27208 16.5 9 16.5Z"
				stroke={color}
				stroke-width="1.125"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9 6V9.75"
				stroke={color}
				stroke-width="1.125"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.25 9.75H9"
				stroke={color}
				stroke-width="1.125"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.25 1.5H6.75"
				stroke={color}
				stroke-width="1.125"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9 3V1.5"
				stroke={color}
				stroke-width="1.125"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
export default timerIcon;
