import React, { useContext, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectGuide,
	selectVideo,
} from "@gigauser/common/src/redux/slices/guideSlice";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import "./ConvertToGif.css";
import {
	GifExtensionMetadata,
	ImageExtensionMetadata,
} from "@gigauser/common/src/components/formats/RichText/extensions/SpecialImageExtension";
import {
	ConvertToGifResponse,
	VideoClip,
} from "@giga-user-fern/api/types/api/resources/guides";
import { Guide } from "@gigauser/common/src/core/types/guide";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay";
import ScreenshotEditor from "@gigauser/common/src/components/formats/RichText/components/EditScreenshot/ScreenshotEditor";
import CanvasProvider from "@gigauser/common/src/core/canvas/CanvasProvider";
import GifMakerTimeline from "../GifMakerTimeline/GifMakerTimeline";
import { GigaUserApi } from "@giga-user-fern/api";
import {
	InsertGifInNotebookFunction,
	ScreenshotRenderEdits,
} from "@gigauser/common/src/components/formats/RichText/components/EditScreenshot/useEditScreenshot";
import { showErrorMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";

type ConvertToGifProps = {
	metadata: ImageExtensionMetadata;
	insertGifInNotebook: InsertGifInNotebookFunction;
};

const ConvertToGif: React.FC<ConvertToGifProps> = (props) => {
	const { metadata, insertGifInNotebook } = props;

	const saver = useAppSelector(selectSaver);
	const guide = useAppSelector(selectGuide);
	if (metadata.format && metadata.format == "gif") {
		const met = metadata as ImageExtensionMetadata & GifExtensionMetadata;

		var initClip: VideoClip = {
			srcId: GigaUserApi.Id("ignore"),
			startTime: met.startTime ? met.startTime : 0,
			endTime: met.endTime ? met.endTime : 0,
		};
	} else {
		var initClip: VideoClip = {
			srcId: GigaUserApi.Id("ignore"),
			startTime: metadata.timestamp ? Math.max(metadata.timestamp - 2, 0) : 0,
			endTime: metadata.timestamp ? metadata.timestamp + 2 : 0,
		};
	}
	const initTime = (initClip.startTime + initClip.endTime) / 2;

	const [clip, setClip] = useState<VideoClip>(initClip);

	const [hideBackground, setHideBackground] = useState(false);
	const [color, setColor] = useState(
		saver.organization?.brandColor || "#4f46e5",
	);

	const [loading, setLoading] = useState(false);

	const dispatch = useAppDispatch();

	const video = useAppSelector(selectVideo);
	const videoSrc = video?.originalSrc;

	useEffect(() => {
		const storedColor = localStorage.getItem("gifTextColor");
		const storedHideBackground = localStorage.getItem("gifTextHideBackground");
		if (storedColor) {
			setColor(storedColor);
		}
		if (storedHideBackground) {
			setHideBackground(storedHideBackground === "true");
		}
	}, []);

	const getGif = async (edits: ScreenshotRenderEdits, rate: number) => {
		try {
			const gif = await saver.convertToGif(
				guide as Guide,
				{
					start: clip.startTime,
					end: clip.endTime,
					length: (clip.endTime - clip.startTime) / rate,
					zoomFactor: edits.zoomFactor,
					zoomCenter: edits.zoomCenter,
				},
				(status: boolean, resp: ConvertToGifResponse | undefined) => {
					if (status && resp) {
						insertGifInNotebook(resp.url, resp.s3ObjectId, {
							format: "gif",
							editable: true,
							zoomCenter: edits.zoomCenter,
							zoomFactor: edits.zoomFactor,
							rate: rate,
							startTime: clip.startTime,
							endTime: clip.endTime,
						});
					}
				},
			);
		} catch (e) {
			dispatch(
				showErrorMessagePopup("GIF too long. Try making a shorter one."),
			);
		}
	};

	const onSubmit = async (edits: ScreenshotRenderEdits, rate: number) => {
		setLoading(true);
		localStorage.setItem("gifTextColor", color);
		localStorage.setItem("gifTextHideBackground", hideBackground.toString());

		await getGif(edits, rate);
		setLoading(false);
		dispatch(closeOverlay());
	};

	if (!videoSrc) return <>Loading...</>;

	return (
		<div className="ConvertToGif">
			<CanvasProvider
				videoSrc={videoSrc}
				videoEdits={{ version: "oct162023" }}
				organization={saver.organization}
				initTime={initTime || 0}
				// onError={refreshVideo}
			>
				<ScreenshotEditor
					rootSrc={videoSrc}
					srcType="video"
					metadata={metadata}
					onMakeGif={onSubmit}
					saveLabel="Make GIF"
					clip={clip}
				/>

				<GifMakerTimeline clip={clip} setClip={setClip} />
			</CanvasProvider>
		</div>
	);
};
export default ConvertToGif;
