import { Switch, color } from "@chakra-ui/react";
import React from "react";
import "./CToggle.css";

type CToggleProps = {
	label: string;
	subtitle?: string;
	value: boolean;
	setterFunction: (value: boolean) => void;
	disabled?: boolean;
	explanation?: string;
	color?: "pink" | "grey";
};

const CToggle: React.FC<CToggleProps> = (props) => {
	const onToggle = () => {
		if (props.disabled) return;
		props.setterFunction(!props.value);
	};

	return (
		<>
			<div className="CToggle">
				<div>
					<div className="C-label CToggle-label">{props.label}</div>
					{props.subtitle && <div className="C-subtitle">{props.subtitle}</div>}
				</div>

				<input checked={props.value} onChange={onToggle} type="checkbox" />
				<div
					className={`label
                ${props.color}
                ${props.disabled ? "disabled" : "enabled"} ${props.value ? "checked" : "unchecked"}`}
					onClick={onToggle}
				>
					Toggle
				</div>
			</div>
			{props.explanation ? (
				<p className="C-explanation">{props.explanation}</p>
			) : null}
		</>
	);
};
export default CToggle;
