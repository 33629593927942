import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "@gigauser/common/src/redux";
import React, { useEffect, useState } from "react";
import HomeLayout from "./home/main/HomeLayout";
import { saver } from "@gigauser/common/src/network/saver";
import SignIn from "@gigauser/common/src/auth/SignIn";
import EditorPlatform from "./editor/main/EditorPlatform";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";

import "../styles/index.css";
import {
	ContextMenuComponent,
	ContextMenuObject,
} from "@gigauser/common/src/layouts/ContextMenu/ContextMenu";
import { CMenuContext } from "@gigauser/common/src/contexts";
import logger from "@gigauser/common/src/utils/logger";
import { getOperatingSystem } from "@gigauser/common/src/utils/os";
import "../App.css";
import {
	AnalyticsLogger,
	initializeAnalytics,
} from "@gigauser/common/src/core/analytics/analytics";
import {
	selectAnalytics,
	setAnalytics,
	setSaver,
} from "@gigauser/common/src/redux/slices/backendSlice";
import "./AppInitializer.css";
import warningIcon from "@gigauser/common/src/assets/icons/warning.png";
import { useAuthInfo, useLogoutFunction } from "@propelauth/react";
import { propelAuthClient } from "@gigauser/common/src/auth/PropelAuthProvider";
import WelcomeFlow from "./welcome/WelcomeFlow";
import { useNavigate } from "react-router";

export type AppInitializerPage =
	| "home"
	| "editor"
	| "welcome/personal-details"
	| "welcome/create-team"
	| "welcome/join-team";

type AppInitializerProps = {
	page: AppInitializerPage;
};

const AppInitializer: React.FC<AppInitializerProps> = (props) => {
	const dispatch = useAppDispatch();

    const navigate = useNavigate();

	const [contextMenu, setContextMenu] = useState<ContextMenuObject>({
		menuArray: [],
		show: false,
		X: 0,
		Y: 0,
	});

	const {
		isLoggedIn: isAuthenticated,
		accessToken,
		user,
		loading: isLoading,
	} = useAuthInfo();
	const logOut = useLogoutFunction();
	const [loadingSaver, setLoadingSaver] = useState(true);
	const [userNotAssociatedToAnyOrg, setUserNotAssociatedToAnyOrg] =
		useState(false);
	const [invalidUser, setInvalidUser] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			initApp();

			//set the font size for rem based on os
			const os = getOperatingSystem();
			var root = document.documentElement;

			if (os === "Windows") {
				// Set the font size
				root.style.fontSize = "12px";
			} else {
				root.style.fontSize = "15px";
			}
		}
	}, [isAuthenticated]);

	const showContextMenu = (cont: ContextMenuObject | false) => {
		if (!cont) {
			setContextMenu({
				menuArray: [],
				show: false,
				X: 0,
				Y: 0,
			});
		} else {
			setContextMenu(cont);
		}
	};

	const isContextMenuVisible = () => {
		if (contextMenu.show) return true;
		else return false;
	};

	const initApp = async () => {
		// -----------Initializing the Access Token --------------
		try {
			// if(isAuthenticated && user){
			if (accessToken) {
				try {
					const org = await saver.initializePlatform(async () => {
						return propelAuthClient
							.getAuthenticationInfoOrNull()
							.then((d) => d?.accessToken as string);
					});
					const analytics = new AnalyticsLogger(org, user?.email);

					initializeAnalytics(org);
					dispatch(setSaver(saver));
					dispatch(setAnalytics(analytics));
					setLoadingSaver(false);
				} catch (e: any) {
					if (e.userExists && !e.associatedToOrg) {
						setUserNotAssociatedToAnyOrg(true);
						if (
							//Auth TODO: Fix this.
							!props.page.includes("welcome/")
						) {
							navigate("/welcome/personal-details");
						}
					} else {
						setInvalidUser(true);
					}
					throw new Error("Invalid User!");
				}
			}

			// }
		} catch (e) {
			console.log(e);
			logger.error("Could not get token: ", e);
		}
	};

	const onLogout = async () => {
		logOut(true);
	};

	const getComponent = () => {
		// if (error) {
		// 	logger.error("Error with Auth0: ", error);

		// 	return (
		// 		<div className="platform-main-loading">
		// 			Oops... {error.message}. Please refresh the page.{" "}
		// 		</div>
		// 	);
		// }

		if (invalidUser) {
			return (
				<div className="platform-main-loading">
					<div className="platform-main-loading-header">
						<img className="platform-main-loading-icon" src={warningIcon}></img>
						<h1 className="oops-h1">Oops! You don't have a Clueso account.</h1>
					</div>

					<p className="platform-main-loading-p">
						<ul>
							<li>
								If your organization is already a Clueso customer, please
								contact the Clueso admin from your organization to add you to
								their team.
							</li>
							<li>
								If your organization is not yet a Clueso customer, please reach
								out to us at{" "}
								<a
									className="email-pin"
									href="mailto:hello@clueso.io"
									target="_blank"
								>
									hello@clueso.io
								</a>{" "}
								to set up Clueso for your company.
							</li>
						</ul>
						In case of any other questions, please reach out at{" "}
						<a
							className="email-pin"
							href="mailto:hello@clueso.io"
							target="_blank"
						>
							hello@clueso.io
						</a>
					</p>

					<p className="platform-main-loading-p">
						You are signed in as{" "}
						<span className="curr-email">{user?.email}</span>. Click the button
						below to use a different account.
					</p>

					<button className="platform-main-logout-button" onClick={onLogout}>
						Logout
					</button>
				</div>
			);
		}
		if (isLoading) {
			//Waiting to see if user is authenticated or not.

			return (
				<div className="platform-main-loading">
					<GridLoader center />
				</div>
			);
		}

		if (props.page.includes("welcome")) {
			return (
				<WelcomeFlow
					userAssociatedWithAnyOrg={!userNotAssociatedToAnyOrg}
					page={props.page}
				/>
			);
		}

		//We are done checking if any user is signed in or not
		else if (!user || !isAuthenticated) {
			//The user is not signed in.
			console.log("The user is not signed in : ", user, isAuthenticated);
			return (
				<div className="AppInitializer-auth">
					<div className="PlatformSignIn-container">
						<SignIn method="redirect" />
					</div>
				</div>
			);
		}

		//There is a signed in user
		else if (loadingSaver || !saver.organization) {
			return (
				<div className="gigauser-main-loading">
					<GridLoader center />
					{/* <Splash/> */}
					{/* <button>Log out</button> */}
				</div>
			);
		} else {
			//We are done loading the saver
			if (props.page === "home") {
				return <HomeLayout />;
			} else return <EditorPlatform />;
		}
	};

	return (
		<CMenuContext.Provider
			value={{
				showContextMenu,
				isContextMenuVisible,
			}}
		>
			<div className={`platform-parent ${getOperatingSystem()}`}>
				{getComponent()}
			</div>
			{contextMenu.show ? (
				<ContextMenuComponent
					visible={contextMenu.show}
					X={contextMenu.X}
					Y={contextMenu.Y}
					menuArray={contextMenu.menuArray}
					showContextMenu={showContextMenu}
					size={props.page === "editor" ? "s" : "n"}
				/>
			) : null}
		</CMenuContext.Provider>
	);
};
export default AppInitializer;
