const shareableLinkIcon = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 29 18"
			fill="none"
		>
			<path
				d="M17.2778 2H20.0556C23.8908 2 27 5.13401 27 9C27 12.866 23.8908 16 20.0556 16H17.2778M11.7222 2H8.94444C5.10914 2 2 5.13401 2 9C2 12.866 5.10914 16 8.94444 16H11.7222M8.94444 9H20.0556"
				stroke={color}
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default shareableLinkIcon;
