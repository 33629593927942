import React, { useContext } from "react";
import "./Navbar.laptop.css";
import Logo from "@gigauser/common/src/assets/logos/Logo.svg"; //../../../../../

import NavbarSection from "./NavbarSection/NavbarSection";
import NavbarMenu from "./NavbarMenu/NavbarMenu";
import NavbarFooter from "./NavbarFooter/NavbarFooter";

const Navbar: React.FC = () => {
	return (
		<div className="Navbar laptop">
			<img src={Logo} className="Navbar-logo"></img>

			<NavbarSection header="Menu">
				<NavbarMenu />
			</NavbarSection>

			<NavbarFooter />
		</div>
	);
};
export default Navbar;
