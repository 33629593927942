export default (
	<svg
		width="10"
		height="10"
		viewBox="0 0 10 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="10"
			width="10"
			height="10"
			transform="rotate(90 10 0)"
			fill="url(#pattern0)"
		/>
		<defs>
			<pattern
				id="pattern0"
				patternContentUnits="objectBoundingBox"
				width="1"
				height="1"
			>
				<use transform="scale(0.015625)" />
			</pattern>
			<image id="image0_224_972" width="64" height="64" />
		</defs>
	</svg>
);
