import "./PronDictTable.css";
import { Icon, Spinner, useToast } from "@chakra-ui/react";
import { Language, PronDictEntry } from "@giga-user-fern/api/types/api";
import playIcon from "@gigauser/common/src/assets/svgs/playIcon";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay";
import { saver } from "@gigauser/common/src/network/saver";
import LanguagePicker from "@gigauser/common/src/overlays/LanguagePicker/LanguagePicker";
import { useAppDispatch } from "@gigauser/common/src/redux";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const PronDictTable = () => {
	const dispatch = useAppDispatch();
	const [currentLanguage, setCurrentLanguage] = useState<Language>({
		languageId: "en",
		name: "English",
	});

	useEffect(() => {
		fetchPronDict();
	}, []);

	const fetchPronDict = async () => {
		const res = await saver.fetchPronDict();
		if (res.ok) {
			setDictionary(res.body);
		}
	};

	const [dictionary, setDictionary] = useState<PronDictEntry[]>([]);

	const openNewEntryOverlay = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: plusIcon("black"),
					title: "Add Word",
				},
				component: (
					<NewPronDictEntryOverlay
						setDictionary={setDictionary}
						dictionary={dictionary}
					/>
				),
			}),
		);
	};

	const openDeleteOverlay = (entry: PronDictEntry) => {
		dispatch(
			openOverlay({
				heading: {
					icon: trashIcon("black"),
					title: "Delete Word",
				},
				component: (
					<DeletePronDictEntryOverlay
						setDictionary={setDictionary}
						dictionary={dictionary}
						entry={entry}
					/>
				),
			}),
		);
	};

	const filteredDict = dictionary.filter(
		(entry) => entry.language === currentLanguage.name,
	);

	return (
		<div className="gigauser-admin-list-table-container" style={{ margin: 0 }}>
			<a className="gigauser-pron-dict-disclaimer">
				All the words added to the dictionary will be treated in a case
				insensitive manner
			</a>
			<div className="gigauser-pron-dict-button-row">
				<Cutton onClick={openNewEntryOverlay}>Add Word</Cutton>
				<LanguagePicker
					currentLanguage={currentLanguage}
					setCurrentLanguage={setCurrentLanguage}
					position="bottom"
				/>
			</div>

			<div className="gigauser-admin-list-table">
				<div className="gigauser-admin-list-table-header">
					<div className="gigauser-admin-list-table-th gigauser-admin-list-table-th-email">
						Actual Word
					</div>
					<div className="gigauser-admin-list-table-th gigauser-admin-list-table-th-email">
						Phonetic Spelling
					</div>
					<div className="gigauser-admin-list-table-th gigauser-admin-list-table-th-role">
						Language
					</div>
					<div className="gigauser-admin-list-table-th gigauser-admin-list-table-th-delete">
						{" "}
					</div>
				</div>
				{filteredDict.map((entry, index) => (
					<div className="gigauser-admin-list-row" key={index}>
						{/* <div className="gigauser-admin-list-row-name">{user.name || ''}</div> */}
						<div className="gigauser-admin-list-row-email">
							{entry.actualWord}
						</div>
						<div className="gigauser-admin-list-row-email">
							{entry.phoneticSpelling}
						</div>
						<div className="gigauser-admin-list-row-role">{entry.language}</div>
						<div className="gigauser-admin-list-row-trash">
							<Icon
								className="gigauser-td-trash gigauser-td-hover-icon"
								onClick={() => {
									openDeleteOverlay(entry);
								}}
							>
								{trashIcon()}
							</Icon>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

type DeletePronDictEntryOverlayProps = {
	setDictionary: (dictionary: PronDictEntry[]) => void;
	dictionary: PronDictEntry[];
	entry: PronDictEntry;
};

export const DeletePronDictEntryOverlay: React.FC<
	DeletePronDictEntryOverlayProps
> = (props) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const dispatch = useDispatch();

	const deleteEntry = async (applyToAll: boolean) => {
		setLoading(true);

		const res = await saver.deletePronDictEntry({
			phoneticSpelling: props.entry.phoneticSpelling,
			actualWord: props.entry.actualWord,
			language: props.entry.language,
			applyToAll: applyToAll,
		});

		if (res.ok) {
			const remainingEntries = props.dictionary.filter((entry) =>
				applyToAll
					? entry.actualWord !== props.entry.actualWord
					: !(
							entry.actualWord === props.entry.actualWord &&
							entry.language === props.entry.language
						),
			);
			props.setDictionary(remainingEntries);
		} else {
			toast({
				title: `Could not delete word ${props.entry.actualWord} in ${props.entry.language} language`,
				description: "Some error occured",
				status: "error",
				duration: 6000,
				isClosable: true,
				position: "top",
			});
		}
		setLoading(false);
		dispatch(closeOverlay());
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
			<div style={{ fontSize: "large" }}>
				Are you sure you want to delete this word?
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "10px",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Cutton isLoading={loading} onClick={() => deleteEntry(false)}>
					Delete For This Language
				</Cutton>
				<Cutton isLoading={loading} onClick={() => deleteEntry(true)}>
					Delete For All Languages
				</Cutton>
			</div>
		</div>
	);
};

type NewPronDictEntryOverlayProps = {
	setDictionary: (dictionary: PronDictEntry[]) => void;
	dictionary: PronDictEntry[];
};

export const NewPronDictEntryOverlay: React.FC<NewPronDictEntryOverlayProps> = (
	props,
) => {
	const dispatch = useAppDispatch();

	const [currentLanguage, setCurrentLanguage] = useState<Language>({
		languageId: "en",
		name: "English",
	});
	const [actualWord, setActualWord] = useState("");
	const [phoneticSpelling, setPhoneticSpelling] = useState("");
	const [isAppliedToAll, setIsAppliedToAll] = useState(false);
	const [loading, setLoading] = useState(false);
	const toast = useToast();

	const playWord = async () => {
		if (actualWord === "" || phoneticSpelling === "") return;
		setLoading(true);
		const res = await saver.returnPreviewAudioBytes({
			actualWord,
			phoneticSpelling,
			language: currentLanguage.name,
			applyToAll: false,
		});
		if (res.ok) {
			const preview = new Audio("data:audio/wav;base64," + res.body);
			preview.play();
		}
		setLoading(false);
	};

	const applyToAllLanguagesToggle = () => {
		setIsAppliedToAll(true);
	};

	const addEntry = async () => {
		if (actualWord === "" || phoneticSpelling === "") return;

		setLoading(true);
		const res = await saver.addPronDictEntry({
			actualWord: actualWord.toLowerCase(),
			phoneticSpelling: phoneticSpelling.toLowerCase(),
			language: currentLanguage.name,
			applyToAll: isAppliedToAll,
		});

		if (!res.ok) {
			if (res.error.error === "EntryAlreadyExistsError") {
				toast({
					title: `Cannot Add Word ${actualWord} in ${currentLanguage.name} language`,
					description: "This word already exists in the dictionary",
					status: "error",
					duration: 6000,
					isClosable: true,
					position: "top",
				});
			} else {
				toast({
					title: `Cannot Add Duplicate Entry`,
					description: "Some error occurred",
					status: "error",
					duration: 6000,
					isClosable: true,
					position: "top",
				});
			}
		} else {
			const newEntries: PronDictEntry[] = [];
			if (!isAppliedToAll) {
				newEntries.push({
					phoneticSpelling: phoneticSpelling,
					actualWord: actualWord,
					language: currentLanguage.name,
				});
			} else {
				for (const language of res.body) {
					newEntries.push({
						phoneticSpelling: phoneticSpelling,
						actualWord: actualWord,
						language: language,
					});
				}
			}
			const newDict = [...props.dictionary, ...newEntries];
			props.setDictionary(newDict);
			dispatch(closeOverlay());
		}
		setLoading(false);
	};

	return (
		<div
			style={{
				display: "flex",
				gap: "20px",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<div
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<input
					className={`Searchbar-input`}
					placeholder="Actual Word"
					value={actualWord}
					onChange={(e) => {
						setActualWord(e.target.value);
					}}
				/>
				<input
					className={`Searchbar-input`}
					placeholder="Phonetic Spelling"
					value={phoneticSpelling}
					onChange={(e) => {
						setPhoneticSpelling(e.target.value);
					}}
				/>
			</div>
			<div
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
					<div
						style={{ opacity: isAppliedToAll ? 0.5 : 1 }}
						onClick={() => setIsAppliedToAll(false)}
					>
						<LanguagePicker
							currentLanguage={currentLanguage}
							setCurrentLanguage={setCurrentLanguage}
						/>
					</div>
					<a
						className="apply-to-all-text-pron-dict"
						onClick={applyToAllLanguagesToggle}
					>
						{" "}
						Apply to all languages{" "}
					</a>
				</div>
				{loading ? (
					<Spinner color="#d43f8c" />
				) : (
					<Icon
						onClick={(e) => {
							playWord();
						}}
					>
						{playIcon("#d43f8c")}
					</Icon>
				)}
				<Cutton isLoading={loading} onClick={addEntry}>
					Add Word
				</Cutton>
			</div>
		</div>
	);
};
