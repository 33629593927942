export default (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 208 224"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M152.464 46.4012H19.6128C13.2701 46.4012 8.12842 51.543 8.12842 57.8856V204.197C8.12842 210.539 13.2701 215.681 19.6128 215.681H152.464C158.807 215.681 163.948 210.539 163.948 204.197V57.8856C163.948 51.543 158.807 46.4012 152.464 46.4012Z"
			stroke="black"
			strokeWidth="15"
		/>
		<path
			d="M45.889 30.3691V25.1322C45.7017 20.8776 47.2051 16.7216 50.0711 13.5716C52.9371 10.4216 56.9329 8.53333 61.1862 8.31906H184.574C188.828 8.53333 192.824 10.4216 195.69 13.5716C198.556 16.7216 200.059 20.8776 199.872 25.1322V160.786C200.059 165.04 198.556 169.196 195.69 172.346C192.824 175.496 188.828 177.384 184.574 177.599H179.292"
			stroke="black"
			strokeWidth="15"
		/>
	</svg>
);
