import React, { useState } from "react";
import arrowIcon from "../../../../assets/svgs/screenshot/arrowIcon";
import downArrowheadIcon from "../../../../assets/svgs/downArrowheadIcon";
import "./AdvancedSettings.css";

type AdvancedSettingsProps = {
	children: React.ReactNode;
	label?: string;
	subtle?: boolean;
	lightTheme?: boolean;
};

const AdvancedSettings: React.FC<AdvancedSettingsProps> = (props) => {
	const [showDropdown, setShowDropdown] = useState(false);

	return (
		<div className="AdvancedSettings">
			<div className="AdvancedSettingsLabel">
				<div
					className={`C-label CSlider-label ${props.subtle ? "subtle" : ""} ${props.lightTheme? "lightText":""}`}
				>
					{props.label || "Advanced settings"}
				</div>

				<div
					onClick={() => {
						setShowDropdown(!showDropdown);
					}}
					className={`AdvancedSettings-arrowhead ${showDropdown ? "showDropdown" : "hideDropdown"}`}
				>
					{downArrowheadIcon("white")}
				</div>
			</div>

			<div
				className={`AdvancedSettingsChildren ${showDropdown ? "showDropdown" : "hideDropdown"} `}
			>
				{props.children}
			</div>
		</div>
	);
};
export default AdvancedSettings;
