import orgSetupCanvas from "@gigauser/common/src/core/canvas/orgSetupCanvas";
import { saver } from "@gigauser/common/src/network/saver";
import { getFonts } from "@gigauser/common/src/utils/fontsUtils";
import React, { useEffect, useState } from "react";
import Select from "react-select";

type FontDropdownProps = {
	onChange: (value: any) => void;
	defaultValue: string;
};

const iconColor = "#c2c1c1";

const dropdownOption = (font: string) => ({ value: font, label: font });

const dropdownStyles = {
	container: (provided: any, _: any) => ({
		...provided,
		width: "100%",
	}),
	control: (provided: any, _: any) => ({
		...provided,
		border: "1px solid #303030",
		backgroundColor: "transparent",
		boxShadow: "none",
		color: "white",
		borderRadius: "5px",
		minHeight: "1.6rem",
		fontSize: "0.9rem",
		transition: "0.3s",
		outline: "none",
		width: "100%",
		"&:hover": { backgroundColor: "#3D4361" },
	}),
	option: (provided: any, _: any) => ({
		...provided,
		backgroundColor: "#303030",
		color: "white",
		padding: "0.5rem",
		borderRadius: "5px",
		transition: "0.3s",
		cursor: "pointer",
		fontSize: "0.8rem",
		"&:hover": { backgroundColor: "#3D4361" },
	}),
	singleValue: (provided: any, _: any) => ({
		...provided,
		color: "white",
	}),
	input: (provided: any, _: any) => ({
		...provided,
		color: "white",
		fontSize: "0.9rem",
	}),
	menu: (provided: any, _: any) => ({
		...provided,
		backgroundColor: "#303030",
		color: "white",
	}),
	indicatorSeparator: (provided: any, _: any) => ({
		...provided,
		display: "none",
	}),
	dropdownIndicator: (provided: any, _: any) => ({
		...provided,
		color: iconColor,
		scale: "0.5",
		padding: "0",
		marginRight: "3px",
	}),
};

const FontDropdown: React.FC<FontDropdownProps> = (props) => {
	const [fontFamilyOptions, setFontFamilyOptions] = useState<string[]>([]);

	useEffect(() => {
		const fetchFonts = async () => {
			const fonts = await getFonts();
			setFontFamilyOptions(fonts);
		};

		fetchFonts();
	}, []);

	return (
		<Select
			styles={dropdownStyles}
			options={fontFamilyOptions.map((f) => dropdownOption(f))}
			defaultValue={dropdownOption(props.defaultValue)}
			onChange={props.onChange}
			isSearchable
		/>
	);
};

export default FontDropdown;
