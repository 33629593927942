import { useState } from "react";
import { PronDictTable } from "./ProndictTable";
import "./SettingsPage.css";

export const SettingsPage = () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const settings = [
		{
			name: "Pronunciation Dictionary",
			component: PronDictTable,
		},
	];

	return (
		<>
			<h2
				style={{
					fontSize: "x-large",
					padding: "20px 0px",
				}}
			>
				Change Settings
			</h2>
			<div className="gigauser-settings-page-panel">
				<div className="gigauser-settings-sidebar">
					{settings.map((setting, index) => {
						return (
							<div
								className="gigauser-settings-sidebar-item"
								onClick={() => setActiveIndex(index)}
							>
								<h1
									style={{
										fontSize: "large",
									}}
								>
									{setting.name}
								</h1>
							</div>
						);
					})}
				</div>
				{settings[activeIndex].component !== null
					? settings[activeIndex].component!()
					: null}
			</div>
		</>
	);
};
