import { useEffect, useState } from "react";
import AdminList from "./AdminList";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
import { Id, User, UserInOrg } from "@giga-user-fern/api/types/api";
import { saver } from "@gigauser/common/src/network/saver";
import { useAppDispatch } from "@gigauser/common/src/redux";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import CreateUserOverlay from "./CreateUserOverlay";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { showErrorMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import usersIcon from "@gigauser/common/src/assets/icons/users.svg";

export const AdminPage = () => {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState<User[]>([]);
	const dispatch = useAppDispatch();
	useEffect(() => {
		saver
			.getOrgUsers()
			.then((users) => {
				setUsers(users);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
	}, []);
	if (loading) {
		return <GridLoader center />;
	} else {
		return (
			<>
				<h2
					style={{
						fontSize: "x-large",
						padding: "20px 5px",
					}}
				>
					Manage Creators
				</h2>
				<p>Manage who can create content here</p>

				<AdminList
					//@ts-expect-error
					self={saver.user}
					users={users}
					deleteUser={async (email: string) => {
						const resp = await saver.removeUserFromOrganization({email});
						if (resp) {
							setUsers(users.filter((x) => x.email !== email));
						} else {
							dispatch(showErrorMessagePopup("Couldn't delete user."));
						}
					}}
					createUser={() => {
						dispatch(
							openOverlay({
								heading: {
									title: "Create User",
									icon: <Icon>{<img src={usersIcon}></img>}</Icon>,
								},
								component: (
									<CreateUserOverlay
										complete={async ({ email }) => {
											const x = await saver.addUserToOrganization({
												userEmail: email
											});
											if (x.ok) {
												setUsers(x.body.creators.map((user)=>{return{...user, organizationId: x.body.id}}));
											} else {
												if (x.error.error === "UnauthorizedError") {
													dispatch(
														showErrorMessagePopup(
															"Unauthorized action!"
														),
													);
												} else if (x.error.error === "UserAlreadyInOrganizationError") {
													dispatch(
														showErrorMessagePopup("User already exists."),
													);
												} else if (x.error.error === "LimitExceededError") {
													dispatch(
														showErrorMessagePopup(`Seat limit exceeded! The limit is ${x.error.content.limit}`)
													)
												}
												else {
													dispatch(
														showErrorMessagePopup("Couldn't create user."),
													);
												}
											}
										}}
									/>
								),
							}),
						);
					}}
				/>
			</>
		);
	}
};
