// theme.ts
import { extendTheme, theme as baseTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
	components: {
		Modal: {
			baseStyle: {
				dialog: {
                    // position: 'absolute',
                    // top: '50%',
                    // transform: 'translateY(-50%) !important',          
                    width: 'fit-content',
                    maxW: '100vw', // Ensures the modal doesn't exceed the content width
					bg: "hsl(225, 21%, 7%)", // Change background color
					color: "white", // Change text color
					borderRadius: "md", // Change border radius
					boxShadow: "xl", // Change box shadow
                    // margin: '0',
				},
				header: {
					color: "white", // Change header text color
				},
				closeButton: {
					color: "white", // Change close button color
				},
			},
			sizes: {
				xl: {
					dialog: {
						maxW: "80vw", // Change max width for xl size
					},
				},
			},
			defaultProps: {
				size: "xl", // Set default size
			},
		},
	},
});

export default customTheme;
