import React, { useEffect, useState } from "react";
import "./ExportVideoProgress.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectExportingVideo,
	selectGuidePreview,
	selectVideo,
	selectVideoExport,
} from "@gigauser/common/src/redux/slices/guideSlice";
import tickIconCircled from "@gigauser/common/src/assets/svgs/tickIconCircled";
import closeIcon from "@gigauser/common/src/assets/svgs/closeIcon";
import {
	setExportingGif,
	setGifDownloadLink,
	setShowGifExportPopup,
	setShowVideoExportPopup,
} from "@gigauser/common/src/redux/slices/processingSlice";
import { format } from "path";
import { Spinner, useToast } from "@chakra-ui/react";
import {
	downloadFromCloudFront,
	downloadFromCloudFrontWithProgress,
} from "@gigauser/common/src/utils/os";
import {
	downloadStringAsFile,
	srtToWebVTT,
} from "@gigauser/common/src/components/formats/RichText/utils/exportProcessing";
import { Progress } from "antd";
import { saver } from "@gigauser/common/src/network/saver";

type ExportVideoProgressProps = {
	format: string;
};

const ExportVideoProgress: React.FC<ExportVideoProgressProps> = (props) => {
	/**
	 * @props downloadLink: The link to download the video once the download has completed.
	 */
	const toast = useToast();

	const guidePreview = useAppSelector(selectGuidePreview);
	const video = useAppSelector(selectVideo);
	const videoSrc = video?.originalSrc;

	const dispatch = useAppDispatch();

	const videoExport = useAppSelector(selectVideoExport);

	const exportSrtFile = useAppSelector(
		(state) => state.platformUi.value.exportSrtFile,
	);

	const [downloading, setDownloading] = useState(false);
	const [downloadingProgress, setDownloadingProgress] = useState(0);
	const showVideoExportPopup = useAppSelector(
		(state) => state.processing.value.showVideoExportPopup,
	);

	const onClose = () => {
		if (props.format == "MP4") {
			dispatch(setShowVideoExportPopup({ showPopup: false }));
			// dispatch(setExportingVideo(false))
			// dispatch(setVideoDownloadLink(null))
		} else {
			dispatch(setShowGifExportPopup(false));
			dispatch(setExportingGif(false));
			dispatch(setGifDownloadLink(null));
		}
	};

	useEffect(() => {
		if (showVideoExportPopup.startExport) {
			onClickDownload();
		}
	}, []);

	const exportingVideo = useAppSelector(selectExportingVideo);
	const exportingGif = useAppSelector(
		(state) => state.processing.value.exportingGif,
	);
	const downloadVideoLink = videoExport?.exportedVideoSrc;
	const downloadGifLink = useAppSelector(
		(state) => state.processing.value.gifDownloadLink,
	);

	const showVideoProgress = useAppSelector(
		(state) => state.processing.value.showVideoExportPopup,
	);

	var displace = false;

	var screen: "download-link" | "exporting" = "exporting";

	const downloadLink =
		props.format === "GIF" ? downloadGifLink : downloadVideoLink;

	if (showVideoProgress.showPopup && props.format == "GIF") {
		displace = true;
	}

	if (props.format == "GIF") {
		if (exportingGif) screen = "exporting";
		else screen = "download-link";
	} else if (props.format === "MP4") {
		if (exportingVideo) screen = "exporting";
		else screen = "download-link";
	}

	if (screen == "download-link" && !downloadLink) {
		//export errored out
		toast({
			title: "Export video failed!",
			description: "Please contact support or try again later.",
			status: "error",
			duration: 80000,
			isClosable: true,
			position: "top",
		});

		onClose();
	}

	const onClickDownload = async () => {
		if (!downloadLink) return;

		setDownloading(true);

		await downloadFromCloudFrontWithProgress(
			downloadLink,
			guidePreview?.header.name || "",
			(progress: number) => {
				setDownloadingProgress(progress);
			},
		);
		if (video?.generated?.subtitles && exportSrtFile) {
			if (
				saver.getOrganization()?.id ===
					"6f44c5e0-bb11-4ed6-a421-9875a8534eea" ||
				saver.getOrganization()?.id === "c7c44004-d4fa-429c-80c2-c3092b5acd5a"
			) {
				downloadStringAsFile(
					srtToWebVTT(video?.generated?.subtitles),
					(guidePreview?.header.name || "") + ".vtt",
					"text/plain",
				);
			} else {
				downloadStringAsFile(
					video?.generated?.subtitles,
					(guidePreview?.header.name || "") + ".srt",
					"text/plain",
				);
			}
		}

		setDownloading(false);
	};

	return (
		<div
			className={`ExportVideoProgress 
            ${screen}`}
			style={{
				top: displace ? "12em" : "5em",
			}}
		>
			<div className="closeButton" onClick={onClose}>
				{closeIcon("white")}
			</div>

			<div className={`ExportVideoProgress-VideoPreview ${screen}`}>
				{/* <video crossOrigin="anonymous" className='ExportVideoProgress-VideoPreview-video'  controls={false} src={videoSrc}></video> */}
				{screen == "download-link" ? (
					<div className="export-done-tick">
						{downloading ? (
							<Progress
								size={"small"}
								strokeColor="#d43f8c"
								percent={Math.floor(downloadingProgress * 100 || 0)}
								type="circle"
							/>
						) : (
							<div className="tickicon">{tickIconCircled("#3a9434")}</div>
						)}
					</div>
				) : null}
			</div>

			<div className="Export-text">
				<div className="Export-title">
					<span className="Export-format">
						{screen == "download-link"
							? downloading
								? `Downloading...`
								: `Export complete!`
							: `Exporting video...`}
					</span>
				</div>

				<div className="Export-subtitle">
					{screen == "download-link" && downloadLink ? (
						<>
							<span className="download-link" onClick={onClickDownload}>
								Click here
							</span>
							<span>&nbsp; to download.</span>
						</>
					) : (
						<>This can take a few minutes.</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ExportVideoProgress;
