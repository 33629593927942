import React from "react";
import { ColorPicker } from "antd";
import "./CColor.css";

type CColorProps = {
	label: string;
	value: string;
	setterFunction: (value: string) => void;
	disabled?: boolean;
};

const CColor: React.FC<CColorProps> = (props) => {
	const onToggle = () => {
		if (props.disabled) return;
		props.setterFunction("hoho");
	};

	return (
		<div className="CColor CToggle">
			<div>
				<div className="C-label CToggle-label">{props.label}</div>
			</div>

			<ColorPicker
				className={`${props.value.length > 7 ? "smallen" : "largen"} `}
				defaultValue={props.value}
				showText
				onChange={(value, hex) => {
					props.setterFunction(hex);
				}}
			/>
		</div>
	);
};
export default CColor;
