export const defaultBGMusic = [
	{
		src: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/background-audio/educational.mp3",
		description: "📖     Educational",
	},
	{
		src: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/background-audio/happy.mp3",
		description: "😺    Happy 1",
	},
	{
		src: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/background-audio/morning_beat.mp3",
		description: "🌞    Happy 2 ",
	},
	{
		src: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/background-audio/soft-guitar.mp3",
		description: "🪶    Soft",
	},
	{
		src: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/background-audio/motivational.mp3",
		description: "🔥    Motivational",
	},
	{
		src: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/background-audio/1.mp3",
		description: "🕺    Funky",
	},
	{
		src: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/background-audio/bass.mp3",
		description: "🎤    Bass",
	},
	{
		src: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/background-audio/rock.mp3",
		description: "🤘    Rock",
	},
];
