import React, { useEffect, useState } from "react";
import useEditItem from "@gigauser/common/src/redux/hooks/FileHooks/useEditItem";
import { Item, unravelItem } from "@gigauser/common/src/types/files";

import TitledInput, {
	TitledInputProps,
} from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";

import trash from "@gigauser/common/src/assets/legacy_assets/icons/trash.svg";
import open from "@gigauser/common/src/assets/legacy_assets/icons/open.svg";
import save from "@gigauser/common/src/assets/legacy_assets/icons/tick.svg";
import move from "@gigauser/common/src/assets/legacy_assets/icons/move.svg";

import "./EditItemDetails.css";
import Button from "@gigauser/common/src/ui/buttons/Button/Button";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay/overlaySlice";

import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";

import { Device } from "@gigauser/common/src/types/devices";
import {
	showErrorMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import { editItemFrontend } from "@gigauser/common/src/redux/slices/collectionDataSlice";
import logger from "@gigauser/common/src/utils/logger";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { ValidateCollectionUrlPathResponse } from "@giga-user-fern/api/types/api/resources/collections";
import { Spinner, useToast } from "@chakra-ui/react";
import { convertToUrl } from "@gigauser/common/src/utils/stringOperations";
import AdvancedSettings from "@gigauser/common/src/ui/video/components/AdvancedSettings/AdvancedSettings";

type EditItemDetailsProps = {
	file: Item;
	editDetailsCallback?: (file: Item) => void;
};

const EditItemDetails: React.FC<EditItemDetailsProps> = ({
	file,
	...props
}) => {
	const backendEditItem = useEditItem();
	const dispatch = useAppDispatch();

	const titledInputProps: any & TitledInputProps = {
		styleSize: "xl",
		edges: "sharp",
	};

	const f = unravelItem(file);

	const [fileName, setFileName] = useState<string>(f.name);

	const [description, setDescription] = useState<string>(
		f.description ? f.description : "",
	);

	const fileType = file.type;

	const onFileNameChange = (e: any) => {
		setFileName(e.target.value);
	};

	const onDescriptionChange = (e: any) => {
		setDescription(e.target.value);
	};

	const submitChanges = () => {
		var itemCopy: Item | undefined;

		if (fileType === "Collection") {
			if (urlConflictStatus?.available){

				const collectionCopy = { ...file.entry };
				collectionCopy.name = fileName;
				collectionCopy.description = description;
				collectionCopy.collectionUrlPath = collectionUrlPath
				
				itemCopy = {
					type: "Collection",
					entry: { ...collectionCopy },
				};
			}
			
		} else if (fileType === "Guide") {
			var guideCopy = { ...file.entry };
			guideCopy.header = {
				...guideCopy.header,
				name: fileName,
				description: description,
			};

			logger.debug("guideCopy = ", guideCopy);

			itemCopy = {
				type: "Guide",
				entry: { ...guideCopy },
			};
		}

		if (itemCopy) {
			backendEditItem(itemCopy);

			if (props.editDetailsCallback) {
				props.editDetailsCallback(itemCopy);
			}
		}

		dispatch(closeOverlay());
	};

	const { device } = useWindowSize();
	
	const saver = useAppSelector(selectSaver)
	const [urlConflictStatus, setUrlConflictStatus] = useState<ValidateCollectionUrlPathResponse>({available: false, conflictingCollections:[]})
	const [isUrlValidationLoading, setIsUrlValidationLoading]= useState<boolean>(true)
	const checkUrlConflict = (urlPath: string)=>{
		setIsUrlValidationLoading(true)
		saver.validateCollectionUrlPath({collectionUrlPath:urlPath, currentCollectionId: file.entry.id }).then((body)=>{
			if(body.ok){
				setUrlConflictStatus(body.body)
				setIsUrlValidationLoading(false)
			}
		})
	}

	const [collectionUrlPath, setCollectionUrlPath] = useState<string>(file.type==="Collection" ? file.entry.collectionUrlPath || convertToUrl(fileName) :convertToUrl(fileName))


	useEffect(() => {
		checkUrlConflict(collectionUrlPath);
	}, [collectionUrlPath]);

	return (
		<div className={`EditFileDetails ${device}`}>
			<div className="folder-details-edit ">
				<TitledInput
					{...titledInputProps}
					label="Name"
					background="white"
					id="edit-folder-name"
					value={fileName}
					onChange={onFileNameChange}
				></TitledInput>

				<TitledInput
					{...titledInputProps}
					label="Description (Optional)"
					background="white"
					placeHolder="Something memorable here"
					id="edit-folder-description"
					value={description}
					onChange={onDescriptionChange}
				></TitledInput>
				{
					file.type === "Collection" && <>
					<AdvancedSettings subtle lightTheme>
						<div className="url-path-validator">
					<TitledInput
					id=""
					label="URL Path"
					value={collectionUrlPath}
					onChange={(e)=>{
						setCollectionUrlPath(convertToUrl((e.target as HTMLInputElement).value))
					}}
					placeHolder="How this collection shall be accessed?"
					// position= "left half"
					styleSize="xl"
					display={true}
					background="white"
					edges="sharp"
				></TitledInput>
				{collectionUrlPath === "" ? (
					<span className="collectionUrlNotAvailable">
						Path URL can not be empty string.
					</span>
				) : isUrlValidationLoading ? (
					<Spinner />
				) : urlConflictStatus?.available ? (
					<span className="collectionUrlAvailable">
						URL is available to use for this collection.
					</span>
				) : (
					<span className="collectionUrlNotAvailable">
						This URL is conflicting with {urlConflictStatus?.conflictingCollections ? urlConflictStatus.conflictingCollections[0].name:"an existing collection" }  in your workspace.
					</span>
				)}
				</div>
				</AdvancedSettings>
					</>
				}
				
			</div>

			

			<div className="buttons-row">
				<Button
					icon={save}
					rank="primary"
					fill="solid"
					edges="round"
					onClick={submitChanges}
					isDisabled={fileType === "Collection" && !urlConflictStatus?.available}
				>
					Save
				</Button>
			</div>
		</div>
	);
};
export default EditItemDetails;
