import React from "react";
import { useEffect } from "react";
import Button from "@gigauser/common/src/ui/buttons/Button/Button";
import plus from "@gigauser/common/src/assets/legacy_assets/icons/plus.svg";

import new_collection from "@gigauser/common/src/assets/svgs/new_collection";

import new_folder from "@gigauser/common/src/assets/legacy_assets/icons/new_folder.svg";
import notebook_icon from "@gigauser/common/src/assets/legacy_assets/icons/notebook.svg";
import folder from "@gigauser/common/src/assets/legacy_assets/icons/folder.svg";
import {
	store,
	useAppDispatch,
	useAppSelector,
} from "@gigauser/common/src/redux";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import { DisplayMenuItem } from "@gigauser/common/src/ui/menus/Menu/Menu";

import { selectCollectionPath } from "@gigauser/common/src/redux/slices/collectionPathSlice";
import { Collection } from "@gigauser/common/src/core/types/collections";
import CreateCollection from "./CreateCollection/CreateCollection";
import logger from "@gigauser/common/src/utils/logger";
import { saver } from "@gigauser/common/src/network/saver";
import { rootCollection } from "@gigauser/common/src/types/files";
import NewGuideOverlay from "../NewGuideOverlay/NewGuideOverlay";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";

type NewButtonProps = {
	openCreateFolderOverlay: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const NewButton: React.FC<NewButtonProps> = () => {
	const dispatch = useAppDispatch();
	const collectionPath = useAppSelector(selectCollectionPath);
	const currentFolder = collectionPath.segments.at(-1) as Collection;

	const openCreateFolderOverlay = (event: any) => {
		event?.preventDefault();
		logger.debug("Need to open overlay");
		dispatch(
			openOverlay({
				heading: {
					icon: folder,
					title: "Create Collection",
					data: "",
				},
				component: <CreateCollection />,
			}),
		);
	};

	const openCreateGuideOverlay = (event: any) => {
		dispatch(
			openOverlay({
				heading: {
					icon: plusIcon(),
					title: "Create a new project",
					data: "",
				},
				component: <NewGuideOverlay />,
			}),
		);
	};

	const newItemMenu: DisplayMenuItem[] = [
		{
			icon: plusIcon(),
			label: "New Project",
			// handler: async () =>{
			//     const newGuide = await saver.createEmptyTextGuide(currentFolder.id )
			//     if (newGuide){
			//         window.open('/guide/' + newGuide, "_blank")
			//     }
			// },
			handler: openCreateGuideOverlay,
			loading: true,
		},
		{
			icon: new_collection,
			label: "New Folder",
			handler: openCreateFolderOverlay,
		},
	];

	return (
		<Button
			icon={plus}
			edges="sharp"
			rank="secondary"
			fill="solid"
			onClick={() => {}}
			dropdown={newItemMenu}
		>
			New
		</Button>
	);
};
export default NewButton;
