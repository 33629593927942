import React from "react";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { SearchResult } from "../SearchBox";
import SearchResultElement from "./SearchResultElement/SearchResultElement";
import "./SearchResults.css";

type SearchResultsProps = {
	searchResults: SearchResult[];
	searchInProgress: boolean;
	loaded: boolean;
};

const SearchResults: React.FC<SearchResultsProps> = ({
	searchResults,
	searchInProgress,
	loaded,
}) => {
	const { device } = useWindowSize();

	return (
		<div className={`searchResults-container ${device}`}>
			<div className={`searchResults-topbar ${device}`}>
				{searchResults.length ? (
					<div className="filled-topbar">
						<div className="type">Type</div>
						<div className="name">Name</div>
						<div className="location">Description</div>
					</div>
				) : null}
			</div>

			<div className="searchResults">
				{searchResults.length ? (
					<div className="searchResults-box">
						<div className="searchResults-content">
							{searchResults.map((searchResult: SearchResult) => (
								<SearchResultElement
									searchResult={searchResult}
								></SearchResultElement>
							))}
						</div>

						{/* <div className='no-more-results-text'>That's all we could find!</div> */}
					</div>
				) : (
					<div className="emptySearchResults">
						{loaded
							? "Type something to begin a search ..."
							: "Initializing search ..."}
					</div>
				)}
			</div>
		</div>
	);
};
export default SearchResults;
