// cModal.tsx
import React from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { closeModal } from "./modalSlice";
import "./CModal.css";
import Icon from "../../ui/Icon/Icon";

const CModal: React.FC = () => {
	const dispatch = useAppDispatch();
	const { isOpen, content } = useAppSelector((state) => state.modal);

	return (
		<div className="CModal">
			<Modal
				isCentered
				isOpen={isOpen}
				onClose={() => dispatch(closeModal())}
				motionPreset="slideInBottom"
			>
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
				<ModalContent>
					<ModalHeader>
						<div className="modal-title">
							<Icon className="modal-icon">{content?.heading?.icon}</Icon>
							{content?.heading?.title}
						</div>
					</ModalHeader>
					<hr />
					<ModalCloseButton />
					<ModalBody>{content?.component}</ModalBody>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default CModal;
