import { ConfigProvider, Select } from "antd";
import React from "react";
import downArrowheadIcon from "../../assets/svgs/downArrowheadIcon";

type CSelectProps = {
	onChange: (value: any) => void;
	mode?: "multiple" | "single";
	value: any;
	optionRender?: (value: any) => React.ReactNode;
	placeholder?: string;
	allowClear?: boolean;
	options: {
		value: unknown;
		label: unknown;
	}[];
};

const CSelect: React.FC<CSelectProps> = (props) => {
	const mode = props.mode
		? props.mode === "single"
			? undefined
			: "multiple"
		: undefined;

	return (
		<ConfigProvider
			theme={{
				components: {
					Select: {
						colorPrimary: "hsl(230, 20%, 35%)",
						colorPrimaryHover: "hsl(230, 20%, 35%)",
						selectorBg: "rgb(13,17,23)",
						colorBorder: "hsl(226, 16%, 13%)",
						optionSelectedBg: "hsl(226, 16%, 13%)",
						colorBgElevated: "rgb(13,17,23)",
						borderRadius: 6,
						zIndexPopup: 99999,
					},
				},
			}}
		>
			<Select
				mode={mode}
				value={props.value}
				options={props.options}
				placeholder={props.placeholder}
				onChange={props.onChange}
				style={{
					width: "100%",
				}}
				optionRender={props.optionRender}
				allowClear={props.allowClear}
				suffixIcon={downArrowheadIcon("white")}
			/>
		</ConfigProvider>
	);
};

export default CSelect;
