const elementsIcon = (color = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 105 101"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M79.6277 1L54.2555 47.1402H105L79.6277 1ZM79.6277 18.0982L91.0364 38.854H68.2191L79.6277 18.0982ZM24.6813 51.2832C11.0696 51.2832 0 62.4322 0 76.1416C0 89.851 11.0696 101 24.6813 101C38.293 101 49.3626 89.851 49.3626 76.1416C49.3626 62.4322 38.293 51.2832 24.6813 51.2832ZM57.5897 55.4263V101H102.839V55.4263H57.5897ZM24.6813 59.5693C33.7558 59.5693 41.1355 67.002 41.1355 76.1416C41.1355 85.2812 33.7558 92.7139 24.6813 92.7139C15.6068 92.7139 8.2271 85.2812 8.2271 76.1416C8.2271 67.002 15.6068 59.5693 24.6813 59.5693ZM65.8168 63.7124H94.6117V92.7139H65.8168V63.7124Z"
				fill={color}
			/>
			<path
				d="M24.5 4V44M15.7143 44H33.2857M45 10.6667V4H4V10.6667"
				stroke={color}
				stroke-width="7"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
export default elementsIcon;
