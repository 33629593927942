const readIcon = (color?: string) => {
	const fill = color || "#000000";

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 28 28"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.0865 0.372769C10.3275 0.134094 10.6544 0 10.9952 0H21.2754C23.4045 0 25.1304 1.70946 25.1304 3.81818V24.1818C25.1304 26.2906 23.4045 28 21.2754 28H5.85507C3.72598 28 2 26.2906 2 24.1818V8.90909C2 8.57154 2.13539 8.24782 2.37637 8.00913L10.0865 0.372769ZM21.2754 2.54545H12.2802V8.90909C12.2802 9.61199 11.7049 10.1818 10.9952 10.1818H4.57005V24.1818C4.57005 24.8847 5.14538 25.4545 5.85507 25.4545H21.2754C21.9851 25.4545 22.5604 24.8847 22.5604 24.1818V3.81818C22.5604 3.11528 21.9851 2.54545 21.2754 2.54545ZM6.38734 7.63636H9.71014V4.34536L6.38734 7.63636ZM7.1401 15.2727C7.1401 14.5698 7.71543 14 8.42512 14H18.7053C19.415 14 19.9903 14.5698 19.9903 15.2727C19.9903 15.9757 19.415 16.5455 18.7053 16.5455H8.42512C7.71543 16.5455 7.1401 15.9757 7.1401 15.2727ZM7.1401 20.3636C7.1401 19.6607 7.71543 19.0909 8.42512 19.0909H18.7053C19.415 19.0909 19.9903 19.6607 19.9903 20.3636C19.9903 21.0666 19.415 21.6364 18.7053 21.6364H8.42512C7.71543 21.6364 7.1401 21.0666 7.1401 20.3636Z"
				fill={fill}
			/>
		</svg>
	);
};

export default readIcon;
