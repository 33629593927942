const trashIcon = (color: string = "black") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M19.5 6H4.5C4.086 6 3.75 5.66475 3.75 5.25C3.75 4.836 4.086 4.5 4.5 4.5H19.5C19.914 4.5 20.25 4.836 20.25 5.25C20.25 5.66475 19.914 6 19.5 6ZM18.75 21C18.75 21.828 18.078 22.5 17.25 22.5H6.75C5.922 22.5 5.25 21.828 5.25 21V7.5H18.75V21ZM9.75 2.25C9.75 1.83525 10.086 1.5 10.5 1.5H13.5C13.914 1.5 14.25 1.83525 14.25 2.25V3H9.75V2.25ZM20.25 3H15.75V1.5C15.75 0.672 15.078 0 14.25 0H9.75C8.922 0 8.25 0.672 8.25 1.5V3H3.75C2.922 3 2.25 3.672 2.25 4.5V6C2.25 6.828 2.922 7.5 3.75 7.5V21C3.75 22.6567 5.09325 24 6.75 24H17.25C18.9067 24 20.25 22.6567 20.25 21V7.5C21.078 7.5 21.75 6.828 21.75 6V4.5C21.75 3.672 21.078 3 20.25 3ZM12 21C12.414 21 12.75 20.6647 12.75 20.25V11.25C12.75 10.836 12.414 10.5 12 10.5C11.586 10.5 11.25 10.836 11.25 11.25V20.25C11.25 20.6647 11.586 21 12 21ZM8.25 21C8.664 21 9 20.6647 9 20.25V11.25C9 10.836 8.664 10.5 8.25 10.5C7.836 10.5 7.5 10.836 7.5 11.25V20.25C7.5 20.6647 7.836 21 8.25 21ZM15.75 21C16.164 21 16.5 20.6647 16.5 20.25V11.25C16.5 10.836 16.164 10.5 15.75 10.5C15.336 10.5 15 10.836 15 11.25V20.25C15 20.6647 15.336 21 15.75 21Z"
				fill={color}
			/>
		</svg>
	);
};

export default trashIcon;
