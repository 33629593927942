import React from "react";

import "./GuidesTable.css";
import { Guide, GuidePreview } from "../../../core/types/guide";
import Icon from "../../../ui/Icon/Icon";
import move_dots from "../../../assets/svgs/move_dots";
import { Collection } from "../../../core/types/collections";
import { Item } from "../../../types/files";
import GuidesTableRow from "./GuidesTableRow";
import logger from "../../../utils/logger";
import GuidesTableGap from "./GuidesTableGap/GuidesTableGap";

export type GuidesTableProps =
	| {
			type: "Guide";
			entries: GuidePreview[];
			onClick: (g: GuidePreview) => void;
			onDelete: (g: GuidePreview) => void;
			showFileContextMenu: (file: Item, X: number, Y: number) => void;
			onRestore?: (g: GuidePreview) => void;
	  }
	| {
			type: "Collection";
			entries: Collection[];
			onClick: (c: Collection) => void;
			onDelete: (c: Collection) => void;
			showFileContextMenu: (file: Item, X: number, Y: number) => void;
	  };

const GuidesTable: React.FC<GuidesTableProps> = (props) => {
	return (
		<div
			className="gigauser-guides-table gigauser-guides-table-container"
			id="gigauser-guides-table"
		>
			<div className="gigauser-table-header">
				<div className="gigauser-table-th gigauser-table-th-move">.</div>
				<div className="gigauser-table-th gigauser-table-th-name">
					{props.type == "Guide" ? "Project" : "Folder"} Name
				</div>
				<div className="gigauser-table-th gigauser-table-th-id">ID</div>
				<div className="gigauser-table-th gigauser-table-th-status">Status</div>
				<div className="gigauser-table-th gigauser-table-th-delete">.</div>
			</div>

			{/* <div> */}
			<GuidesTableGap type={props.type} />

			{props.entries.map((entry, index) => {
				const propsEntry: Item =
					props.type === "Collection"
						? {
								type: "Collection",
								entry: entry as Collection,
							}
						: {
								type: "Guide",
								entry: entry as GuidePreview,
							};

				return (
					<div key={entry.id}>
						<GuidesTableRow
							index={index}
							entry={propsEntry}
							onClick={props.onClick}
							onDelete={props.onDelete}
							showFileContextMenu={props.showFileContextMenu}
							onRestore={props.type === "Guide" ? props.onRestore:undefined}
						/>
						<GuidesTableGap type={props.type} preceedingItem={propsEntry} />
					</div>
				);
			})}

			{props.entries.length == 0 ? (
				<div className="emptytable-guidestable-message">
					No {props.type === "Collection" ? "collections" : "guides"} found
					here.
				</div>
			) : null}
			{/* </div> */}
		</div>
	);
};
export default GuidesTable;
