import React from "react";
import new_folder from "@gigauser/common/src/assets/legacy_assets/icons/new_folder.svg";
import upload_file from "@gigauser/common/src/assets/legacy_assets/icons/upload_file.svg";
import notebook_icon from "@gigauser/common/src/assets/legacy_assets/icons/notebook.svg";
import "./NewButtonOverlay.css";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import CreateCollection from "../../laptop/CreateCollection/CreateCollection";
import folder from "@gigauser/common/src/assets/legacy_assets/icons/folder.svg";
import { selectCollectionPath } from "@gigauser/common/src/redux/slices/collectionPathSlice";
import { Collection } from "@gigauser/common/src/core/types/collections";
import logger from "@gigauser/common/src/utils/logger";

type NewButtonOverlayProps = {};

type IconButton = {
	icon: string;
	iconLabel: string;
	handler: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const NewButtonOverlay: React.FC<NewButtonOverlayProps> = () => {
	const dispatch = useAppDispatch();
	const collectionPath = useAppSelector(selectCollectionPath);
	const currentFolder = collectionPath.segments.at(-1) as Collection;

	const openCreateFolderOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
		event?.preventDefault();
		logger.debug("Need to open overlay");
		dispatch(
			openOverlay({
				heading: {
					title: "Create Collection",
					icon: folder,
					data: "",
				},
				component: <CreateCollection />,
			}),
		);
	};

	const IconButtons: IconButton[] = [
		{
			icon: new_folder,
			iconLabel: "New Folder",
			handler: openCreateFolderOverlay,
		},
		{ icon: notebook_icon, iconLabel: "New Notebook", handler: () => {} },
	];

	return (
		<div className="newButtonOverlay-container">
			{IconButtons.map((Iconbutton) => (
				<div className="IconButton" onClick={Iconbutton.handler}>
					<div className="IconButton-icon-wrapper">
						<img className="IconButton-icon" src={Iconbutton.icon} alt="" />
					</div>
					<div>{Iconbutton.iconLabel}</div>
				</div>
			))}
		</div>
	);
};
export default NewButtonOverlay;
