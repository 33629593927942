const moveFolderIcon = (color: string = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 105 105"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.75 83.125V35H96.25V67.6637L100.625 72.0825V17.5H53.375L48.125 8.75H17.5L12.25 17.5H4.375V87.5H43.75V83.125H8.75ZM8.75 21.875H14.7262L19.9762 13.125H45.6488L50.8987 21.875H96.25V30.625H8.75V21.875ZM101.421 85.3125L84.6738 102.169L81.5719 99.0806L93.1525 87.4212H52.5V83.0462H92.9994L81.5762 71.54L84.6781 68.46L101.421 85.3125Z"
				fill={color}
				stroke={color}
			/>
		</svg>
	);
};

export default moveFolderIcon;
