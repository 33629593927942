import {
	selectAiEnhancingArticle,
	selectAiEnhancingVideo,
	selectAutoUpdatingGuide,
	selectExportingVideo,
	selectLoadingGeneratedVideo,
	selectPublishingGuide,
	selectTrimmingVideo,
} from "../../slices/guideSlice";
import { useAppSelector } from "../../useAppRedux";

const useBackendBusy: (ignoreSave?: boolean) => string | undefined = (
	ignoreSave = false,
) => {
	const isPublishing = useAppSelector(selectPublishingGuide);
	const isGeneratingVideo = useAppSelector(selectLoadingGeneratedVideo);
	const isExportingVideo = useAppSelector(selectExportingVideo);
	const isAiEnhancingArticle = useAppSelector(selectAiEnhancingArticle);
	const isAiEnhancingVideo = useAppSelector(selectAiEnhancingVideo);
	const isTrimmingVideo = useAppSelector(selectTrimmingVideo);
	const autoUpdatingGuide = useAppSelector(selectAutoUpdatingGuide);
	const saveInProgress = useAppSelector(
		(state) => state.processing.value.saveInProgress,
	);

	var operation: string | undefined = undefined;

	if (isPublishing) operation = "A publish job";
	else if (isGeneratingVideo) operation = "A speech generation";
	else if (isExportingVideo) operation = "A video export job";
	else if (isAiEnhancingArticle) operation = "An AI rewrite on article";
	else if (isAiEnhancingVideo) operation = "An AI rewrite on video";
	else if (isTrimmingVideo) operation = "A trim job";
	else if (saveInProgress) {
		if (!ignoreSave) {
			operation = "A save";
		}
	} else if (autoUpdatingGuide) operation = "An update";

	return operation;
};

export default useBackendBusy;
