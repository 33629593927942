import React, { useContext, useEffect, useRef } from "react";
import "./VideoPlayer.css";
import { useAppSelector } from "@gigauser/common/src/redux";

import circledPauseIcon from "@gigauser/common/src/assets/svgs/videoeditor/circledPauseIcon";
import circledPlayIcon from "@gigauser/common/src/assets/svgs/videoeditor/circledPlayIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { useAppDispatch } from "@gigauser/common/src/redux/useAppRedux";
import {
	selectVideo,
	selectLoadingGeneratedVideo,
	selectClips,
	selectTrimmingVideo,
	undoVideoEdits,
	redoVideoEdits,
	addVideoEditHistory,
} from "@gigauser/common/src/redux/slices/guideSlice";

import ZoomSlider from "@gigauser/common/src/ui/sliders/ZoomSlider/ZoomSlider";
import CanvasPlayer from "@gigauser/common/src/core/canvas/CanvasPlayer/CanvasPlayer";

import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import scissorsIcon from "@gigauser/common/src/assets/svgs/videoeditor/scissorsIcon";

import cropIcon from "@gigauser/common/src/assets/svgs/videoeditor/cropIcon";

import {
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Spinner,
} from "@chakra-ui/react";
import CropVideo from "../CropVideo/CropVideo";
import LoadingTip from "../LoadingTip/LoadingTip";
import { useGeneratedTimestamp } from "@gigauser/common/src/redux/hooks/FileHooks/useTimestamp";
import { selectOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import {
	usePlayPauseListener,
	useSeekVideoListener,
	useUndoRedoListener,
} from "../../../../../utils/listenerUtils";
import { openFullScreenOverlay } from "@gigauser/common/src/layouts/FullScreenOverlay";
import TrimStich from "../../../components/TrimStich/TrimStich";
import FormattedPlayTime from "@gigauser/common/src/ui/video/components/FormattedPlayTime/FormattedPlayTime";
import { selectFullScreenOverlay } from "@gigauser/common/src/layouts/FullScreenOverlay/FullScreenOverlaySlice";
import undoIcon from "@gigauser/common/src/assets/svgs/undoIcon";
import redoIcon from "@gigauser/common/src/assets/svgs/redoIcon";

type VideoPlayerProps = {
	onGenerate?: () => void;
	AiMode: boolean;
	setAiMode: (bool: boolean) => void;

	timelineSliderValue: number;
	setTimelineSliderValue: (number: number) => void;
};

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
	const { AiMode } = props;
	const dispatch = useAppDispatch();
	const video = useAppSelector(selectVideo);
	const isOverlayPresent = useAppSelector(selectOverlay).overlay;
	const isFullScreenOverlayPresent = useAppSelector(selectFullScreenOverlay);
	const isModalPresent = useAppSelector((state) => state.modal.isOpen);
	const generatingVideo = useAppSelector(selectLoadingGeneratedVideo);
	const trimmingVideo = useAppSelector(selectTrimmingVideo);

	const generatedVideo = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.generatedSrc,
	);

	const debounceHistoryTimer = useRef<any>(null);
	const videoEditsHistory = useAppSelector(
		(state) => state.guide.value.history,
	);
	const cp = useContext(CanvasPlayerContext);
	const videoEdits = cp.videoEdits;
	const undoRedoChange = useRef(false);

	var clips = useAppSelector(selectClips);
	const convertGeneratedToOriginal = useGeneratedTimestamp(() => {});
	const isGenerating = generatingVideo && AiMode;
	const isTrimming = trimmingVideo;

	const isConstructing = isGenerating || isTrimming;

	if (!video || !cp.vidRef)
		throw new Error("Somehow video missing in Video Player!");

	const handleSpacePress = usePlayPauseListener();
	const handleArrowKeys = useSeekVideoListener();
	const undoRedoShortcut = useUndoRedoListener(
		() => {
			undoRedoChange.current = true;
			dispatch(undoVideoEdits());
		},
		() => {
			undoRedoChange.current = true;
			dispatch(redoVideoEdits());
		},
	);

	useEffect(() => {
		if (
			isOverlayPresent ||
			isFullScreenOverlayPresent ||
			generatingVideo ||
			isModalPresent
		) {
			cp.pause();
			document.removeEventListener("keydown", handleSpacePress);
			document.removeEventListener("keydown", handleArrowKeys);
			document.removeEventListener("keydown", undoRedoShortcut);
			return;
		}

		document.addEventListener("keydown", handleSpacePress);
		document.addEventListener("keydown", handleArrowKeys);
		document.addEventListener("keydown", undoRedoShortcut);
		return () => {
			document.removeEventListener("keydown", handleSpacePress);
			document.removeEventListener("keydown", handleArrowKeys);
			document.removeEventListener("keydown", undoRedoShortcut);
		};
	}, [
		handleSpacePress,
		handleArrowKeys,
		undoRedoShortcut,
		isOverlayPresent,
		isFullScreenOverlayPresent,
		isConstructing,
		isModalPresent,
	]);

	useEffect(() => {
		if (cp.videoEdits) {
			autoAddVideoEditsToHistory();
		}
	}, [cp.videoEdits]);

	const addVideoEditsToHistory = () => {
		const newVideoEdits = JSON.stringify(videoEdits);
		const latestHistoryVideoEdits = JSON.stringify(
			videoEditsHistory.videoEdits.slice(-1)[0],
		);
		if (newVideoEdits !== latestHistoryVideoEdits && videoEdits) {
			dispatch(addVideoEditHistory(videoEdits));
		}
	};

	const autoAddVideoEditsToHistory = () => {
		if (debounceHistoryTimer.current !== undefined) {
			clearTimeout(debounceHistoryTimer.current);
		}
		if (!undoRedoChange.current) {
			debounceHistoryTimer.current = setTimeout(addVideoEditsToHistory, 500);
		}
		undoRedoChange.current = false;
	};

	const openTrimPanel = () => {
		cp.pause();
		let currentOriginalTime = cp.getUnadjustedTime(cp?.currentTime);
		if (convertGeneratedToOriginal) {
			currentOriginalTime =
				convertGeneratedToOriginal(currentOriginalTime) ?? 0;
		} else {
			currentOriginalTime = 0;
		}

		dispatch(
			openFullScreenOverlay({
				component: <TrimStich />,
			}),
		);

		// dispatch(openOverlay({
		//     heading: {
		//         icon: scissorsIcon("white"),
		//         title: "Trim Video",
		//     },

		//     component: <TrimVideo currentOriginalTime={currentOriginalTime}/>

		// }))
	};

	const openCropPanel = () => {
		cp.pause();
		dispatch(
			openOverlay({
				heading: {
					icon: cropIcon("white"),
					title: "Crop Video",
				},
				component: (
					<CropVideo currentTime={cp.getUnadjustedTime(cp.currentTime)} />
				),
			}),
		);
	};

	return (
		<div className="VideoPlayer">
			<div
				className={`Ai-Video-container`}
				style={{
					display: isConstructing ? "none" : "flex",
				}}
			>
				{cp.loading ? (
					<div className="Spinner-Video">
						<Spinner size={"xl"} color="#d43f8c" />
					</div>
				) : (
					<CanvasPlayer />
				)}
			</div>

			<div
				className="WizardLoader-container"
				style={{ display: isConstructing ? "flex" : "none" }}
			>
				{isConstructing && (
					<LoadingTip
						message={`${isGenerating ? "Generating" : "Trimming"} your video ..`}
					/>
				)}

				{/* <WizardLoader />
                <h2>Sit tight!</h2>
                <p className="Wizard-caption">
                    {" "}
                    A magic wizard is creating an amazing new video for you.
                </p> */}
			</div>

			<div
				className="VideoPlayer-controls"
				style={{
					opacity: isConstructing || cp.loading ? 0.5 : 1,
				}}
			>
				<div className="VideoPlayer-controls-container">
					<div className="VideoPlayer-option-buttons">
						<Icon
							hoverTip={"Trim & stitch"}
							hoverPosition="top"
							className={`VideoPlayer-icon right-video-icon`}
							onClick={isConstructing ? undefined : openTrimPanel}
						>
							{scissorsIcon("#c2c1c1")}
						</Icon>

						<Icon
							hoverTip={"Crop"}
							hoverPosition="top"
							className={`VideoPlayer-icon right-video-icon`}
							onClick={isConstructing ? undefined : openCropPanel}
						>
							{cropIcon("#c2c1c1")}
						</Icon>

						<div
							className="VideoPlayer-slider"
							style={{
								display: "none",
								// display: cp.videoDuration > 30?"block":"none"
							}}
						>
							<Slider
								focusThumbOnChange={false}
								value={props.timelineSliderValue}
								onChange={props.setTimelineSliderValue}
								className="VPSlider-Slider"
								min={0}
								max={100}
								step={0.05}
							>
								<SliderTrack className="VPSlider-track">
									<SliderFilledTrack className="VPSlider-filledtrack" />
								</SliderTrack>
								<SliderThumb
									className="VPSlider-thumb"
									fontSize="sm"
									boxSize="12px"
								/>
							</Slider>
						</div>
					</div>

					<div className="VideoPlayer-play-buttons">
						<Icon
							hoverTip={!cp.paused ? "Pause" : "Play"}
							hoverPosition="top"
							className={`VideoPlayer-icon play-icon ${
								!cp.pausedRef ? "pause-icon" : ""
							}`}
							onClick={() => {
								if (cp.paused && !generatingVideo) {
									cp.play();
								} else {
									cp.pause();
								}
							}}
						>
							{!cp.paused
								? circledPauseIcon("#bfc7d4")
								: circledPlayIcon("#bfc7d4")}
						</Icon>
						{/* <Icon
                        hoverTip="Next frame"
                        hoverPosition="top"
                        className="VideoPlayer-icon frame-icon forward-icon"
                        onClick={incrementOneFrame}
                    >
                        {incrementFrameIcon("#9094A5")}
                    </Icon> */}

						<FormattedPlayTime
							currentTime={cp.currentTime}
							totalTime={cp.getVideoDuration()}
						/>
					</div>

					<div className="VideoPlayer-slider-container">
						<div className="VideoPlayer-UndoRedo">
							<div className="undo">
								<Icon
									hoverTip="Undo"
									hoverPosition="top"
									className={`VideoPlayer-icon right-video-icon`}
									disabled={
										videoEditsHistory.currentIndex ===
											videoEditsHistory.videoEdits.length ||
										videoEditsHistory.videoEdits.length <= 1
									}
									onClick={() => {
										if (
											videoEditsHistory.currentIndex ===
												videoEditsHistory.videoEdits.length ||
											videoEditsHistory.videoEdits.length <= 1
										) {
											return;
										}
										undoRedoChange.current = true;
										dispatch(undoVideoEdits());
									}}
								>
									{undoIcon("white")}
								</Icon>
							</div>

							<div className="redo">
								<Icon
									hoverTip="Redo"
									hoverPosition="top"
									className={`VideoPlayer-icon right-video-icon`}
									disabled={
										videoEditsHistory.currentIndex === 1 ||
										videoEditsHistory.videoEdits.length <= 1
									}
									onClick={() => {
										if (
											videoEditsHistory.currentIndex === 1 ||
											videoEditsHistory.videoEdits.length <= 1
										) {
											return;
										}
										undoRedoChange.current = true;
										dispatch(redoVideoEdits());
									}}
								>
									{redoIcon("white")}
								</Icon>
							</div>
						</div>

						<ZoomSlider
							setTimelineSliderValue={props.setTimelineSliderValue}
							timelineSliderValue={props.timelineSliderValue}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default VideoPlayer;
