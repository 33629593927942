import React, { useContext, useEffect, useRef, useState } from "react";
import "./VideoEditorPlatform.css";
import TranscriptEditor from "../components/TranscriptEditor/TranscriptEditor";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import TimelineContainer from "../components/Timeline/main/TimelineContainer";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectLoadingGeneratedVideo,
	setLoadingGeneratedVideo,
	selectVideo,
} from "@gigauser/common/src/redux/slices/guideSlice";
import logger from "@gigauser/common/src/utils/logger";
import useFetchGuidePreviewAndCheck from "@gigauser/common/src/redux/hooks/FileHooks/useFetchGuidePreviewAndCheck";

import Icon from "@gigauser/common/src/ui/Icon/Icon";

import introIcon from "@gigauser/common/src/assets/svgs/videoeditor/introIcon";
import voiceoverIcon from "@gigauser/common/src/assets/svgs/videoeditor/voiceoverIcon";

import backgroundIcon from "@gigauser/common/src/assets/svgs/videoeditor/backgroundIcon";
import outroIcon from "@gigauser/common/src/assets/svgs/videoeditor/outroIcon";
import zoomIcon from "@gigauser/common/src/assets/svgs/videoeditor/zoomIcon";
import elementsIcon2 from "@gigauser/common/src/assets/svgs/videoeditor/elementsIcon2";

import { Tooltip, useToast } from "@chakra-ui/react";
import VideoCustomizer from "../components/VideoCustomizer/VideoCustomizer";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import {
	VideoCustomizerPage,
	setActiveElement,
	setActiveZoom,
	setCustomizerPage,
	setHardRefreshTranscript,
} from "@gigauser/common/src/redux/slices/platformUiSlice";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import SlidesTimeline from "../components/Timeline/components/SlidesTimeline/SlidesTimeline";
import ZoomTimeline from "../components/Timeline/components/ZoomTimeline/ZoomTimeline";

import useSaveGuide from "@gigauser/common/src/redux/hooks/FileHooks/useSaveGuide";
import useShowTrimmingVideo from "@gigauser/common/src/redux/hooks/FileHooks/useShowTrimmingVideo";
import musicIcon from "@gigauser/common/src/assets/svgs/videoeditor/musicIcon";
import ShapesTimeline from "../components/Timeline/components/ShapesTimeline/ShapesTimeline";
import useBackendBusy from "@gigauser/common/src/redux/hooks/FileHooks/useBackendBusy";


type VideoEditorPlatformProps = {
	setAiMode: (boolean: boolean) => void;
	AiMode: boolean;
};

const VideoEditorPlatform: React.FC<VideoEditorPlatformProps> = (props) => {
	const { AiMode } = props;

	//setup stuff
	const cp = useContext(CanvasPlayerContext);
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	useShowTrimmingVideo();
	//gen guide stuff
	const id = useAppSelector((state) => state.guide.value.guide?.id);
	const isGeneratingVideoInit = useAppSelector(selectLoadingGeneratedVideo);
	const [isGeneratingVideo, setIsGeneratingVideo] = useState(
		isGeneratingVideoInit || false,
	);
	const saveGuide = useSaveGuide();
	const video = useAppSelector(selectVideo);
	const isAiVideoPossible = true;

	const hardRefreshTranscript = useAppSelector(
		(state) => state.platformUi.value.hardRefreshTranscript,
	);
	const customizerPage = useAppSelector(
		(state) => state.platformUi.value.customizerPage,
	);
	const activeElement = useAppSelector(
		(state) => state.platformUi.value.activeElement,
	);
	const [timelineSliderValue, setTimelineSliderValue] = useState(50);
	const isBackendBusy = useBackendBusy(true);
	const toast = useToast();
	const saveInProgress = useAppSelector(
		(state) => state.processing.value.saveInProgress,
	);
	const pendingGenerateRef = useRef(false);
	//state variables
	// var clips = useAppSelector(selectClips) || [{startTime: 0, endTime: 0}]

	const fetchGuidePreviewAndCheck = useFetchGuidePreviewAndCheck();

	useEffect(() => {
		if (pendingGenerateRef.current && !saveInProgress) {
			pendingGenerateRef.current = false;
			generateFromPlainDoc();
		}
	}, [saveInProgress]);

	const generateFromPlainDoc = async () => {
		if (isBackendBusy) {
			toast({
				title: `${isBackendBusy} is in progress!`,
				description: "Please wait for it to finish and then try again.",
				status: "warning",
				duration: 4000,
				isClosable: true,
				position: "top",
			});
			return false;
		}

		setIsGeneratingVideo(true);
		if (saveInProgress) {
			//save is in progress.
			pendingGenerateRef.current = true;
			return;
		}

		const resp = await saveGuide(true);
		pendingGenerateRef.current = false;

		if (resp && id) {
			dispatch(setLoadingGeneratedVideo(true));
			await saver.generateVoiceover(id);
			fetchGuidePreviewAndCheck();
		} else {
			setIsGeneratingVideo(false);
		}
	};

	useEffect(() => {
		setIsGeneratingVideo(isGeneratingVideoInit || false);
	}, [isGeneratingVideoInit]);

	const onGenerate = async () => {
		logger.debug("time to generate");
		if (!video) return;
		if (isGeneratingVideo) return;
		logger.debug("starting generation");
		generateFromPlainDoc();
	};

	useEffect(() => {
		if (customizerPage !== "Elements") {
			dispatch(setActiveElement(null));
		}
		if (customizerPage !== "Zooms") {
			dispatch(setActiveZoom(null));
		}
	}, [customizerPage, dispatch]);

	useEffect(() => {
		setTimeout(() => {
			dispatch(setHardRefreshTranscript(false));
		}, 600);
	}, [hardRefreshTranscript]);

	const showTranscript =
		isAiVideoPossible &&
		!hardRefreshTranscript &&
		customizerPage === "Voiceover";
	const buttonsMenu: {
		icon: (color: string) => JSX.Element;
		label: string;
		hoverTip?: string;
		handler: () => void;
		value: VideoCustomizerPage;
	}[] = [
		{
			icon: voiceoverIcon,
			label: "Voiceover",
			value: "Voiceover",
			handler: () => {},
		},
		{
			icon: introIcon,
			label: "Intro",
			value: "Intro",
			handler: () => {
				const videoStartTime = cp.getScreenclipStartTime();
				const currentTime = cp.currentTime;
				if (currentTime >= videoStartTime) {
					cp.hardsetCurrentTime(0, true);
					cp.pause();
				} else {
					return;
				}
			},
		},
		{
			icon: backgroundIcon,
			label: "Bg",
			value: "Background",
			handler: () => {
				if (!cp.vidRef) return;

				const videoStartTime = cp.getScreenclipStartTime();
				const videoEndTime = cp.getScreenclipEndTime();
				const currentTime = cp.currentTime;

				if (currentTime >= videoStartTime && currentTime <= videoEndTime) {
					// currentTime is between videoStartTime and videoEndTime
					// Add your logic here
					return;
				} else {
					cp.hardsetCurrentTime(videoStartTime, true);
				}
			},
		},
		{
			icon: outroIcon,
			label: "Outro",
			value: "Outro",
			// hoverTip: "Outro Slide",
			handler: () => {
				if (!cp.vidRef) return;

				const videoEndTime = cp.getScreenclipEndTime();
				const currentTime = cp.currentTime;

				if (currentTime <= videoEndTime && cp.paused) {
					// currentTime is between videoStartTime and videoEndTime
					// Add your logic here
					cp.hardsetCurrentTime(videoEndTime, true);
				} else {
					return;
				}
			},
		},

		{
			icon: zoomIcon,
			label: "Zoom",
			value: "Zooms",
			// hoverTip: "Zoom Effects",
			handler: () => {},
		},

		{
			icon: elementsIcon2,
			label: "Elements",
			value: "Elements",
			// hoverTip: "Shapes & Elements",
			handler: () => {},
		},
		{
			icon: musicIcon,
			label: "Music",
			value: "Music",
			// hoverTip: "Music",
			handler: () => {
				if (!cp.vidRef) return;

				const videoStartTime = cp.getScreenclipStartTime();
				const videoEndTime = cp.getScreenclipEndTime();
				const currentTime = cp.currentTime;

				if (currentTime >= videoStartTime && currentTime <= videoEndTime) {
					// currentTime is between videoStartTime and videoEndTime
					// Add your logic here
					return;
				} else {
					cp.hardsetCurrentTime(videoStartTime, true);
				}
			},
		},
		// {
		//     icon: zoomIcon(),
		//     label: "Zooms",
		//     value: "Zooms",
		//     hoverTip: "Zoom effect",
		//     handler: () => {}
		// }
	];

	return (
		<>
			<div className="VideoEditorPlatform">
				<div className="ButtonsPanel-container">
					{buttonsMenu.map((button) => {
						const isActive = customizerPage === button.value;

						return (
							<div
								key={button.label}
								className={`ButtonsPanel-button
                        ${isActive ? "active-customizer" : "inactive-customizer"}
                    `}
								id={button.value}
								onClick={() => {
									dispatch(setCustomizerPage(button.value));
									if (button.handler) button.handler();
								}}
							>
								<Tooltip
									placement="right-start"
									borderRadius={6}
									bg="#303030"
									className="editor-button-tooltip"
									color={"white"}
									label={button.hoverTip}
								>
									<div>
										<Icon className="ButtonsPanel-icon">
											{button.icon(`${isActive ? "white" : "white"}`)}
											{isActive ? <div className="active-dot">.</div> : null}
										</Icon>
										<div className="ButtonsPanel-label">{button.label}</div>
									</div>
								</Tooltip>
							</div>
						);
					})}
				</div>
				<div
					className="TranscriptPanel-container "
					style={{ display: showTranscript ? "initial" : "none" }}
				>
					<TranscriptEditor
						onGenerate={onGenerate}
						isGenerating={isGeneratingVideo}
					/>
				</div>
				{customizerPage !== "Voiceover" && customizerPage ? (
					<div className="VideoCustomizer-container">
						<VideoCustomizer />
					</div>
				) : null}
				<div
					className="VideoPlayer-container"
					style={{
						width:
							customizerPage === "Voiceover"
								? `calc(70% - 80px)`
								: `calc(70% - 80px)`,
					}}
				>
					<VideoPlayer
						onGenerate={onGenerate}
						AiMode={props.AiMode}
						setAiMode={props.setAiMode}
						timelineSliderValue={timelineSliderValue}
						setTimelineSliderValue={setTimelineSliderValue}
					/>

					<div className="Timeline-container">
						<TimelineContainer
							theme={AiMode ? "pink" : "grey"}
							width="fixed-gap"
							timelineSliderValue={timelineSliderValue}
						>
							{activeElement && <ShapesTimeline />}
							<SlidesTimeline />
							<ZoomTimeline />
						</TimelineContainer>
					</div>
				</div>
			</div>
		</>
	);
};
export default VideoEditorPlatform;
