import React from "react";
import new_doc from "../../../../../assets/images/new/new_doc.png";
import screen_record from "../../../../../assets/images/new/screen_record.png";
import upload_video from "../../../../../assets/images/new/upload.png";
import "./NewGuideOverlay.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { selectCollectionPath } from "@gigauser/common/src/redux/slices/collectionPathSlice";
import { Collection } from "@gigauser/common/src/core/types/collections";
import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay";
import folder from "@gigauser/common/src/assets/legacy_assets/icons/folder.svg";
import PreparingGuide from "../PreparingGuide/PreparingGuide";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import { setVideoUploadProgress } from "@gigauser/common/src/redux/slices/platformUiSlice";
import { showErrorMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import { useToast } from "@chakra-ui/react";
import UploadGuideOverlay from "../UploadGuideOverlay/UploadGuideOverlay";

type NewGuideOverlayProps = {};

const NewGuideOverlay: React.FC<NewGuideOverlayProps> = () => {
	const saver = useAppSelector(selectSaver);
	const collectionPath = useAppSelector(selectCollectionPath);
	const currentFolder = collectionPath.segments.at(-1) as Collection;

	const dispatch = useAppDispatch();
	const toast = useToast();

	const onCreateGuide = async () => {
		dispatch(
			openOverlay({
				heading: {
					icon: plusIcon(),
					title: "Preparing ...",
					data: "",
				},
				component: <PreparingGuide />,
			}),
		);

		const newGuide = await saver.createEmptyTextGuide(currentFolder.id);
		if (newGuide) {
			dispatch(closeOverlay());
			window.open("/guide/" + newGuide, "_blank");
		}
	};

	const onUploadVideo = async () => {
		dispatch(
			openOverlay({
				heading: {
					icon: plusIcon(),
					title: "Upload Video ",
					data: "",
				},
				component: <UploadGuideOverlay currentFolderId={currentFolder.id} />,
			}),
		);

		return;
	};

	return (
		<div className="NewGuideOverlay">
			<h2 className="NewGuideOverlay-heading">
				How do you want to create your new project?
			</h2>

			<NewGuideOption
				title="Capture screen-recording"
				subtitle="Use the Clueso chrome extension to capture a screen-recording of any process. Automatically get a step-by-step article with screenshots and a studio-style video."
				tagline="MOST COMMON"
				image={screen_record}
				handler={async () => {
					try {
						const resp = await chrome.runtime.sendMessage(
							"hnfgakkbiefcdbkonflgfkadncclmpme",
							{ type: "ping" },
						);
						if (!resp) {
							window.open(
								"https://chrome.google.com/webstore/detail/clueso-extension/hnfgakkbiefcdbkonflgfkadncclmpme",
								"_blank",
							);
							return;
						}
					} catch (e) {
						window.open(
							"https://chrome.google.com/webstore/detail/clueso-extension/hnfgakkbiefcdbkonflgfkadncclmpme",
							"_blank",
						);
						return;
					}
					const resp = await chrome.runtime.sendMessage(
						"hnfgakkbiefcdbkonflgfkadncclmpme",
						{
							type: "NewRecording",
							token: await saver.getTokenPlatform(),
							currentCollectionID: currentFolder.id,
						},
					);
					if (!resp) {
						toast({
							title: `Click on the Clueso chrome extension to start a recording.`,
							description:
								"You'll find it under the puzzle icon on your browser topbar.",
							status: "info",
							duration: 4000,
							isClosable: true,
							position: "top",
						});
					}
				}}
			/>

			<NewGuideOption
				title="Upload a video"
				subtitle="Upload a screen-recording from your computer. Get a step-by-step article with screenshots and a studio-style video."
				image={upload_video}
				handler={onUploadVideo}
			/>

			<NewGuideOption
				title="Type an article from scratch"
				subtitle="Create an empty document and type out your text article from scratch. This project won’t come with a video."
				image={new_doc}
				handler={onCreateGuide}
			/>
		</div>
	);
};

export default NewGuideOverlay;

type NewGuideOptionProps = {
	title: string;
	subtitle: string;
	tagline?: string;
	image: string;
	handler: () => void;
};

const NewGuideOption: React.FC<NewGuideOptionProps> = (props) => {
	const dispatch = useAppDispatch();

	const handler = () => {
		dispatch(closeOverlay());
		props.handler();
	};

	return (
		<div className="NewGuideOption" onClick={handler}>
			<img
				className={`NewGuideOption-image ${props.tagline == "MOST COMMON" ? "screen" : ""}`}
				src={props.image}
			></img>

			<div className="NewGuideOption-text">
				<div className="NewGuideOption-title-container">
					<h1 className="NewGuideOption-title">{props.title}</h1>
					<div className="NewGuideOption-tagline">{props.tagline}</div>
				</div>
				<h2 className="NewGuideOption-subtitle">{props.subtitle}</h2>
			</div>
		</div>
	);
};
