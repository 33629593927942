const transcriptIcon = (color = "#ffffff") => {
	const fill = color || "#000000";

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M1 11V5C1 2.79086 2.79086 1 5 1H11"
				stroke={color}
				stroke-width="1.75"
				stroke-linecap="round"
			/>
			<path
				d="M11 31H5C2.79086 31 1 29.2091 1 27L1 21"
				stroke={color}
				stroke-width="1.75"
				stroke-linecap="round"
			/>
			<path
				d="M31 11V5C31 2.79086 29.2091 1 27 1H21"
				stroke={color}
				stroke-width="1.75"
				stroke-linecap="round"
			/>
			<path
				d="M21 31H27C29.2091 31 31 29.2091 31 27V21"
				stroke={color}
				stroke-width="1.75"
				stroke-linecap="round"
			/>
			<circle cx="15" cy="14" r="6" stroke={color} stroke-width="2" />
			<path
				d="M19 19L23 24"
				stroke={color}
				stroke-width="2"
				stroke-linecap="round"
			/>
		</svg>
	);
};
export default transcriptIcon;
