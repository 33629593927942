import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import NewButton from "../../components/NewButton";

import Overlay from "@gigauser/common/src/layouts/Overlay/Overlay";

import "./Home.laptop.css";
import Files from "../../components/Files/Files";
import InfoMessage from "@gigauser/common/src/layouts/MessagePopup/MessagePopup";
import { useAppSelector } from "@gigauser/common/src/redux";
import Bin from "../../components/Bin/Bin";
import Analytics from "../../components/Analytics/Analytics";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { AdminPage } from "../../components/Admin/AdminPage";
import { HostingPage } from "../../components/Admin/HostingPage";
import UsagePage from "../../components/Admin/UsagePage";
import HelpCenterSettings from "../../components/Admin/HelpCenterSettings";
import { SettingsPage } from "../../components/Settings/SettingsPage";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
	const { homePage } = useAppSelector((state) => state.platformUi.value);

	return (
		<div className="Home laptop row">
			<Overlay />
			<InfoMessage />

			<div className="Navbar-container">
				<Navbar />
			</div>
			<div className="HomeContents-container column">
				{homePage === "anal" ? (
					<Analytics />
				) : homePage === "admin" ? (
					<AdminPage />
				) : homePage === "help-center-config" ? (
					<HelpCenterSettings />
				) : homePage === "usage" ? (
					<UsagePage />
				) : homePage === "settings" ? (
					<SettingsPage />
				) : (
					<>
						<div className="row">
							<Header showTitle={true} />
						</div>
						{homePage === "home" ? (
							<div className="Home-ActionButtons row">
								<NewButton />
							</div>
						) : null}

						<div className="Home-Files vertical-scroll show-scroll">
							<div style={{ display: homePage === "home" ? "block" : "none" }}>
								<Files />
							</div>
							<div style={{ display: homePage === "bin" ? "block" : "none" }}>
								<Bin />
							</div>
							{/* <div style={{display: homePage=== "anal"?"block":"none"}}><Analytics></Analytics></div> */}
						</div>
					</>
				)}
			</div>
		</div>
	);
};
export default Home;
