const t = (color: string = "black") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22 12C22 14.2091 20.2091 16 18 16C15.7909 16 14 14.2091 14 12C14 9.79086 15.7909 8 18 8C20.2091 8 22 9.79086 22 12Z"
				stroke={color}
				stroke-width="1.5"
			/>
			<path
				d="M10 12C10 14.2091 8.20914 16 6 16C3.79086 16 2 14.2091 2 12C2 9.79086 3.79086 8 6 8C8.20914 8 10 9.79086 10 12Z"
				stroke={color}
				stroke-width="1.5"
			/>
			<path
				d="M6 16H18"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
			/>
		</svg>
	);
};

export default t;
