import React from "react";
import "./CloseButton.css";
import closeIcon from "../../../assets/svgs/closeIcon";
import Icon from "../../Icon/Icon";

type CloseButtonProps = {
	className?: string;
	onClose: () => void;
};

const CloseButton: React.FC<CloseButtonProps> = (props) => {
	return (
		<div onClick={props.onClose} className={`CloseButton ${props.className}`}>
			<Icon className="close-Icon">{closeIcon("white")}</Icon>
		</div>
	);
};
export default CloseButton;
