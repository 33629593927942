const savingIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 26 26"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.9167 3.25C10.2564 3.25012 8.65914 3.88553 7.45249 5.0259C6.24584 6.16627 5.5213 7.72515 5.4275 9.38275C4.12845 9.91034 3.05098 10.8696 2.37666 12.0989C1.70234 13.3282 1.4724 14.7524 1.72559 16.1314C1.97878 17.5105 2.69962 18.7601 3.76663 19.6697C4.83364 20.5793 6.18159 21.0933 7.58333 21.125L18.4167 21.1261C19.7484 21.0969 21.0338 20.631 22.0763 19.8018C23.1188 18.9726 23.8609 17.8247 24.189 16.5337C24.5171 15.2427 24.4131 13.8797 23.893 12.6534C23.3729 11.4271 22.4653 10.405 21.3092 9.7435L20.1023 10.9503C21.0471 11.3727 21.8161 12.1097 22.2781 13.0358C22.74 13.9618 22.8663 15.0195 22.6354 16.0283C22.4046 17.0371 21.8308 17.9345 21.012 18.5674C20.1933 19.2004 19.1803 19.5296 18.1458 19.4989L7.85417 19.5V19.4978C6.71084 19.5313 5.59821 19.1251 4.7454 18.3628C3.89259 17.6006 3.36454 16.5403 3.26999 15.4004C3.17544 14.2605 3.52158 13.1277 4.23715 12.2354C4.95271 11.343 5.9832 10.759 7.11642 10.6037C6.90527 9.42898 7.1324 8.21765 7.75474 7.19923C8.37708 6.18082 9.3514 5.4261 10.4931 5.07809C11.6347 4.73008 12.8644 4.81296 13.949 5.31104C15.0336 5.80912 15.8978 6.68778 16.3778 7.7805L17.5868 6.57042C17.0221 5.56299 16.199 4.72428 15.2024 4.14067C14.2058 3.55705 13.0716 3.24961 11.9167 3.25Z"
				fill="#7B8189"
			/>
			<circle cx="10" cy="15" r="1" fill="#7B8189" />
			<circle cx="13" cy="15" r="1" fill="#7B8189" />
			<circle cx="16" cy="15" r="1" fill="#7B8189" />
		</svg>
	);
};
export default savingIcon;
