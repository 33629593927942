import { Cover, CoverType } from "@giga-user-fern/api/types/api";
import React, { useEffect, useRef, useState } from "react";
import { drawIntro } from "../../../../core/canvas/canvasUtils/canvasIntro";
import { useAppSelector } from "../../../../redux";
import { selectSaver } from "../../../../redux/slices/backendSlice";
import { Spinner } from "@chakra-ui/react";
import "./CoverCard.css";
import { drawOutro } from "../../../../core/canvas/canvasUtils/canvasOutro";

type CoverCardProps = {
	coverType: CoverType;
	cover: Cover;
	noMargin?: boolean;
	onLoadDimensions?: (w: number, h: number) => void;
};

const CoverCard: React.FC<CoverCardProps> = (props) => {
	const canvasRef = React.useRef<HTMLCanvasElement>(null);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	const [loading, setLoading] = useState(true);

	const logos = useAppSelector((state) => state.platformDetails.value?.logos);

	const backgroundRef = useRef<HTMLImageElement>(new Image());
	const saver = useAppSelector(selectSaver);

	const initCover = () => {
		backgroundRef.current = new Image();
		if (!props.cover.backgroundSrc) return;

		backgroundRef.current.src = props.cover.backgroundSrc;

		backgroundRef.current.onload = () => {
			setWidth(backgroundRef.current.width);
			setHeight(backgroundRef.current.height);

			if (props.onLoadDimensions) {
				props.onLoadDimensions(
					backgroundRef.current.width,
					backgroundRef.current.height,
				);
			}

			setTimeout(() => {
				renderCover();
				setLoading(false);
			}, 200);
		};

		backgroundRef.current.onerror = (e: any) => {
			console.error("error loading image", backgroundRef.current.src, e);
			setLoading(false);
		};
	};

	const renderCover = () => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvasRef.current?.getContext("2d") as CanvasRenderingContext2D;

		const logo = new Image();
		if (logos)
			logo.src = logos.find((logo) => logo.id === props.cover.logo)?.src || "";

		const renderFunction = props.coverType === "intro" ? drawIntro : drawOutro;

		renderFunction(ctx, props.cover, {
			width: canvas.width,
			height: canvas.height,
			background: backgroundRef.current,
			logo: logo,
			organization: saver.organization,
		});
	};

	useEffect(() => {
		initCover();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.cover, canvasRef.current]);

	return (
		<div className={`CoverCard ${props.noMargin ? "noMargin" : "margin"}`}>
			<canvas
				width={width}
				height={height}
				ref={canvasRef}
				className="CoverCardCanvas"
			></canvas>

			<div
				className="CoverCard-spinner"
				style={{ display: loading ? "block" : "none" }}
			>
				<Spinner size={"md"} color="#d43f8c"></Spinner>
			</div>
		</div>
	);
};
export default CoverCard;
