import React, { useRef, useState } from "react";
import "./TitledInput.css";
import dropdown_icon from "../../../assets/icons/dropdown_icon.png";
import logger from "../../../utils/logger";
import { Size } from "../../../types/sizes";

export type TitledInputOption = {
	icon?: string; //(must be an emoji string, not a filepath)
	text: string;
};

export type TitledInputProps = {
	id: string;
	placeHolder?: string;
	label?: string;
	icon?: string; //file path to an image
	dims?: Size;
	options?: TitledInputOption[];
	edges?: "sharp" | "round";
	onChange: (e: React.FormEvent<HTMLInputElement> | dummyEvent) => void;
	value: string;
	type?: string; //text, email, password, etc.
	position?: "default" | "full" | "right half" | "left half";
	styleSize?: string;
	error?: boolean | string; //(if true, the input box will be in error style but no message will be shown)
	onEnter?: () => void; //(function that runs when enter key is pressed),
	display?: boolean; //(false if the box should be hidden)
	className?: string; //classNames appended to the topmost div. Can be used to edit width, margin, etc.
	background?: string;
} & React.HTMLProps<HTMLInputElement>; //Any more props allowed by input are allowed

type dummyEvent = {
	target: {
		id: string;
		value: string;
	};
};

const TitledInput: React.FC<TitledInputProps> = ({
	placeHolder,
	label,
	icon,
	options,
	id,
	onChange,
	value,
	type = "text",
	position = "full",
	styleSize = "normal",
	error,
	display = true,
	...props
}) => {
	/**
	 * display?: boolean (false if the box should be hidden)
	 */

	const triggerRef = useRef<HTMLDivElement>(null);

	const [focussed, setFocus] = useState(false);

	const [showOptions, setShowOptions] = useState(false);

	const getInputWidthClass = () => {
		if (icon && options) return "two-icons";
		else if (icon || options) return "one-icon";
		else return "no-icon";
	};

	const inputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		onChange(e);
	};

	const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key == "Enter") {
			if (props.onEnter) props.onEnter();
			e.preventDefault();
		}
	};

	const onFocus = () => {
		/**
		 * On clicking the input box
		 */
		setFocus(true);
		if (options) {
			setShowOptions(true);
			document.addEventListener("click", closeOptionsClickHandler);
		}
	};

	const onTextInputBlur = () => {
		/**
		 * On clicking outside the input box
		 */
		setFocus(false);
	};

	const setOption = (option: string) => {
		logger.debug("setOption: ", option);
		// setValue(option)
		const dummyEvent = {
			target: {
				id: id,
				value: option,
			},
		};
		onChange(dummyEvent);
		setShowOptions(false);
	};

	const closeOptionsClickHandler = (e: MouseEvent) => {
		logger.debug(e.target);
		if (!triggerRef.current?.contains(e.target as Node)) {
			setFocus(false);
			setShowOptions(false);
			document.removeEventListener("click", closeOptionsClickHandler);
		}
	};

	if (display)
		return (
			<div
				className={`TitledInput thin-scroll ${error ? "error-input" : ""} ${position} ${props.className} ${options ? "titled-dropdown" : ""}`}
				onClick={options ? onFocus : undefined}

			>
				<div
					className={`titledinput-box input-main-container ${options ? "titled-dropdown" : ""} ${props.edges} ${focussed ? "active" : ""}`}
					ref={triggerRef}
				>
					{label ? (
						<span className={`input-label ${focussed ? "" : "active"}`}>
							{label}
						</span>
					) : null}
					{icon ? <img src={icon} className="input-icon"></img> : null}
					<input
						value={value}
						onChange={options ? undefined : inputOnChange}
						className={`titledInput ${options ? "dropdown-input" : ""}  ${getInputWidthClass()} ${styleSize} `}
						id={id}
						type={type}
						autoComplete={props.autoComplete}
						onKeyDown={props.onEnter ? inputOnKeyDown : undefined}
						onFocus={onFocus}
						onBlur={options ? undefined : onTextInputBlur}
						placeholder={placeHolder}
						{...props}
					/>
					{options ? (
						<img className="input-icon dropdown-icon" src={dropdown_icon}></img>
					) : null}
				</div>
				{typeof error === "string" ? (
					<div className="error-message">{error}</div>
				) : null}
				{options ? (
					<div
						className="titledinput-options-container "
						style={{ visibility: showOptions ? "visible" : "hidden" }}
					>
						{options.map((option) => (
							<div
								className={`titledinput-option ${props.size}`}
								key={option.text}
								id={option.text}
								onClick={() => {
									setOption(option.text);
								}}
							>
								<span className="icon">{option.icon}</span>
								<span className="text">{option.text}</span>
							</div>
						))}
					</div>
				) : null}
			</div>
		);
	else return null;
};

export default React.memo(TitledInput);
