import { GuidePreview } from "../../../core/types/guide";
import {
	showLoadingMessagePopup,
	showMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import { Item } from "../../../types/files";
import { selectSaver } from "../../slices/backendSlice";
import {
	editItemFrontend,
	setPinnedGuide,
} from "../../slices/collectionDataSlice";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";

const usePinGuide: () => (
	isPinned: boolean,
	guidePreview: GuidePreview,
) => Promise<boolean> = () => {
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);

	return async (isPinned: boolean, guidePreview: GuidePreview) => {
		try {
			dispatch(showLoadingMessagePopup());
			const resp = await saver.pinGuide(isPinned, guidePreview);

			const updatedItem: Item = {
				type: "Guide",
				entry: {
					...guidePreview,
					isPinned: isPinned,
				},
			};

			dispatch(
				setPinnedGuide({
					isPinned: isPinned,
					guidePreview: guidePreview,
				}),
			);

			dispatch(showMessagePopup("Edited 1 item successfully."));
			return true;
		} catch (e) {
			return false;
		}
	};
};

export default usePinGuide;
