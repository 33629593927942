import React from "react";
import {
	ContextMenuObject,
	ContextMenuComponent,
} from "../layouts/ContextMenu/ContextMenu";
import logger from "../utils/logger";

export type Navigate = (a: string) => void;

export type EnvironmentContextValue = {
	name: "platform" | "remote-script" | "fail";
	navigator: () => Navigate;
};

export type CMenuContextValue = {
	showContextMenu: (a: ContextMenuObject | false) => void;
	isContextMenuVisible: () => boolean;
};

const EnvironmentContext = React.createContext<EnvironmentContextValue>({
	name: "fail",
	navigator: () => {
		return (a: string) => {
			logger.debug(a);
		};
	},
});

const CMenuContext = React.createContext<CMenuContextValue>({
	showContextMenu: (a) => {},
	isContextMenuVisible: () => false,
});

export { EnvironmentContext, CMenuContext };
