import reelIcon from "@gigauser/common/src/assets/svgs/reelIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";

import scissors from "@gigauser/common/src/assets/icons/scissors.svg";
import trash from "@gigauser/common/src/assets/icons/trash.svg";

import React, { useContext, useRef, useState } from "react";

import "./TimelineClip.css";
import { CMenuContext } from "@gigauser/common/src/contexts";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import { ContextMenuEntry } from "@gigauser/common/src/layouts/ContextMenu/ContextMenu";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectClips,
	setClips,
} from "@gigauser/common/src/redux/slices/guideSlice";
import logger from "@gigauser/common/src/utils/logger";
import TimelineContext from "../../TimelineContext";
import VideoPlayerContext from "@gigauser/common/src/core/canvas/VideoPlayerContext";
import {
	formatTime,
	simpleFormatTime,
} from "@gigauser/common/src/core/canvas/canvasUtils/videoUtils";
import clockIcon from "@gigauser/common/src/assets/svgs/clockIcon";
import eyeClosedIcon from "@gigauser/common/src/assets/svgs/eyeClosedIcon";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";

export type ClipTheme = "slide" | "clip" | "zoom" | "newzoom" | "shape";

type TimelineClipProps = {
	title?: string;

	startTime: number;
	endTime: number;
	index: number;
	hidden?: boolean;
	theme?: ClipTheme;
	active?: boolean;

	contextMenu?: ContextMenuEntry[];
	onClick?: () => void;
	onDelete?: (clipIndex: number) => void;
	resizeClip?: (clipIndex: number, t: number, handle: "left" | "right") => void;
	dragClip?: (clipIndex: number, dt: number) => void;
	splitClip?: (clipIndex: number, t: number) => void;
};

const TimelineClip: React.FC<TimelineClipProps> = (props) => {
	const [activeDragHandle, setActiveDragHandle] = useState<
		"left" | "right" | null
	>(null);

	const timelineContext = useContext(TimelineContext);

	const cMenuContext = useContext(CMenuContext);
	const { showContextMenu } = cMenuContext;

	const cp = useContext(CanvasPlayerContext);
	const tp = useContext(TimelineContext);

	const startDragX = useRef(0);

	const formattedTime = simpleFormatTime(props.endTime - props.startTime);

	var left = tp.timeToPosition(props.startTime);
	var width = tp.timeToPosition(props.endTime) - left;

	const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		var menuArray: ContextMenuEntry[] = [];

		if (props.contextMenu) {
			menuArray = props.contextMenu;
		}

		if (props.splitClip) {
			menuArray.push({
				icon: scissors,
				label: "Split clip",
				handler: () => {
					const x = tp.getXCoord(event.clientX);
					const t = tp.positionToTime(x);
					if (props.splitClip) props.splitClip(props.index, t);
				},
			});
		}
		if (props.onDelete) {
			menuArray.push({
				icon: trash,
				label: "Delete",
				handler: () => {
					if (props.onDelete) props.onDelete(props.index);
				},
				type: "danger",
			});
		}

		if (menuArray.length) {
			showContextMenu({
				show: true,
				menuArray: menuArray,
				X: event.clientX + 2,
				Y: event.clientY - 30,
			});
		}
	};

	const handleMouseDown = () => {
		document.body.style.userSelect = "none"; // Disable text selection
		document.addEventListener("mouseup", handleMouseUp);
	};

	const handleMousdownLeft = () => {
		document.addEventListener("mousemove", handleResizeLeft);
		handleMouseDown();
	};

	const handleMousdownRight = () => {
		document.addEventListener("mousemove", handleResizeRight);
		handleMouseDown();
	};

	const handleMousedownDrag = (e: any) => {
		document.addEventListener("mousemove", handleDrag);
		const X = timelineContext.getXCoord(e.clientX);
		startDragX.current = X;

		handleMouseDown();
	};

	const handleDrag = (event: any) => {
		if (!props.dragClip) return;
		var X = timelineContext.getXCoord(event.clientX);
		const dX = X - startDragX.current;
		const dt = tp.positionToTime(dX);

		props.dragClip(props.index, dt);
	};

	const handleResizeLeft = (event: any) => {
		if (!props.resizeClip) return;

		const X = Math.max(timelineContext.getXCoord(event.clientX), 0);
		const t = tp.positionToTime(X);
		props.resizeClip(props.index, t, "left");
	};

	const handleResizeRight = (event: any) => {
		if (!props.resizeClip || !cp.vidRef?.current) return;

		var X = timelineContext.getXCoord(event.clientX);
		const duration = cp.getVideoDuration();

		if (tp.positionToTime(X) > duration) {
			X = tp.timeToPosition(duration);
		}
		const t = tp.positionToTime(X);
		props.resizeClip(props.index, t, "right");
	};

	const handleMouseUp = () => {
		document.body.style.userSelect = "auto"; // Enable text selection

		document.removeEventListener("mousemove", handleResizeLeft);
		document.removeEventListener("mousemove", handleResizeRight);
		document.removeEventListener("mousemove", handleDrag);

		document.removeEventListener("mouseup", handleMouseUp);
	};

	return (
		<div
			className={`TimelineClip ${width} ${props.active ? "active" : "inactive"} ${props.theme} ${props.hidden ? "hidden" : "show"}
                ${props.resizeClip ? "resizable" : "non-resizable"}
                ${props.dragClip ? "draggable" : "non-draggable"}
        `}
			style={{ left: left, width: width }}
			onContextMenu={onContextMenu}
			onClick={props.onClick}
		>
			<div className="clip-handle handle-left" onMouseDown={handleMousdownLeft}>
				.
			</div>

			<div className="clip-content" onMouseDown={handleMousedownDrag}>
				<div className="Timelineclip-desc">
					<div
						className="TimelineClip-title"
						style={{
							display: width > 60 ? "block" : "none",
						}}
					>
						{props.title}
					</div>

					<div
						className="Timelineclip-timing"
						style={{
							display: width > 100 ? "block" : "none",
						}}
					>
						{/* <Icon className="reelicon">{clockIcon("rgba(255, 255, 255, 0.722)")}</Icon>  */}
						{props.theme !== "newzoom" ? (
							props.hidden ? (
								<div className="reelicon eyeclosed">
									{eyeClosedIcon("rgba(255, 255, 255, 0.722)")}
								</div>
							) : (
								<div className="reelduration">{formattedTime}</div>
							)
						) : null}
					</div>
				</div>
			</div>

			<div
				className="clip-handle handle-right"
				onMouseDown={handleMousdownRight}
			>
				.
			</div>
		</div>
	);
};
export default TimelineClip;

function dispatch(arg0: any) {
	throw new Error("Function not implemented.");
}
