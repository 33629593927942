import home from "@gigauser/common/src/assets/icons/home.svg";
import trash from "@gigauser/common/src/assets/icons/trash.svg";
import { PlatformHomePage } from "@gigauser/common/src/redux/slices/platformUiSlice";

// import download from 'assets/icons/download.svg'

import { LabeledMenu } from "@gigauser/common/src/types/menus";

export const navbarMenuArray: {
	label: string;
	icon: string;
	id: PlatformHomePage;
}[] = [
	// {
	//     label: 'Home', icon: home,
	//     handler: () =>{}
	// },

	{
		label: "Home",
		icon: home,
		id: "home",
	},
	{
		label: "Bin",
		icon: trash,
		id: "bin",
	},
	// {
	//     label: 'Folders', icon: folder,
	//     exclusiveTo: 'mobile',
	//     handler: () =>{}
	// },

	// {
	//     label: 'Trash', icon: trash,
	//     handler: () =>{}
	// }
];

export const navbarFooterButtons: LabeledMenu = [
	// {
	//     label: 'Download Desktop App', icon: star,
	// handler: () =>{}
	// },
	// {
	//     label: "Help & Getting Started",
	//     icon: home,
	//     handler: () =>{
	//         //@ts-expect-error
	//         window.clueso.display()
	//     }
	// },
];
