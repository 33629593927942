import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectIntroEdits,
	selectOutroEdits,
	updateIntroEdits,
	updateOutroEdits,
} from "@gigauser/common/src/redux/slices/guideSlice";
import React, { useContext } from "react";
import TimelineClip from "../TimelineClip/TimelineClip";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import eye_closed from "@gigauser/common/src/assets/icons/eye_closed.svg";
import eye from "@gigauser/common/src/assets/icons/eye.svg";
import { setCustomizerPage } from "@gigauser/common/src/redux/slices/platformUiSlice";

const SlidesTimeline: React.FC = () => {
	const introEdits = useAppSelector(selectIntroEdits);
	const outroEdits = useAppSelector(selectOutroEdits);
	const cp = useContext(CanvasPlayerContext);

	if (!cp.vidRef) throw new Error("missing cp.vidref in slidestimeline");

	const totalDuration = cp.getVideoDuration();
	const outroStart = totalDuration - outroEdits.duration;

	const isIntroVisible = cp.videoEdits.intro?.visible;
	const isOutroVisible = cp.videoEdits.outro?.visible;

	const dispatch = useAppDispatch();

	const openIntro = () => {
		dispatch(setCustomizerPage("Intro"));
	};

	const openOutro = () => {
		dispatch(setCustomizerPage("Outro"));
	};

	const startTime = cp.getScreenclipStartTime();
	const endTime = cp.getScreenclipEndTime();

	return (
		<div className="SlidesTimeline">
			<TimelineClip
				index={0}
				title={`Intro`}
				hidden={!isIntroVisible}
				theme="slide"
				startTime={0}
				endTime={introEdits.duration}
				onClick={openIntro}
				contextMenu={[
					{
						icon: isIntroVisible ? eye_closed : eye,
						label: isIntroVisible ? `Hide` : "Show",
						handler: () => {
							dispatch(
								updateIntroEdits({
									...introEdits,
									visible: !isIntroVisible,
								}),
							);
						},
					},
				]}
			/>

			<TimelineClip
				index={1}
				title="Outro"
				hidden={!cp.videoEdits.outro?.visible}
				theme="slide"
				startTime={outroStart}
				endTime={totalDuration}
				onClick={openOutro}
				contextMenu={[
					{
						icon: isOutroVisible ? eye_closed : eye,
						label: isOutroVisible ? `Hide` : "Show",
						handler: () => {
							dispatch(
								updateOutroEdits({
									...outroEdits,
									visible: !isOutroVisible,
								}),
							);
						},
					},
				]}
			/>

			<TimelineClip
				title="Screen"
				key={`${startTime}:${endTime}`}
				index={2}
				theme={"clip"}
				startTime={startTime}
				endTime={endTime}
			/>
		</div>
	);
};
export default SlidesTimeline;
