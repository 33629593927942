import React, { useState } from "react";
import { Item } from "../../../../types/files";
import "./GuidesTableGap.css";
import useReorderItem from "../../../../redux/hooks/FileHooks/useReorderItem";
import { GuidePreview } from "../../../../core/types/guide";
import { Collection } from "../../../../core/types/collections";
import { useAppDispatch } from "../../../../redux";
import { showErrorMessagePopup } from "../../../../layouts/MessagePopup/messagePopupSlice";

type GuidesTableGapProps = {
	preceedingItem?: Item;
	type: "Guide" | "Collection";
};

const GuidesTableGap: React.FC<GuidesTableGapProps> = (props) => {
	const [isOnDrop, setIsOnDrop] = useState<boolean>(false); //true if something is being dropped on the folder
	const dispatch = useAppDispatch();
	const backendReorderItem = useReorderItem();

	const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const onDragOverCapture = (e: React.DragEvent<HTMLDivElement>) => {
		// if(data.entry.id!==props.entry.entry.id)
		setIsOnDrop(true);
	};

	const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		setIsOnDrop(false);
	};

	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		const succeedingItem = JSON.parse(e.dataTransfer.getData("text")) as Item;

		setIsOnDrop(false);

		if (props.type !== succeedingItem.type) {
			dispatch(
				showErrorMessagePopup(
					`Can't reorder ${succeedingItem.type}s into ${props.type}s`,
				),
			);
			return;
		}

		if (props.type === "Guide") {
			onReorderGuide(
				succeedingItem.entry as GuidePreview,
				props.preceedingItem?.entry as GuidePreview,
			);
		} else {
			onReorderCollection(
				succeedingItem.entry as Collection,
				props.preceedingItem?.entry as Collection,
			);
		}
	};

	const onReorderGuide = (
		succeedingGuide: GuidePreview,
		precedingGuide?: GuidePreview,
	) => {
		if (precedingGuide) {
			backendReorderItem({
				type: "Guide",
				succeedingItem: succeedingGuide,
				precedingItem: precedingGuide,
			});
		} else {
			backendReorderItem({ type: "Guide", succeedingItem: succeedingGuide });
		}
	};

	const onReorderCollection = (
		succeedingCollection: Collection,
		precedingCollection?: Collection,
	) => {
		if (precedingCollection) {
			backendReorderItem({
				type: "Collection",
				succeedingItem: succeedingCollection,
				precedingItem: precedingCollection,
			});
		} else {
			backendReorderItem({
				type: "Collection",
				succeedingItem: succeedingCollection,
			});
		}
	};

	return (
		<div
			className={`GuidesTableGap ${isOnDrop ? "onDrop" : ""}`}
			onDragOver={onDragOver}
			onDragOverCapture={onDragOverCapture}
			onDragLeave={onDragLeave}
			onDrop={onDrop}
		>
			Have a good coding
		</div>
	);
};
export default GuidesTableGap;
