const i = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
		>
			<g clip-path="url(#clip0_2428_3600)">
				<path
					d="M19.5 22.5H18V20.25H15C14.0057 20.2489 13.0526 19.8535 12.3495 19.1504C11.6465 18.4474 11.2511 17.4942 11.25 16.5V15.5405L9.51282 14.9615C9.40629 14.926 9.30909 14.867 9.2284 14.7889C9.14772 14.7108 9.0856 14.6156 9.04664 14.5102C9.00768 14.4049 8.99287 14.2922 9.0033 14.1804C9.01374 14.0685 9.04915 13.9605 9.10692 13.8642L11.25 10.2922V8.25C11.252 6.4604 11.9638 4.74466 13.2292 3.47922C14.4946 2.21378 16.2104 1.50199 18 1.5H21.75V3H18C16.6081 3.00155 15.2736 3.55517 14.2894 4.5394C13.3051 5.52363 12.7515 6.85809 12.75 8.25V10.5C12.75 10.6359 12.713 10.7693 12.643 10.8858L10.8741 13.8342L12.2371 14.2885C12.3865 14.3383 12.5164 14.4338 12.6084 14.5615C12.7004 14.6892 12.75 14.8426 12.75 15V16.5C12.7506 17.0965 12.9879 17.6685 13.4097 18.0903C13.8315 18.5121 14.4034 18.7493 15 18.75H18.75C18.9489 18.75 19.1396 18.829 19.2803 18.9697C19.421 19.1103 19.5 19.3011 19.5 19.5V22.5Z"
					fill={color}
				/>
				<path d="M17.25 9H14.25V10.5H17.25V9Z" fill={color} />
				<path
					d="M6.99937 18.9126C6.44899 18.4201 6.00869 17.8171 5.70725 17.1428C5.40581 16.4686 5.25 15.7384 5.25 14.9998C5.25 14.2613 5.40581 13.531 5.70725 12.8568C6.00869 12.1826 6.44899 11.5795 6.99937 11.087L7.99987 12.2045C7.60659 12.5563 7.29197 12.9871 7.07656 13.4687C6.86115 13.9504 6.74981 14.472 6.74981 14.9997C6.74981 15.5273 6.86115 16.049 7.07656 16.5306C7.29197 17.0123 7.60659 17.4431 7.99987 17.7948L6.99937 18.9126Z"
					fill={color}
				/>
				<path
					d="M4.79953 21.6006C3.77509 20.832 2.94362 19.8354 2.37097 18.6898C1.79831 17.5443 1.50018 16.2811 1.50018 15.0004C1.50018 13.7196 1.79831 12.4565 2.37097 11.3109C2.94362 10.1653 3.77509 9.16878 4.79953 8.40015L5.69998 9.6C4.86158 10.2288 4.1811 11.0442 3.71242 11.9815C3.24374 12.9189 2.99973 13.9525 2.99973 15.0005C2.99973 16.0485 3.24374 17.0821 3.71242 18.0195C4.1811 18.9569 4.86158 19.7722 5.69998 20.401L4.79953 21.6006Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_2428_3600">
					<rect width="100%" height="100%" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default i;
