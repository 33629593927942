import React, { useState } from "react";
import searchIcon from "@gigauser/common/src/assets/legacy_assets/icons/search.svg";
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import { useAppDispatch } from "@gigauser/common/src/redux";

import "./Searchbar.css";
import SearchBox from "./SearchBox/SearchBox";
import logger from "@gigauser/common/src/utils/logger";
import { getOperatingSystem } from "@gigauser/common/src/utils/os";

type SearchbarProps = {};

const Searchbar: React.FC<SearchbarProps> = () => {
	const [searchString, setSearchString] = useState<string>("");

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);
	};

	const [focus, setFocus] = useState<boolean>(false);

	const onFocus = () => {
		setFocus(true);
	};

	const onBlur = () => {
		setFocus(false);
	};

	const dispatch = useAppDispatch();

	const openSearchBoxOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
		event?.preventDefault();
		logger.debug("Need to open overlay");
		dispatch(
			openOverlay({
				heading: {
					icon: "",
					title: "Search",
					data: "",
					hideSeperator: true,
				},
				component: <SearchBox />,
			}),
		);
	};

	// return null

	return (
		<div
			className={`Searchbar laptop ${focus ? "active" : ""}`}
			onClick={openSearchBoxOverlay}
		>
			{/* // <TitledInput value="" id='search' onChange={() =>{}} icon={searchIcon} /> */}

			<img className="Searchbar-icon" src={searchIcon}></img>
			<div className="Searchbar-input" onChange={onChange}>
				What would you like to work on today?
			</div>
			<div className="Searchbar-shortcut"> {"⌘ + P"}</div>
		</div>
	);
};
export default Searchbar;
