import React, { useContext } from "react";
import "./ShapeTimeline.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	deleteElement,
	selectVideo,
	updateElement,
} from "@gigauser/common/src/redux/slices/guideSlice";
import TimelineClip from "../TimelineClip/TimelineClip";
import { GigaUserApi } from "@giga-user-fern/api";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";

const ShapesTimeline: React.FC = () => {
	// const tempZoom
	const video = useAppSelector(selectVideo);
	const videoEdits = video?.videoEdits;
	let shapeEdits: GigaUserApi.video.videoEdits.ElementEdit[] = [];

	videoEdits?.elements?.forEach((element) => {
		if (
			element.geo === "rectangle" ||
			element.geo === "blur" ||
			element.geo === "text"
		) {
			shapeEdits.push(element);
		}
	});

	if (shapeEdits.length !== 0)
		shapeEdits = shapeEdits.sort((a, b) => a.startTime - b.startTime);

	const cp = useContext(CanvasPlayerContext);
	const activeElement = useAppSelector(
		(state) => state.platformUi.value.activeElement,
	);

	const dispatch = useAppDispatch();

	const getAdjustedTime = (time: number) => {
		if (videoEdits?.intro && videoEdits?.intro.visible) {
			return time + videoEdits.intro.duration;
		}
		return time;
	};

	const getUnadjustedTime = (time: number) => {
		if (videoEdits?.intro && videoEdits?.intro.visible) {
			return time - videoEdits.intro.duration;
		}
		return time;
	};

	const resizeShape = (
		clipIndex: number,
		t: number,
		handle: "left" | "right",
	) => {
		if (!shapeEdits) return;
		const origShape = shapeEdits[clipIndex];

		if (!origShape) return;

		const time = getUnadjustedTime(t);

		if (handle === "right") {
			let newEndTime = Math.max(time, origShape.startTime);
			newEndTime = Math.min(newEndTime, cp.videoDuration);

			dispatch(
				updateElement({
					...origShape,
					endTime: newEndTime,
				}),
			);
		} else if (handle === "left") {
			let newStartTime = Math.min(time, origShape.endTime);
			newStartTime = Math.max(newStartTime, 0);

			dispatch(
				updateElement({
					...origShape,
					startTime: newStartTime,
				}),
			);
		}
	};

	const dragShape = (clipIndex: number, dt: number) => {
		if (!shapeEdits) return;
		const origShape = shapeEdits[clipIndex];

		const duration = origShape.endTime - origShape.startTime;
		let newStartTime = origShape.startTime + dt;

		if (dt > 0) {
			//moving forwar
			//last clip
			newStartTime = Math.min(newStartTime, cp.videoDuration - duration);
		} else {
			//moving backward

			//first Clip
			newStartTime = Math.max(0, newStartTime);
		}

		dispatch(
			updateElement({
				...origShape,
				startTime: newStartTime,
				endTime: newStartTime + duration,
			}),
		);
	};

	const capitaliseWord = (word: string) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	};

	return (
		<div className="ZoomTimeline">
			{shapeEdits?.map((edit, index) => {
				if (edit.endTime <= cp.videoDuration || !cp.videoDuration) {
					if (edit.id === activeElement) {
						return (
							<TimelineClip
								title={capitaliseWord(edit.geo)}
								theme="shape"
								index={index}
								key={`${edit.startTime}`}
								startTime={getAdjustedTime(edit.startTime)}
								endTime={getAdjustedTime(edit.endTime)}
								resizeClip={resizeShape}
								dragClip={dragShape}
								active
							/>
						);
					}
				} else {
					dispatch(deleteElement(edit.id));
					return null;
				}
			})}
		</div>
	);
};
export default ShapesTimeline;
