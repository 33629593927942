import React, { useState } from "react";
import "./CSlider.css";

import {
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberDecrementStepper,
	Flex,
	NumberIncrementStepper,
} from "@chakra-ui/react";
import CInput from "../CInput/CInput";

type CSliderProps = {
	label: string;
	value: number;
	setValue: (value: number) => void;
	disabled?: boolean;

	unit?: string;
	min?: number;
	max?: number;
};

export const round10 = (num: number) => {
	return Math.round(num * 10) / 10;
};

const CSlider: React.FC<CSliderProps> = (props) => {
	const handleChangeSlider = (value: number) => {
		if (props.disabled) return;
		props.setValue(value);
	};

	const onChangeInput = (value: number) => {
		if (props.disabled) return;
		props.setValue(value);
	};

	return (
		<div className="CSlider">
			<div className="C-label CSlider-label">{props.label}</div>

			<div className="CSlider-container">
				<Slider
					focusThumbOnChange={false}
					value={props.value}
					onChange={handleChangeSlider}
					className="CSlider-Slider"
					min={props.min || -0.01}
					max={props.max}
					step={0.05}
				>
					<SliderTrack className="CSlider-track">
						<SliderFilledTrack className="CSlider-filledtrack" />
					</SliderTrack>
					<SliderThumb className="CSlider-thumb" fontSize="sm" boxSize="12px" />
				</Slider>

				{/* TODO */}
				<div style={{ width: "25%" }}>
					<CInput
						value={round10(props.value)}
						onChange={onChangeInput}
						unit={props.unit}
					/>
				</div>
			</div>
		</div>
	);
};
export default CSlider;
