import React, { useEffect, useState } from "react";
import HomeLaptop from "./laptop/Home.laptop";
import HomeMobile from "./mobile/Home.mobile";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { Device } from "@gigauser/common/src/types/devices";
import {
	store,
	useAppDispatch,
	useAppSelector,
} from "@gigauser/common/src/redux";
import Overlay from "@gigauser/common/src/layouts/Overlay/Overlay";

import "./HomeLayout.css";
import { Collection } from "@giga-user-fern/api/types/api/resources/collections";
import { rootCollection } from "@gigauser/common/src/types/files";
import {
	loadingCollectionPath,
	updateCollectionPath,
} from "@gigauser/common/src/redux/slices/collectionPathSlice";
import logger from "@gigauser/common/src/utils/logger";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { useNavigate } from "react-router-dom";

type Props = {};

const HomeLayout: React.FC<Props> = () => {
	const { device } = useWindowSize();

	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	const navigate = useNavigate();
	useEffect(() => {
		initHome();
	}, []);

	const getPathFromRoute = async () => {
		var urlSegments = window.location.pathname.split("/");
		if (urlSegments.includes("home")) {
			navigate("/folders/Collection_root");
			urlSegments = window.location.pathname.split("/");
		}
		if (!urlSegments.includes("folders") && !urlSegments.includes("home")) {
			throw Error;
		}
		const collectionId = urlSegments.at(-1);

		let newPath: Collection[] = [];

		if (collectionId === "Collection_root") {
			newPath = [rootCollection];
			document.title = "Home";
		} else if (collectionId) {
			const sections = await saver.getPath(collectionId);
			if (sections) {
				logger.debug("getPath sections: ", sections);
				newPath = sections;
				const name = newPath.at(-1)?.name;
				if (name) document.title = name;
			} else {
				logger.debug("no sections");
			}
		}

		logger.debug("the path to initialise is: ", newPath);

		return newPath;
	};

	const initHome = async () => {
		// -------- Initialise the page ------------------

		const newPath = await getPathFromRoute();

		dispatch(updateCollectionPath(newPath as [Collection, ...Collection[]]));

		//------- Handle the back/forward button -----------

		window.addEventListener("popstate", async () => {
			//TODO: Fix this ...

			logger.debug("popstate time!");

			store.dispatch(loadingCollectionPath());
			const newPath = await getPathFromRoute();
			store.dispatch(
				updateCollectionPath(newPath as [Collection, ...Collection[]]),
			);
		});
	};

	return (
		<div className="HomeLayout">
			{device === "laptop" ? <HomeLaptop /> : <HomeMobile />}
		</div>
	);
};
export default HomeLayout;
