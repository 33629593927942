import React, { useContext } from "react";
import "./CDuration.css";
import Icon from "../../../Icon/Icon";
import timerIcon from "../../../../assets/svgs/timerIcon";
import { formatTime } from "../../../../core/canvas/canvasUtils/videoUtils";
import IBox from "../../../messages/iBox/iBox";
import CanvasPlayerContext from "../../../../core/canvas/CanvasPlayerContext";
import { useAppDispatch } from "../../../../redux";
import {
	closeMessagePopup,
	showErrorMessagePopup,
} from "../../../../layouts/MessagePopup/messagePopupSlice";

type CDurationProps = {
	startTime: number;
	endTime: number;

	setStartTime: (value: number) => void;
	setEndTime: (value: number) => void;
};

const CDuration: React.FC<CDurationProps> = (props) => {
	const cp = useContext(CanvasPlayerContext);

	const formattedStartTime = formatTime(cp.getAdjustedTime(props.startTime));
	const formattedEndTime = formatTime(cp.getAdjustedTime(props.endTime));

	const dispatch = useAppDispatch();

	const onSetStartTime = () => {
		if (!cp.paused) {
			dispatch(showErrorMessagePopup("Pause video to set timing."));
		}

		const requestedTime = cp.getUnadjustedTime(cp.currentTime);

		if (requestedTime < props.endTime) {
			props.setStartTime(Math.max(requestedTime, 0));
		} else {
			dispatch(
				showErrorMessagePopup("Start time can't be greater than end time."),
			);
			setTimeout(() => {
				dispatch(closeMessagePopup());
			}, 3000);
		}
	};

	const onSetEndTime = () => {
		if (!cp.paused) {
			dispatch(showErrorMessagePopup("Pause video to set timing."));
		}

		const requestedTime = cp.getUnadjustedTime(cp.currentTime);

		if (requestedTime > props.startTime) {
			props.setEndTime(Math.min(requestedTime, cp.videoDuration));
		} else {
			dispatch(
				showErrorMessagePopup("End time can't be less than start time."),
			);
			setTimeout(() => {
				dispatch(closeMessagePopup());
			}, 3000);
		}
	};

	return (
		<div className="CDuration">
			{/* <div className='C-label CDuration-label'>Duration</div> */}

			<div className="CDuration-container">
				<div className="CDuration-row CDuration-start">
					<Icon
						className="CDuration-icon"
						onClick={onSetStartTime}
						hoverTip="Set start time"
					>
						{timerIcon("#d6dce6")}
					</Icon>

					<p>Start</p>

					<div className="CDuration-time">
						<span className=" currTime mins">{formattedStartTime.minutes}</span>
						:
						<span className=" currTime secs">{formattedStartTime.seconds}</span>
						:
						<span className=" currTime secs">
							{formattedStartTime.milliseconds}
						</span>
					</div>
				</div>

				<div className="CDuration-row CDuration-end">
					<p>End</p>

					<div className="CDuration-time">
						<span className=" currTime mins">{formattedEndTime.minutes}</span>:
						<span className=" currTime secs">{formattedEndTime.seconds}</span>:
						<span className=" currTime secs">
							{formattedEndTime.milliseconds}
						</span>
					</div>

					<Icon
						className="CDuration-icon"
						onClick={onSetEndTime}
						hoverTip="Set end time"
					>
						{timerIcon("#d6dce6")}
					</Icon>
				</div>
			</div>

			<IBox>
				To edit timing, click anywhere in the timeline, then click the clock
				button.
			</IBox>

			<img
				src={
					"https://clueso-public-assets.s3.ap-south-1.amazonaws.com/web_assets/blur.gif"
				}
				className="new_zoom_img"
			></img>
		</div>
	);
};
export default CDuration;
