import React, { useEffect, useState } from "react";
import "./VersionHistory.css";
import VersionBox from "./VersionBox/VersionBox";
import closeIcon from "@gigauser/common/src/assets/svgs/closeIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectGuide,
	setCurrentVersions,
	setGuideData,
} from "@gigauser/common/src/redux/slices/guideSlice";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import { GuideVersion } from "@giga-user-fern/api/types/api/resources/guides";
import { formatDateTime } from "@gigauser/common/src/utils/os";
import { GuideData } from "@gigauser/common/src/core/types/guide";
import LoadingRing from "@gigauser/common/src/assets/gifs/LoadingRing/LoadingRing";
import { TextEditor } from "@gigauser/common/src/components/formats/RichText/TextEditor";
import CanvasPlayerController from "@gigauser/common/src/core/canvas/CanvasPlayerController/CanvasPlayerController";
import CanvasProvider from "@gigauser/common/src/core/canvas/CanvasProvider";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import useFetchGuidePreviewAndCheck from "@gigauser/common/src/redux/hooks/FileHooks/useFetchGuidePreviewAndCheck";
import useBackendBusy from "@gigauser/common/src/redux/hooks/FileHooks/useBackendBusy";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { closeFullScreenOverlay } from "@gigauser/common/src/layouts/FullScreenOverlay";

type VersionHistoryProps = {};

const VersionHistory: React.FC<VersionHistoryProps> = () => {
	const dispatch = useAppDispatch();
	const guide = useAppSelector(selectGuide);
	const saver = useAppSelector(selectSaver);

	const [activeVersion, setActiveVersion] = useState<GuideVersion | null>(null);
	const [activeGuideData, setActiveGuideData] = useState<GuideData | null>(
		null,
	);
	const [isRestoring, setIsRestoring] = useState(false);

	const backendBusy = useBackendBusy();
	const backendBusyBoolean = backendBusy ? true : false;

	/**
	 * VARIABLES TO BE REPLACED
	 */
	const [versions, setVersions] = useState<GuideVersion[]>([]);

	const onCloseVersions = () => {
		dispatch(closeFullScreenOverlay());
	};

	const initVersions = async () => {
		if (!guide) return;

		const versions = await saver.getAllVersions(guide);

		if (versions) {
			const sortedVersions = versions.sort(
				(a, b) => b.versionNumber - a.versionNumber,
			);

			//Loop through the sorted versions and set the updatedAt field to formatDateTime(updatedAt) then setVersions to the modified versions
			sortedVersions.forEach((version) => {
				version.updatedAt = formatDateTime(version.updatedAt);
			});

			setVersions(sortedVersions);
			var currentVersion = versions.find(
				(version) => version.versionNumber === guide?.guidePreview.versionSaved,
			);
			if (!currentVersion) currentVersion = versions[0];

			setActiveVersion(currentVersion);
		}
	};

	const submitRestore = async () => {
		if (!guide || !activeVersion) return;

		setIsRestoring(true);

		const res = await saver.restoreVersion(
			guide.id,
			activeVersion.versionNumber,
		);

		if (res) {
			// const {guideData, version} = res
			// dispatch(setGuideData(guideData))
			// dispatch(setCurrentVersions({
			//     saved: version
			// }))
			// dispatch(setShowVersionsPage(false))
		}

		setIsRestoring(false);
		window.location.reload();
	};

	useEffect(() => {
		initVersions();
	}, []);

	const initActiveVersion = async () => {
		if (!guide) return;

		setActiveGuideData(null);
		const guideData = await saver.fetchGuideData(
			guide?.id,
			activeVersion?.versionNumber,
		);

		setActiveGuideData(guideData);
	};

	useEffect(() => {
		initActiveVersion();
	}, [activeVersion]);

	//Get the current version
	const currentVersion = versions.find(
		(version) => version.versionNumber === guide?.guidePreview.versionSaved,
	);

	//Older versions
	const olderVersions = versions.filter(
		(version) => version.versionNumber !== currentVersion?.versionNumber,
	);

	var videoSrc = "";

	if (activeGuideData) {
		videoSrc =
			activeGuideData.video.generatedSrc ||
			activeGuideData.video.originalSrc ||
			"";
	}

	return (
		<div className="VersionHistory">
			<div className="Version-Preview">
				<div className="Version-Preview-Header">
					<div className="Version-Preview-left">
						<Icon
							className="Version-Preview-close"
							style={{
								opacity: isRestoring ? 0.5 : 1,
							}}
							onClick={isRestoring ? undefined : onCloseVersions}
						>
							{closeIcon("#ffffff")}
						</Icon>

						<div>
							<div className="Version-Title">
								{guide?.guidePreview.header.name}
							</div>
							{currentVersion ? (
								<div className="Version-Date">
									{" "}
									<b>Version: </b> {activeVersion?.updatedAt}{" "}
								</div>
							) : null}
						</div>
					</div>

					<div className="Version-Submit-button">
						<Cutton
							onClick={submitRestore}
							disabled={
								activeVersion?.versionNumber ===
									currentVersion?.versionNumber ||
								isRestoring ||
								backendBusyBoolean
							}
							isLoading={isRestoring}
							loadingText="Restoring..."
						>
							Restore this version
						</Cutton>
					</div>
				</div>

				<div className="Verison-Preview-Viewer-Container">
					{activeGuideData ? (
						<div className="Verison-Preview-Viewer">
							{videoSrc ? (
								<>
									<h2>VIDEO</h2>

									<div className="Version-Preview-CanvasPlayer">
										<CanvasProvider
											videoSrc={videoSrc}
											videoEdits={
												activeGuideData.video.videoEdits as VideoEdits
											}
											organization={saver.organization}
										>
											<CanvasPlayerController environment="ext-hc" />
										</CanvasProvider>
									</div>
								</>
							) : null}

							<h2>ARTICLE</h2>

							<TextEditor
								onOpenGuideFromId={() => {}}
								type={"helpCenter"}
								size={"l"}
								initialContent={activeGuideData.plainDoc.data as any}
								editable={false}
								appTheme={"gigauser-light"}
							/>
						</div>
					) : (
						<div className="VersionViewer-Loading">
							<LoadingRing />
						</div>
					)}
				</div>
			</div>

			<div className="Versions-List">
				<div className="List-Header">
					<h1 className="List-Header-Title">Version History</h1>

					<div className="Version-tag-container">
						<div className="Version-tag">Created</div>
						<div className="Version-tag-value">
							{formatDateTime(guide?.guidePreview.createdAt || "")}
						</div>
					</div>

					<div className="Version-tag-container">
						<div className="Version-tag">Author</div>
						<div className="Version-tag-value">
							{guide?.guidePreview.author
								? guide.guidePreview.author.includes("clueso@")
									? "Clueso admin"
									: guide.guidePreview.author
								: "Unknown"}
						</div>
					</div>
				</div>

				<div className="Version-List-Items">
					<h2>CURRENT VERSION</h2>
					{currentVersion ? (
						<VersionBox
							key={currentVersion.updatedAt}
							version={currentVersion}
							activeVersion={activeVersion}
							setActiveVersion={setActiveVersion}
						></VersionBox>
					) : null}

					<h2>OLDER VERSIONS</h2>

					{olderVersions.map((version) => (
						<VersionBox
							key={version.updatedAt}
							version={version}
							activeVersion={activeVersion}
							setActiveVersion={setActiveVersion}
						></VersionBox>
					))}
				</div>
			</div>
		</div>
	);
};
export default VersionHistory;
