import React, { useState } from "react";
import Icon from "../../../Icon/Icon";
import trashIcon from "../../../../assets/svgs/trashIcon";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { BackgroundOption } from "@giga-user-fern/api/types/api";
import { Modal } from "antd";
import {
	selectBackgroundEdits,
	updateBackgroundEdits,
} from "../../../../redux/slices/guideSlice";
import { setActiveBackgroundTemplate } from "../../../../redux/slices/platformUiSlice";
import { Tooltip } from "@chakra-ui/react";
import { selectSaver } from "../../../../redux/slices/backendSlice";
import { useToast } from "@chakra-ui/react";
import "./CBackgrounds.css";
import { uploadIcon } from "../../../../assets/svgs/uploadIcon";
import { starIcon } from "../../../../assets/svgs/starIcon";
import { v4 as uuidv4 } from "uuid";
import { addBackground } from "../../../../redux/slices/platformDetailsSlice";
import { GigaUserApi } from "@giga-user-fern/api";

type CBackgroundsProps = {
	onClickNew: () => void;
	onClickDelete: (backgroundOption: BackgroundOption) => void;
};

export const CBackgrounds: React.FC<CBackgroundsProps> = (props) => {
	const backgrounds = useAppSelector(
		(state) => state.platformDetails.value?.background,
	);

	const premadeBackgrounds = useAppSelector(
		(state) => state.platformDetails.value?.premadeBackgrounds,
	);
	const activeTemplate = useAppSelector(
		(state) => state.platformUi.value.activeBackgroundTemplate,
	);

	const [bgToDelete, setBgToDelete] = useState<BackgroundOption | null>(null);
	const [addToFavourites, setAddToFavourites] =
		useState<BackgroundOption | null>(null);

	const dispatch = useAppDispatch();
	const backgroundEdits = useAppSelector(selectBackgroundEdits);
	const saver = useAppSelector(selectSaver);
	const toast = useToast();

	const onDeleteBackground = () => {
		if (bgToDelete) props.onClickDelete(bgToDelete);
		setBgToDelete(null);
	};

	const tags: Set<string> = new Set<string>();
	premadeBackgrounds?.forEach((template) => {
		template.tags?.forEach((tag) => {
			tags.add(tag);
		});
	});
	const [activeTag, setActiveTag] = useState<string | null>(null);

	const onChangeBackground = (bgOptions: BackgroundOption) => {
		dispatch(
			updateBackgroundEdits({
				...backgroundEdits,
				src: bgOptions.background.src,
			}),
		);
		dispatch(setActiveBackgroundTemplate(bgOptions.id));
	};

	const onSetDefaultTemplate = () => {
		if (activeTemplate) {
			const promise = saver.setDefaultBackground(activeTemplate);

			toast.promise(promise, {
				success: {
					title: `Set default background`,
					description: "Looks great!",
				},
				error: {
					title: `Error setting default background!`,
					description: "Try again later",
				},
				loading: {
					title: "Working ...",
					description: "Setting default background",
				},
			});
		} else {
			toast({
				title: `No template selected!`,
				description: "Choose a template to set it as default.",
				status: "warning",
				duration: 6000,
				isClosable: true,
				position: "top",
			});
		}
	};

	return (
		<div className="CBackgrounds">
			<div className="Set-default-container">
				<Tooltip
					placement="right"
					borderRadius={6}
					bg="#303030"
					className="editor-button-tooltip"
					color={"white"}
					label={"All new videos will use this template"}
				>
					<div className="set-default-message" onClick={onSetDefaultTemplate}>
						Set selected as default
					</div>
				</Tooltip>
			</div>

			<div className="My-Bgs-Title">
				<p>My Backgrounds</p>
			</div>

			<div className="BackgroundGrid">
				<div className="NewBg BackgroundPreview" onClick={props.onClickNew}>
					<Icon>{uploadIcon("white")}</Icon>
				</div>

				{backgrounds?.options.map((template) => {
					return (
						<div
							key={template.id}
							data-bg-id={template.id}
							className={`BackgroundPreview BgContainer ${activeTemplate == template.id ? "active" : "inactive"}`}
							onClick={() => {
								onChangeBackground(template);
							}}
						>
							<img className="bg-img" src={template.background.src}></img>

							<div
								className="hoverOptions"
								onClick={() => {
									setBgToDelete(template);
								}}
							>
								<Icon className="hoverIcon">{trashIcon("#ffa69e")}</Icon>
							</div>
						</div>
					);
				})}

				<Modal
					title="Delete Background"
					open={bgToDelete ? true : false}
					onOk={onDeleteBackground}
					onCancel={() => {
						setBgToDelete(null);
					}}
				>
					<p>{"Are you sure? You can't undo this action afterwards."}</p>
				</Modal>
			</div>

			<div className="Premade-Bgs-Title">
				<p>Premade Backgrounds</p>
			</div>

			<div className="Premade-Tags-Chips">
				{Array.from(tags).map((tag) => {
					return (
						<div
							onClick={() => {
								if (activeTag === tag) {
									setActiveTag(null);
								} else {
									setActiveTag(tag);
								}
							}}
							className={`Premade-Tags-Chip ${tag === activeTag ? "active" : ""}`}
							key={tag}
						>
							{tag}
						</div>
					);
				})}
			</div>

			<div className="BackgroundGrid">
				{premadeBackgrounds
					?.filter((template) => {
						if (activeTag) {
							return template.tags?.includes(activeTag);
						}
						return true;
					})
					.map((template) => {
						return (
							<div
								key={template.id}
								data-bg-id={template.id}
								className={`BackgroundPreview BgContainer ${activeTemplate == template.id ? "active" : "inactive"}`}
								onClick={() => {
									onChangeBackground(template);
								}}
							>
								<img className="bg-img" src={template.background.src}></img>
								<Icon
									onClick={() => {
										setAddToFavourites(template);
									}}
									className="starIcon"
								>
									{starIcon()}
								</Icon>
							</div>
						);
					})}

				<Modal
					title="Add this background to favorites?"
					open={addToFavourites ? true : false}
					onOk={() => {
						if (addToFavourites) {
							const addToFavouritesCopy = { ...addToFavourites };
							addToFavouritesCopy.id = GigaUserApi.Id(uuidv4());
							dispatch(addBackground(addToFavouritesCopy));
							onChangeBackground(addToFavouritesCopy);
							setAddToFavourites(null);
						}
					}}
					onCancel={() => {
						setAddToFavourites(null);
					}}
				>
					<p>{"This will add this image to your backgrounds"}</p>
				</Modal>
			</div>
		</div>
	);
};
