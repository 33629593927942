import React from "react";

import { HeaderProps } from "..";
import PageTitle from "../common/PageTitle/PageTitle";

import TopbarButtons from "../common/TopbarButtons/TopbarButtons";

import "./Header.mobile.css";

const Header: React.FC<HeaderProps> = () => {
	return (
		<div className="Header mobile">
			<div className="PageTitle-container Header-item ">
				<PageTitle />
			</div>

			<div className="TopbarButtons-container Header-item">
				<TopbarButtons />
			</div>

			<div className="ProfileButton-container Header-item">
				{/* <ProfileButton /> */}
			</div>
		</div>
	);
};
export default Header;
