import React, { useEffect, useRef, useState } from "react";
import "./CropVideo.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	batchUpdateElement,
	batchUpdateZoom,
	selectGuide,
	selectVideo,
	setCrop,
} from "@gigauser/common/src/redux/slices/guideSlice";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import CDropdown from "@gigauser/common/src/ui/dropdown/CDropdown/CDropdown";
import { selectAnalytics } from "@gigauser/common/src/redux/slices/backendSlice";

import cropIcon from "@gigauser/common/src/assets/svgs/videoeditor/cropIcon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import asteriskIconCircled from "@gigauser/common/src/assets/svgs/asteriskIconCircled";
import DragController from "@gigauser/common/src/components/formats/RichText/components/EditScreenshot/components/SizeController/DragController";
import {
	Shape,
	UpdateShapeFunction,
} from "@gigauser/common/src/components/formats/RichText/components/EditScreenshot/ScreenshotEditorContext";
import { Coordinates } from "@giga-user-fern/api/types/api";
import SizeControllerThumb, {
	SizeControllerPos,
} from "@gigauser/common/src/components/formats/RichText/components/EditScreenshot/components/SizeController/SizeControllerThumb";
import {
	closeOverlay,
	setDisableClickoutOverlay,
} from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import {
	Crop,
	ElementEdit,
	ZoomEdit,
} from "@giga-user-fern/api/types/api/resources/video";
import { DEFAULT_CROP } from "@gigauser/common/src/core/canvas/videoEditTypes/core";
import { DisplayMenuItem } from "@gigauser/common/src/ui/menus/Menu/Menu";
import { Device } from "@gigauser/common/src/types/devices";
import { getVideoDevice } from "../../../../../utils/dimensionUtils";

type CropVideoProps = {
	currentTime?: number;
};

type AspectRatio = "16:9" | "4:3" | "1:1" | "3:4" | "9:16" | "Any";

const CropVideo: React.FC<CropVideoProps> = (props) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const video = useAppSelector(selectVideo);
	const guide = useAppSelector(selectGuide);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);

	const [aspectRatio, _setAspectRatio] = useState<AspectRatio>("Any");
	const analytics = useAppSelector(selectAnalytics);

	const [device, setDevice] = useState<Device | null>(null);

	const aspectRatioLabelToValue = (label: AspectRatio) => {
		if (label === "Any") return 0;

		try {
			const [numerator, denominator] = label.split(":");
			const value = parseFloat(numerator) / parseFloat(denominator);

			return value;
		} catch {
			return 0;
		}
	};

	const prevCrop = guide?.guideData.video.videoEdits?.crop || DEFAULT_CROP;

	const [stateCrop, setStateCrop] = useState<Crop>(prevCrop);

	useEffect(() => {
		if (device) setLoading(false);
	}, [device]);

	const setAspectRatio: (a: AspectRatio) => void = (a) => {
		const video = videoRef.current;
		if (!video) return;

		const newAspectRatio = aspectRatioLabelToValue(a);
		const [currentWidth, currentHeight] = stateCrop.size;

		const currentAspectRatio = aspectRatioLabelToValue(aspectRatio);

		if (newAspectRatio === 0) {
			// If the ratio is invalid, set the crop size to the default ratio
			_setAspectRatio("Any");
			return;
		} else {
			let newWidth: number;
			let newHeight: number;

			let cropWidth: number;
			let cropHeight: number;

			const videoWidth = videoRef.current.videoWidth;
			const videoHeight = videoRef.current.videoHeight;

			// Calculate the aspect ratio of the video
			const videoAspectRatio = videoWidth / videoHeight;

			if (newAspectRatio > videoAspectRatio) {
				// The desired crop is wider than the video aspect ratio
				cropWidth = videoWidth;
				cropHeight = cropWidth / newAspectRatio;
			} else {
				// The desired crop is taller than the video aspect ratio
				cropHeight = videoHeight;
				cropWidth = cropHeight * newAspectRatio;
			}

			newWidth = cropWidth / videoWidth;
			newHeight = cropHeight / videoHeight;

			setStateCrop((prevCrop) => ({
				...prevCrop,
				position: [0, 0],
				size: [newWidth, newHeight],
			}));

			_setAspectRatio(a);
		}
	};

	useEffect(() => {
		dispatch(setDisableClickoutOverlay(true));

		const video = videoRef.current;
		if (!video) return;

		const handleMetadataLoaded = () => {
			if (props.currentTime) video.currentTime = props.currentTime;
			setDevice(getVideoDevice(video.videoWidth, video.videoHeight));
		};

		video.addEventListener("loadedmetadata", handleMetadataLoaded);
		return () =>
			video.removeEventListener("loadedmetadata", handleMetadataLoaded);
	}, []);

	const onDiscard = () => {
		dispatch(closeOverlay());
	};

	const pixelsToFractionalCoords: (pos: Coordinates) => Coordinates = (pos) => {
		if (!videoRef.current) return pos;

		const rect = videoRef.current.getBoundingClientRect();

		const cf = {
			x: pos.x / rect.width,
			y: pos.y / rect.height,
		};

		return cf;
	};

	const fractionalCoordsToPixels: (pos: Coordinates) => Coordinates = (pos) => {
		if (!videoRef.current) return pos;

		const rect = videoRef.current.getBoundingClientRect();

		return {
			x: pos.x * rect.width,
			y: pos.y * rect.height,
		};
	};

	const updateCrop: UpdateShapeFunction = (id, updatedProperties) => {
		const { position, size } = updatedProperties;

		setStateCrop({
			position: position || stateCrop.position,
			size: size || stateCrop.size,
		});
	};

	const resetCrop = () => {
		setStateCrop({
			position: [0, 0],
			size: [1, 1],
		});
		setAspectRatio("Any");
	};

	const pixelCrop = {
		position: fractionalCoordsToPixels({
			x: stateCrop.position[0],
			y: stateCrop.position[1],
		}),
		size: fractionalCoordsToPixels({
			x: stateCrop.size[0],
			y: stateCrop.size[1],
		}),
	};

	const shape: Shape = {
		id: "ignore",
		geo: "rectangle",
		position: stateCrop.position,
		size: stateCrop.size,
	};

	var sizeControls: SizeControllerPos[] = ["tl", "tr", "br", "bl"];

	if (aspectRatio == "Any") {
		sizeControls = [...sizeControls, "t", "l", "b", "r"];
	}

	const recomputeVideoEdits = async () => {
		const videoEdits = video?.videoEdits;
		const newCrop = stateCrop;
		const originalCrop = prevCrop;

		//#region recompute all the elements
		let updatedElements: ElementEdit[] = [];
		let deletedElements: ElementEdit[] = [];

		if (videoEdits?.elements) {
			videoEdits.elements.forEach((element) => {
				// Convert element's position and size from originalCrop reference to full video reference
				const fullVideoPos: [number, number] = [
					originalCrop.position[0] + element.position[0] * originalCrop.size[0],
					originalCrop.position[1] + element.position[1] * originalCrop.size[1],
				];

				const fullVideoSize: [number, number] = [
					element.size[0] * originalCrop.size[0],
					element.size[1] * originalCrop.size[1],
				];

				// Convert position and size from full video reference to newCrop reference
				const newPos: [number, number] = [
					(fullVideoPos[0] - newCrop.position[0]) / newCrop.size[0],
					(fullVideoPos[1] - newCrop.position[1]) / newCrop.size[1],
				];

				const newSize: [number, number] = [
					fullVideoSize[0] / newCrop.size[0],
					fullVideoSize[1] / newCrop.size[1],
				];

				// Check if the element is within the new crop boundaries
				const isInNewCrop =
					newPos[0] < 1 &&
					newPos[0] + newSize[0] > 0 &&
					newPos[1] < 1 &&
					newPos[1] + newSize[1] > 0;

				if (isInNewCrop) {
					// Adjust the position and size to ensure they are within the new crop boundaries
					const adjustedPos: [number, number] = [
						Math.max(newPos[0], 0),
						Math.max(newPos[1], 0),
					];

					const adjustedSize: [number, number] = [
						Math.min(1 - adjustedPos[0], newSize[0]),
						Math.min(1 - adjustedPos[1], newSize[1]),
					];

					// Check if element ends up outside the new crop (fully or partially)
					if (adjustedSize[0] <= 0 || adjustedSize[1] <= 0) {
						deletedElements.push(element);
					} else {
						const updatedElement = { ...element };
						updatedElement.position = adjustedPos;
						updatedElement.size = adjustedSize;
						updatedElements.push(updatedElement);
					}
				} else {
					// Element is completely outside the new crop area
					deletedElements.push(element);
				}
			});
		}

		dispatch(batchUpdateElement({ updatedElements, deletedElements }));

		//#endregion

		//#region recompute all the zooms

		let updatedZooms: ZoomEdit[] = [];
		let deletedZooms: ZoomEdit[] = [];

		if (videoEdits?.zooms) {
			videoEdits.zooms.forEach((zoom) => {
				// Convert zoom center from originalCrop reference to full video reference
				const fullVideoZoomCenter = {
					x:
						originalCrop.position[0] + zoom.zoomCenter.x * originalCrop.size[0],
					y:
						originalCrop.position[1] + zoom.zoomCenter.y * originalCrop.size[1],
				};

				// Convert zoom center from full video reference to newCrop reference
				const newZoomCenter = {
					x: (fullVideoZoomCenter.x - newCrop.position[0]) / newCrop.size[0],
					y: (fullVideoZoomCenter.y - newCrop.position[1]) / newCrop.size[1],
				};

				// Adjust the zoom center to ensure it is within the new crop boundaries
				newZoomCenter.x = Math.max(0, Math.min(newZoomCenter.x, 1));
				newZoomCenter.y = Math.max(0, Math.min(newZoomCenter.y, 1));

				const updatedZoom = { ...zoom };
				updatedZoom.zoomCenter = newZoomCenter;
				updatedZooms.push(updatedZoom);
			});
		}

		dispatch(batchUpdateZoom({ updatedZooms, deletedZooms }));

		//#endregion zooms
	};

	const onSaveChanges = async () => {
		await recomputeVideoEdits();

		if (guide?.id) {
			analytics.captureEvent({
				eventName: "CropVideo",
				value: { guide_id: guide.id.toString() },
			});
		}

		dispatch(
			setCrop({
				position: stateCrop.position,
				size: stateCrop.size,
			}),
		);

		dispatch(closeOverlay());
	};

	const aspectRationMenu: DisplayMenuItem[] = [
		{
			label: "Any",
			handler: () => {
				setAspectRatio("Any");
			},
		},
		{
			label: "16:9",
			handler: () => {
				setAspectRatio("16:9");
			},
		},
		{
			label: "4:3",
			handler: () => {
				setAspectRatio("4:3");
			},
		},
		{
			label: "1:1",
			handler: () => {
				setAspectRatio("1:1");
			},
		},
		{
			label: "3:4",
			handler: () => {
				setAspectRatio("3:4");
			},
		},
		{
			label: "9:16",
			handler: () => {
				setAspectRatio("9:16");
			},
		},
	];

	return (
		<div className="CropVideo">
			<div className={`CropVideo-video-container crop-${device}`}>
				<video
					className="CropVideo-video"
					ref={videoRef}
					controls={false}
					src={video?.originalSrc}
				></video>
				{!loading ? (
					<div
						className="CropVideo-selector"
						style={{
							left: `${pixelCrop.position.x}px`,
							top: `${pixelCrop.position.y}px`,
							width: `${pixelCrop.size.x}px`,
							height: `${pixelCrop.size.y}px`,
						}}
					>
						<DragController
							shape={shape}
							zoomFactor={1}
							getRelativeCoords={pixelsToFractionalCoords}
							updateShape={updateCrop}
							boundLimits
						/>
						{sizeControls.map((pos) => (
							<SizeControllerThumb
								shape={shape}
								position={pos}
								key={pos}
								zoomFactor={1}
								getRelativeCoords={pixelsToFractionalCoords}
								updateShape={updateCrop}
								boundLimits
								lockRatio={aspectRatio !== "Any"}
							/>
						))}
					</div>
				) : null}
			</div>

			<div className="CropVideo-buttons">
				<div className="CropVideo-buttons-case">
					<div style={{ zIndex: 99 }}>
						<CDropdown
							menu={aspectRationMenu}
							activeLabel={aspectRatio}
							position="top"
						/>
					</div>

					<Cutton
						onClick={resetCrop}
						leftIcon={cropIcon("white")}
						rank="secondary"
					>
						Reset crop
					</Cutton>
				</div>

				<div className="CropVideo-buttons-case">
					<Cutton
						leftIcon={trashIcon("white")}
						rank="secondary"
						onClick={onDiscard}
					>
						Cancel
					</Cutton>

					<Cutton
						leftIcon={asteriskIconCircled("white")}
						onClick={onSaveChanges}
					>
						Save changes
					</Cutton>
				</div>
			</div>
		</div>
	);
};
export default CropVideo;
