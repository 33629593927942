const rectangleShapeIcon = (color: string = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path fill={color} d="M1 21h22V3H1zM2 4h20v16H2z" />
			<path fill="none" d="M0 0h24v24H0z" />
		</svg>
	);
};
export default rectangleShapeIcon;
