import React, { useReducer, useState } from "react";
/**
 * We could use render props method or cloneElement method. Going ahead with renderProps method
 */
import TitledInput, {
    TitledInputOption,
	TitledInputProps,
} from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import "./Form.css";
import Cutton, { CuttonProps } from "../../ui/buttons/Cutton/Cutton";

export type FormProps = {
	className?: string;
	setFormState: (formState: FormState) => void;
	submit: (formState: any) => (Promise<any>);
	onSuccess: (res: any) => void;
	onError?: (errors: any) => void;
	children: React.ReactElement[]; //elements can be titled inputs or cuttons
	initFormState?: FormState;
};

export type FormStateValue = {
	value: string;
	error?: string | boolean;

    //design
    label?: string;
	options?: TitledInputOption[];
    placeholder?: string;
};

export type FormState = {
	[key: string]: FormStateValue;
} & {
	error?: string;
	success?: string;
};

const Form: React.FC<FormProps> = ({
	setFormState,
	onSuccess,
	onError,
	children,
	initFormState,
	...props
}) => {
	/**
	 * @props
	 * children: The components to be rendered
	 * submit: () =>{} (The function to be run on submitting the form)
	 * setFormState: ()=>{} (The useState function in the parent component of the Form)
	 * initFormState?: formState (The initial state of the form)
	 */

	const [loading, setLoading] = useState(false);

	var fields: string[] = [];

	for (var element of children) {
		if (element.type === TitledInput && "id" in element.props) {
			fields.push(element.props.id);
		}
	}

	const init = {
		value: "",
		error: undefined,
	};

	var initialFormState: FormState = {};

	for (var field of fields) {
		if (initFormState && initFormState[field])
			initialFormState[field] = { ...initFormState[field] };
		else initialFormState[field] = { ...init };
	}

	if (initFormState?.success) initialFormState.success = initFormState.success;
	if (initFormState?.error) initialFormState.error = initFormState.error;

	const formStateReducer = (
		formState: FormState,
		action: {
			type: "error" | "value";
			payload?: string;
			field?: string;
		},
	) => {
		var formStateCopy = { ...formState };

		if (action.type === "error" && !action.field) {
			formStateCopy.error = action.payload;
			return formStateCopy;
		}

		if (action.field) {
			formStateCopy[action.field][action.type] = action.payload || "";
			setFormState(formStateCopy);
		}

		return formStateCopy;
	};

	const [formState, dispatchFormChange] = useReducer(
		formStateReducer,
		initialFormState,
	);

	const onChange = (e: any) => {
		dispatchFormChange({
			type: "value",
			field: e.target.id,
			payload: e.target.value,
		});

		dispatchFormChange({
			type: "error",
			field: e.target.id,
			payload: undefined,
		});

		dispatchFormChange({
			type: "error",
			payload: undefined,
		});
	};

	const onSubmit = async () => {
		setLoading(true);

		props
			.submit(formState)
			.then((res) => {
				onSuccess(res);
			})
			.catch((errors) => {
				errors.map((error: any) => {
					dispatchFormChange({
						type: "error",
						field: error.field,
						payload: error.message || true,
					});
				});

				if (onError) onError(errors);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	var l = children.length;

	var transformedChildren = children.map((element) => {
		//Too many renders here
		//TO OPTIMIZE

		if (element.type == TitledInput && "id" in element.props) {
			//Transform Inputs
			// console.log("Here is a titled input: ", element)

			const a = React.cloneElement(element, {
				onChange: onChange,
				value: formState[element.props.id].value,
				error: formState[element.props.id].error,
				onEnter: onSubmit,
				style: {
					zIndex: l,
				},
			});

			return a;
		} else if (element.type == Cutton) {
			//Transform Buttons
			// console.log("Here is a button: ", element)
			if (element.props.onClick) {
				return React.cloneElement(element, {
					onClick: onSubmit,
					isLoading: loading,
				});
			} else return element;
		} else {
			console.error(
				"unknown element in form: ",
				element,
				element.type,
			);

			return element;
		}
		l -= 1;
	});

	return (
		<form autoComplete="off" className={`gigauser-Form ${props.className}`}>
			<p className="gen-form-caption gen-form-error">{formState.error}</p>
			<p className="gen-form-caption gen-form-success">{formState.success}</p>

			<div className="form-children">{transformedChildren}</div>
		</form>
	);
};

export default Form;
