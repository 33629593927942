import { Id, Language } from "@giga-user-fern/api/types/api";
import React, { useEffect, useState } from "react";
import LanguagePicker from "@gigauser/common/src/overlays/LanguagePicker/LanguagePicker";
import "./UploadGuideOverlay.css";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import FileUpload from "@gigauser/common/src/ui/inputs/file/FileUpload/FileUpload";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { setVideoUploadProgress } from "@gigauser/common/src/redux/slices/platformUiSlice";
import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import PreparingGuide from "../PreparingGuide/PreparingGuide";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";
import { FileFormat } from "@giga-user-fern/api/types/api/resources/guides";

type UploadGuideOverlayProps = {
	currentFolderId: Id;
};

const UploadGuideOverlay: React.FC<UploadGuideOverlayProps> = (props) => {
	const [file, setFile] = useState<File | null>(null);
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);

	const onChangeFile = (file: any) => {
		setFile(file);
	};

	const [currentLanguage, _setCurrentLanguage] = useState<Language>({
		languageId: "en",
		name: "English",
	});

	const initCurrentLanguage = async () => {
		var currentLanguage = localStorage.getItem("uploadVideoLanguage");
		if (currentLanguage) {
			const currentLanguageItem = JSON.parse(currentLanguage) as Language;
			_setCurrentLanguage(currentLanguageItem);
		}
	};

	useEffect(() => {
		initCurrentLanguage();
	}, []);

	const setCurrentLanguage = (language: Language) => {
		localStorage.setItem("uploadVideoLanguage", JSON.stringify(language));
		_setCurrentLanguage(language);
	};

	const onSubmit = () => {
		const onProgress = (progress: number) => {
			dispatch(setVideoUploadProgress(progress));
		};

		if (!file) return;
		const fileName = file.name; // Get the name of the file including extension

		// Extract the extension
		const extension = fileName.slice(fileName.lastIndexOf(".") + 1);
		let format: FileFormat = "video";
		if (extension === "pdf") {
			format = "pdf";
		} 

		return new Promise((resolve, reject) => {
			dispatch(setVideoUploadProgress(0));

			dispatch(
				openOverlay({
					heading: {
						icon: plusIcon(),
						title: "Preparing ...",
						data: "",
					},
					component: <PreparingGuide progressable />,
				}),
			);

			saver
				.uploadCreateGuide(
					file,
					currentLanguage.languageId,
					format,
					props.currentFolderId,
					onProgress,
				)
				.then((id) => {
					if (id) {
						dispatch(closeOverlay());

						window.location.href = "/guide/" + id;
						resolve(true);
					} else {
						dispatch(closeOverlay());

						console.log("NO ID");
						reject(false);
					}
				});
		});
	};

	return (
		<div className="UploadGuideOverlay">
			<div className="">
				<div className="LanguagePicker-Upload">
					<div className="LanguagePicker-text">Input language:</div>
					<LanguagePicker
						onlyTranscriptable
						position="bottom"
						currentLanguage={currentLanguage}
						setCurrentLanguage={setCurrentLanguage}
					/>
				</div>

				<div className="LanguageUploadInfo">
					Choose the language being spoken in the uploaded video.
				</div>

				<div className="LanguagePicker-text videofile">Video file:</div>

				<div className="LanguagePicker-File">
					<FileUpload
						fileTypes={[".mp4", ".mov", ".wav", ".webm", ".pdf"]}
						file={file}
						onChange={onChangeFile}
					/>
				</div>

				<Cutton onClick={onSubmit} disabled={!file}>
					Upload
				</Cutton>
			</div>
		</div>
	);
};
export default UploadGuideOverlay;
