import React from "react";
import PreparingGuide from "../../../home/components/NewButton/PreparingGuide/PreparingGuide";
import "./CreatingGuide.css";
import logoIcon from "@gigauser/common/src/assets/svgs/logoIcon";

type CreatingGuideProps = {};

const CreatingGuide: React.FC<CreatingGuideProps> = () => {
	return (
		<div className="CreatingGuide">
			<div className="clueso-logo">{logoIcon()}</div>

			<PreparingGuide env="web" tip={true} />
		</div>
	);
};

export default CreatingGuide;
