import React, { useState } from "react";
import "./Topbar.css";
import ProfileButton from "@gigauser/common/src/auth/ProfileButton/ProfileButton";
import Searchbar from "../Searchbar/Searchbar";
import TopbarButtons from "../../common/TopbarButtons/TopbarButtons";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { Organization } from "@gigauser/common/src/core/types/guide";
import settingsIcon from "@gigauser/common/src/assets/svgs/settingsIcon";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import OrganizationSettings from "../../../../../../features/organization/OrganizationSettings";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";

type TopbarProps = {};

const Topbar: React.FC<TopbarProps> = () => {
	const saver = useAppSelector(selectSaver);

	if (!saver.organization) throw new Error("Organization is missing!");

	const [showFrill, setShowFrill] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const onLogOut = () => {};

	return (
		<div className="Topbar laptop">
			{showFrill ? (
				<div className={`frill-feedback-dropdown ${showFrill ? "active" : ""}`}>
					Hello there
					{/* <iframe frameBorder="0" title = "Feedback" height="100%" width="100%" src = {`https://feedback.desklamp.io/embed/widget/57cdd1fa-8500-4990-9e59-3489cf9708e6/?ssoToken=${this.state.frillUserToken}`} ></iframe> */}
				</div>
			) : null}

			<div className="SearchBar-container Topbar-item">
				<Searchbar />
			</div>
			<div className="sidebuttons-container">
				<div className="TopbarButtons-container Topbar-item">
					<TopbarButtons />
				</div>
				<div className="ProfileButton-container Topbar-item">
					<ProfileButton
						environment="platform"
						organization={saver.organization}
						setOrganization={(organization: Organization) => {
							localStorage.setItem("organization_id", organization.id);
							window.location.assign("/");
						}}
						dropdownMenu={{
							menu: [
								// {
								//     label: "My Organization",
								//     icon: settingsIcon(),
								//     closeMenuOnClick: true,
								//     handler: () =>{
								//         dispatch(openOverlay({
								//             heading: {
								//                 icon: settingsIcon(),
								//                 title: `Edit Organization`,
								//             },
								//             component: <OrganizationSettings />
								//         }))
								//     }
								// },
							],
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Topbar;
