const introIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 32 31"
			fill="none"
		>
			<path
				d="M5 16V9.43402C5 8.74401 5.29287 8.08642 5.80575 7.62483V7.62483C6.25269 7.22258 6.83272 7 7.43402 7H24.566C25.1673 7 25.7473 7.22258 26.1943 7.62483V7.62483C26.7071 8.08642 27 8.74401 27 9.43402V14.2"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M5 2V5.03647C5 5.7802 5.4202 6.4601 6.08541 6.79271V6.79271C6.35806 6.92903 6.6587 7 6.96353 7H25.0365C25.3413 7 25.6419 6.92903 25.9146 6.79271V6.79271C26.5798 6.4601 27 5.7802 27 5.03647V3"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M5 28V24.9635C5 24.2198 5.4202 23.5399 6.08541 23.2073V23.2073C6.35806 23.071 6.6587 23 6.96353 23H25.0365C25.3413 23 25.6419 23.071 25.9146 23.2073V23.2073C26.5798 23.5399 27 24.2198 27 24.9635V27"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M5 13V20.4393C5 21.1185 5.26978 21.7698 5.75 22.25V22.25C6.23022 22.7302 6.88153 23 7.56066 23H24.4393C25.1185 23 25.7698 22.7302 26.25 22.25V22.25C26.7302 21.7698 27 21.1185 27 20.4393V15"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 1V30"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M5 1V30"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M31 1V30"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M0.996124 3.875H4.91021"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 28H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 7H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 10H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 13H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 16H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 19H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 22H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 25H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 1V30"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M0.996124 3.875H4.91021"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 28H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 7H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 10H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 13H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 16H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 19H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 22H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M1 25H4.91408"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 1V30"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27 3.875H30.9141"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 28H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 7H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 10H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 13H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 16H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 19H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 22H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 25H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 1V30"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27 3.875H30.9141"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 28H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 7H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 10H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 13H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 16H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 19H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 22H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<path
				d="M27.0039 25H30.918"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="square"
			/>
			<circle cx="16" cy="15" r="5.25" stroke={color} stroke-width="1.5" />
			<path d="M15 14L16 13H16.5V17" stroke={color} stroke-linecap="square" />
		</svg>
	);
};
export default introIcon;
