import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@gigauser/common/src/redux";
import {
	selectCollectionPath,
	updateCollectionPath,
} from "@gigauser/common/src/redux/slices/collectionPathSlice";
import { EnvironmentContextValue } from "../../../contexts";
import { rootCollection } from "@gigauser/common/src/types/files";

import useGetPath from "./useGetPath";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { GigaUserApi } from "@giga-user-fern/api";
import logger from "../../../utils/logger";

const useCollectionNavigate: (
	env: EnvironmentContextValue,
) => (
	folder: Collection,
	newPath?: [Collection, ...Collection[]] | null,
) => void = (env) => {
	// This hook returns a function that allows the consumer to navigate to a given folder
	// given a folder object.
	// In web, this means updating the path in the app state and also changing the route

	// const navigate = env.navigator()
	// Env.navigator is a hook (useNavigate in web)

	//TODO: this navigate function should be set in env ideally.
	const navigate = env.navigator();

	// Navigate is a function that will actually be able to navigate

	const collectionPath = useAppSelector(selectCollectionPath);
	const dispatch = useAppDispatch();
	const backendGetPath = useGetPath();

	// We must update folderPath based on ID given in the core function

	return async (
		_collection: Collection,
		newPath?: [Collection, ...Collection[]] | null,
	) => {
		// We first create updated folderPath state

		const collection = { ..._collection };

		if (!collection.parentId) {
			logger.debug("collection missing parentId");
			collection.parentId = GigaUserApi.Id("Collection_root");
		}

		logger.debug("collection: ", collection);

		logger.debug("going to navigate: ", collection, newPath);

		let newSegments: [Collection, ...Collection[]] = collectionPath.segments;
		logger.debug("folderPath: ", collectionPath);

		if (newPath) {
			dispatch(updateCollectionPath(newPath));
			navigate("/folders/" + collection.id);
			document.title = collection.name;
			return;
		}
		const folderExists = newSegments.findIndex((x) => x.id === collection.id);
		logger.debug("folderExists: ", folderExists);

		if (collection.id === "Collection_root") {
			newSegments = [rootCollection];
		} else if (collection.parentId === "Collection_root") {
			// This is a root folder: it is either in the shared files folder or the root
			// For now, I put it in my library
			newSegments = [rootCollection, collection];
		} else if (folderExists >= 0) {
			newSegments = newSegments.slice(0, folderExists + 1) as [
				Collection,
				...Collection[],
			];
		} else {
			const parentExists = newSegments.findIndex(
				(x) => x.id === collection.parentId,
			);
			logger.debug("parentExists: ", parentExists);

			if (parentExists !== -1) {
				newSegments = newSegments
					.slice(0, parentExists + 1)
					.concat([collection]) as [Collection, ...Collection[]];
			} else {
				//There is no path provided
				logger.debug("no path provided!");
				const sections = await backendGetPath(collection.id);
				if (sections) {
					//TODO: Fix backendGetPath function.
					// newSegments = sections
					logger.debug("need to fix here: ", sections);
				} else {
					newSegments = [rootCollection];
				}
				logger.debug("newSegments errored: ", rootCollection);
			}
		}
		logger.debug("updateFolderPath: ", newSegments);
		dispatch(updateCollectionPath(newSegments));
		navigate("/folders/" + collection.id);
		document.title = collection.name;
	};
};

export default useCollectionNavigate;
