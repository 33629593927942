import React from "react";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { Device } from "@gigauser/common/src/types/devices";
import CreateFolder from "./laptop/CreateCollection/CreateCollection";
import NewButtonLaptop from "./laptop/NewButton.laptop";
import NewButtonMobile from "./mobile/NewButton.mobile";
import folder from "@gigauser/common/src/assets/legacy_assets/icons/folder.svg";
import logger from "@gigauser/common/src/utils/logger";

type NewButtonProps = {};

const NewButton: React.FC<NewButtonProps> = () => {
	const dispatch = useAppDispatch();

	const openCreateFolderOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
		event?.preventDefault();
		logger.debug("Need to open overlay");
		dispatch(
			openOverlay({
				heading: {
					icon: folder,
					title: "Create Folder",
					data: "",
				},
				component: <CreateFolder />,
			}),
		);
	};

	const { device } = useWindowSize();

	const currentCollection = useAppSelector(
		(state) => state.collectionData.currentCollectionData.currentCollection,
	);

	if (currentCollection.id === "Folder_shared") return null;

	if (device === "laptop")
		return (
			<NewButtonLaptop openCreateFolderOverlay={openCreateFolderOverlay} />
		);
	else
		return (
			<NewButtonMobile openCreateFolderOverlay={openCreateFolderOverlay} />
		);
};
export default NewButton;
