import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import "./CreateCollection.css";
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import Button from "@gigauser/common/src/ui/buttons/Button/Button";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";

import { selectCollectionPath } from "@gigauser/common/src/redux/slices/collectionPathSlice";
import useCreateCollection from "@gigauser/common/src/redux/hooks/FileHooks/useCreateCollection";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import { Device } from "@gigauser/common/src/types/devices";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { ValidateCollectionUrlPathResponse } from "@giga-user-fern/api/types/api/resources/collections";
import { Spinner } from "@chakra-ui/react";
import { convertToUrl } from "@gigauser/common/src/utils/stringOperations";
import AdvancedSettings from "@gigauser/common/src/ui/video/components/AdvancedSettings/AdvancedSettings";
import { boolean } from "@giga-user-fern/api/types/core/schemas";

type CreateCollectionProps = {};

const CreateCollection: React.FC<CreateCollectionProps> = () => {
	const [CollectionName, setCollectionName] = useState<string>("");
	const [Description, setDescription] = useState<string>("");
	const [creating, setCreating] = useState<boolean>(false);

	const collectionPath = useAppSelector(selectCollectionPath);
	const env = useContext(EnvironmentContext);
	const createCollection = useCreateCollection(env);
	const currCollection = collectionPath.segments.at(-1) as Collection;
	const currentCollectionData = useAppSelector(
		(state) => state.collectionData.currentCollectionData,
	);

	const onCollectionNameChange = (e: any) => {
		const target = e.target as HTMLInputElement;
		setCollectionName(target.value);
	};

	const onDescriptionChange = (e: any) => {
		const target = e.target as HTMLInputElement;
		setDescription(target.value);
	};

	const [collectionUrlPath, setCollectionUrlPath] = useState<string>(
		convertToUrl(CollectionName),
	);

	const dispatch = useAppDispatch();

	const { device } = useWindowSize();

	const saver = useAppSelector(selectSaver);
	const [collectionUrlPathInputNotActive,setCollectionUrlPathInputNotActive] = useState<boolean>(true)
	const [urlConflictStatus, setUrlConflictStatus] =
		useState<ValidateCollectionUrlPathResponse>({available: false, conflictingCollections:[]});
	const [isUrlValidationLoading, setIsUrlValidationLoading] =
		useState<boolean>(true);
	const checkUrlConflict = (urlPath: string) => {
		setIsUrlValidationLoading(true);
		saver
			.validateCollectionUrlPath({ collectionUrlPath: urlPath })
			.then((body) => {
				if (body.ok) {
					setUrlConflictStatus(body.body);
					setIsUrlValidationLoading(false);
				}
			});
	};

	useEffect(() => {
		checkUrlConflict(collectionUrlPath);
	}, [collectionUrlPath]);

	useEffect(() => {
		setCollectionUrlPath(convertToUrl(CollectionName));
	}, [CollectionName]);

	useEffect(()=>{
		if(!urlConflictStatus?.available && collectionUrlPathInputNotActive){
			var index =1
			if (isNaN(Number(collectionUrlPath.split("-")[collectionUrlPath.split("-").length - 1]))){
				index =1
			}
			else{
				index=Number(collectionUrlPath.split("-")[collectionUrlPath.split("-").length - 1])+1
			}
			setCollectionUrlPath(convertToUrl(`${collectionUrlPath.split("-").slice(0,-1).join(" ")===""?collectionUrlPath:collectionUrlPath.split("-").slice(0,-1).join(" ")} ${index}`))
		}
	},[urlConflictStatus])

	return (
		<div className="create-new-collection">
			<div className={`input-field ${device}`}>
				<TitledInput
					id=""
					label="Name"
					value={CollectionName}
					onChange={onCollectionNameChange}
					placeHolder="Folder name ..."
					// position="left half"
					styleSize="xl"
					display={true}
					background="white"
					edges="sharp"
					autoFocus
				></TitledInput>
			</div>

			{/* <div className={`motivation ${device}`}>This collection is the start of something big. Let’s give it a memorable name.</div> */}

			<div className={`input-field ${device}`}>
				<TitledInput
					id=""
					label="Description (Optional)"
					value={Description}
					onChange={onDescriptionChange}
					placeHolder="What's this collection about?"
					// position= "left half"
					styleSize="xl"
					display={true}
					background="white"
					edges="sharp"
				></TitledInput>
			</div>
			<div className={`input-field ${device}`}>
			<AdvancedSettings subtle lightTheme>
				<TitledInput
					id=""
					label="URL Path"
					value={collectionUrlPath}
					onFocus={(e)=>{
						setCollectionUrlPathInputNotActive(false)
					}}
					onBlur={(e)=>{
						setCollectionUrlPathInputNotActive(true)
					}}
					onChange={(e) => {
						setCollectionUrlPath(
							convertToUrl((e.target as HTMLInputElement).value),
						);
					}}
					placeHolder="How this collection shall be accessed?"
					// position= "left half"
					styleSize="xl"
					display={true} 
					background="white"
					edges="sharp"
				></TitledInput>
			{collectionUrlPath === "" ? (
				<span className="collectionUrlNotAvailable">
					Path URL can not be empty string.
				</span>
			) : isUrlValidationLoading ? (
				<Spinner />
			) : urlConflictStatus?.available ? (
				<span className="collectionUrlAvailable">
					URL is available to use for this collection.
				</span>
			) : (
				<span className="collectionUrlNotAvailable">
					This URL is conflicting with{" "}
					{urlConflictStatus?.conflictingCollections
						? urlConflictStatus.conflictingCollections[0].name
						: "an existing collection"}{" "}
					in your workspace.
				</span>
			)}
			</AdvancedSettings>
			</div>
			
			

			<div className={`create-collection-buttons ${device}`}>
				<Button
					edges="round"
					isDisabled={!CollectionName.length || !urlConflictStatus?.available}
					rank="primary"
					fill="solid"
					loading={creating}
					onClick={async () => {
						if (urlConflictStatus?.available){
							setCreating(true); 

							const resp = await createCollection({
								name: CollectionName,
								description: Description,
								parentId: currCollection.id,
								private: true,
								collectionUrlPath: collectionUrlPath,
							});
							// TODO: Handle error on true or false
							dispatch(closeOverlay());
							setCreating(false);
						}
					}}
				>
					Create
				</Button>

				<Button
					edges="round"
					rank="warning"
					fill="ghost"
					onClick={() => {
						dispatch(closeOverlay());
					}}
				>
					Cancel
				</Button>
			</div>
		</div>
	);
};
export default CreateCollection;
