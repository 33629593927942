import React, { useState, useEffect } from "react";
import "./Icon.css";

/**
 * This component is used as a wrapper on <img/> icons, so that a hover tip comes up
 */

type IconProps = {
	hoverTip?: string;
	size?: "n" | "s";
	src: string;
	shortcut?: string;
	hoverProperties?: boolean;
	active?: boolean;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const Icon: React.FC<IconProps> = ({
	hoverTip,
	size = "n",
	className,
	shortcut,
	hoverProperties,
	active,
	...props
}) => {
	const [isHovering, setIsHovering] = useState<boolean>(false);

	const onMouseEnter = (e: any) => {
		setIsHovering(true);
	};

	const onMouseLeave = () => {
		setIsHovering(false);
	};

	return (
		<div className="HoverTipIcon-parent">
			<div style={{ display: "flex" }}>
				<img
					className={`HoverTipIcon ${size} ${className} ${hoverProperties ? "hovered" : ""} ${active ? "active" : ""}`}
					onMouseEnter={(e) => {
						onMouseEnter(e);
					}}
					onMouseLeave={onMouseLeave}
					{...props}
				></img>
			</div>
			{isHovering && hoverTip ? (
				<div className="HoverTipIcon-HoverTip bottom">
					<div className="hovertip-text">{hoverTip}</div>
					{shortcut ? (
						<div className="hovertip-shortcut">{shortcut}</div>
					) : null}
				</div>
			) : null}
		</div>
	);
};
export default Icon;
