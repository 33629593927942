const musicIcon = (color = "#ffffff") => {
	return (
		<svg
			fill={color}
			version="1.1"
			id="Icons"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 32 32"
			xmlSpace="preserve"
		>
			<path
				d="M28.6,3.2C28.4,3,28.1,3,27.8,3l-16,3C11.3,6.1,11,6.5,11,7v14.8C10.2,21.3,9.1,21,8,21c-2.8,0-5,1.8-5,4s2.2,4,5,4
            s5-1.8,5-4V7.8l14-2.6v13.6c-0.8-0.5-1.9-0.8-3-0.8c-2.8,0-5,1.8-5,4s2.2,4,5,4s5-1.8,5-4V4C29,3.7,28.9,3.4,28.6,3.2z"
			/>
		</svg>
	);
};
export default musicIcon;
