import React from "react";
import {
	useAppSelector,
	useAppDispatch,
} from "@gigauser/common/src/redux/useAppRedux";
import { closeFullScreenOverlay } from "./FullScreenOverlaySlice";
import backButton from "../../assets/legacy_assets/icons/backButton.svg";
import "./FullScreenOverlay.css";

type FullScreenOverlayProps = {};

const FullScreenOverlay: React.FC<FullScreenOverlayProps> = () => {
	const FullScreenOverlay = useAppSelector(
		(state) => state.fullscreenOverlay.value,
	);

	const dispatch = useAppDispatch();

	if (FullScreenOverlay) {
		return (
			<div className="FullScreenOverlay">{FullScreenOverlay.component}</div>
		);
	} else {
		return null;
	}
};
export default FullScreenOverlay;
