import React from "react";
import "./AdminList.css"; // Make sure to create this CSS file
import { User } from "@giga-user-fern/api/types/api";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";

type AdminListProps = {
	self: User;
	users: User[] | { name?: string; email: string; role: "viewer" }[];
	deleteUser: (email: string) => void;
	createUser: () => void;
};
const AdminList = ({ users, deleteUser, createUser, self }: AdminListProps) => {
	return (
		<div className="gigauser-admin-list-table-container">
			{self.role === "admin" && (
				<div style={{ paddingTop: "20px" }}>
					<Cutton onClick={createUser}>Add User</Cutton>
				</div>
			)}

			<div className="gigauser-admin-list-table">
				<div className="gigauser-admin-list-table-header">
					{/* <div className="gigauser-admin-list-table-th gigauser-admin-list-table-th-name">Name</div> */}
					<div className="gigauser-admin-list-table-th gigauser-admin-list-table-th-email">
						Email
					</div>
					{/* <div className="gigauser-admin-list-table-th gigauser-admin-list-table-th-role">
						Status
					</div> */}
					<div className="gigauser-admin-list-table-th gigauser-admin-list-table-th-role">
						Role
					</div>
					<div className="gigauser-admin-list-table-th gigauser-admin-list-table-th-delete">
						{" "}
					</div>
				</div>
				{users.map((user, index) => (
					<div className="gigauser-admin-list-row" key={index}>
						{/* <div className="gigauser-admin-list-row-name">{user.name || ''}</div> */}
						<div className="gigauser-admin-list-row-email"><p>{user.name || ''}</p><p>{user.email}</p></div>
						{/* <div className="gigauser-admin-list-row-role">
							{user.propel ? "Joined" : "Yet to Join"}
						</div> */}
						<div className="gigauser-admin-list-row-role">
							{user.role.toUpperCase()}
						</div>
						<div className="gigauser-admin-list-row-trash">
							{user.role !== "admin" && self.role === "admin" && (
								<Icon
									className="gigauser-td-trash gigauser-td-hover-icon"
									onClick={() => deleteUser(user.email)}
								>
									{trashIcon()}
								</Icon>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default AdminList;
