import React, { useState } from "react";

import "./ToggleSwitch.css";
import Icon from "../../Icon/Icon";
import { SwitchProps } from "../SwitchProps";
import logger from "../../../utils/logger";

const ToggleSwitch: React.FC<SwitchProps> = (props) => {
	const [currentState, setCurrentState] = useState<boolean>(props.initialState);

	const toggleState = () => {
		logger.debug("toggleState called");
		if (props.disabled) return;

		if (currentState) {
			if (props.toggleFunctions.left) props.toggleFunctions.left();
		} else if (props.toggleFunctions.right) props.toggleFunctions.right();

		setCurrentState(!currentState);
	};

	return (
		<div className="gigauser-toggle-switch-wrapper">
			<label className="gigauser-toggle-switch" htmlFor="checkbox">
				<span
					onClick={toggleState}
					className={`gigauser-theme-slider gigauser-theme-round ${props.styleSet || "multi"}-toggle
                        ${currentState ? "gigauser-theme-active-toggle" : "gigauser-theme-inactive-toggle"}`}
				>
					<Icon
						className="gigauser-toggle-img gigauser-right"
						style={{ filter: currentState ? "invert(100%)" : "" }}
					>
						{props.activeIcon}
					</Icon>

					{/* <img className="toggle-img left" src={props.activeIcon}
                        style={{filter: currentState?"invert(100%)":""}}
                    ></img> */}

					<Icon className="gigauser-toggle-img gigauser-right">
						{props.inactiveIcon}
					</Icon>
					{/* <img  src={props.inactiveIcon}></img> */}
				</span>
			</label>
		</div>
	);
};
export default ToggleSwitch;
