import { Collection } from "@giga-user-fern/api/types/api/resources/collections";
import { GuidePreview } from "@giga-user-fern/api/types/api/resources/guides";
import React, { useContext, useState } from "react";
import { Item } from "../../../types/files";
import Icon from "../../../ui/Icon/Icon";
import move_dots from "../../../assets/svgs/move_dots";
import copy from "../../../assets/svgs/copy";

import tick from "../../../assets/svgs/tick";
import folder_color from "../../../assets/svgs/folder_color";
import { type } from "os";
import trashIcon from "../../../assets/svgs/trashIcon";
import logger from "../../../utils/logger";
import { useMoveItem } from "../../../redux/hooks/FileHooks";
import { EnvironmentContext } from "../../../contexts";
import { openExternalLink } from "../../../utils/url";
import externalLinkIcon from "../../../assets/svgs/externalLinkIcon";
import { useAppSelector } from "../../../redux";
import { selectSaver } from "../../../redux/slices/backendSlice";
import Cutton from "../../../ui/buttons/Cutton/Cutton";

type GuidesTableRowProps = {
	entry: Item;
	index: number;
	onClick: (c: any) => void;
	onDelete: (c: any) => void;
	showFileContextMenu: (file: Item, X: number, Y: number) => void;
	onRestore?:(c:any)=>void
};

const GuidesTableRow: React.FC<GuidesTableRowProps> = (props) => {
	const saver = useAppSelector(selectSaver);

	var item: Collection | GuidePreview;
	var name: string = "";
	var description: string | undefined = "";

	const [isOnDrop, setIsOnDrop] = useState<boolean>(false); //true if something is being dropped on the folder
	const [idCopied, setIdCopied] = useState<boolean>(false); //true if the id was copied to clipboard

	const env = useContext(EnvironmentContext);
	const backendMoveItem = useMoveItem(env);

	// const onDragOver = (e: React.DragEvent<HTMLDivElement>) =>{
	//     logger.debug("onDragOver!")
	//     e.preventDefault()
	// }

	// const onDragOverCapture = (e: React.DragEvent<HTMLDivElement>) =>{

	//     logger.debug("onDragOverCapture")

	//     if(props.entry.type==="Collection"){
	//         var data = (e.dataTransfer.getData("text"))
	//         setIsOnDrop(true)
	//     }

	// }

	// const onDragLeave = (e: React.DragEvent<HTMLDivElement>) =>{

	//     logger.debug("onDragLeave")
	//     setIsOnDrop(false)

	// }

	// const onDropFolder = (e: React.DragEvent<HTMLDivElement>) => {

	//     // Move function needs to be written
	//     logger.debug("onDropFolder")
	//     // var data = e.dataTransfer.getData("text");
	//     // logger.debug("data in drop", JSON.parse(data) as Folder)
	//     // setIsOnDrop(false);

	//     // //Here, we move the folder

	//     // backendMoveFile({
	//     //     file: JSON.parse(data),
	//     //     newParentID: folder.objectID
	//     // })

	// }

	// const onDragFolder = (e: React.DragEvent<HTMLDivElement>) => {
	//     // setIsHovering(false)
	//     // e.dataTransfer?.setData("text", JSON.stringify(folder))
	// }

	const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
		e.dataTransfer?.setData("text", JSON.stringify(props.entry));
	};

	const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		setIsOnDrop(false);

		const data = JSON.parse(e.dataTransfer.getData("text")) as Item;
		if (data.entry.id === props.entry.entry.id) return;

		if (props.entry.type === "Collection") {
			//We are in a collection

			backendMoveItem({
				item: data,
				newParentID: props.entry.entry.id,
			});
		}
	};

	const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		setIsOnDrop(false);
	};

	const onDragOverCapture = (e: React.DragEvent<HTMLDivElement>) => {
		// if(data.entry.id!==props.entry.entry.id)
		setIsOnDrop(true);
	};

	if (props.entry.type == "Collection") {
		item = props.entry.entry as Collection;
		name = item.name;
		description = item.description;

		var onClick = () => {
			props.onClick(props.entry.entry as Collection);
		};

		var onDelete = (e: any) => {
			props.onDelete(props.entry.entry as Collection);
			e.stopPropagation();
			e.preventDefault();
		};

		var onRestore = () => {
			props.onRestore?.(props.entry.entry as Collection);
		};

		var openFileContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
			props.showFileContextMenu(
				{
					type: "Collection",
					entry: props.entry.entry as Collection,
				},
				event.clientX,
				event.clientY,
			);
		};
	} else {
		item = props.entry.entry as GuidePreview;
		name = item.header.name;

		description = item.header.description;
		onClick = () => {
			props.onClick(props.entry.entry as GuidePreview);
		};
		onDelete = (e: any) => {
			logger.debug("onDelete if");
			props.onDelete(props.entry.entry as GuidePreview);
			e.stopPropagation();
			e.preventDefault();
		};
		onRestore = () => {
			props.onRestore?.(props.entry.entry as GuidePreview);
		};

		var openFileContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
			props.showFileContextMenu(
				{
					type: "Guide",
					entry: props.entry.entry as GuidePreview,
				},
				event.clientX,
				event.clientY,
			);
		};
	}

	const published =
		props.entry.type === "Guide"
			? (props.entry.entry as GuidePreview).header.published
				? true
				: false
			: (props.entry.entry as Collection).private
				? false
				: true;

	return (
		<div
			key={item?.id}
			id={item?.id}
			onClick={props.entry.entry.deleted?()=>{}:onClick}
			className={`
                gigauser-guides-row ${isOnDrop && props.entry.type === "Collection" ? "onDropActive" : ""}
            `}
			onDragStart={onDragStart}
			onDrop={onDrop}
			onDragOver={onDragOver}
			onDragOverCapture={onDragOverCapture}
			onDragLeave={onDragLeave}
			// onDragOver={onDragOver}
			// onDragOverCapture={onDragOverCapture}
			// onDragLeave={onDragLeave}
			draggable
			onContextMenu={(e) => {
				e.preventDefault();
				openFileContextMenu(e);
			}}
		>
			<div
				className="gigauser-row-columns"

				// onDragOver={onDragOver}
				// onDragStart={onDragFolder}
				// onDragOverCapture={onDragOverCapture}
				// onDragLeave={onDragLeave}
				// onDrop={onDropFolder}
			>
				<div className="gigauser-row-dots">
					<Icon
						className="gigauser-td-move gigauser-td-hover-icon"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							openFileContextMenu(e);
						}}
					>
						{move_dots}
					</Icon>
				</div>

				<div className="gigauser-row-name">
					{props.entry.type === "Collection" ? (
						<Icon className="gigauser-guidestablerow-folder">
							{folder_color}
						</Icon>
					) : null}

					<div className="gigauser-row-name-text">
						<div className="gigauser-table-name">{name}</div>
						<div className="gigauser-table-description">{description}</div>
						{(props.entry.type==="Guide" && props.entry.entry.deleteAt)&&<div className="gigauser-delete-at">Will be deleted on: {props.entry.entry.deleteAt?.toLocaleDateString()}</div>}
					</div>
				</div>

				<div
					className="gigauser-row-id"
					onClick={(e: any) => {
						navigator.clipboard
							.writeText(props.entry.entry.id)
							.then(() => {
								setIdCopied(true);
								setTimeout(() => {
									setIdCopied(false);
								}, 3000);

								logger.debug("Text copied to clipboard");
							})
							.catch((error) => {
								alert("Error copying to clipboard!");
								logger.error("Error copying text to clipboard:", error);
							});

						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<div
						className={`gigauser-table-id ${idCopied ? "gigauser-idCopied" : ""}`}
						title="copy"
					>
						<div className="gigauser-id-text">{props.entry.entry.id}</div>

						<Icon className="gigauser-id-copy">{idCopied ? tick : copy}</Icon>

						{idCopied ? (
							<div className="gigauser-id-copiedPopup">Copied!</div>
						) : null}
					</div>
				</div>

				<div className="gigauser-row-status">
					<div
						className={`gigauser-status-circle 
                        ${published ? "published" : "unpublished"}`}
					></div>

					{published ? <span>Published</span> : <span>Unpublished</span>}
				</div>

				<div className="gigauser-row-trash">
					{props.entry.entry.deleted? <Icon
						className="gigauser-td-link gigauser-td-hover-icon"
						hoverTip="View in help center"
						hideHoverTipArrow
						// onClick={(e) => {
						// 	window.open(
						// 		`https://${saver.hostname}/${props.entry.type === "Collection" ? "folder" : "guide"}/${props.entry.entry.id}`,
						// 		"_blank",
						// 	);
						// 	e.stopPropagation();
						// }}
						onClick={onClick}
					>
						{externalLinkIcon("#1b1d23")}
					</Icon>:<Icon
						className="gigauser-td-link gigauser-td-hover-icon"
						hoverTip="View in help center"
						hideHoverTipArrow
						onClick={(e) => {
							window.open(
								`https://${saver.hostname}/${props.entry.type === "Collection" ? "folder" : "guide"}/${props.entry.entry.id}`,
								"_blank",
							);
							e.stopPropagation();
						}}
					>
						{externalLinkIcon("#1b1d23")}
					</Icon>}

					{!props.entry.entry.deleted && <Icon
						className="gigauser-td-trash gigauser-td-hover-icon"
						onClick={onDelete}
					>
						{trashIcon()}
					</Icon>}
					{props.entry.entry.deleted && <div className="gigauser-td-hover-icon">
					<Cutton onClick={onRestore}>Restore</Cutton>
					</div>}
				</div>
			</div>
		</div>
	);
};
export default GuidesTableRow;
