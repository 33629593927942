import React from "react";

type CuttonGroupProps = {
	children: React.ReactElement | React.ReactElement[];
};

const CuttonGroup: React.FC<CuttonGroupProps> = ({ children, ...props }) => {
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				alignItems: "center",
				marginTop: "1.8em",
				gap: "1rem",
			}}
		>
			{children}
		</div>
	);
};
export default CuttonGroup;
