import React, { useState } from "react";
import "./Button.css";
// import '../ui-styles/ui-styles.css'
// import loading_sec from '../../icons/gifs/loading_secondary.gif'
// import loading_prim from '../../icons/gifs/loading_primary.gif'
import { ButtonType } from "../types";
import dropdown_icon from "../../../assets/svgs/dropdown";
import Menu from "../../menus/Menu/Menu";

import { DisplayMenuItem } from "../../menus/Menu/Menu";
import Clickout from "../../../layouts/Clickout/Clickout";
import { Size } from "../../../types/sizes";

// import loading_sec from '../../../assets/gifs/loading_secondary.gif'
// import loading_prim from '../../../assets/gifs/loading_primary.gif'
import { Device } from "../../../types/devices";
import useWindowSize from "@gigauser/common/src/hooks//useWindowSize";
import Icon from "../../Icon/Icon";
import Ring from "../../Loading/Ring/Ring";

type ButtonProps = {
	edges?: "round" | "sharp";
	rank?: "primary" | "secondary" | "warning";
	fill?: "ghost" | "solid";
	isDisabled?: boolean;
	size?: Size;
	dropdown?: DisplayMenuItem[];
	loading?: boolean;
	hideTextOnLoading?: boolean;
	isClickable?: boolean; //Whether the onClick should run on click or not. Similar to isDisables except the styles don't change.
} & ButtonType;

const Button: React.FC<ButtonProps> = ({
	fill = "ghost",
	edges = "round",
	rank = "primary",
	isDisabled = false,
	onClick,
	dropdown,
	size = "n",
	loading = false,
	isClickable = true,
	...props
}) => {
	const [dropdownMenu, setDropdownMenu] = useState<boolean>(false);

	var secondaryIcon: React.ReactNode = <></>;
	if (dropdown) secondaryIcon = dropdown_icon;
	else secondaryIcon = secondaryIcon;

	const openDropdown = () => {
		setDropdownMenu(true);
	};

	const { device } = useWindowSize();

	return (
		<>
			<div
				className={`Button ${loading ? "loading" : ""} ${fill} ${edges} ${rank} ${size} 
                ${isDisabled ? "disabled" : ""} ${device}`}
				onClick={
					isDisabled || !isClickable
						? undefined
						: dropdown
							? openDropdown
							: onClick
				}
			>
				{props.icon ? (
					<img src={props.icon} className="icon left-icon"></img>
				) : null}
				<div className="button-text">
					{props.hideTextOnLoading && loading ? null : props.children}
				</div>
				{secondaryIcon ? (
					<Icon
						className={`icon right-icon ${dropdown ? "dropdown_icon" : ""}`}
					>
						{secondaryIcon}
					</Icon>
					// <img src={secondaryIcon} className={`icon right-icon ${dropdown?"dropdown_icon":""}`}></img>
				) : null}
				<div
					className={`loading-image ${props.hideTextOnLoading ? "hideTextOnLoading" : ""}`}
				>
					<Ring
						color={rank == "primary" ? "white" : "brandColor"}
						height={20}
						width={20}
					></Ring>
				</div>
				{/* <img className={`loading-image ${props.hideTextOnLoading?"hideTextOnLoading":""}`} src={rank=="primary"?loading_prim: loading_sec} /> */}
			</div>
			{dropdownMenu && dropdown ? (
				<div className="Button-dropdown">
					<Clickout
						closeFunction={() => {
							setDropdownMenu(false);
						}}
					>
						<Menu
							menu={dropdown}
							closeDisplayMenu={() => setDropdownMenu(false)}
						/>
					</Clickout>
				</div>
			) : null}
		</>
	);
};

export default React.memo(Button);
