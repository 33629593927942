import React, { useState } from "react";
import "./MCQColorPicker.css";

type MCQColorPickerProps = {
	color: MCQColorOption;
	setColor: (color: MCQColorOption) => void;
};

const colors = [
	"#ff3131",
	"#ff5757",
	"#d43f8d",
	"#5e17eb",
	"#5271ff",
	"#00bf63",
	"#ffde59",
] as const;

export type MCQColorOption = (typeof colors)[number];

const MCQColorPicker: React.FC<MCQColorPickerProps> = ({ setColor }) => {
	const [selectedColor, setSelectedColor] = useState<string>("#5271ff");

	return (
		<div className="MCQColorPicker">
			{colors.map((color) => (
				<div
					key={color}
					className={`MCQColorOption ${selectedColor === color ? "selected" : ""}`}
					style={{ backgroundColor: color }}
					onClick={() => {
						setSelectedColor(color);
						setColor(color as MCQColorOption);
					}}
				>
					.
				</div>
			))}
		</div>
	);
};
export default MCQColorPicker;
