import React from "react";
import "./AuthFormLayout.css";
import logo from "@gigauser/common/src/assets/logos/Logo.svg";
import director from "@gigauser/common/src/assets/auth/director.svg";
import { FormProps } from "antd";
import { useNavigate } from "react-router-dom";

type AuthFormLayoutProps = {
	stepNumber: number;
	title: string;
	subtitle: string;
	children: React.ReactElement;
	// goBack?:() => void;
};

const AuthFormLayout: React.FC<AuthFormLayoutProps> = (props) => {
	const navigate = useNavigate();

	const goBack = () => {
		navigate("/welcome/personal-details");
	};

	return (
		<div className="AuthFormLayout gigauser-light">
			<img className="AuthForm-Logo" src={logo} />
			<img className="AuthForm-Director" src={director} />

			<div className="AuthFormBox">
				<div className="AuthFormSteps">
					{Array.from({ length: 2 }, (_, i) => i + 1).map((step, index) => {
						return (
							<>
								<div
									key={index}
									className={`AuthFormStepCircle ${step <= props.stepNumber ? "AuthFormStepActive" : "AuthFormStepInactive"}`}
								></div>
								{step < 2 && (
									<div
										className={` AuthFormLine ${step < props.stepNumber ? "AuthFormStepActive" : "AuthFormStepInactive"}`}
									>
										.
									</div>
								)}
							</>
						);
					})}
				</div>

				<div className="AuthFormContainer">
					<div className="AuthForm-Step">
						{props.stepNumber > 1 && (
							<div className="back-arrow" onClick={goBack}>
								←
							</div>
						)}
						<span className="AuthForm-currstep">{props.stepNumber}</span> / 2
					</div>

					<div className="AuthForm-Title">{props.title}</div>
					<div className="AuthForm-Subtitle">{props.subtitle}</div>

					<div className="AuthForm-Form">{props.children}</div>
				</div>
			</div>
		</div>
	);
};
export default AuthFormLayout;
