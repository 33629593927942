import React, { useState } from "react";
import "./UploadMusic.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { addAudioTrack } from "@gigauser/common/src/redux/slices/platformDetailsSlice";
import { Flex, useToast } from "@chakra-ui/react";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import TitledInput, {
	TitledInputProps,
} from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";

import FileUpload from "@gigauser/common/src/ui/inputs/file/FileUpload/FileUpload";
import musicIcon from "@gigauser/common/src/assets/svgs/videoeditor/musicIcon";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay";

type UploadMusicProps = {};

const UploadMusic: React.FC<UploadMusicProps> = () => {
	const saver = useAppSelector(selectSaver);
	const dispatch = useAppDispatch();
	const toast = useToast();

	const [title, setTitle] = useState("");
	const [file, setFile] = useState<File | null>(null);
	const [uploading, setUploading] = useState(false);

	const onChangeTitle = (e: any) => {
		setTitle(e.target.value);
	};

	const onSubmitMusic = async () => {
		setUploading(true);

		const emojis = [`🎹`, `🎧`, `🎺`, `🎻`, `🎹`, `🎧`, `🪇`];
		const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];

		const resp = await saver.uploadBackgroundAudio(
			file,
			`${randomEmoji}   ${title.trimStart()}`,
		);
		if (resp) {
			dispatch(addAudioTrack(resp));
		} else {
			toast({
				title: "Error",
				description: "Failed to upload audio",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
		setUploading(false);
		dispatch(closeOverlay());
	};

	const onChangeFile = (file: any) => {
		setFile(file);
	};

	const onCancel = () => {
		dispatch(closeOverlay());
	};

	const titledInputProps: any & TitledInputProps = {
		styleSize: "xl",
		edges: "sharp",
	};

	return (
		<div className="UploadMusic">
			<div className="UploadMusic-form">
				<TitledInput
					{...titledInputProps}
					label="Name"
					background="white"
					id="edit-folder-name"
					value={title}
					onChange={onChangeTitle}
					autoComplete="off"
				></TitledInput>

				<FileUpload
					fileTypes={[".mp3", ".wav"]}
					file={file}
					onChange={onChangeFile}
					icon={musicIcon}
				/>
			</div>

			<Flex gap={"1rem"} marginTop={"2rem"}>
				<Cutton
					rank="secondary"
					leftIcon={trashIcon("white")}
					onClick={onCancel}
				>
					Cancel
				</Cutton>
				<Cutton
					disabled={!(title && file)}
					onClick={onSubmitMusic}
					isLoading={uploading}
					loadingText="Uploading"
				>
					Submit
				</Cutton>
			</Flex>
		</div>
	);
};
export default UploadMusic;
