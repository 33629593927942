import React, { useContext, useEffect, useRef, useState } from "react";
import "./TrimStitch.css";
import CanvasProvider from "@gigauser/common/src/core/canvas/CanvasProvider";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import useRefreshVideo from "@gigauser/common/src/redux/hooks/FileHooks/useRefreshVideo";
import { selectVideo } from "@gigauser/common/src/redux/slices/guideSlice";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import TrimmerTimeline from "../../video/components/TrimVideo/TrimmerTimeline/TrimmerTimeline";
import CanvasPlayer from "@gigauser/common/src/core/canvas/CanvasPlayer/CanvasPlayer";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import TrimStitchTopbar from "./TrimStitchTopbar";
import { Progress } from "antd";

type TrimStichProps = {
	currentOriginalTime?: number;
};

const TrimStich: React.FC<TrimStichProps> = (props) => {
	const ogVidRef = useRef<HTMLVideoElement>(null);
	const video = useAppSelector(selectVideo);
	const saver = useAppSelector(selectSaver);
	const refreshVideo = useRefreshVideo();
	const cp = useContext(CanvasPlayerContext);

	const [uploading, setUploading] = useState<number | null>(null);

	const dispatch = useAppDispatch();

	if (!video?.originalSrc) return <></>;

	var originalSrc = video.originalSrc;

	if (video.originalEdits?.sources) {
		originalSrc = video.originalEdits.sources[0].src;
	}

	return (
		<CanvasProvider
			videoSrc={originalSrc}
			videoEdits={{ version: "oct162023" }}
			organization={saver.organization}
			initTime={props.currentOriginalTime}
			onError={refreshVideo}
		>
			<div className="TrimStitch">
				<TrimStitchTopbar />

				<div className="TrimStitch-VideoPlayer">
					<CanvasPlayer
					// environment='internal'
					// hideFullscreen hideScrubber
					// hideBigPlay hideControls
					/>

					<div
						className="Spinner-container"
						style={{ display: uploading !== null ? "block" : "none" }}
					>
						<div className="Spinner">
							<Progress
								strokeColor="#d43f8c"
								percent={Math.floor(uploading || 0)}
								type="circle"
							/>
						</div>
					</div>
				</div>

				<div className="TrimmerTimeline-container">
					<TrimmerTimeline uploading={uploading} setUploading={setUploading} />
				</div>
			</div>
		</CanvasProvider>
	);
};
export default TrimStich;
