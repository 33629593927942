import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import { selectTrimmingVideo } from "../../slices/guideSlice";
import {
	closeMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import useInitGuideEditor from "./useInitGuideEditor";
import { useToast } from "@chakra-ui/react";

const useShowTrimmingVideo = () => {
	const dispatch = useAppDispatch();
	const trimmingVideo = useAppSelector(selectTrimmingVideo);
	const [prevTrimmingVideo, setPrevTrimmingVideo] = useState(trimmingVideo);
	const initGuideEditor = useInitGuideEditor();
	const toast = useToast();

	const onFinishTrim = async () => {
		await initGuideEditor();
		dispatch(closeMessagePopup());
		console.log("Trimming video complete");
		toast({
			title: "Trim successful!",
			description: "Now click Generate Speech to make your video",
			status: "success",
			duration: 200000,
			isClosable: true,
			position: "top",
		});
	};

	useEffect(() => {
		if (trimmingVideo) {
			console.log("Trimming video in progress");
			dispatch(showLoadingMessagePopup("Trimming video in progress"));
		} else if (!trimmingVideo && prevTrimmingVideo) {
			onFinishTrim();
		}
		setPrevTrimmingVideo(trimmingVideo);
	}, [trimmingVideo]);

	return {
		// Add any values or functions you want to expose
	};
};

export default useShowTrimmingVideo;
