export const getPlaybackVolume: (inputVolume?: number) => number = (
	inputVolume?: number,
) => {
    var playbackVolume = 0;
	if (inputVolume) {
        const newVol =  (inputVolume * 0.2) / 100;
        return newVol > 0 ? newVol : playbackVolume;
    }
	else {
		return getPlaybackVolume(getDefaultVolume());
	}
};

export const getDefaultVolume = (inputVolume?: number) => {
	if (inputVolume) return inputVolume;
	else return 20;
};
