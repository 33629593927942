import React, { useContext, useRef, useState } from "react";
import "./ZoomTimeline.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	addZoom,
	deleteZoom,
	selectVideo,
	updateZoom,
} from "@gigauser/common/src/redux/slices/guideSlice";
import TimelineClip from "../TimelineClip/TimelineClip";
import { ZoomEdit } from "@giga-user-fern/api/types/api/resources/video";
import TimelineContext from "../../TimelineContext";

import {
	setActiveZoom,
	setCustomizerPage,
} from "@gigauser/common/src/redux/slices/platformUiSlice";
import { v4 as uuidv4 } from "uuid";
import { GigaUserApi } from "@giga-user-fern/api";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import { ZOOM_TRANSITION_TIME } from "@gigauser/common/src/core/canvas/CanvasPlayer/CanvasPlayer";

type ZoomTimelineProps = {};

const NEW_ZOOM_TIME = 3;

const ZoomTimeline: React.FC<ZoomTimelineProps> = (props) => {
	// const tempZoom
	const video = useAppSelector(selectVideo);
	const videoEdits = video?.videoEdits;
	var zooms = [...(video?.videoEdits?.zooms || [])];
	if (zooms) zooms = zooms.sort((a, b) => a.startTime - b.startTime);

	const cp = useContext(CanvasPlayerContext);
	const tc = useContext(TimelineContext);

	const activeZoom = useAppSelector(
		(state) => state.platformUi.value.activeZoom,
	);

	const dispatch = useAppDispatch();

	const timelineContext = useContext(TimelineContext);

	//state for whether a new zoom is in the process of being created.
	const [constructing, setConstructing] = useState(false);
	const [newZoomStart, setNewZoomStart] = useState(0);
	const [newZoom, setNewZoom] = useState<ZoomEdit | null>(null);

	const getMouseTime = (e: React.MouseEvent) => {
		const X = timelineContext.getXCoord(e.clientX);
		const xTime = tc.positionToTime(X); //returns adjusted time
		return getUnadjustedTime(xTime);
	};

	const constructNewZoom = (e: React.MouseEvent) => {
		const mouseTime = getMouseTime(e);

		var new_start = Math.max(newZoomStart, 0);
		var new_end = new_start + NEW_ZOOM_TIME;

		if (new_end > cp.videoDuration) {
			return;
		}

		const new_zoom: ZoomEdit = {
			id: GigaUserApi.Id(uuidv4()),
			startTime: new_start,
			endTime: Math.max(new_start + NEW_ZOOM_TIME, mouseTime),
			zoomCenter: { x: 0.5, y: 0.5 },
			zoomFactor: 1.8,
		};

		if (isNewZoomIntersecting(new_zoom)) {
			endConstruction();
		} else {
			setNewZoom(new_zoom);
		}
	};

	const isNewZoomIntersecting = (new_zoom: ZoomEdit): boolean => {
		if (!zooms) return false;

		for (const zoom of zooms) {
			if (
				(new_zoom.startTime >= zoom.startTime &&
					new_zoom.startTime <= zoom.endTime) ||
				(new_zoom.endTime >= zoom.startTime &&
					new_zoom.endTime <= zoom.endTime) ||
				(new_zoom.startTime <= zoom.startTime &&
					new_zoom.endTime >= zoom.endTime) ||
				new_zoom.endTime > cp.videoDuration ||
				new_zoom.startTime < 0
			) {
				return true;
			}
		}

		return false;
	};

	const handleMouseOver = (e: React.MouseEvent) => {
		if (!e.clientX) return;

		setConstructing(true);
		setNewZoomStart(getMouseTime(e) - NEW_ZOOM_TIME / 2);

		constructNewZoom(e);
	};

	const handleMouseMove = (e: React.MouseEvent) => {
		setConstructing(true);
		setNewZoomStart(getMouseTime(e) - NEW_ZOOM_TIME / 2);
		constructNewZoom(e);
	};

	const handleMouseDown = (e: React.MouseEvent) => {
		if (!newZoom || isNewZoomIntersecting(newZoom)) return;

		dispatch(addZoom(newZoom));
		dispatch(setActiveZoom(newZoom.id));
		dispatch(setCustomizerPage("Zooms"));
		cp.hardsetCurrentTime(
			getAdjustedTime((newZoom.startTime + newZoom.endTime) / 2),
			true,
		);
	};

	// const handleMouseUp = (e: React.MouseEvent) =>{
	//     endConstruction()

	// }

	const endConstruction = () => {
		setConstructing(false);
		setNewZoom(null);
	};

	const getAdjustedTime = (time: number) => {
		if (videoEdits?.intro && videoEdits?.intro.visible) {
			return time + videoEdits.intro.duration;
		} else return time;
	};

	const getUnadjustedTime = (time: number) => {
		if (videoEdits?.intro && videoEdits?.intro.visible) {
			return time - videoEdits.intro.duration;
		} else return time;
	};

	const resizeZoom = (
		clipIndex: number,
		t: number,
		handle: "left" | "right",
	) => {
		if (!zooms) return;
		const origZoom = zooms[clipIndex];

		if (!origZoom) return;

		var time = getUnadjustedTime(t);

		if (handle == "right") {
			var newEndTime = Math.max(time, origZoom.startTime + 2);

			if (clipIndex == zooms.length - 1) {
				//this is the last zoom.
				newEndTime = Math.min(newEndTime, cp.videoDuration);
			} else {
				newEndTime = Math.min(newEndTime, zooms[clipIndex + 1].startTime);
			}

			dispatch(
				updateZoom({
					...origZoom,
					endTime: newEndTime,
					transitionTime: Math.min(
						origZoom.transitionTime || ZOOM_TRANSITION_TIME,
						(newEndTime - origZoom.startTime) / 2,
					),
				}),
			);
		} else if (handle === "left") {
			var newStartTime = Math.min(time, origZoom.endTime - 2);

			if (clipIndex == 0) {
				//this is the last zoom.
				newStartTime = Math.max(newStartTime, 0);
			} else {
				newStartTime = Math.max(newStartTime, zooms[clipIndex - 1].endTime);
			}

			dispatch(
				updateZoom({
					...origZoom,
					startTime: newStartTime,
					transitionTime: Math.min(
						origZoom.transitionTime || ZOOM_TRANSITION_TIME,
						(origZoom.endTime - newStartTime) / 2,
					),
				}),
			);
		}
	};

	const dragZoom = (clipIndex: number, dt: number) => {
		if (!zooms) return;
		const origZoom = zooms[clipIndex];

		const duration = origZoom.endTime - origZoom.startTime;
		var newStartTime = origZoom.startTime + dt;

		if (dt > 0) {
			//moving forward
			if (clipIndex == zooms.length - 1) {
				//last clip
				newStartTime = Math.min(newStartTime, cp.videoDuration - duration);
			} else {
				//There's another clip ahead
				const nextClip = zooms[clipIndex + 1];
				newStartTime = Math.min(newStartTime, nextClip.startTime - duration);
			}
		} else {
			//moving backward
			if (clipIndex == 0) {
				//first Clip
				newStartTime = Math.max(0, newStartTime);
			} else {
				//theres another clip before
				const prevClip = zooms[clipIndex - 1];
				newStartTime = Math.max(prevClip.endTime, newStartTime);
			}
		}

		dispatch(
			updateZoom({
				...origZoom,
				startTime: newStartTime,
				endTime: newStartTime + duration,
			}),
		);
	};

	return (
		<div
			className="ZoomTimeline"
			onMouseOver={handleMouseOver}
			onMouseMove={handleMouseMove}
			onMouseDown={handleMouseDown}
			onMouseOut={endConstruction}
			onMouseLeave={endConstruction}
			onMouseUp={endConstruction}
		>
			{zooms?.map((zoom, index) => {
				if (zoom.endTime <= cp.videoDuration || !cp.videoDuration) {
					return (
						<TimelineClip
							title="Zoom"
							theme="zoom"
							index={index}
							key={`${zoom.startTime}`}
							startTime={getAdjustedTime(zoom.startTime)}
							endTime={getAdjustedTime(zoom.endTime)}
							resizeClip={resizeZoom}
							dragClip={dragZoom}
							onClick={() => {
								dispatch(setActiveZoom(zoom.id));
								dispatch(setCustomizerPage("Zooms"));
							}}
							active={activeZoom == zoom.id}
							onDelete={(clipIndex: number) => {
								dispatch(deleteZoom(zoom));
								endConstruction();
							}}
						/>
					);
				} else {
					// dispatch(deleteZoom(zoom));
					return null;
				}
			})}

			{newZoom ? (
				<div className="newZoomTime">
					<TimelineClip
						title="+ Zoom"
						theme="newzoom"
						index={(zooms?.length || 0) + 1}
						key={`${newZoom.startTime}`}
						startTime={getAdjustedTime(newZoom.startTime)}
						endTime={getAdjustedTime(newZoom.endTime)}
					/>
				</div>
			) : null}
		</div>
	);
};
export default ZoomTimeline;
