export const starIcon = (color?: string) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 19 18"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.65802 1.86439C8.87657 1.37167 8.98589 1.1253 9.13807 1.04944C9.27024 0.983521 9.42544 0.983521 9.5576 1.04944C9.70978 1.1253 9.81911 1.37167 10.0377 1.86439L11.7785 5.78904C11.8431 5.9347 11.8754 6.00753 11.9255 6.0633C11.9697 6.11266 12.0237 6.15213 12.0841 6.17915C12.1523 6.2097 12.2312 6.21806 12.3891 6.2348L16.6414 6.68581C17.1752 6.74243 17.4421 6.77074 17.561 6.89273C17.6642 6.99869 17.7121 7.14706 17.6906 7.29377C17.6659 7.46263 17.4665 7.64317 17.0677 8.00433L13.8913 10.8809C13.7735 10.9877 13.7144 11.0411 13.6772 11.1061C13.6441 11.1636 13.6234 11.2275 13.6165 11.2935C13.6087 11.3682 13.6251 11.4462 13.6581 11.6022L14.5453 15.8056C14.6567 16.3333 14.7124 16.5971 14.6337 16.7484C14.5652 16.8799 14.4397 16.9715 14.2942 16.9963C14.1267 17.0247 13.8942 16.89 13.4292 16.6205L9.72517 14.4736C9.58772 14.3939 9.51899 14.3542 9.44592 14.3385C9.38126 14.3248 9.31442 14.3248 9.24975 14.3385C9.17668 14.3542 9.10795 14.3939 8.9705 14.4736L5.26654 16.6205C4.80152 16.89 4.56901 17.0247 4.40152 16.9963C4.25603 16.9715 4.13044 16.8799 4.06205 16.7484C3.98333 16.5971 4.03902 16.3333 4.1504 15.8056L5.03758 11.6022C5.07051 11.4462 5.08698 11.3682 5.07916 11.2935C5.07224 11.2275 5.05159 11.1636 5.01855 11.1061C4.98122 11.0411 4.92227 10.9877 4.80439 10.8809L1.62801 8.00433C1.22924 7.64317 1.02985 7.46263 1.00505 7.29377C0.98353 7.14706 1.0315 6.99869 1.13472 6.89273C1.25356 6.77074 1.52049 6.74243 2.05435 6.68581L6.30662 6.2348C6.46445 6.21806 6.54335 6.2097 6.61159 6.17915C6.67196 6.15213 6.72602 6.11266 6.77026 6.0633C6.82024 6.00753 6.85255 5.9347 6.91717 5.78904L8.65802 1.86439Z"
				stroke={color || "white"}
				stroke-linecap="round"
				stroke-linejoin="round"
                strokeWidth={1}
			/>
		</svg>
	);
};
