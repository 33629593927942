import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import {
	selectAutoUpdatingGuide,
	selectTrimmingVideo,
} from "../../slices/guideSlice";
import {
	closeMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import useInitGuideEditor from "./useInitGuideEditor";
import { useToast } from "@chakra-ui/react";
import { openOverlay } from "../../../layouts/Overlay";
import updateIcon from "@gigauser/common/src/assets/svgs/updateIcon";
import PreUpdate from "../../../components/PreUpdate/PreUpdate";

const useShowUpdatingGuide = () => {
	const dispatch = useAppDispatch();
	const updatingGuide = useAppSelector(selectAutoUpdatingGuide);
	const [prevUpdatingGuide, setPrevUpdatingGuide] = useState(updatingGuide);
	const initGuideEditor = useInitGuideEditor();
	const toast = useToast();

	const onFinishUpdate = async () => {
		await initGuideEditor();
		//should there be a true here
		dispatch(closeMessagePopup());
		console.log("Trimming video complete");
		toast({
			title: "Update successful!",
			description: "Now click Generate Speech to make your video",
			status: "success",
			duration: 40000,
			isClosable: true,
			position: "top",
		});
	};

	useEffect(() => {
		if (updatingGuide) {
			dispatch(
				openOverlay({
					heading: {
						icon: updateIcon("white"),
						title: "A guide update is in progres ...",
					},
					component: <PreUpdate onUpdate={() => {}} updateStarted={true} />,
				}),
			);
		} else if (!updatingGuide && prevUpdatingGuide) {
			onFinishUpdate();
		}
		setPrevUpdatingGuide(updatingGuide);
	}, [updatingGuide]);

	return {
		// Add any values or functions you want to expose
	};
};

export default useShowUpdatingGuide;
