import React, { useState } from "react";
import "./PreUpdate.css";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import updateIcon from "@gigauser/common/src/assets/svgs/updateIcon";
import downArrowheadIcon from "@gigauser/common/src/assets/svgs/downArrowheadIcon";

type PreUpdateProps = {
	onUpdate: () => void;
	updateStarted: boolean;
};

const PreUpdate: React.FC<PreUpdateProps> = (props) => {
	const [loading, setLoading] = useState(false);

	const onStartUpdate = async () => {
		setLoading(true);
		await props.onUpdate();
		setLoading(false);
	};

	if (!props.updateStarted)
		return (
			<div className="PreUpdate">
				<p>No worries! It happens to all fast-moving teams 🚀</p>

				<p>
					<b>Clueso makes it easy to update your guide:</b>

					<ol>
						<li>Click the button below to start a new screen recording.</li>

						<li>Record the updated flow for this guide without speaking.</li>

						<li>
							Clueso will intelligently update screenshots and scripts for the
							article and video based on your recording.
						</li>
					</ol>
				</p>

				<p className="PreUpdate-bottom-p">
					<a className="PreUpdate-link">Click here</a>
					to learn more about auto-updating.
				</p>

				<Cutton
					isLoading={loading}
					loadingText="Getting ready"
					onClick={onStartUpdate}
				>
					Proceed to Update
				</Cutton>
			</div>
		);
	else
		return (
			<div className="PreUpdate">
				<p>Your updated guide has opened on another tab.</p>

				<p>Click the button below to continue editing on this tab.</p>

				<Cutton
					onClick={() => {
						window.location.reload();
					}}
				>
					Continue editing here
				</Cutton>
			</div>
		);
};
export default PreUpdate;
