import React, { useRef, useEffect } from "react";
import { Device } from "@gigauser/common/src/types/devices";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";

import { useAppSelector, useAppDispatch } from "@gigauser/common/src/redux";
import { closeOverlay, openOverlay } from "./overlaySlice";
import close from "../../assets/icons/close.svg";
import OverlayLaptop from "./Overlay.laptop/Overlay.laptop";
import OverlayMobile from "./Overlay.mobile/Overlay.mobile";
import logger from "../../utils/logger";

type OverlayProps = {};

const Overlay: React.FC<OverlayProps> = () => {
	const { overlay, disableClose, disableClickoutOverlay } = useAppSelector(
		(state) => state.overlay.value,
	);

	const { device } = useWindowSize();

	const dispatch = useAppDispatch();

	const clickOutsideOverlay = (e: any) => {
		if (disableClickoutOverlay) return;

		if (
			e.target.classList.contains("OverlayLaptop") ||
			e.target.classList.contains("OverlayMobile")
		) {
			//clicked on the transluscent background
			logger.debug("I need to close overlay here. ", disableClickoutOverlay);
			if (!disableClose) {
				dispatch(closeOverlay());
			}
		}
	};

	useEffect(() => {
		logger.debug("mounting overlay");
		if (!disableClose && !disableClickoutOverlay) {
			setTimeout(() => {
				document.addEventListener("mousedown", clickOutsideOverlay);
			}, 300);
		} else {
			document.removeEventListener("mousedown", clickOutsideOverlay);
		}

		return () => {
			logger.debug("removing useOverlayListener");
			document.removeEventListener("mousedown", clickOutsideOverlay);
		};
	}, [disableClose, disableClickoutOverlay]);

	// logger.debug("overlay is: ", overlay)

	if (overlay) {
		return (
			<div>
				{device == "laptop" ? (
					<OverlayLaptop overlay={overlay} />
				) : (
					<OverlayMobile overlay={overlay} />
				)}
			</div>
		);
	} else {
		return null;
	}
};
export default Overlay;
