import { GigaUserApi } from "@giga-user-fern/api";
import { VideoClip } from "@giga-user-fern/api/types/api/resources/guides";
import React, { useContext, useEffect, useState } from "react";
import TimelineContainer from "../../video/components/Timeline/main/TimelineContainer";
import TimelineClip from "../../video/components/Timeline/components/TimelineClip/TimelineClip";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";

import "./GifMakerTimeline.css";

type GifMakerTimelineProps = {
	clip: VideoClip;
	setClip: (clip: VideoClip) => void;
};

const GifMakerTimeline: React.FC<GifMakerTimelineProps> = (props) => {
	const cp = useContext(CanvasPlayerContext);

	const resizeClip = (
		clipIndex: number,
		t: number,
		handle: "left" | "right",
	) => {
		const origClip = props.clip;

		var newEndTime = origClip.endTime;
		var newStartTime = origClip.startTime;

		if (handle == "right") {
			newEndTime = Math.max(t, origClip.startTime + 0.2);
			newEndTime = Math.min(newEndTime, cp.videoDuration);
		} else if (handle === "left") {
			newStartTime = Math.min(t, origClip.endTime - 0.2);
			newStartTime = Math.max(newStartTime, 0);
		}

		props.setClip({
			...props.clip,
			startTime: newStartTime,
			endTime: newEndTime,
		});
	};

	useEffect(() => {
		if (!cp.paused && cp.currentTime >= props.clip.endTime) {
			cp.pause();
		}
	}, [cp.currentTime]);

	return (
		<div className="GifMakerTimeline">
			<TimelineContainer
				width="fixed-gap"
				timelineSliderValue={60}
				// unsmoothSolidLine
				size="s"
			>
				<TimelineClip
					index={0}
					startTime={props.clip.startTime}
					endTime={props.clip.endTime}
					theme="clip"
					resizeClip={resizeClip}
				/>
			</TimelineContainer>
		</div>
	);
};
export default GifMakerTimeline;
