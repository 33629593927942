import React, { useState } from "react";

import "./SliderInput.css";

type SliderInputProps = {
	value: number;
	onChange: React.FormEventHandler<HTMLInputElement>;
	inState?: boolean;
};

const SliderInput: React.FC<SliderInputProps> = ({
	value,
	onChange,
	inState,
}) => {
	const [stateValue, setValue] = useState<number>(value);
	if (inState) {
		return (
			<input
				type="range"
				min="1"
				max="100"
				value={stateValue}
				onInput={(e) => {
					// @ts-expect-error
					setValue(e.target.value);
					onChange(e);
				}}
				className="tooltip-slider"
				id="myRange"
			/>
		);
	}
	return (
		<input
			type="range"
			min="1"
			max="100"
			value={value}
			onInput={onChange}
			className="tooltip-slider"
			id="myRange"
		/>
	);
};
export default SliderInput;
