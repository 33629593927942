import React, { useEffect, useRef } from "react";
import loading_prim from "../../assets/legacy_assets/icons/gifs/loading_primary.gif";
import close from "../../assets/icons/close.svg";

import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";

import "./MessagePopup.css";
import { closeMessagePopup } from "./messagePopupSlice";

type MessagePopupProps = {
	// type: "loading" | "error" | "message"
};

const MessagePopup: React.FC<MessagePopupProps> = () => {
	const { type, isVisible, message, showTime } = useAppSelector(
		(state) => state.messagePopup,
	);
	const dispatch = useAppDispatch();

	const closeHandler = () => {
		dispatch(closeMessagePopup());
	};

	const timer = useRef<any>(null);

	useEffect(() => {
		if (timer.current !== undefined) {
			clearTimeout(timer.current);
		}
		if (type !== "loading") timer.current = setTimeout(closeHandler, showTime);
	});

	return (
		<div className={`MessagePopup ${isVisible ? "show" : "hide"} ${type}`}>
			{type !== "loading" ? (
				<>
					{message}
					<img src={close} onClick={closeHandler} className="close"></img>
				</>
			) : (
				<>
					{message}
					<img src={loading_prim} className="icon"></img>
				</>
			)}
		</div>
	);
};
export default MessagePopup;
