import { Collection } from "../../../core/types/collections";
import {
	showErrorMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import { Item } from "../../../types/files";
import { selectSaver } from "../../slices/backendSlice";
import { editItemFrontend } from "../../slices/collectionDataSlice";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";

const useSetCollectionVisibility: () => (
	collection: Collection,
	isprivate: boolean,
) => Promise<boolean> = () => {
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);

	return async (collection: Collection, isprivate: boolean) => {
		const collectionCopy = { ...collection };

		collectionCopy.private = !collection.private;

		const itemCopy: Item = {
			type: "Collection",
			entry: { ...collectionCopy },
		};

		try {
			dispatch(showLoadingMessagePopup());

			const res = await saver.setCollectionVisibility(collection, isprivate);

			dispatch(editItemFrontend(itemCopy));
			dispatch(showMessagePopup("Edited 1 item successfully."));

			return true;
		} catch {
			dispatch(showErrorMessagePopup("Error while editing item."));
			return false;
		}
	};
};

export default useSetCollectionVisibility;
