import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectBackgroundEdits,
	updateBackgroundEdits,
} from "@gigauser/common/src/redux/slices/guideSlice";
import React, { useContext } from "react";
import { getDefaultVolume } from "@gigauser/common/src/core/canvas/canvasUtils/volumeUtils";
import CSection from "../../layout/CSection/CSection";
import musicIcon from "@gigauser/common/src/assets/svgs/videoeditor/musicIcon";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import UploadMusic from "../../../UploadMusic/UploadMusic";
import { selectAudioTracks } from "@gigauser/common/src/redux/slices/platformDetailsSlice";
import CSlider from "@gigauser/common/src/ui/video/components/CSlider/CSlider";
import "./MusicCustomizer.css";
import { defaultBGMusic } from "../../defaultVideoEdits";

type MusicCustomizerProps = {};

const MusicCustomizer: React.FC<MusicCustomizerProps> = () => {
	const cp = useContext(CanvasPlayerContext);
	const dispatch = useAppDispatch();
	const backgroundState = useAppSelector(selectBackgroundEdits);
	const playBackgroundAudio = backgroundState.audio?.visible || false;
	const audioTracks = useAppSelector(selectAudioTracks);

	const addNewMusic = async () => {
		dispatch(
			openOverlay({
				heading: {
					icon: musicIcon("white"),
					title: "Add New Music",
					data: "",
				},
				component: <UploadMusic />,
			}),
		);
	};

	const onHideMusic = (value: boolean) => {
		cp.pause();
		if (!value)
			dispatch(
				updateBackgroundEdits({
					...backgroundState,
					audio: {
						...backgroundState.audio,
						visible: false,
					},
				}),
			);
		else
			dispatch(
				updateBackgroundEdits({
					...backgroundState,
					audio: {
						...backgroundState.audio,
						visible: true,
					},
				}),
			);
	};

	const onSetMusic = (value: string) => {
		cp.pause();
		dispatch(
			updateBackgroundEdits({
				...backgroundState,
				audio: {
					...backgroundState.audio,
					visible: true,
					src: value,
					volume: getDefaultVolume(backgroundState.audio?.volume),
				},
			}),
		);
	};

	return (
		<div className="MusicCustomizer">
			<CSection icon={musicIcon} label="Music">
				{/* <CToggle label="Add Background Audio" value={playBackgroundAudio} setterFunction={onHideAudio} /> */}

				<div className="Music-track-upload" onClick={addNewMusic}>
					<div className="font-bold">+</div>
					<div>Upload</div>
				</div>

				<div className="Music-track-container">
					<div
						className={`Music-track ${!playBackgroundAudio ? "active" : "inactive"}`}
						onClick={() => onHideMusic(!playBackgroundAudio)}
					>
						🔇 None
					</div>
					{audioTracks.concat(defaultBGMusic).map((music, index) => (
						<div
							key={index}
							className={`Music-track ${backgroundState.audio?.src === music.src && backgroundState.audio.visible ? "active" : "inactive"}`}
							onClick={() => onSetMusic(music.src)}
						>
							{music.description}
						</div>
					))}
				</div>

				<CSlider
					label="Volume"
					unit="%"
					min={0}
					max={100}
					disabled={!playBackgroundAudio}
					value={backgroundState.audio?.volume || 0}
					setValue={(value) =>
						dispatch(
							updateBackgroundEdits({
								...backgroundState,
								audio: {
									...backgroundState.audio,
									visible: backgroundState.audio?.visible || false,
									volume: value,
								},
							}),
						)
					}
				/>
			</CSection>
		</div>
	);
};
export default MusicCustomizer;
