import React, { useContext } from "react";
import "./DeletePopup.css";
import Button from "@gigauser/common/src/ui/buttons/Button/Button";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import {
	showLoadingMessagePopup,
	showMessagePopup,
} from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import { showErrorMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import {
	deleteCollectionFrontend,
	deleteGuideFrontend,
} from "@gigauser/common/src/redux/slices/collectionDataSlice";
import { Item, rootCollection } from "@gigauser/common/src/types/files";
import logger from "@gigauser/common/src/utils/logger";
import useCollectionNavigate from "@gigauser/common/src/redux/hooks/FileHooks/useCollectionNavigate";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";

const DeletePopup: React.FC<Item> = (item) => {
	const env = useContext(EnvironmentContext);

	const saver = useAppSelector(selectSaver);
	const dispatch = useAppDispatch();
	const navigate = useCollectionNavigate(env);

	const onDelete = async () => {
		try {
			var deleteResult;

			dispatch(closeOverlay());
			dispatch(showLoadingMessagePopup());

			if (item.type == "Guide") {
				// deleteResult = await saver.deleteGuide(item.entry.id)
				const guidePreview = { ...item.entry };
				guidePreview.header = {
					...guidePreview.header,
					published: false,
				};
				guidePreview.deleted = true;
				guidePreview.isPinned = false;
				deleteResult = await saver.updateGuidePreview(guidePreview);

				var currentURL = window.location.href;

				// Check if the URL contains "/guide"
				if (currentURL.indexOf("/guide") !== -1) {
					navigate(rootCollection);
				}
			} else if (item.type === "Collection") {
				// deleteResult = await saver.updateCollection(collection)
				deleteResult = await saver.deleteCollection(item.entry.id);
			}

			if (deleteResult) {
				item.type == "Guide"
					? dispatch(deleteGuideFrontend(item.entry))
					: dispatch(deleteCollectionFrontend(item.entry));

				dispatch(showMessagePopup("Deleted successfully."));
			} else {
				dispatch(showErrorMessagePopup("Error deleting 1 item."));
			}
		} catch (e) {
			logger.error("Couldn't delete file: ", e);
			dispatch(showErrorMessagePopup("Error deleting 1 item."));
		}
	};

	return (
		<div className="gigauser-deletepopup">
			<p>Are you sure you would like to delete this item? </p>

			<div className="gigauser-delete-buttons">
				<Cutton onClick={onDelete} leftIcon={trashIcon("white")}>
					Delete
				</Cutton>
				<Cutton
					rank="secondary"
					onClick={() => {
						dispatch(closeOverlay());
					}}
				>
					Cancel
				</Cutton>
			</div>
		</div>
	);
};
export default DeletePopup;
