import { PublishedVideoType } from "@giga-user-fern/api/types/api/resources/guides";
import { Guide } from "../../../core/types/guide";
import { closeOverlay, setDisableCloseOverlay } from "../../../layouts/Overlay/overlaySlice";
import { selectSaver } from "../../slices/backendSlice";
import { selectGuide, setGuide, setPublishingGuide } from "../../slices/guideSlice";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import useFetchGuidePreviewAndCheck from "./useFetchGuidePreviewAndCheck";
import { useToast } from "@chakra-ui/react";

type usePublishGuideProps = {
    source: "helpcenter" | "embed" | "viewlink";
    isPrivate: boolean;
    hasPrivateParent?: boolean;
    articleVisible?: boolean;
    videoVisible?: boolean;
    unpublish?: boolean;
    guideUrlPath?: string;
    isFirstPublish?: boolean;
};

export const usePublishGuide: () => (props: usePublishGuideProps) => Promise<void> = () => {
    const dispatch = useAppDispatch();
	const fetchGuidePreviewAndCheck = useFetchGuidePreviewAndCheck();
    const toast = useToast()
    const guide = useAppSelector(selectGuide) as Guide;
	const saver = useAppSelector(selectSaver);

    const publishGuide = async (props: usePublishGuideProps) => {
		dispatch(setDisableCloseOverlay(true));

		var published = guide.guidePreview.header.published;
		var publishedVideo: PublishedVideoType =
			guide.guideData.settings.publishedVideo || "ai";

		if (props.videoVisible == false) {
			publishedVideo = "none";
		} else {
			publishedVideo = "ai";
		}

		if (props.unpublish) {
			published = false;
		} else if (props.source == "helpcenter") {
			published = true;
		}

		const guideCopy: Guide = {
			...guide,
			guidePreview: {
				...guide.guidePreview,
				header: {
					...guide.guidePreview.header,
					published: published,
				},
			},

			guideData: {
				...guide.guideData,
				settings: {
					hideArticle:
						props.articleVisible == undefined
							? guide.guideData.settings.hideArticle
							: !props.articleVisible,
					publishedVideo: publishedVideo,
				},
			},
		};

		if(props.guideUrlPath){
			guideCopy.guidePreview["guideUrlPath"] = props.guideUrlPath
		}

		dispatch(setGuide(guideCopy));
		await saver.updateGuide(guideCopy);
		dispatch(setPublishingGuide(true));
		saver.publishGuide(guideCopy, {
			onSuccess: () => {
				toast({
					title: "Publish completed successfully!",
					status: "success",
					duration: 10000,
					isClosable: true,
					position: "top",
				});
			},
		});
		setTimeout(() => fetchGuidePreviewAndCheck(), 3000);
		dispatch(setDisableCloseOverlay(false));
		dispatch(closeOverlay());
    }
	return publishGuide;
};