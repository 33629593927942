import React from "react";
import "./CInput.css";

type CInputProps = {
	disabled?: boolean;
	unit?: string;
	onChange?: (val: number) => void;
	value: number;
};

const CInput: React.FC<CInputProps> = (props) => {
	const onChange = (e: any) => {
		const val = Number(e.target.value);
		if (props.onChange) props.onChange(val);
	};

	return (
		<div className="CSlider-inputcontainer">
			<input
				disabled={props.disabled}
				className="CSlider-input"
				type="number"
				value={props.value}
				onChange={onChange}
			></input>
			<div className="CSlider-unit">{props.unit}</div>
		</div>
	);
};
export default CInput;
