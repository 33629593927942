import React, { useState } from "react";
import AuthFormLayout from "../AuthFormLayout/AuthFormLayout";
import Form, {
	FormState,
	FormStateValue,
} from "@gigauser/common/src/layouts/Form/Form";
import TitledInput, {
	TitledInputProps,
} from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import Cutton, {
	CuttonProps,
} from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import "./PersonalDetailsForm.css";
import { Size } from "@gigauser/common/src/types/sizes";
import { AnalyticsForNewUser } from "@giga-user-fern/api/types/api/resources/organizations";

type PersonalDetailsFormProps = {
	personalDetails: AnalyticsForNewUser;
	onSubmit: (f: FormState) => void;
};

export const isValidPersonalDetails = (
	personalDetails: AnalyticsForNewUser,
) => {
	/**
	 * This function is used to check if the personalDetails submitted by the user are valid.
	 * In particular, it checks if any fields are empty. If so, returns false.
	 */
	for (const key in personalDetails) {
		if (personalDetails[key as keyof AnalyticsForNewUser] === "") {
			return false;
		}
	}
	return true;
};

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = (props) => {
	const { howDidYouHearAboutClueso, roleInTeam } = props.personalDetails;

	const [formState, setFormState] = useState<FormState>({
		// name: {
		// 	value: "",
		// 	label: "Full Name",
		// },
		// company: {
		// 	value: "",
		// 	label: "Company",
		//     placeholder: "Where do you work?",
		// },
		role: {
			value: roleInTeam ?? "",
			// placeholder: "What do you do?",
			options: [
				{ icon: "🤝", text: "Customer Education" },
				{ icon: "🫶", text: "Customer Success" },
				{ icon: "🛠️", text: "Developer" },
				{ icon: "🎯", text: "Product Manager" },
				{ icon: "🍎", text: "Learning & Development" },
				{ icon: "🔊", text: "Marketing" },
				{ icon: "💼", text: "Sales" },
				{ icon: "✨", text: "Other" },
			],
			label: "Role",
		},
		source: {
			value: howDidYouHearAboutClueso ?? "",
			options: [
				{ icon: "📱", text: "Social media" },
				{ text: "Friend or colleague", icon: "👫" },
				{ icon: "🔍", text: "Search engine" },
				{ text: "Event or conference", icon: "🎉" },
				{ icon: "🤷", text: "Other" },
			],
			label: "How did you hear about Clueso?",
		},
	});

	const onSubmit = () => {
		props.onSubmit(formState);
	};

	var inputs: React.ReactElement<TitledInputProps>[] = Object.entries(formState)
		.filter(
			([key, value]) =>
				key !== "error" && key !== "success" && typeof value !== "string",
		)
		.map(([key, value]) => {
			const newValue = value as FormStateValue;

			return (
				<TitledInput
					dims={"s"}
					placeHolder={newValue.placeholder}
					id={key}
					key={key}
					onChange={() => {}}
					value=""
					label={newValue.label}
					options={newValue.options}
				/>
			);
		});

	return (
		<AuthFormLayout
			stepNumber={1}
			title="Welcome to Clueso!"
			subtitle="First, let's get to know you ..."
		>
			<Form
				className="PersonalDetailsForm"
				initFormState={formState}
				setFormState={setFormState}
				onSuccess={() => {}}
				submit={async (formState) => {
					onSubmit();
				}}
			>
				{[
					...inputs,
					<Cutton disabled={!isValidPersonalDetails({
                        roleInTeam: formState.role.value,
                        howDidYouHearAboutClueso: formState.source.value,
                    })} rank="secondary" size="s" onClick={onSubmit}>
						Continue
					</Cutton>,
				]}
			</Form>
		</AuthFormLayout>
	);
};
export default PersonalDetailsForm;
