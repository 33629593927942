const outroIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M15.14 21L10.39 17.75M17.64 5L18.5236 4.41094C18.9255 4.14299 19.3978 4 19.8808 4V4C20.671 4 21.4068 4.38321 21.8099 5.06285C22.8053 6.74112 24.8605 10.5513 27.64 17.5C31.64 27.5 19.64 35 12.14 26C10.4327 23.9512 9.26951 22.5761 8.49118 21.6742C8.14551 21.2736 5.51414 19.3741 5.14 19V19C4.87813 18.7381 5.02772 18.0648 5.2296 17.4828C5.45131 16.8437 6.0177 16.4056 6.67397 16.2415V16.2415C7.29073 16.0873 7.94374 16.1933 8.48008 16.5346L10.39 17.75M17.64 5L20.64 12M17.64 5L16.5391 2.79818C16.2863 2.29252 15.8215 1.92559 15.271 1.79696V1.79696C14.5052 1.61804 13.7083 1.92902 13.2661 2.57934L12.64 3.5M12.64 3.5L16.64 13M12.64 3.5L12.3851 2.8628C11.9766 1.84144 10.807 1.35699 9.79592 1.79032L9.5933 1.87716C8.77185 2.22921 8.35738 3.15215 8.64 4V4L9.3099 5.48866M13.14 14L9.3099 5.48866M9.3099 5.48866H8.30022C7.8699 5.48866 7.44938 5.61718 7.09258 5.85774V5.85774C6.49702 6.25929 6.14 6.93059 6.14 7.64887V7.91714C6.14 8.30117 6.22301 8.68067 6.38334 9.02963L10.39 17.75"
				stroke={color}
				stroke-width="1.75"
				stroke-linecap="round"
			/>
			<path
				d="M29 10C27.944 5.64998 26.5422 3.98114 23 2"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
			/>
			<path
				d="M3.14229 5.00003C1.1551 9.01113 1.15625 11.1906 2.59625 14.9851"
				stroke={color}
				stroke-width="1.5"
				stroke-linecap="round"
			/>
		</svg>
	);
};
export default outroIcon;
