const rocketIcon = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 25 25"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M20.5282 5.94474C20.9349 5.53794 20.9349 4.8784 20.5282 4.4716C20.1214 4.06481 19.4618 4.06481 19.055 4.4716L14.6166 8.91007C13.9963 8.54351 13.2726 8.33317 12.4999 8.33317C10.1988 8.33317 8.33327 10.1987 8.33327 12.4998C8.33327 13.2725 8.54362 13.9962 8.91017 14.6165L4.4717 19.0549C4.06491 19.4617 4.06491 20.1213 4.4717 20.5281C4.8785 20.9348 5.53804 20.9348 5.94484 20.5281L10.3833 16.0896C11.0036 16.4562 11.7272 16.6665 12.4999 16.6665C14.8011 16.6665 16.6666 14.801 16.6666 12.4998C16.6666 11.7271 16.4563 11.0035 16.0897 10.3832L20.5282 5.94474ZM13.0395 10.487C12.8674 10.4411 12.6866 10.4165 12.4999 10.4165C11.3493 10.4165 10.4166 11.3492 10.4166 12.4998C10.4166 12.6865 10.4412 12.8673 10.4871 13.0394L13.0395 10.487ZM11.9604 14.5127L14.5128 11.9603C14.5587 12.1323 14.5833 12.3132 14.5833 12.4998C14.5833 13.6505 13.6506 14.5832 12.4999 14.5832C12.3133 14.5832 12.1324 14.5586 11.9604 14.5127ZM16.4219 5.0214C15.2382 4.50035 13.9244 4.1665 12.4999 4.1665C9.52863 4.1665 7.03866 5.61923 5.20631 7.19354C3.36731 8.77355 2.09116 10.5598 1.5248 11.4267C1.09688 12.0816 1.09688 12.9181 1.5248 13.573C2.00281 14.3046 2.98646 15.6912 4.38738 17.056L5.86063 15.5828C4.64108 14.3987 3.76186 13.1802 3.31246 12.4998C3.84411 11.695 4.97735 10.1369 6.56397 8.77373C8.20204 7.36635 10.2247 6.24984 12.4999 6.24984C13.304 6.24984 14.0765 6.38926 14.8117 6.63169L16.4219 5.0214ZM12.4999 18.7498C11.696 18.7498 10.9235 18.6104 10.1883 18.368L8.57802 19.9783C9.76177 20.4993 11.0756 20.8332 12.4999 20.8332C15.4713 20.8332 17.9613 19.3805 19.7937 17.8062C21.6326 16.2261 22.9088 14.4398 23.4751 13.573C23.9031 12.9181 23.9031 12.0816 23.4751 11.4267C22.9971 10.695 22.0135 9.30845 20.6125 7.9437L19.1393 9.41695C20.3589 10.601 21.2382 11.8195 21.6875 12.4998C21.1559 13.3046 20.0226 14.8628 18.436 16.226C16.798 17.6334 14.7753 18.7498 12.4999 18.7498Z"
				fill={color}
			/>
		</svg>
	);
};

export default rocketIcon;
