import "./Menu.css";

import React, { useState } from "react";
import Menu, { DisplayMenuItem, MenuDirection } from "./Menu";
// import ToggleSwitch from '../../buttons/ToggleSwitch/ToggleSwitch';
import downArrowheadIcon from "../../../assets/svgs/downArrowheadIcon";

import Icon from "../../Icon/Icon";
import logger from "../../../utils/logger";
import LoadingRing from "../../../assets/gifs/LoadingRing/LoadingRing";
import { propIsFunction } from "@remirror/react";

type MenuElementProps = {
	MenuItem: DisplayMenuItem;
	direction?: MenuDirection;
	closeDisplayMenu: (() => void) | undefined;
};

const MenuElement: React.FC<MenuElementProps> = ({
	MenuItem,
	closeDisplayMenu,
	...props
}) => {
	const direction: MenuDirection = props.direction || "ltr";

	const [toggleActive, setToggleActive] = useState<boolean>();
	const [loading, setLoading] = useState<boolean>(false);

	const toggleState = () => {
		//reason for try catch is that checkDisable is an optional prop
		logger.debug("toggleState called");
		// if(this.props.disable) return
		setToggleActive(!toggleActive);
		MenuItem.toggleStates?.left.handler();
	};

	const onLeftIconClick = () => {
		MenuItem.toggleStates?.left.handler();
	};

	const onRightIconClick = () => {
		MenuItem.toggleStates?.right.handler();
	};

	return (
		<div
			className={` MenuElement dropdownElement ${MenuItem.type}`}
			onClick={async (e) => {
				if (MenuItem.handler && MenuItem.type !== "file" && !loading) {
					if (MenuItem.loading) {
						setLoading(true);
						await MenuItem.handler(e);
						setLoading(false);
					} else {
						MenuItem.handler(e);
					}
				}
				if (closeDisplayMenu && MenuItem.closeMenuOnClick) {
					closeDisplayMenu();
				} else {
				}
			}}
		>
			{/* label and icon */}
			<div className="clickable-container">
				<div className="clickable">
					<Icon className="Menu-icon">{MenuItem.icon}</Icon>
					{MenuItem.href ? (
						<a
							className="label"
							href={MenuItem.href}
							target="_blank"
							rel="noreferrer"
						>
							{" "}
							{MenuItem.label}{" "}
						</a>
					) : (
						<div className="label">{MenuItem.label}</div>
					)}

					{loading && <LoadingRing color={"#d43f8c"}></LoadingRing>}
				</div>
				<div>
					{MenuItem.description ? (
						<div className="MenuItem-description">{MenuItem.description}</div>
					) : null}
					{MenuItem.submenu ? (
						<>
							<Icon className={"submenu-arrow-icon"}>
								{downArrowheadIcon("white")}
							</Icon>
							<div className={`Submenu ${props.direction}`}>
								<Menu
									size="n"
									menu={MenuItem.submenu}
									spacing="s"
									closeDisplayMenu={closeDisplayMenu}
								/>
							</div>
						</>
					) : null}
				</div>
			</div>

			{/* {
                //Toggle Element
                (MenuItem.type=="toggle") ?
                <span className='MenuElement-ToggleSwitch'>
                    <ToggleSwitch 
                        toggleFunctions={{"left": MenuItem.toggleStates?.left.handler, "right":MenuItem.toggleStates?.right.handler }}
                        initialState={MenuItem.toggleStates?.active || false}
                        activeIcon = {sun}
                        inactiveIcon = {moon}
                    />
                </span>
                    

                :null
            } */}
		</div>
	);
};
export default MenuElement;
