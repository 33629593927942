import { EnvironmentContextValue } from "../../../contexts";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { Item, rootCollection } from "@gigauser/common/src/types/files";

import {
	moveItemFrontend,
	selectExploreCollectionData,
} from "@gigauser/common/src/redux/slices/collectionDataSlice";
import {
	showErrorMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import { GigaUserApi } from "@giga-user-fern/api";
import logger from "../../../utils/logger";
import { selectSaver } from "../../slices/backendSlice";

type MoveItemInput = {
	item: Item;
	newParentID?: GigaUserApi.Id;
};

const useMoveItem: (
	env: EnvironmentContextValue,
) => (input: MoveItemInput) => Promise<boolean> = (env) => {
	//newParentID is currentParent.objectID
	//folder is objectData
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);

	return async (input: MoveItemInput) => {
		const { item } = input;
		var newParentID = input.newParentID;

		if (item.entry.id === newParentID) {
			//trying to move a folder within the same folder itself
			logger.debug("trying to move a folder within the same folder itself");
			return false;
		}

		logger.debug("file: ", item);
		logger.debug("newParentID: ", newParentID);

		if (newParentID === rootCollection.id) {
			newParentID = undefined;
		}

		try {
			dispatch(showLoadingMessagePopup());

			var res: any;

			if (item.type === "Collection") {
				res = await saver.moveCollection(item.entry, newParentID);
				logger.debug("moved Collection: ", res);
			} else if (item.type === "Guide") {
				res = await saver.moveGuide(item.entry, newParentID);
				logger.debug("moved Guide: ", res);
			}

			if (res) {
				dispatch(
					moveItemFrontend({
						item: item,
						newParentID: newParentID || rootCollection.id,
					}),
				);

				dispatch(showMessagePopup(`Moved 1 ${item.type} successfully.`));
			} else {
				dispatch(showErrorMessagePopup("Error while moving item"));
			}

			return true;
		} catch (e) {
			logger.error("Error while moving item: ", e);
			dispatch(showErrorMessagePopup("Error while moving folder."));
			return false;
		}
	};
};

export default useMoveItem;
