import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import {
	AsyncOperationError,
	OperationError,
} from "@giga-user-fern/api/types/api/resources/guides";
import { selectSaver } from "../../slices/backendSlice";
import { showErrorMessagePopup } from "../../../layouts/MessagePopup/messagePopupSlice";
import { useToast } from "@chakra-ui/react";
import { openOverlay } from "../../../layouts/Overlay";
import SubscribePopup from "../../../overlays/SubscribePopup/SubscribePopup";

const useShowAsyncError = () => {
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	const id = useAppSelector((state) => state.guide.value.guide?.id);
	const toast = useToast();
	const showError = (error: OperationError) => {
		if (!id) return;
		if (!error.viewed) {
			saver.markErrorViewed(id);

			if (error.details.type === "generating_voiceover") {
				if (error.details.value.errorType === "screenshotOrdering") {
					toast({
						title:
							"Screenshot number " +
							error.details.value.screenshotNumber +
							" is out of order. ",
						description:
							"Please delete or reorder this screenshot and try again.",
						status: "error",
						duration: 40000,
						isClosable: true,
						position: "top",
					});
				} else if (error.details.value.errorType === "transcriptionMatching") {
					const matchingString = error.details.value.words.join(" ");
					toast({
						title:
							"Speech generation failed at or before '" + matchingString + "'.",
						description: "Please revise your transcript and try again.",
						status: "error",
						duration: 40000,
						isClosable: true,
						position: "top",
					});
				} else if (error.details.value.errorType === "unsubscribedOrg") {
					const admins = error.details.value.admins;
					dispatch(
						openOverlay({
							component: <SubscribePopup admins={admins} />,
						}),
					);
				}
			} else if (error.details.type === "exporting_video") {
				if (error.details.value.errorType === "blurEffects") {
					toast({
						title: "Export failed: Could not add blurs.",
						description:
							"Please contact Clueso support to get your exported video.",
						status: "error",
						duration: 80000,
						isClosable: true,
						position: "top",
					});
				} else if (error.details.value.errorType === "unsubscribedOrg") {
					const admins = error.details.value.admins;
					dispatch(
						openOverlay({
							component: <SubscribePopup admins={admins} trial={saver.getOrganization()?.trial}/>,
						}),
					);
				}
			}
		}
	};

	return showError;
};

export default useShowAsyncError;
