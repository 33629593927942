import React, { useContext, useEffect, useRef, useState } from "react";

import "./TimelineContainer.css";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { CMenuContext } from "@gigauser/common/src/contexts";
import TimelineContext from "../TimelineContext";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import { Size } from "@gigauser/common/src/types/sizes";


type TimelineContainerProps = {
	children: [React.ReactNode, ...React.ReactNode[]] | React.ReactNode;
	theme?: "pink" | "grey";
	width:
		| "fixed-width" //The timeline is full width (100%) and doesn't scroll in x direction
		| "fixed-gap"; // The timeline is of non-restricted width. Gap between timestamps is fixed

	size?: Size;
	alwaysScroll?: boolean;
	//Options
	unsmoothSolidLine?: boolean;
	timelineSliderValue: number;
};

type TimeStamp = {
	time: number;
	x: number;
};

const TimelineContainer: React.FC<TimelineContainerProps> = (props) => {
	const [dashedLinePosition, setDashedLinePosition] = useState<number | null>(
		null,
	);
	const [solidLinePosition, setSolidLinePosition] = useState<number>(0);

	const cMenuContext = useContext(CMenuContext);
	const { isContextMenuVisible } = cMenuContext;
	const [unsmoothen, setUnsmoothen] = useState<boolean>(false);

	// const [timeStampMarkers, setTimeStampMarkers] = useState<[number]>([])
	// const [timeStamp, setTimeStamp] = useState<TimeStamp>({
	//     stepSize: 0, gap:0
	// })

	const alpha = props.timelineSliderValue;
	const TIME_STAMP_GAP = 0.0077 * alpha * alpha + 0.2583 * alpha + 10.7451;

	const cp = useContext(CanvasPlayerContext);

	const timelineContainerRef = useRef<HTMLDivElement | null>(null);
	const timelineScrollerRef = useRef<HTMLDivElement | null>(null);
	const solidLineRef = useRef<HTMLDivElement | null>(null);

	const windowSize = useWindowSize();

	const getXCoord = (clientX: number) => {
		if (!timelineContainerRef.current) return 0;
		var rect = timelineContainerRef.current.getBoundingClientRect();
		var offsetX = clientX - rect.left; //x position within the element.

		return offsetX;
	};

	if (!cp.vidRef) throw new Error("Missing video ref!");

	const totalDuration = cp.getVideoDuration();

	const checkIfSolidLineIsOutOfView = () => {
		if (
			solidLineRef.current &&
			timelineScrollerRef.current &&
			timelineContainerRef.current
		) {
			const solidLineRect = solidLineRef.current.getBoundingClientRect();
			const timelineScrollerRect =
				timelineScrollerRef.current.getBoundingClientRect();
			const timelineContainerRect =
				timelineContainerRef.current.getBoundingClientRect();

			var offsetX = solidLineRect.left - timelineContainerRect.left; //x position within the element.

			if (
				offsetX - timelineScrollerRef.current.scrollLeft >
				0.8 * timelineScrollerRect.width
			)
				return true;
			else return false;
		}
	};

	const timeToPosition = (time: number) => {
		if (timelineContainerRef.current && totalDuration) {
			const duration = totalDuration;
			var timelineWidth = 0;

			if (props.width === "fixed-width") {
				timelineWidth = timelineContainerRef.current.clientWidth;
			} else {
				timelineWidth = TIME_STAMP_GAP * duration;
			}
			// var timelineWidth = timelineContainerRef.current.clientWidth || parseInt(timelineContainerRef.current.style.width, 10)

			// if(timelineContainerRef.current.style.width==="100%"){
			//     timelineWidth = timelineContainerRef.current.offsetWidth
			// }

			// logger.debug("data: ", time, duration, timelineWidth)
			const calculatedPosition = (time / duration) * timelineWidth;

			if (calculatedPosition === timelineWidth) {
				return calculatedPosition - 2;
			}

			return calculatedPosition;
		} else {
			return 0;
		}
	};

	const positionToTime = (position: number) => {
		var duration = totalDuration;

		if (timelineContainerRef.current && duration) {
			var widthDenominator = 1;

			if (props.width == "fixed-width") {
				widthDenominator = timelineContainerRef.current.clientWidth;
			} else {
				widthDenominator = TIME_STAMP_GAP * totalDuration;
			}

			const duration = totalDuration;

			const time = (position / widthDenominator) * duration;

			return time;
		} else return 0;
	};

	const smoothScrollTimeline = (scrollPosition: number) => {
		if (timelineScrollerRef.current)
			timelineScrollerRef.current.scrollTo({
				left: scrollPosition,
				behavior: "smooth",
			});
	};

	const animateLine = () => {
		const currentTime = cp.currentTime;

		if (
			timeToPosition(currentTime) !== solidLinePosition &&
			!cp.paused
			// paused check is needed to prevent changing position on hover
		) {
			setSolidLinePosition(timeToPosition(cp.currentTime));
		}

		if (
			cp._hardUpdateTimelinePending &&
			timeToPosition(currentTime) !== solidLinePosition
		) {
			setSolidLinePosition(timeToPosition(cp.currentTime));
			cp._setHardUpdateTimelinePending(false);
		}

		if (cp.paused && cp._hardUpdateTimelinePending) {
			//if paused bring the thing into view
			smoothScrollTimeline(timeToPosition(cp.currentTime) - 200);
		} else if (!cp.paused) {
			//the video is playing so stay along
			const isOutOfView = checkIfSolidLineIsOutOfView();
			if (isOutOfView)
				smoothScrollTimeline(timeToPosition(cp.currentTime) - 200);
		}
	};

	const handleMouseMove = (event: any) => {
		if (!timelineContainerRef.current) return; //type safety check

		if (isContextMenuVisible()) return;

		var rect = timelineContainerRef.current.getBoundingClientRect();
		var offsetX = event.clientX - rect.left; //x position within the element.
		setTimelineTimeByPosition(offsetX);
	};

	const setTimelineTimeByPosition = (offsetX: number) => {
		const time = positionToTime(offsetX);

		if (time > totalDuration) {
			handleMouseLeave();
			return;
		}

		if (cp.paused) {
			cp.hardsetCurrentTime(time);
		}

		setDashedLinePosition(offsetX);
	};

	const handleMouseLeave = () => {
		if (!timelineContainerRef.current) return; //type safety check

		if (isContextMenuVisible()) return;

		const duration = totalDuration;
		var widthDenominator = TIME_STAMP_GAP * duration;
		if (props.width === "fixed-width") {
			widthDenominator = timelineContainerRef.current.clientWidth;
		}

		if (duration && cp.paused) {
			//On moving mouse out, the time should be set back to sold line and not dashed line

			const time = (solidLinePosition / widthDenominator) * duration;
			cp.hardsetCurrentTime(time);
		}

		setDashedLinePosition(null);
	};

	const handleMouseClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (!timelineContainerRef.current) return;

		cp.pause();

		var rect = timelineContainerRef.current.getBoundingClientRect();
		var offsetX = event.clientX - rect.left; //x position within the element.

		const time = positionToTime(offsetX);
		if (time > totalDuration) {
			return;
		}

		setSolidLinePosition(offsetX);
	};

	const calculateTimestampMarkers: () => {
		timeStamps: TimeStamp[];
		timelineWidth: number | null; //Total length of the timeline in pixels
	} = () => {
		/**
		 * @returns timelineWidth null if the whole timeline fits in 100% of window width.
		 */

		var timeStamps: TimeStamp[] = [];

		var W0 = windowSize.width;

		if (timelineContainerRef.current) {
			//We should be here only!
			W0 = timelineContainerRef.current.clientWidth;
		}

		var T = totalDuration;

		//The minimum gap between time stamp markers is 100px. The minimum unit of time is 1s.

		var g = TIME_STAMP_GAP;

		var timelineWidth = null;
		//this setting works when the duration is sufficiently short

		if (props.width === "fixed-gap") {
			//The duration is too long, we can do fixed gap.
			timelineWidth = TIME_STAMP_GAP * T;
		} else {
			//TODO: These or's are chutiya code.
			timelineWidth =
				timelineContainerRef.current?.clientWidth || TIME_STAMP_GAP * T;
			g = timelineWidth ? timelineWidth / totalDuration : TIME_STAMP_GAP;
		}

		const g_min = TIME_STAMP_GAP - 10;

		var lastEntry = undefined;

		for (var i = 0; i < T; i += 1) {
			if (i !== 0) {
				const x = g * i;

				const nextEntry = {
					time: i,
					x: x,
				};

				if (
					props.width == "fixed-width" &&
					lastEntry &&
					nextEntry.x - lastEntry.x < g_min
				) {
					//do nothing
				} else {
					if (lastEntry) {
						if (nextEntry.x - lastEntry.x > 40) {
							timeStamps.push(nextEntry);
							lastEntry = nextEntry;
						}
					} else {
						timeStamps.push(nextEntry);
						lastEntry = nextEntry;
					}
				}
			}
		}

		return {
			timeStamps,
			timelineWidth,
		};
	};

	useEffect(() => {
		setUnsmoothen(true);
		setSolidLinePosition(timeToPosition(cp.currentTime));
		setTimeout(() => {
			setUnsmoothen(false);
		}, 300);
	}, [props.timelineSliderValue]);

	useEffect(() => {
		if (cp._timelineInitPending && timelineScrollerRef.current) {
			timelineScrollerRef.current.scrollTo({
				left: timeToPosition(cp.currentTime) - 200,
			});
			setSolidLinePosition(timeToPosition(cp.currentTime));
			cp._setTimelineInitPending(false);
		}
	}, [cp._timelineInitPending, timeToPosition, cp]);

	const { timeStamps } = calculateTimestampMarkers();

	animateLine();

	const containerWidth =
		props.width === "fixed-gap" ? TIME_STAMP_GAP * totalDuration : "100%";

	return (
		<TimelineContext.Provider
			value={{
				getXCoord: getXCoord,
				setTimelineTimeByPosition: setTimelineTimeByPosition,
				timeToPosition: timeToPosition,
				positionToTime: positionToTime,
			}}
		>
			{/* <div className='TimelineContainer-superparent'>

        <div className='Timeline-icons'>

            <div className='Timeline-icons-icon'>{textIcon("#b7b7b7")}</div>
            <div className='Timeline-icons-icon'>{videoIcon("#b7b7b7")}</div>
            <div className='Timeline-icons-icon'>{zoomIcon("#b7b7b7")}</div>

        </div> */}
			<div
				className={`TimelineScroller ${props.alwaysScroll ? "alwaysScroll" : "noAlwaysScroll"}`}
				ref={timelineScrollerRef}
			>
				<div
					ref={timelineContainerRef}
					className="TimelineContainer"
					onMouseMove={handleMouseMove}
					onMouseLeave={handleMouseLeave}
					onClick={handleMouseClick}
					style={{
						width: containerWidth,
					}}
				>
					{/* ABSOLUTE TIMELINE LINES */}
					{dashedLinePosition !== null ? (
						<div
							className={`DashedLine TimelineLine pink`}
							style={{ left: dashedLinePosition }}
						></div>
					) : null}
					<div
						className={`SolidLine TimelineLine pink 
                        ${props.unsmoothSolidLine || unsmoothen ? "unsmooth" : "smooth"} 
                    `}
						ref={solidLineRef}
						style={{ marginLeft: solidLinePosition }}
					></div>

					{/* TIMESTAMPS */}
					<div
						className="Timeline-timestamps"
						style={{
							position: "relative",
							width: containerWidth,
						}}
					>
						{timeStamps.map((timeStamp, index) => (
							<div
								className="Timeline-timestamp-marker"
								key={index}
								style={{
									left: timeStamp.x,
								}}
							>
								<div className="Timeline-timestamp-marker-time">
									{timeStamp.time}s
								</div>
								<div className="Timeline-timestamp-marker-dot">.</div>
							</div>
						))}
					</div>

					{/* TIME LINES */}

					<div
						className={`Timelines-container ${props.width} ${props.size}`}
						style={{ width: containerWidth }}
					>
						{Array.isArray(props.children) ? (
							props.children.map((timeline) => {
								if (timeline === null) return null;
								return <div className="Timeline-track">{timeline}</div>;
							})
						) : (
							<div className="Timeline-track">{props.children}</div>
						)}
					</div>
				</div>
			</div>
		</TimelineContext.Provider>
	);
};
export default TimelineContainer;
