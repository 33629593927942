import React, { useContext, useState } from "react";

import Clickout from "../../layouts/Clickout/Clickout";
import Menu from "../../ui/menus/Menu/Menu";
import logoutIcon from "../../assets/svgs/logout";

import "./ProfileButton.css";

import { DisplayMenu } from "../../ui/menus/Menu/Menu";

import { Organization } from "../../core/types/guide";
import ProfilePreview from "../ProfilePreview/ProfilePreview";
import Icon from "../../ui/Icon/Icon";
import { useAuth0 } from "@auth0/auth0-react";
import downArrowheadIcon from "../../assets/svgs/downArrowheadIcon";
import { ChrextPage } from "../../chrext-popup/ChrextPopup";
import logger from "../../utils/logger";
import { useAppSelector } from "../../redux";
import { getPlatformPage } from "../../utils/url";
import { InitResponse } from "@giga-user-fern/api/types/api";
import { saver } from "../../network/saver";
import { useLogoutFunction } from "@propelauth/react";

type ProfileButtonProps = {
	size?: string;
	organization: Organization;
	logout?: () => void;
	setPage?: React.Dispatch<React.SetStateAction<ChrextPage>>;
	setOrganization?: (organization: Organization) => void;
	dropdownMenu?: DisplayMenu; //Logout added at end automatically
	environment: "chrext" | "platform";
};

const ProfileButton: React.FC<ProfileButtonProps> = ({
	size = "n",
	organization,
	...props
}) => {
	const logout = useLogoutFunction()

	const [showDropdown, setDropdown] = useState<boolean>(false);
	// const {logout} = useAuth0()
	const [profileOptions, setProfileOptions] = useState<InitResponse[] | null>(
		null,
	);

	var theme = "light";

	const url = window.location.href;
	if (url.includes("/guide")) {
		theme = "dark";
	}
	theme = "light";

	const onLogOut = () => {
		logger.debug("Logging out ...");
		if (props.logout) {
			//logout override from props
			props.logout();
		} else {
			logout(false)
		}
		// window.close();
	};


	// logger.debug("SIGN OUT CHECK", signOut)

	// var appendableMenu = []

	// if(props.dropdownMenu){
	//     appendableMenu = props.dropdownMenu.menu.map((item) =>{
	//         return {
	//             ...item,
	//             handler: (e) =>{
	//                 if(item.handler) {
	//                     item.handler()

	//             }
	//         }
	//     })
	// }

	const profileDropdownMenu: DisplayMenu = {
		menu: [
			...(props.dropdownMenu?.menu || []),

			{
				type: "separator",
			},

			{
				label: "Logout",
				icon: logoutIcon,
				handler: onLogOut,
			},
		],
	};

	return (
		<>
			<div
				className={`gigauser-ProfileButton ${size}`}
				onClick={async () => {
					const orgs = (await saver.getMyUsers()).filter(
						(initOption) => initOption.userWithOrg.org.id !== organization.id,
					);
					setProfileOptions(orgs);
					setDropdown(true);
				}}
			>
				{organization.name}

				<Icon className="down-arrow" onClick={() => {}}>
					{downArrowheadIcon(theme === "dark" ? "#bfc7d4" : "#000000")}
				</Icon>
			</div>
			{showDropdown ? (
				<div
					className={`gigauser-profileDropdown dropdown-${props.environment}`}
				>
					<Clickout
						closeFunction={() => {
							setDropdown(false);
						}}
					>
						{/* <ArrowDropdown> */}
						<ProfilePreview
							active={true}
							onClick={() => {}}
							organization={organization}
						/>
						{props?.setOrganization
							? profileOptions?.map((initOption) => (
									<ProfilePreview
										active={false}
										onClick={() =>
											props?.setOrganization?.(initOption.userWithOrg.org)
										}
										organization={initOption.userWithOrg.org}
									/>
								))
							: null}
						<Menu
							{...profileDropdownMenu}
							spacing="s"
							closeDisplayMenu={() => {
								setDropdown(false);
							}}
						/>
						{/* </ArrowDropdown> */}
					</Clickout>
				</div>
			) : null}
		</>
	);
};
export default ProfileButton;
