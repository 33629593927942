import {
	showLoadingMessagePopup,
	showMessagePopup,
	showErrorMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import { editItemFrontend } from "@gigauser/common/src/redux/slices/collectionDataSlice";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { Item, unravelItem } from "@gigauser/common/src/types/files";
import logger from "../../../utils/logger";
import { selectSaver } from "../../slices/backendSlice";

const useEditItem: () => (object: Item) => Promise<boolean> = () => {
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);

	return async (item: Item) => {
		//folder has all new details only. Whether details have changed or not are to be checked elsewhere.

		const { name, description } = unravelItem(item);

		try {
			dispatch(showLoadingMessagePopup());

			if (item.type === "Collection") {
				const resp = await saver.updateCollection(item.entry);
			} else if (item.type === "Guide") {
				logger.debug("going to edit guide");
				const resp = await saver.updateGuidePreview(item.entry);
			}

			dispatch(editItemFrontend(item));

			dispatch(showMessagePopup("Edited 1 item successfully."));
			return true;
		} catch (e) {
			logger.error("Error while editing item: ", e);
			dispatch(showErrorMessagePopup("Error while editing item."));
			return false;
		}
	};
};

export default useEditItem;
