import React, { useEffect, useState } from "react";
import Icon from "../../ui/Icon/Icon";
import downArrowheadIcon from "../../assets/svgs/downArrowheadIcon";
import Clickout from "../../layouts/Clickout/Clickout";
import { getFlag } from "../../utils/voices/voiceUtils";
import { saver } from "../../network/saver";
import { Language } from "@giga-user-fern/api/types/api";
import "./LanguagePicker.css";
import CSearch from "../../ui/inputs/CSearch/CSearch";

type LanguagePickerProps = {
	position?: "top" | "bottom";
	currentLanguage: Language;
	setCurrentLanguage: (l: Language) => void;
	onlyTranscriptable?: boolean;
};

const LanguagePicker: React.FC<LanguagePickerProps> = (props) => {
	const [allLanguages, setAllLanguages] = useState<Language[]>([]);
	const [showLanguages, setShowLanguages] = useState(false);
	const [listedLanguages, setListedLanguages] = useState<Language[]>([]);

	const initAllLanguages = async () => {
		var allLanguages = await saver.getAllLanguages();

        if (props.onlyTranscriptable) {
			allLanguages = allLanguages.filter((l) => l.transcriptable);
		}

		setAllLanguages(allLanguages);
		setListedLanguages(allLanguages);
	};

	useEffect(() => {
		initAllLanguages();
	}, []);

	const flag = getFlag(props.currentLanguage.languageId).flag;
	const sortedLanguages = listedLanguages.sort((a, b) =>
		a.name.localeCompare(b.name),
	);

	return (
		<div className="LanguagePicker">
			<div>
				<div className="voice-rounded active select-language-button">
					<div
						style={{
							display: "flex",
							alignItems: "center",
						}}
						onClick={() => {
							setShowLanguages(true);
						}}
					>
						<Icon className="currentvoice-flag">{flag}</Icon>
						<div className="currentvoice-name">
							{props.currentLanguage.name}
						</div>
						<div className="arrowhead">{downArrowheadIcon("#c7c7c7")}</div>
					</div>
					{showLanguages ? (
						<Clickout
							closeFunction={() => {
								setShowLanguages(false);
							}}
						>
							<div
								className={`languages-popup-container ${props.position || "top"}`}
							>
								<CSearch
									handleResults={setListedLanguages}
									searchableItems={allLanguages}
								/>
								<div className={`languages-popup `}>
									{sortedLanguages.map((lang) => (
										<div
											className="language-option"
											key={lang.languageId}
											onClick={() => {
												props.setCurrentLanguage(lang);
												setShowLanguages(false);
											}}
										>
											<Icon className="language-option-flag">
												{getFlag(lang.languageId).flag}
											</Icon>
											<div className="language-option-name">{lang.name}</div>
										</div>
									))}
									{sortedLanguages.length === 0 && (
										<div className="nr">No Results</div>
									)}
								</div>
							</div>
						</Clickout>
					) : null}
				</div>
			</div>
		</div>
	);
};
export default LanguagePicker;
