import React from 'react';
import './OrLine.css';

type OrLineProps = {
    
};

const OrLine:React.FC<OrLineProps> = () => {
    
    return (
        <div className='OrLine'>
            <div className='OrLine-line'></div>
            <div className='OrLine-or'>or</div>
            <div className='OrLine-line'></div>
        </div>
    )
}
export default OrLine;