import React from "react";
import { navbarFooterButtons } from "../../data";
import { LabeledMenu } from "@gigauser/common/src/types/menus";
import "./NavbarFooter.css";
import { saver } from "@gigauser/common/src/network/saver";

type NavbarFooterProps = {};

const NavbarFooter: React.FC<NavbarFooterProps> = () => {
	const menu: LabeledMenu = navbarFooterButtons;

	return (
		<div className="NavbarFooter laptop">
			{menu.map((menuItem) => (
				<NavbarFooterButton
					key={menuItem.label}
					label={menuItem.label}
					icon={menuItem.icon}
					handler={menuItem.handler as () => void}
				/>
			))}
			{saver.organization?.trial && (
				<div className="NavbarFooter FreeTrial">
					You are on a 3-day trial.
					<br />
					Book a{" "}
					<a
						href="https://calendly.com/clueso_intro/neel"
						style={{ color: "inherit", textDecoration: "underline" }}
					>
						demo
					</a>{" "}
					to upgrade
				</div>
			)}
		</div>
	);
};

type NavbarFooterButtonProps = {
	icon: string;
	label: string;
	handler: () => void;
};

const NavbarFooterButton: React.FC<NavbarFooterButtonProps> = ({
	icon,
	label,
	handler,
}) => {
	return (
		<div className="NavbarFooterButton" onClick={handler}>
			<img className="NavbarFooterButton-icon" src={icon}></img>
			<div className="NavbarFooterButton-label">{label}</div>
		</div>
	);
};

export default NavbarFooter;
