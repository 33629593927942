import React from "react";

import "./VersionBox.css";
import { GuideVersion } from "@giga-user-fern/api/types/api/resources/guides";
import { saver } from "@gigauser/common/src/network/saver";

type VersionBoxProps = {
	version: GuideVersion;
	activeVersion: GuideVersion | null;
	setActiveVersion: (g: GuideVersion) => void;
};

const VersionBox: React.FC<VersionBoxProps> = ({ version, ...props }) => {
	const isActive = props.activeVersion?.versionNumber === version.versionNumber;

	const onClick = () => {
		props.setActiveVersion(version);
	};

	return (
		<div
			className={`VersionBox ${isActive ? "activeVersionBox" : "inactiveVersionBox"}`}
			onClick={onClick}
		>
			<div className="Version-Letter-Preview">
				{version.changeAuthor.charAt(0).toUpperCase()}
			</div>

			<div className="VersionBox-text">
				<div className="VersionBox-date">{version.updatedAt}</div>
				<div className="VersionBox-author">
					{(version.changeAuthor.includes("clueso.io") && !saver.organization?.name.includes("Clueso") )
						? "Clueso Admin"
						: version.changeAuthor}
				</div>
			</div>
		</div>
	);
};
export default VersionBox;
