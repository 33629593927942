import React, { useContext, useRef, useState } from "react";
import "./TextCustomizer.css";
import textIcon from "@gigauser/common/src/assets/svgs/videoeditor/textIcon";
import CSection from "../../../layout/CSection/CSection";
import { ElementEdit } from "@giga-user-fern/api/types/api/resources/video";
import { useAppDispatch } from "@gigauser/common/src/redux";
import { updateElement } from "@gigauser/common/src/redux/slices/guideSlice";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import {
	computeFontSize,
	computeTextboxHeight,
	wrapText,
} from "@gigauser/common/src/core/canvas/canvasUtils/elements/canvasTextbox";
import centerAlign from "@gigauser/common/src/assets/svgs/text-editor-toolbar/center-align";
import leftAlign from "@gigauser/common/src/assets/svgs/text-editor-toolbar/left-align";
import rightAlign from "@gigauser/common/src/assets/svgs/text-editor-toolbar/right-align";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import orgSetupCanvas from "@gigauser/common/src/core/canvas/orgSetupCanvas";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import minusIcon from "@gigauser/common/src/assets/svgs/minusIcon";
import paintbucketIcon from "@gigauser/common/src/assets/svgs/screenshot/paintbucketIcon";
import textColorIcon from "@gigauser/common/src/assets/svgs/screenshot/textColorIcon";
import Clickout from "@gigauser/common/src/layouts/Clickout/Clickout";
import { TextAlignment } from "@giga-user-fern/api/types/api";
import CColorPicker from "@gigauser/common/src/ui/inputs/CColorPicker/CColorPicker";
import FontDropdown from "../../../../../../components/FontDropdown/FontDropdown";
import { getFont } from "@gigauser/common/src/utils/fontsUtils";
import { useToast } from "@chakra-ui/react";
import { saver } from "@gigauser/common/src/network/saver";

type TextCustomizerProps = {
	element: ElementEdit;
};

const iconColor = "#c2c1c1";

const TextCustomizer: React.FC<TextCustomizerProps> = ({ element }) => {
	const dispatch = useAppDispatch();
	const cp = useContext(CanvasPlayerContext);
	const toast = useToast();
	const [showBgColorPopup, setShowBgColorPopup] = useState(false);
	const [showTextColorPopup, setShowTextColorPopup] = useState(false);
	const [fontSize, setFontSize] = useState<number>(
		element.textdata?.fontSize || 100,
	);

	const alignmentOptions: {
		label: TextAlignment;
		icon: JSX.Element;
	}[] = [
		{
			label: "left",
			icon: leftAlign(iconColor),
		},
		{
			label: "center",
			icon: centerAlign(iconColor),
		},
		{
			label: "right",
			icon: rightAlign(iconColor),
		},
	];

	const onUpdateElement = (newElement: ElementEdit) => {
		localStorage.setItem("textData", JSON.stringify(newElement.textdata));
		dispatch(updateElement(newElement));
	};

	const onUpdateText = (e: any) => {
		const video = cp.vidRef?.current;
		const canvas = cp.canvasRef?.current;

		if (!canvas || !video || !element.textdata) return;

		const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

		const text = e.target.value;
		const w = element.size[0] * video.videoWidth;

		const lines = wrapText(ctx, text, w, element.textdata.fontSize);
		const newHeight_f = computeTextboxHeight(
			canvas,
			lines,
			element.textdata.fontSize,
		);

		if (element.textdata) {
			const newElement: ElementEdit = {
				...element,
				size: [element.size[0], newHeight_f],
				textdata: {
					...element.textdata,
					text: (e.target.value as string) || "",
					lines: lines,
				},
			};

			onUpdateElement(newElement);
		}
	};

	const onChangeFontSize = (e: any) => {
		setFontSize(parseFloat(e.target.value));
	};

	const onKeyDownFontSize = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			e.preventDefault();
			onSubmitFontSize(fontSize);
		}
	};

	const onSubmitFontSize = (f: number) => {
		if (element.textdata) {
			const canvas = cp.canvasRef?.current;
			const video = cp.vidRef?.current;

			if (!canvas || !video) return;

			const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
			const w = element.size[0] * video.videoWidth;

			const lines = wrapText(ctx, element.textdata.text, w, f);
			const newHeight_f = computeTextboxHeight(canvas, lines, f);

			const newElement: ElementEdit = {
				...element,
				size: [element.size[0], newHeight_f],
				textdata: {
					...element.textdata,
					fontSize: f,
					lines: lines,
				},
			};

			onUpdateElement(newElement);
		}
	};

	const onUpdateBackgroundColor = (color?: string, opacity?: number) => {
		if (!element.textdata) return;

		if (color) localStorage.setItem("TextCustomizerBackgroundColor", color);
		if (opacity !== undefined)
			localStorage.setItem(
				"TextCustomizerBackgroundOpacity",
				opacity.toString(),
			);

		const newElement: ElementEdit = {
			...element,
			textdata: {
				...element.textdata,
				backgroundColor: color,
				backgroundOpacity: opacity,
			},
		};
		onUpdateElement(newElement);
	};

	const onUpdateTextColor = (color?: string) => {
		if (!element.textdata || !color) return;

		const newElement: ElementEdit = {
			...element,
			textdata: {
				...element.textdata,
				textColor: color,
			},
		};
		onUpdateElement(newElement);
	};

	const onIncrementFontSize = (i: number) => {
		if (!element.textdata) return;

		setFontSize(element.textdata.fontSize + i);
		onSubmitFontSize(element.textdata.fontSize + i);
	};

	const onChangeAlignment = (t: TextAlignment) => {
		if (!element.textdata) return;

		const newElement: ElementEdit = {
			...element,
			textdata: { ...element.textdata, alignment: t },
		};

		onUpdateElement(newElement);
	};

	const onFontFamilyChange = async (e: any) => {
		if (!element.textdata || !e.value) return;
		let newFontFamily = e.value as string;

		const res = await getFont(false, newFontFamily);
		if (!res) {
			toast({
				title: "Font not working!",
				description: "Please try again later.",
				status: "error",
				duration: 10000,
				isClosable: true,
				position: "top",
			});
			const res = await getFont(false);
			newFontFamily = res.toString().split("-")[0];
		}

		if (element.textdata) {
			const newElement: ElementEdit = {
				...element,
				textdata: { ...element.textdata, font: newFontFamily },
			};

			onUpdateElement(newElement);
		}
	};

	return (
		<div className="TextCustomizer">
			<CSection label="Text" icon={textIcon}>
				<textarea
					className="TextCustomizer-textarea"
					value={element.textdata?.text}
					onChange={onUpdateText}
				></textarea>
				<div className="TextCustomizer-options">
					<FontDropdown
						onChange={onFontFamilyChange}
						defaultValue={element.textdata?.font || "Inter"}
					/>
					<div className="texteditor-options-container texteditor-font">
						<Icon
							className="font-icon"
							onClick={() => {
								onIncrementFontSize(-1);
							}}
						>
							{minusIcon(iconColor)}
						</Icon>
						<input
							className="fontsize-input"
							value={Math.floor(fontSize)}
							onBlur={() => onSubmitFontSize(fontSize)}
							onChange={onChangeFontSize}
							onKeyDown={onKeyDownFontSize}
						></input>
						<Icon
							className="font-icon"
							onClick={() => {
								onIncrementFontSize(1);
							}}
						>
							{plusIcon(iconColor)}
						</Icon>
					</div>
				</div>
				<div className="TextCustomizer-options">
					<div className="texteditor-options-container texteditor-alignment">
						{alignmentOptions.map((align) => (
							<Icon
								onClick={() => {
									onChangeAlignment(align.label);
								}}
								className={`align-icon ${element.textdata?.alignment === align.label ? "active" : "inactive"}`}
							>
								{align.icon}
							</Icon>
						))}
					</div>

					<div className="texteditor-options-container texteditor-color">
						<CColorPicker
							color={element.textdata?.backgroundColor || "#000000"}
							opacity={
								element.textdata?.backgroundOpacity == undefined
									? 50
									: element.textdata?.backgroundOpacity
							}
							onChange={onUpdateBackgroundColor}
						>
							<Icon
								className="color-icon"
								onClick={() => {
									setShowBgColorPopup(true);
								}}
								style={{
									borderColor:
										element.textdata?.backgroundColor || "transparent",
								}}
							>
								{paintbucketIcon(iconColor)}
							</Icon>
						</CColorPicker>

						<CColorPicker
							color={element.textdata?.textColor || "#ffffff"}
							disableOpacity
							onChange={onUpdateTextColor}
						>
							<Icon
								className="color-icon"
								onClick={() => {
									setShowTextColorPopup(true);
								}}
								style={{
									borderColor: element.textdata?.textColor || "transparent",
								}}
							>
								{textColorIcon(iconColor)}
							</Icon>
						</CColorPicker>
					</div>
				</div>
			</CSection>
		</div>
	);
};

export default TextCustomizer;
