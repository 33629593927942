import React, { useContext, useState } from "react";
import { rootCollection } from "@gigauser/common/src/types/files";

import "./Breadcrumbs.css";
import BreadcrumbSection from "./BreadcrumbSection";
import { Collection } from "@gigauser/common/src/core/types/collections";
import homeIcon from "../../../assets/svgs/home";
import Icon from "../../Icon/Icon";

export type BreadcrumbsProps = {
	rootHandler?: () => void; //defined if root is to be appended to the beginning.
	segments: [Collection, ...Collection[]];
	navigationHandler?: (segment: Collection) => void;
	droppable?: boolean; //whether files can be dropped on the sections to move them there.
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
	const { segments } = props;

	// Map each segment
	const finalList: any = [];

	if (props.rootHandler && segments[0].id !== "Collection_root") {
		finalList.push(
			<BreadcrumbSection
				key={rootCollection.id}
				segment={rootCollection}
				navigationHandler={props.rootHandler}
			/>,
		);
		finalList.push(<span>{" > "}</span>);
	}

	const navigationHandler = (segment: Collection) => {
		if (props.navigationHandler) return props.navigationHandler(segment);
		else return null;
	};

	segments.forEach((segment, index) => {
		finalList.push(
			<BreadcrumbSection
				key={segment.id}
				segment={segment}
				navigationHandler={navigationHandler}
			/>,
		);
		if (index < segments.length - 1) {
			finalList.push(<span key={segment.id + ">"}>{" > "}</span>);
		}
	});
	return <div className="Breadcrumbs">{finalList}</div>;
};
export default Breadcrumbs;
