const i = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
		>
			<path
				d="M24 8.33333H20.4444C18.5776 8.33333 17.6442 8.33333 16.9311 8.69664C16.3039 9.01622 15.794 9.52616 15.4744 10.1534C15.1111 10.8664 15.1111 11.7998 15.1111 13.6667V15M24 8.33333L20.6667 5M24 8.33333L20.6667 11.6667M11.7778 5H9.33333C7.46649 5 6.53307 5 5.82003 5.36331C5.19282 5.68289 4.68289 6.19282 4.36331 6.82003C4 7.53307 4 8.46649 4 10.3333V19.6667C4 21.5336 4 22.4669 4.36331 23.18C4.68289 23.8072 5.19282 24.3171 5.82003 24.6367C6.53307 25 7.46649 25 9.33333 25H18.6667C20.5336 25 21.4669 25 22.18 24.6367C22.8072 24.3171 23.3171 23.8072 23.6367 23.18C24 22.4669 24 21.5336 24 19.6667V17.2222"
				stroke={color}
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default i;
