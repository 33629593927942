const textColorIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
		>
			<g>
				<path
					stroke={color}
					fill={color}
					d="M13.23 15h1.9L11 4H9L5 15h1.88l1.07-3h4.18zm-1.53-4.54H8.51L10 5.6z"
				/>
			</g>
		</svg>
	);
};
export default textColorIcon;
