import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import { showErrorMessagePopup, showLoadingMessagePopup, showMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { useCollectionNavigate } from "@gigauser/common/src/redux/hooks/FileHooks";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { Item, rootCollection } from "@gigauser/common/src/types/files"
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import logger from "@gigauser/common/src/utils/logger";
import { useContext } from "react";

const RestorePopup: React.FC<Item> = (item)=>{
  const env = useContext(EnvironmentContext);

	const saver = useAppSelector(selectSaver);
	const dispatch = useAppDispatch();
	const navigate = useCollectionNavigate(env);

	const onRestore = async () => {
		try {
			var restoreResult;

			dispatch(closeOverlay());
			dispatch(showLoadingMessagePopup());

			if (item.type == "Guide") {
				// deleteResult = await saver.deleteGuide(item.entry.id)
				const guidePreview = { ...item.entry };
				guidePreview.header = {
					...guidePreview.header,
				};
				guidePreview.deleted = false;
				restoreResult = await saver.updateGuidePreview(guidePreview);

				window.location.reload()
			} 

			if (restoreResult) {
				
				dispatch(showMessagePopup("Restored successfully."));
			} else {
				dispatch(showErrorMessagePopup("Error restoring 1 item."));
			}
		} catch (e) {
			logger.error("Couldn't delete file: ", e);
			dispatch(showErrorMessagePopup("Error restoring 1 item."));
		}
	};

	return (
		<div className="gigauser-deletepopup">
			<p>Are you sure you would like to restore this item? </p>

			<div className="gigauser-delete-buttons">
				<Cutton onClick={onRestore}>
					Restore
				</Cutton>
				<Cutton
					rank="secondary"
					onClick={() => {
						dispatch(closeOverlay());
					}}
				>
					Cancel
				</Cutton>
			</div>
		</div>
	);
}

export default RestorePopup