import {
	selectGeneratedTimestamps,
	selectOriginalTimestamps,
} from "../../slices/guideSlice";
import store from "../../store";
import { useAppSelector } from "../../useAppRedux";

export const useOriginalTimestamp: (
	navigateFunction: (generatedTimestamp: number) => void,
) => ((timestamp: number) => number | null) | null = (navigateFunction) => {
	// Just a check if the function can be called
	// or it is a video without time mapping maintained
	const generatedTimestamps = useAppSelector(selectGeneratedTimestamps);
	const originalTimestamps = useAppSelector(selectOriginalTimestamps);
	if (!generatedTimestamps || !originalTimestamps) return null;
	// CAN"T USE AS HOOK WILL NOT BE UPDATED IN THE CLASS
	return (timestamp: number) => {
		const state = store.getState();
		const originalTimestamps = selectOriginalTimestamps(state);
		const generatedTimestamps = selectGeneratedTimestamps(state);
		if (!generatedTimestamps || !originalTimestamps) return null;
		const newTimestamp = convertOriginalToGeneratedTimestamp(
			originalTimestamps,
			generatedTimestamps,
			timestamp,
		);
		navigateFunction(newTimestamp);
		return newTimestamp;
	};
};

export const useGeneratedTimestamp: (
	navigateFunction: (originalTimestamp: number) => void,
) => ((timestamp: number) => number | null) | null = (navigateFunction) => {
	// Just a check if the function can be called
	// or it is a video without time mapping maintained
	const generatedTimestamps = useAppSelector(selectGeneratedTimestamps);
	const originalTimestamps = useAppSelector(selectOriginalTimestamps);
	if (!generatedTimestamps || !originalTimestamps) return null;
	// CAN"T USE AS HOOK WILL NOT BE UPDATED IN THE CLASS
	return (timestamp: number) => {
		const state = store.getState();
		const originalTimestamps = selectOriginalTimestamps(state);
		const generatedTimestamps = selectGeneratedTimestamps(state);
		if (!generatedTimestamps || !originalTimestamps) return null;
		const newTimestamp = convertGeneratedToOriginalTimestamp(
			originalTimestamps,
			generatedTimestamps,
			timestamp,
		);
		navigateFunction(newTimestamp);
		return newTimestamp;
	};
};

export function convertOriginalToGeneratedTimestamp(
	originalTimestamps: number[],
	generatedTimestamps: number[],
	originalTimestamp: number,
): number {
	/**
	 * Converts the original timestamp to the generated timestamp.
	 */
	if (generatedTimestamps === originalTimestamps) {
		return originalTimestamp;
	}
	for (let i = 0; i < originalTimestamps.length - 1; i++) {
		if (
			originalTimestamp >= originalTimestamps[i] &&
			originalTimestamp < originalTimestamps[i + 1]
		) {
			return (
				generatedTimestamps[i] +
				Math.round(
					(((originalTimestamp - originalTimestamps[i]) *
						(generatedTimestamps[i + 1] - generatedTimestamps[i])) /
						(originalTimestamps[i + 1] - originalTimestamps[i])) *
						100,
				) /
					100
			);
		}
	}
	return generatedTimestamps[generatedTimestamps.length - 1];
}

export function convertGeneratedToOriginalTimestamp(
	originalTimestamps: number[],
	generatedTimestamps: number[],
	generatedTimestamp: number,
): number {
	/**
	 * Converts the generated timestamp to the original timestamp.
	 */
	if (generatedTimestamps === originalTimestamps) {
		return generatedTimestamp;
	}
	for (let i = 0; i < generatedTimestamps.length - 1; i++) {
		if (
			generatedTimestamp >= generatedTimestamps[i] &&
			generatedTimestamp < generatedTimestamps[i + 1]
		) {
			return (
				originalTimestamps[i] +
				Math.round(
					(((generatedTimestamp - generatedTimestamps[i]) *
						(originalTimestamps[i + 1] - originalTimestamps[i])) /
						(generatedTimestamps[i + 1] - generatedTimestamps[i])) *
						100,
				) /
					100
			);
		}
	}
	return originalTimestamps[originalTimestamps.length - 1];
}
