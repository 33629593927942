import React, { useState } from "react";
import Header from "../Header";
import {
	Button,
	Card,
	ConfigProvider,
	DatePicker,
	Flex,
	Form,
	FormProps,
	List,
	Typography,
	theme,
} from "antd";
import { saver } from "@gigauser/common/src/network/saver";
import dayjs from "dayjs";
import {
	ArticleUsageEvent,
	VideoUsageEvent,
} from "@giga-user-fern/api/types/api";
import { Footer } from "antd/es/layout/layout";

const { Title, Paragraph, Text, Link } = Typography;

type FieldType = {
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
};

const UsagePage: React.FC = () => {
	const [videoUsage, setVideoUsage] = useState<VideoUsageEvent[]>([]);
	const [articleUsage, setArticleUsage] = useState<ArticleUsageEvent[]>([]);
	const [videoTotalUsage, setVideoTotalUsage] = useState<number>(0);
	const [articleTotalUsage, setArticleTotalUsage] = useState<number>(0);
	const [finalStart, setFinalStart] = useState<dayjs.Dayjs>();
	const [finalEnd, setFinalEnd] = useState<dayjs.Dayjs>();
	const checkUsage: FormProps<FieldType>["onFinish"] = async (values) => {
		// setVideoUsage([])
		// setArticleUsage([])
		// setVideoTotalUsage(0)
		// setArticleTotalUsage(0)
		setFinalStart(values.startDate);
		setFinalEnd(values.endDate);
		const videoDataResp = await saver.getUsageVideo({
			start: values.startDate.toDate(),
			end: values.endDate.toDate(),
		});
		if (videoDataResp.ok) {
			setVideoUsage(videoDataResp.body.videoUsage);
			setVideoTotalUsage(videoDataResp.body.total);
		} else {
			setVideoUsage([]);
			setVideoTotalUsage(0);
		}

		const articleDataResp = await saver.getUsageArticle({
			start: values.startDate.toDate(),
			end: values.endDate.toDate(),
		});
		if (articleDataResp.ok) {
			setArticleUsage(articleDataResp.body.articleUsage);
			setArticleTotalUsage(articleDataResp.body.total);
		} else {
			setArticleUsage([]);
			setArticleTotalUsage(0);
		}
	};

	const downloadVideoCSV = () => {
		const data = videoUsage;
		const data_rows = data.map((x) => [
			x.guideId,
			x.name,
			x.userId,
			x.timestamp.toISOString().split("T")[0],
			x.duration,
		]);
		const rows: any[] = [
			["Guide ID", "Guide Name", "User ID", "Date", "Duration (Seconds)"],
		];
		rows.push(...data_rows);
		//@ts-expect-error
		const csv_name = `clueso_video_usage_${finalStart.format("YYYY-MM-DD")}_${finalEnd.format("YYYY-MM-DD")}.csv`;
		const csvContent =
			"data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", csv_name);
		document.body.appendChild(link); // Required for FF
		link.click();
	};

	const downloadArticleCSV = () => {
		const data = articleUsage;
		const data_rows = data.map((x) => [
			x.guideId,
			x.name,
			x.userId,
			x.timestamp.toISOString().split("T")[0],
		]);
		const rows: any[] = [["Guide ID", "Guide Name", "User ID", "Date"]];
		rows.push(...data_rows);
		//@ts-expect-error
		const csv_name = `clueso_article_usage_${finalStart.format("YYYY-MM-DD")}_${finalEnd.format("YYYY-MM-DD")}.csv`;
		const csvContent =
			"data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", csv_name);
		document.body.appendChild(link); // Required for FF
		link.click();
	};

	return (
		<>
			<Header></Header>
			<h2
				style={{
					fontSize: "x-large",
					padding: "10px 5px",
				}}
			>
				View Usage Data
			</h2>

			<div style={{ marginLeft: "20px" }}>
				<ConfigProvider
					theme={{
						algorithm: theme.defaultAlgorithm,
					}}
				>
					<Flex justify="center" align="left" vertical>
						<div>
							<Flex justify="left" align="left">
								<Form
									autoComplete="off"
									style={{ width: "200px", marginTop: "20px" }}
									labelCol={{ span: 8 }}
									wrapperCol={{ span: 54 }}
									onFinish={checkUsage}
									layout="vertical"
								>
									<Form.Item<FieldType>
										label="From"
										name="startDate"
										rules={[
											{
												required: true,
												message: "Please select a starting date!",
											},
										]}
									>
										<DatePicker />
									</Form.Item>
									<Form.Item<FieldType>
										label="To"
										name="endDate"
										rules={[
											{
												required: true,
												message: "Please select an ending date!",
											},
										]}
									>
										<DatePicker />
									</Form.Item>
									<Form.Item>
										<Button type="primary" htmlType="submit">
											Check Usage
										</Button>
									</Form.Item>
								</Form>
								{videoTotalUsage > 0 ? (
									<Card
										onClick={downloadVideoCSV}
										hoverable
										title="Video Usage"
										style={{
											marginLeft: "40px",
											minWidth: "275px",
											border: "2px solid #e0e7ff",
										}}
									>
										<center>
											<h1 style={{ fontSize: "x-large" }}>
												{Math.floor(videoTotalUsage / 60)}:
												{(videoTotalUsage % 60).toLocaleString("en-US", {
													minimumIntegerDigits: 2,
												})}
											</h1>
											<p>minutes</p>
											<br></br>
											<p style={{ bottom: "10px", color: "gray" }}>
												Click to download detailed usage
											</p>
										</center>
									</Card>
								) : (
									<Card
										title="Video Usage"
										style={{
											marginLeft: "40px",
											minWidth: "275px",
											border: "2px solid #e0e7ff",
										}}
									>
										<center>
											<p>No usage for the selected interval</p>
										</center>
									</Card>
								)}
								{articleTotalUsage > 0 ? (
									<Card
										onClick={downloadArticleCSV}
										hoverable
										title="Article Usage"
										style={{
											marginLeft: "40px",
											minWidth: "275px",
											border: "2px solid #e0e7ff",
										}}
									>
										<center>
											<h1 style={{ fontSize: "x-large" }}>
												{articleTotalUsage}
											</h1>
											<p>articles</p>
											<br></br>
											<p style={{ bottom: "10px", color: "gray" }}>
												Click to download detailed usage
											</p>
										</center>
									</Card>
								) : (
									<Card
										title="Article Usage"
										style={{
											marginLeft: "40px",
											minWidth: "275px",
											border: "2px solid #e0e7ff",
										}}
									>
										<center>
											<p>No usage for the selected interval</p>
										</center>
									</Card>
								)}
							</Flex>
						</div>
						<br></br>
						<Paragraph>Please note the following</Paragraph>
						<Paragraph>
							<ul>
								<li>
									Article usage data is available only after{" "}
									<Text code>06-06-2024</Text>
								</li>
								<li>
									For each guide, the duration in the detailed usage sheet
									corresponds to the longest exported/published version in that
									interval
								</li>
								<li>
									For data that accurately reflects your usage, please map the
									start and end date to your billing cycle
								</li>
							</ul>
						</Paragraph>
					</Flex>
				</ConfigProvider>
			</div>
		</>
	);
};

export default UsagePage;
