const infoIcon = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 13 13"
			fill="none"
		>
			<path
				d="M5.31836 5.31814C5.31836 5.6445 5.58291 5.90905 5.90927 5.90905V9.45451C5.58291 9.45451 5.31836 9.71906 5.31836 10.0454C5.31836 10.3718 5.58291 10.6363 5.90927 10.6363H7.09109C7.41745 10.6363 7.682 10.3718 7.682 10.0454C7.682 9.71906 7.41745 9.45451 7.09109 9.45451V4.72723H5.90927C5.58291 4.72723 5.31836 4.9918 5.31836 5.31814Z"
				fill={color}
			/>
			<path
				d="M6.50013 4.13637C6.98964 4.13637 7.3865 3.73954 7.3865 3.25001C7.3865 2.76048 6.98964 2.36365 6.50013 2.36365C6.01062 2.36365 5.61377 2.76048 5.61377 3.25001C5.61377 3.73954 6.01062 4.13637 6.50013 4.13637Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M13 1.77273C13 0.79368 12.2063 0 11.2273 0H1.77273C0.79368 0 0 0.79368 0 1.77273V11.2273C0 12.2063 0.79368 13 1.77273 13H11.2273C12.2063 13 13 12.2063 13 11.2273V1.77273ZM11.8182 1.77273C11.8182 1.44638 11.5536 1.18182 11.2273 1.18182H1.77273C1.44638 1.18182 1.18182 1.44638 1.18182 1.77273V11.2273C1.18182 11.5536 1.44638 11.8182 1.77273 11.8182H11.2273C11.5536 11.8182 11.8182 11.5536 11.8182 11.2273V1.77273Z"
				fill={color}
			/>
		</svg>
	);
};

export default infoIcon;
