import { GigaUserApi, GigaUserApiClient } from "@giga-user-fern/api";
import {
	cleanJSON,
	insertImageMetadata,
} from "../components/formats/RichText/utils/cleanImageSrc";
import {
	Guide,
	GuideData,
	GuidePreview,
	Organization,
	Voice,
} from "../core/types/guide";
import { setBranding } from "../core/utils/styleUtils";
import { fetcher } from "./Adapter";
import { Collection, CollectionInput } from "../core/types/collections";
import { rootCollection } from "../types/files";
import logger from "../utils/logger";
import {
	AutoUpdateGuideRequest,
	ConvertToGifResponse,
	FileFormat,
	FinishCreateGuideRequest,
	FinishUploadSourceRequest,
	GifSettings,
	GuideRequest,
	OriginalEdits,
	ValidateGuideUrlRequest,
	VideoMetadata,
} from "@giga-user-fern/api/types/api/resources/guides";
import { captureEvent } from "../core/analytics/analytics";
import {
	getDataUris,
	getHTMLandTextWithImages,
} from "../components/formats/RichText/utils/exportProcessing";
import {
	AuthenticatedUser,
	Background,
	Cover,
	CoverOption,
	CoverType,
	GetUsageRequest,
	HelpCenterConfig,
	HostingDetails,
	InitResponse,
	Language,
	PronDictEntryRequest,
	User,
	UserInOrg,
	UserRole,
	VideoFormat,
} from "@giga-user-fern/api/types/api";

import _saverconfig from "./_saverconfig";

// import { Id } from '../core/types/baseTypes';
import { Supplier } from "@giga-user-fern/api/types/core";
import { ValidateCollectionUrlPathRequest } from "@giga-user-fern/api/types/api/resources/collections";
import {
	AddUserToOrganizationRequest,
	CreateOrganizationRequest,
	JoinOrganizationRequest,
	RemoveUserFromOrgRequest,
} from "@giga-user-fern/api/types/api/resources/organizations";
import { message } from "antd";
const Id = GigaUserApi.Id;

const main_url = _saverconfig.main_url;

type PresignedUrl = any;

function dataURItoBlob(dataURI: string) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this

	try {
		var byteString = atob(dataURI.split(",")[1]);

		// separate out the mime component
		var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		// write the bytes of the string to an ArrayBuffer
		var ab = new ArrayBuffer(byteString.length);

		// create a view into the buffer
		var ia = new Uint8Array(ab);

		// set the bytes of the buffer to the correct values
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		// write the ArrayBuffer to a blob, and you're done
		var blob = new Blob([ab], { type: mimeString });
		return blob;
	} catch (e) {
		logger.error("Error", e, dataURI);
		return dataURI;
	}
}

export const uploadToPresignedXhr = async (
	body: any,
	presignedURL: PresignedUrl,
	progressCallback?: (number: number) => void,
) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData();
		const data = { ...presignedURL };

		// Append fields to formData
		for (let key in data.fields) {
			formData.append(key, data.fields[key]);
		}
		formData.append("file", body);

		const xhr = new XMLHttpRequest();

		// Track upload progress
		xhr.upload.addEventListener("progress", (event) => {
			if (event.lengthComputable) {
				if (progressCallback) {
					progressCallback((event.loaded / event.total) * 100);
				}
			}
		});

		// Handle the response
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				// Done
				if (xhr.status === 200 || xhr.status === 204) {
					resolve(true);
				} else {
					console.error("Upload failed:", xhr.status, xhr.responseText);
					captureEvent({
						eventName: "UploadError",
						value: { error: xhr.responseText },
					});
					reject(null);
				}
			}
		};

		// Handle network errors
		xhr.onerror = () => {
			console.error("Network Error");
			captureEvent({
				eventName: "UploadError",
				value: { error: "Network Error" },
			});
			reject("Network Error");
		};

		// Set up the request and send the formData
		xhr.open("POST", data.url, true);
		xhr.send(formData);
	});
};

export const uploadToPresigned = async (
	body: any,
	presignedURL: PresignedUrl,
) => {
	const formData = new FormData();
	const data = { ...presignedURL };
	// data.fields.key = presignedURL.fields.key + "hello.txt"
	for (let key in data.fields) {
		formData.append(key, data.fields[key]);
	}
	formData.append("file", body);
	try {
		const resp = await fetch(data.url, { method: "POST", body: formData });
		logger.debug("UPLOADED", resp);
		if (!resp.ok) {
			// No upload
			captureEvent({
				eventName: "UploadError",
				value: { error: JSON.stringify(resp) },
			});
			logger.debug("BODY OF FAILED UPLOAD", body);
			return false;
		}
		return resp;
	} catch (e: any) {
		try {
			const resp = await fetch(data.url, { method: "POST", body: formData });
			logger.debug("UPLOADED", resp);
			if (!resp.ok) {
				// No upload
				captureEvent({
					eventName: "UploadError",
					value: { error: JSON.stringify(resp) },
				});
				logger.debug("BODY OF FAILED UPLOAD", body);
				return false;
			}
			return resp;
		} catch (e: any) {
			logger.debug(e, e.status);

			try {
				captureEvent({
					eventName: "UploadError",
					value: { error: JSON.stringify(e) },
				});
			} catch (e) {}
			return Promise.resolve(null);
		}
	}
};

export class GuideSaver {
	mutationAPI: GigaUserApiClient;
	userAPI: GigaUserApiClient;
	token: Supplier<string>;
	organization: Organization | null;
	hostname: string | null;
	user: User | null;
	allUsers: InitResponse[] | null;
	languages: Language[] | null;
	voices: Voice[] | null;
	helpCenterConfig: HelpCenterConfig | null;

	constructor() {
		this.mutationAPI = new GigaUserApiClient({
			token: "random",
			environment: main_url,
		});
		this.mutationAPI = new GigaUserApiClient({
			token: "random",
			environment: main_url,
		});
		this.userAPI = new GigaUserApiClient({
			token: "random",
			environment: main_url,
		});
		this.token = "";
		this.organization = null;
		this.hostname = null;
		this.user = null;
		this.allUsers = null;
		this.languages = null;
		this.voices = null;
		this.helpCenterConfig = null;
	}

	setCurrentCollection = () => {};
	getBrandingColor = () => {
		return this.organization?.brandColor;
	};

	getOrganization = () => {
		return this.organization;
	};

	getTokenPlatform = () => {
		//@ts-expect-error
		return this.token();
	};

	// setBrandingColor = (color:string)=>{
	//     // Used in Chrext to just maintain the same color
	//     if (!this.organization){
	//         this.organization = {id: Id('') ,domain:'no.com', brandColor: color, name: 'no'}
	//     }
	// }

	initialiseWithValues = (token: string, organization: Organization) => {
		this.token = token;
		this.mutationAPI = new GigaUserApiClient({
			//auth header goes here.
			token: token,
			//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
			//function to make sure that this token is valid.
			environment: main_url,
			fetcher: fetcher,
		});
		this.organization = organization;
		this.mutationAPI = new GigaUserApiClient({
			token: organization.id,
			environment: main_url,
			fetcher: fetcher,
		});
	};

	initializeExtension: (accessToken: string) => Promise<Organization> = (
		accessToken: string,
	) => {
		const tokenSupplier = async () => accessToken;
		return new Promise(async (resolve, reject) => {
			if (this.user && this.organization) {
				resolve(this.organization);
			}
			if (accessToken) {
				this.token = tokenSupplier;
				this.userAPI = new GigaUserApiClient({
					//auth header goes here.
					token: tokenSupplier,
					//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
					//function to make sure that this token is valid.
					environment: main_url,
					fetcher: fetcher,
				});

				let orgId: string | undefined = undefined;
				if (process.env.NODE_ENV !== "development") {
					const res = await chrome.storage.local.get("organization_id");
					orgId = res.organization_id;
				}
				chrome.runtime.sendMessage({
					type: "kek",
					message: `${orgId} ${accessToken}`,
				});
				let resp: any = await this.userAPI.users.login();
				if (resp.ok) {
					if (resp.body.associatedToOrg) {
						console.log(orgId);
						resp = await this.userAPI.users.initialize({
							orgId: orgId ? Id(orgId) : undefined,
						});
						if (resp.ok) {
							this.getMyUsers();
							this.getAllLanguages();
						} else if (resp.error) {
							await chrome.storage.local.remove("organization_id");
							return await this.initializeExtension(accessToken);
						}
					} else {
						reject({ userExists: true, associatedToOrg: false });
					}
				} else {
					reject(null);
				}

				if (resp.ok) {
					const { userWithOrg, hostname } = resp.body;
					await chrome.storage.local.set({
						organization_id: userWithOrg.org.id,
					});
					this.hostname = hostname || null;
					this.organization = userWithOrg.org;
					this.mutationAPI = new GigaUserApiClient({
						token: async () => {
							return JSON.stringify({
								organization_id: userWithOrg.org.id,
								token: accessToken,
							});
						},
						environment: main_url,
						fetcher: fetcher,
					});
					this.hostname = hostname || null;
					this.user = {
						...userWithOrg.user,
						role: userWithOrg.role as UserRole,
						organizationId: userWithOrg.org.id,
					};
					//@ts-expect-error
					resolve(this.organization);
				} else {
					reject(null);
				}
			}
		});
	};

	initializePlatform: (
		accessToken: () => Promise<string>,
	) => Promise<Organization> = (accessToken: () => Promise<string>) => {
		return new Promise(async (resolve, reject) => {
			if (this.user && this.organization) {
				resolve(this.organization);
			}
			if (accessToken) {
				this.token = accessToken;
				this.userAPI = new GigaUserApiClient({
					//auth header goes here.
					token: accessToken,
					//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
					//function to make sure that this token is valid.
					environment: main_url,
					fetcher: fetcher,
				});
				const orgId = localStorage.getItem("organization_id");
				let resp;

				resp = await this.userAPI.users.login();
				if (resp.ok) {
					if (resp.body.associatedToOrg) {
						console.log(orgId, "this is the org Id");
						resp = await this.userAPI.users.initialize({
							orgId: orgId ? Id(orgId) : undefined,
						});
						console.log("Resp", resp);
						if (resp.ok) {
							this.initializePlatformWithResp(resp.body);
							this.getMyUsers();
							this.getAllLanguages();
							this.getVoices();
						} else if (resp.error)  {
							localStorage.removeItem("organization_id");
							return await this.initializePlatform(accessToken);
						}
					} else {
						reject({ userExists: true, associatedToOrg: false });
					}
				} else {
					reject(null);
				}
				if (resp.ok) {
					//@ts-expect-error
					resolve(this.organization);
				} else {
					reject(null);
				}
			}
		});
	};

	initializePlatformWithResp = (initResp: InitResponse) => {
		const { userWithOrg, hostname } = initResp;
		localStorage.setItem("organization_id", userWithOrg.org.id);
		this.organization = userWithOrg.org;
		this.mutationAPI = new GigaUserApiClient({
			//auth header goes here.
			token: async () => {
				//@ts-ignore
				const stringAccess = await this.token();
				return JSON.stringify({
					organization_id: userWithOrg.org.id,
					token: stringAccess,
				});
			},
			//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
			//function to make sure that this token is valid.
			environment: main_url,
			fetcher: fetcher,
		});
		// setBranding(this.organization.brandColor)
		this.hostname = hostname || null;
		this.user = {
			...userWithOrg.user,
			role: userWithOrg.role as UserRole,
			organizationId: userWithOrg.org.id,
		};
	};

	initialise: (accessToken: string) => Promise<Organization> = (
		accessToken: string,
	) => {
		return new Promise(async (resolve, reject) => {
			if (accessToken) {
				this.token = accessToken;
				this.mutationAPI = new GigaUserApiClient({
					//auth header goes here.
					token: accessToken,
					//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
					//function to make sure that this token is valid.
					environment: main_url,
					fetcher: fetcher,
				});

				const org =
					await this.mutationAPI.organizations.organization.getOrganization();
				const host =
					await this.mutationAPI.organizations.organization.getHostname();
				if (host.ok) this.hostname = host.body;

				logger.debug("got org: ", org);

				if (org.ok) {
					this.organization = org.body;
					logger.debug("set org: ", this);
					this.mutationAPI = new GigaUserApiClient({
						token: org.body.id,
						environment: main_url,
						fetcher: fetcher,
					});
					logger.debug("branding being set", this.organization.brandColor);
					// setBranding(this.organization.brandColor)
					resolve(this.organization);
				} else {
					reject(org);
				}
			}
		});
	};

	saveAudio = async () => {
		const url = await this.mutationAPI.audio.saveAudio();
		if (url.ok) {
			return url.body;
		} else {
			return null;
		}
	};

	setDefaultVoice = async (voice: Voice) => {
		const url =
			await this.mutationAPI.organizations.organization.setDefaultVoice({
				voice,
			});
		if (url.ok) {
			return true;
		} else {
			return false;
		}
	};

	initialiseFromOrgId = async (orgId: string) => {
		/**
		 * Used in remote script
		 */

		logger.debug("initialiseFromOrgId: ", orgId);
		this.mutationAPI = new GigaUserApiClient({
			token: orgId,
			environment: main_url,
			fetcher: fetcher,
		});

		const organization =
			await this.mutationAPI.organizations.organization.getOrganizationFromId({
				id: Id(orgId),
			});

		const hostname =
			await this.mutationAPI.organizations.organization.getHostnameFromId({
				id: Id(orgId),
			});

		logger.debug("hostname: ", hostname);

		if (organization.ok) {
			this.organization = organization.body;
			setBranding(this.organization.brandColor);

			if (hostname.ok) {
				this.hostname = hostname.body;
			}

			return this.organization;
		} else {
			logger.error("failed", organization);
			return null;
		}
	};

	initialiseFromHostname = async (hostname: string) => {
		this.mutationAPI = new GigaUserApiClient({
			token: "ignore",
			environment: main_url,
			fetcher: fetcher,
		});

		const organization =
			await this.mutationAPI.organizations.organization.getOrganizationFromHostName(
				{
					hostname: hostname,
				},
			);

		if (organization.ok) {
			this.organization = organization.body;
			setBranding(this.organization.brandColor);
			this.mutationAPI = new GigaUserApiClient({
				token: this.organization.id,
				environment: main_url,
				fetcher: fetcher,
			});
			return this.organization.id;
		} else {
			logger.error("failed", organization);
			return null;
		}
	};

	// checked ✅
	fetchAllGuidePreviews = async (onlyPublished = true) => {
		const allGuides =
			await this.mutationAPI.guides.guideQueries.getAllGuidePreviews();

		if (allGuides.ok) {
			const guidePreviews = allGuides.body;
			logger.debug("guidePreviews: ", guidePreviews);
			if (onlyPublished) {
				return guidePreviews.filter((i) => i.header.published == true);
			} else {
				return guidePreviews;
			}
		} else {
			return [];
		}
	};

	createEmptyTextGuide = async (parentId?: GigaUserApi.Id) => {
		const resp =
			await this.mutationAPI.guides.guideMutations.createEmptyTextGuide({
				parentId: parentId === rootCollection.id ? undefined : parentId,
			});

		if (resp.ok) {
			return resp.body;
		} else {
			return false;
		}
	};
	fetchHelpCenterConfig = async () => {
		const resp = await this.mutationAPI.admin.getHelpCenterConfig();

		if (resp.ok) {
			return resp.body;
		} else {
			return false;
		}
	};
	setHelpCenterConfig = async (helpCenterConfig?: HelpCenterConfig) => {
		const resp =
			await this.mutationAPI.admin.setHelpCenterConfig(helpCenterConfig);
		if (resp.ok) {
			return resp.body;
		} else {
			return false;
		}
	};
	setLogo = async (logo: string) => {
		const resp = await this.mutationAPI.admin.setLogo(logo);
		if (resp.ok) {
			return resp.body;
		} else {
			return false;
		}
	};
	setFavicon = async (favicon: string) => {
		const resp = await this.mutationAPI.admin.setFavicon(favicon);
		if (resp.ok) {
			return resp.body;
		} else {
			return false;
		}
	};
	fetchAllCollections = async () => {
		const allCollections =
			await this.mutationAPI.collections.collectionQueries.getAllCollections();

		if (allCollections.ok) {
			const collections = allCollections.body;

			return collections;
		} else {
			return [];
		}
	};

	fetchAllChildren = async (
		_parentId?: string,
		onlyPublished: boolean = true,
	) => {
		var parentId = undefined;

		if (_parentId == "Collection_root") {
			parentId = undefined;
		} else if (_parentId) {
			parentId = GigaUserApi.Id(_parentId);
		}

		const allChildren =
			await this.mutationAPI.collections.collectionQueries.getAllChildren({
				parentId: parentId,
			});

		logger.debug("allChildren: ", allChildren);

		if (allChildren.ok) {
			const { guides, collections } = allChildren.body;
			if (onlyPublished) {
				const _guides = guides.filter((g) => g.header.published);
				return {
					guides: _guides,
					collections: collections,
				};
			} else {
				return allChildren.body;
			}
		} else {
			return {
				guides: [],
				collections: [],
			};
		}
	};

	getPath = async (id: string) => {
		logger.debug("saver getPath: ", id);

		const res = await this.mutationAPI.collections.collectionQueries.getPath({
			id: GigaUserApi.Id(id),
		});

		if (res.ok) {
			var path = res.body.collections;
			const full_path = [rootCollection, ...path];

			logger.debug("got path: ", full_path);

			return full_path as [Collection, ...Collection[]];
		} else {
			return false;
		}
	};

	uploadVideoChunk = async (
		chunkNumber: number,
		guideId: GigaUserApi.Id,
		data: unknown,
		formatString: VideoFormat,
		autoUpdate: GigaUserApi.Id | undefined,
	) => {
		const uploadChunkURL =
			await this.mutationAPI.guides.guideMutations.uploadVideoChunk({
				guideId: guideId,
				chunkNumber: chunkNumber,
				format: formatString,
				updateId: autoUpdate,
			});
		if (uploadChunkURL.ok) {
			const result = await uploadToPresigned(data, uploadChunkURL.body);
			if (!result) {
				const uploadChunkURL =
					await this.mutationAPI.guides.guideMutations.uploadVideoChunk({
						guideId: guideId,
						chunkNumber: chunkNumber,
						format: formatString,
						updateId: autoUpdate,
					});
				if (uploadChunkURL.ok) {
					const result = await uploadToPresigned(data, uploadChunkURL.body);
					return result;
				}
			}
			return result;
		} else {
			const uploadChunkURL =
				await this.mutationAPI.guides.guideMutations.uploadVideoChunk({
					guideId: guideId,
					chunkNumber: chunkNumber,
					format: formatString,
					updateId: autoUpdate,
				});
			if (uploadChunkURL.ok) {
				const result = await uploadToPresigned(data, uploadChunkURL.body);
				if (!result) {
					const uploadChunkURL =
						await this.mutationAPI.guides.guideMutations.uploadVideoChunk({
							guideId: guideId,
							chunkNumber: chunkNumber,
							format: formatString,
							updateId: autoUpdate,
						});
					if (uploadChunkURL.ok) {
						const result = await uploadToPresigned(data, uploadChunkURL.body);
						return result;
					}
				}
				return result;
			}
		}
		return false;
	};

	uploadImage = async (guideId: GigaUserApi.Id, data: string) => {
		const uploadChunkURL =
			await this.mutationAPI.guides.guideMutations.uploadImage(guideId);
		if (uploadChunkURL.ok) {
			const result = await uploadToPresigned(
				dataURItoBlob(data),
				uploadChunkURL.body,
			);
			if (result) {
				return (uploadChunkURL.body as any).fields.key.split("/").at(-1);
			}
			return (uploadChunkURL.body as any).fields.key.split("/").at(-1);
		}
		return false;
	};

	compileVideo = async (
		totalChunks: number,
		guideId: GigaUserApi.Id,
		formatString: VideoFormat,
		autoUpdate: GigaUserApi.Id | undefined,
		onlyCheck: boolean = false,
	) => {
		const compiledVideo =
			await this.mutationAPI.guides.guideMutations.compileVideo({
				totalChunks: totalChunks,
				guideId: guideId,
				format: formatString,
				updateId: autoUpdate,
				onlyCheck: onlyCheck,
			});
		if (compiledVideo.ok) {
			return compiledVideo.body;
		} else {
			return false;
		}
	};

	enhanceArticle = async (request: GuideRequest) =>
		await this.mutationAPI.guides.aiEnhance.enhanceArticleAsync(request);
	enhanceVideoTranscript = async (request: GuideRequest) =>
		await this.mutationAPI.guides.aiEnhance.enhanceVideoTranscriptAsync(
			request,
		);

	search = async (text: string) => {
		const searchResults = await this.mutationAPI.guides.guideQueries.search({
			text: text,
		});
		if (searchResults.ok) {
			return searchResults.body;
		} else {
			return [];
		}
	};

	//checked ✅
	fetchGuideData = async (id: GigaUserApi.Id, version_number?: number) => {
		const rootGuides = await this.mutationAPI.guides.guideQueries.getGuideData({
			id: id,
			version: version_number,
		});

		if (rootGuides.ok) {
			const guideData = rootGuides.body;
			// if (guideData.video.generated?.subtitles){
			//     const blob = new Blob([guideData.video.generated?.subtitles], { type: 'text/vtt' });
			//     guideData.video.generated.subtitles = URL.createObjectURL(blob);
			// }

			return guideData;
		} else {
			return null;
		}
	};

	//checked ✅
	fetchGuidePreview = async (id: GigaUserApi.Id) => {
		const res = await this.mutationAPI.guides.guideQueries.getGuidePreview({
			id: id,
		});

		if (res.ok) {
			const guidePreview = res.body;
			return guidePreview;
		} else {
			return null;
		}
	};

	getAllVersions = async (guide: Guide) => {
		const res = await this.mutationAPI.guides.guideQueries.getAllVersions({
			id: guide.id,
		});

		if (res.ok) {
			return res.body;
		} else {
			return null;
		}
	};

	deleteGuide = async (guideID: GigaUserApi.Id) => {
		const resp = await this.mutationAPI.guides.guideMutations.deleteGuide({
			id: guideID,
		});
		return resp.ok;
	};

	deleteCollection = async (collectionID: GigaUserApi.Id) => {
		const resp =
			await this.mutationAPI.collections.collectionMutations.deleteCollection({
				id: collectionID,
			});
		return resp.ok;
	};

	updateCollection = async (_collection: Collection) => {
		/**
		 * Used to update the Collection. NOT to be used for moving or reordering!
		 * (description, name, published)
		 */

		const collection = { ..._collection };

		const res =
			await this.mutationAPI.collections.collectionMutations.updateCollection(
				collection,
			);

		if (res.ok) {
			return res;
		} else {
			return false;
		}
	};

	setCollectionVisibility = async (
		_collection: Collection,
		isPrivate: boolean,
	) => {
		const collection = { ..._collection };

		const res =
			await this.mutationAPI.collections.collectionMutations.setCollectionVisibility(
				{
					collection: collection,
					private: isPrivate,
				},
			);

		if (res.ok) {
			return res;
		} else {
			return false;
		}
	};

	updateGuidePreview = async (_guidePreview: GuidePreview) => {
		/**
		 * Used to update only the Guide Preview.
		 * (description, name, published)
		 * Do not use this to move or reorder guides.
		 */

		var guidePreview = { ..._guidePreview };

		const res =
			await this.mutationAPI.guides.guideMutations.updateGuidePreview(
				guidePreview,
			);

		if (res.ok) {
			return res;
		}

		return false;
	};

	pinGuide = async (pinned: boolean, guidePreview: GuidePreview) => {
		const res = await this.mutationAPI.guides.guideMutations.pinGuide({
			guidePreview: guidePreview,
			isPinned: pinned,
		});

		if (res.ok) return res;

		return false;
	};

	moveGuide = async (
		guidePreview: GuidePreview,
		newParentId?: GigaUserApi.Id,
	) => {
		/**
		 * Use this function to move a guide to a new collection
		 */

		const res = await this.mutationAPI.guides.guideMutations.moveGuide({
			guidePreview: guidePreview,
			newParentId: newParentId,
		});

		if (res.ok) {
			return res.body;
		} else {
			return false;
		}
	};

	moveCollection = async (
		collection: Collection,
		newParentId?: GigaUserApi.Id,
	) => {
		/**
		 * Use this function to move a guide to a new collection
		 */

		const res =
			await this.mutationAPI.collections.collectionMutations.moveCollection({
				collection: collection,
				newParentId: newParentId,
			});

		if (res.ok) {
			return res.body;
		} else {
			return false;
		}
	};

	reorderGuide = async (
		succeedingGuide: GuidePreview,
		precedingGuide?: GuidePreview,
	) => {
		/**
		 * Used to reorder the sequence_number. SucceedingGuide is placed after precedingGuide
		 */

		logger.debug("from saver: ", precedingGuide, succeedingGuide);

		const res = await this.mutationAPI.guides.guideMutations.reorderGuide({
			succeedingGuide: succeedingGuide,
			precedingGuide: precedingGuide,
		});

		logger.debug("res: ", res);

		if (res.ok) return res;
		else return false;
	};

	reorderCollection = async (
		succeedingCollection: Collection,
		precedingCollection?: Collection,
	) => {
		/**
		 * Used to reorder the sequence_number. SucceedingGuide is placed after precedingGuide
		 */

		const res =
			await this.mutationAPI.collections.collectionMutations.reorderCollection({
				succeedingCollection,
				precedingCollection,
			});

		logger.debug("res: ", res);

		if (res.ok) return res;
		else return false;
	};

	updateGuide = async (guide: Guide, incrementVersion: boolean = true) => {
		/**
		 * Used to update the entire Guide (i.e: GuidePreview + GuideData)
		 */

		const { id, guidePreview, guideData } = guide;

		const sanitizedGuide: GuideData = JSON.parse(JSON.stringify(guideData));
		sanitizedGuide.plainDoc.data = cleanJSON(sanitizedGuide.plainDoc.data);
		if (sanitizedGuide.video.generated) {
			sanitizedGuide.video.generated.transcript.data = cleanJSON(
				sanitizedGuide.video.generated.transcript.data,
			);
		}
		// const sanitizedGuide: GuideData = {
		//     plainDoc: {version: "2023-03-12", data:cleanJSON(richTextData)},
		//     video: {
		//         ...guideData.video
		//     },
		// };

		const updateData =
			await this.mutationAPI.guides.guideMutations.updateGuideData({
				id: id,
				guidePreview: guidePreview,
				guideData: sanitizedGuide,
			});

		if (updateData.ok) {
			const presignedURLs = updateData.body.presignedUrls;
			const promiseArray: Promise<Response | null | false>[] = [];

			const sanitisedWithID: any = insertImageMetadata(
				JSON.parse(JSON.stringify(guideData.plainDoc.data)),
				presignedURLs.images!,
				(src: string, index: number) => {
					logger.debug("UPLOADING DATA URI", src);
					const imageUpload = uploadToPresigned(
						dataURItoBlob(src),
						presignedURLs.images![index],
					);
					promiseArray.push(imageUpload);
				},
			);
			const fullGuide: Guide = JSON.parse(JSON.stringify(guide));
			fullGuide.guideData.plainDoc.data = sanitisedWithID;
			if (fullGuide.guideData.video.generated) {
				fullGuide.guideData.video.generated.transcript.data = cleanJSON(
					fullGuide.guideData.video.generated.transcript.data,
				);
			}

			const finishUpdate =
				await this.mutationAPI.guides.guideMutations.finishUpdateGuideData({
					guide: fullGuide,
					incrementVersion: incrementVersion,
				});

			if (finishUpdate.ok) {
				const success = await Promise.all(promiseArray);
				if (!success.find((x) => !x || x.ok === false)) {
					return finishUpdate.body;
				} else {
					return false;
				}
			}
		} else {
			return false;
		}
	};

	updateOriginalEdits = async (
		id: GigaUserApi.Id,
		originalEdits: OriginalEdits,
	) => {
		/**
		 * This is the save function to be run from the trim window.
		 */

		const res =
			await this.mutationAPI.guides.guideMutations.updateOriginalEdits({
				id: id,
				originalEdits: originalEdits,
			});
		if (res.ok) {
			return res.body;
		} else {
			return null;
		}
	};

	initiateCreateGuide = async (parentID?: GigaUserApi.Id) => {
		const id = await this.mutationAPI.guides.guideMutations.initiateCreateGuide(
			{ parentId: parentID === rootCollection.id ? undefined : parentID },
		);
		if (id.ok) {
			return id.body;
		} else {
			return null;
		}
	};

	uploadCreateGuide = async (
		blob: Blob,
		languageId: string,
		fileFormat: FileFormat,
		parentID?: GigaUserApi.Id,
		progressCallback?: (progress: number) => void,
	) => {
		const resp =
			await this.mutationAPI.guides.guideMutations.initiateUploadCreate({
				parentId: parentID === rootCollection.id ? undefined : parentID,
				fileFormat: fileFormat,
			});
		if (resp.ok) {
			const { id, url } = resp.body;

			const status = await uploadToPresignedXhr(blob, url, progressCallback);
			if (status) {
				const resp =
					await this.mutationAPI.guides.guideMutations.finishUploadCreate({
						id: id,
						languageId: languageId,
						fileFormat: fileFormat,
					});
				return id;
			}
		}
		return null;
	};

	saveGuideAudio = async (
		guideId: GigaUserApi.Id,
		autoUpdate: GigaUserApi.Id | undefined,
	) => {
		const id = await this.mutationAPI.audio.saveGuideAudioUpdated({
			guideId: guideId,
			updateId: autoUpdate,
		});
		if (id.ok) {
			return id.body as any;
		} else {
			return null;
		}
	};

	finishCreateGuide = async (request: FinishCreateGuideRequest) => {
		const id =
			await this.mutationAPI.guides.guideMutations.finishCreateGuide(request);
		if (id.ok) {
			return id.body;
		} else {
			return null;
		}
	};

	autoUpdateGuide = async (request: AutoUpdateGuideRequest) => {
		const id =
			await this.mutationAPI.guides.guideMutations.autoUpdateGuideVideo(
				request,
			);
		if (id.ok) {
			return id.body;
		} else {
			return null;
		}
	};

	createCollection = async (collection: CollectionInput) => {
		try {
			logger.debug("collectioninput: ", collection);
			const resp =
				await this.mutationAPI.collections.collectionMutations.createCollection(
					collection,
				);
			if (resp?.ok) {
				logger.debug("created collection: ", resp.body);
				return resp.body;
			} else {
				logger.error("could not create collection: ", resp);
				return false;
			}
		} catch (e) {
			logger.error("could not create collection: ", e);
			return false;
		}
	};

	exportArticle = async (
		guide: Guide,
		html: string,
		markdown: string,
		onUnsubscribed?: (admins?: UserInOrg[]) => void,
	) => {
		// const {id, guidePreview, guideData} = guide
		// const richTextData : RichTextData = cleanJSON(JSON.parse(JSON.stringify(guideData.plainDoc.data)))
		// const sanitisedGuide : Guide = JSON.parse(JSON.stringify(guide))
		// sanitisedGuide.guideData.plainDoc.data = richTextData
		// if (sanitisedGuide.guideData.video.generated){
		//     sanitisedGuide.guideData.video.generated.transcript = cleanJSON(JSON.parse(JSON.stringify(sanitisedGuide.guideData.video.generated.transcript)))
		// }

		// const updateData = await this.mutationAPI.guides.guideMutations.exportGuide({guide: sanitisedGuide})

		const res = await this.mutationAPI.guides.guideExports.exportArticle({
			guideId: guide.id,
		});

		if (res.ok) {
			const imagePresignedURLs = res.body.imagePresignedUrls;
			const dataUris = await getDataUris(html);
			const promiseArray = dataUris.map((src, index) =>
				uploadToPresigned(dataURItoBlob(src), imagePresignedURLs[index]),
			);

			const success = await Promise.all(promiseArray);
			return {
				...getHTMLandTextWithImages(html, markdown, res.body.hostedImageUrls),
				// videoOutput: updateData.body.hostedVideoUrl
			};
		} else {
			if (res.error.error == "UnsubscribedOrgError") {
				onUnsubscribed?.(res.error.content.admins);
			}
			return false;
		}
	};

	getEventData = async () => {
		return await this.mutationAPI.analytics.getEventData();
	};

	getIdentity = async () => {
		return await this.mutationAPI.analytics.getIdentity();
	};

	duplicateGuide = async (guideId: GigaUserApi.Id) => {
		return await this.mutationAPI.guides.guideMutations.duplicateGuide({
			guideId: guideId,
		});
	};

	autoTranslateGuide = async (guideId: GigaUserApi.Id, language: Language) => {
		return await this.mutationAPI.guides.aiEnhance.translateGuideAndDuplicate({
			guideId: guideId,
			destinationLanguage: language,
			sourceLanguage: Id("en"),
		});
	};

	generateVoiceover = async (guideId: GigaUserApi.Id) => {
		const a = await this.mutationAPI.guides.guideMutations.generateVoiceover({
			guideId: guideId,
		});
		return a;
	};

	publishGuide = async (
		guide: Guide,
		callbacks?: {
			onSuccess?: () => void;
		},
	) => {
		const a = await this.mutationAPI.guides.guideMutations.publishGuide({
			guideId: guide.id,
		});
		if (a.ok) {
			callbacks?.onSuccess?.();
		}
		return a;
	};

	restoreVersion = async (guideId: GigaUserApi.Id, version: number) => {
		const a = await this.mutationAPI.guides.guideMutations.restoreVersion({
			id: guideId,
			version: version,
		});
		if (a.ok) {
			return a.body;
		} else {
			return false;
		}
	};

	exportVideo = async (guide: Guide, captions: boolean = false) => {
		/**
		 * callback :
		 *      @param status true if succeeded. false if failed.
		 *      @param url presigned_url of the exported file.
		 */

		const a = this.mutationAPI.guides.guideExports.exportVideo({
			guideId: guide.id,
			captions,
		});
		return a;
	};

	exportGif = async (
		guide: Guide,
		gifSettings?: GifSettings,
		callback?: (status: boolean, url?: string) => void,
	) => {
		/**
		 * callback :
		 *      @param status true if succeeded. false if failed.
		 *      @param url presigned_url of the exported file.
		 */

		this.mutationAPI.guides.guideExports
			.exportGif({
				guideId: guide.id,
				gifSettings: gifSettings || {},
			})
			.then((res) => {
				if (res.ok) {
					callback?.(true, res.body);
				} else {
					callback?.(false);
				}
			});
	};
	convertToGif = async (
		guide: Guide,
		gifSettings: GifSettings,
		callback: (status: boolean, resp: ConvertToGifResponse | undefined) => void,
	) => {
		/**
		 * callback :
		 *      @param status true if succeeded. false if failed.
		 *      @param url presigned_url of the exported file.
		 */

		const res = await this.mutationAPI.guides.guideExports.convertToGif({
			guideId: guide.id,
			gifSettings: gifSettings,
		});

		if (res?.ok) {
			callback(true, res.body);
		} else {
			callback(false, undefined);
		}
	};

	getMyUsers = async () => {
		if (this.allUsers) {
			return this.allUsers;
		}
		const res = await this.mutationAPI.users.getUsers();
		if (res.ok) {
			this.allUsers = res.body;
			return res.body;
		} else {
			return [];
		}
	};

	getAllLanguages = async () => {
		if (this.languages) {
			return this.languages;
		}
		const res =
			await this.mutationAPI.organizations.organization.getAllLanguages();
		if (res.ok) {
			this.languages = res.body.languages;
			return res.body.languages;
		} else return [];
	};

	getAllLanguagesChrext = async () => {
		if (this.languages) {
			return this.languages;
		}
		const res =
			await this.mutationAPI.organizations.organization.getAllLanguagesChrext();
		if (res.ok) {
			this.languages = res.body.languages;
			return res.body.languages;
		} else return [];
	};

	getLanguage = async (languageId: string) => {
		const allLanguages = await this.getAllLanguages();
		const language = allLanguages.find((l) => l.languageId == languageId);
		return language;
	};

	getVoices = async () => {
		if (this.voices) {
			return this.voices;
		}

		const res = await this.mutationAPI.organizations.organization.getVoices();

		if (res.ok) {
			this.voices = res.body.voices;
			return res.body.voices;
		} else return [];
	};

	getOrgUsers = async () => {
		const res = await this.mutationAPI.admin.getUsers();
		if (res.ok) {
			return res.body;
		} else {
			return [];
		}
	};

	createUser = async (user: User) => {
		const res = await this.mutationAPI.admin.createUser(user);
		return res;
	};

	deleteUser = async (email: string) => {
		const res = await this.mutationAPI.admin.deleteUser(email);
		if (res.ok) {
			return res.body;
		} else {
			return false;
		}
	};

	getUserForGuide = async (guideId: GigaUserApi.Id) => {
		return await this.userAPI.users.getUserForGuide(guideId);
	};

	getGuidePlatformStatus = async (guideId: GigaUserApi.Id) => {
		return await this.mutationAPI.guides.guidePlatformStatus.getStatus({
			guideId: guideId,
		});
	};

	markErrorViewed = async (guideId: GigaUserApi.Id) =>
		this.mutationAPI.guides.guidePlatformStatus.markErrorViewed({
			guideId: guideId,
		});

	getHostingDetails = async () => this.mutationAPI.admin.getHostingDetails();
	setHostingDetails = async (x: HostingDetails) =>
		this.mutationAPI.admin.setHostingDetails(x);

	// #region -- TEMPLATES --

	getPlatformDetails = async () =>
		this.mutationAPI.organizations.organization.getPlatformDetails();

	uploadBackgroundAudio = async (file: any, description: string) => {
		const track = await this.mutationAPI.audio.initiateBackgroundAudioUpload();
		if (track.ok) {
			const resp = await uploadToPresigned(file, track.body.url);
			if (resp) {
				const trackId =
					await this.mutationAPI.audio.finishCreateBackgroundAudioUpload({
						src: track.body.src,
						description: description,
					});
				if (trackId.ok) {
					return { src: track.body.src, description: description };
				}
			}
		}
		return null;
	};

	uploadLogo = async (file: any) => {
		const logo = await this.mutationAPI.templates.initiateLogoUpload();
		if (logo.ok) {
			const { id, src, url } = logo.body;
			const resp = await uploadToPresigned(file, logo.body.url);
			if (resp) {
				const finishResp = await this.mutationAPI.templates.finishLogoUpload({
					id: id,
					src: logo.body.src,
				});
				if (finishResp.ok) {
					return { id: id, src: src };
				}
			}
		}
	};

	uploadAsset = async () => {
		const post = await this.mutationAPI.templates.initiateAssetUpload();
		return post;
	};

	initiateUploadSource = async (guideId: GigaUserApi.Id, mime: string) => {
		const post =
			await this.mutationAPI.guides.guideMutations.initiateUploadSource({
				guideId,
				fileType: mime,
			});

		return post;
	};

	finishUploadSource = async (req: FinishUploadSourceRequest) => {
		return await this.mutationAPI.guides.guideMutations.finishUploadSource(req);
	};

	createCover = async (type: CoverType, cover: Cover) => {
		const resp = await this.mutationAPI.templates.createCover({
			cover: cover,
			type: type,
		});

		if (resp && resp.ok) return resp.body;
		return false;
	};

	createBackground = async (background: Background) => {
		const resp = await this.mutationAPI.templates.createBackground({
			background,
		});

		if (resp && resp.ok) return resp.body;
		return false;
	};

	deleteCover = async (type: CoverType, id: GigaUserApi.Id) => {
		const resp = await this.mutationAPI.templates.deleteCover({
			type,
			id,
		});

		if (resp && resp.ok) return true;
		return false;
	};

	deleteBackground = async (id: GigaUserApi.Id) => {
		const resp = await this.mutationAPI.templates.deleteBackground({
			id,
		});

		if (resp && resp.ok) return true;
		return false;
	};

	updateCover = async (type: CoverType, coverOption: CoverOption) => {
		const resp = await this.mutationAPI.templates.updateCover({
			type,
			coverOption,
		});

		if (resp && resp.ok) return resp.body;
		return false;
	};

	setDefaultCover = async (type: CoverType, id: GigaUserApi.Id) => {
		const resp = await this.mutationAPI.templates.setDefaultCover({
			type,
			id,
		});

		if (resp && resp.ok) return resp.body;
		return false;
	};

	setDefaultBackground = async (id: GigaUserApi.Id) => {
		const resp = await this.mutationAPI.templates.setDefaultBackground({
			id,
		});
		if (resp && resp.ok) return resp.body;
		return false;
	};

	// #endregion --- TEMPLATES ---

	getShareableLink = async (guidePreview: GuidePreview) => {
		var isPrivate = false;
		var hasPrivateParent = false;
		var fullPath: [Collection, ...Collection[]] = [rootCollection];

		if (!guidePreview.header.published) {
			isPrivate = true;
		}

		if (!guidePreview.parentId || guidePreview.parentId == rootCollection.id) {
			isPrivate = !guidePreview.header.published;
			hasPrivateParent = false;
		} else {
			const res = await saver.getPath(guidePreview.parentId);
			if (res) {
				fullPath = res;
				fullPath.map((collection) => {
					if (collection.private) {
						isPrivate = true;
						hasPrivateParent = true;
					}
				});
			}
		}

		return {
			sharingLink: `https://${this.hostname}/${isPrivate ? "share" : "guide"}/${guidePreview.id}`,
			path: fullPath,
			hasPrivateParent,
			isPrivate,
		};
	};

	getUsageVideo = async (req: GetUsageRequest) => {
		return await this.mutationAPI.admin.getUsageVideo(req);
	};

	getUsageArticle = async (req: GetUsageRequest) => {
		return await this.mutationAPI.admin.getUsageArticle(req);
	};

	validateGuideUrlPath = async (req: ValidateGuideUrlRequest) => {
		return await this.mutationAPI.guides.guideMutations.validateGuideUrlPath(
			req,
		);
	};

	validateCollectionUrlPath = async (req: ValidateCollectionUrlPathRequest) => {
		return await this.mutationAPI.collections.collectionQueries.validateCollectionUrlPath(
			req,
		);
	};

	fetchPronDict = async () => {
		return await this.mutationAPI.settings.fetchPronDict();
	};

	addPronDictEntry = async (req: PronDictEntryRequest) => {
		return await this.mutationAPI.settings.addPronDictEntry(req);
	};

	deletePronDictEntry = async (req: PronDictEntryRequest) => {
		return await this.mutationAPI.settings.deletePronDictEntry(req);
	};

	returnPreviewAudioBytes = async (req: PronDictEntryRequest) => {
		return await this.mutationAPI.settings.returnPreviewAudioBytes(req);
	};

	listOfEligibleOrganizations = async () => {
		return await this.userAPI.organizations.organizationMutations.listEligibleOrganizations();
	};

	createOrganization = async (req: CreateOrganizationRequest) => {
		return await this.userAPI.organizations.organizationMutations.createOrganization(
			req,
		);
	};

	joinOrganization = async (req: JoinOrganizationRequest) => {
		return await this.userAPI.organizations.organizationMutations.joinOrganization(
			req,
		);
	};

	addUserToOrganization = async (req: AddUserToOrganizationRequest) => {
		return await this.mutationAPI.organizations.organizationMutations.addUserToOrganization(
			req,
		);
	};

	removeUserFromOrganization = async (req: RemoveUserFromOrgRequest) => {
		return await this.mutationAPI.organizations.organizationMutations.removeUserFromOrganization(
			req,
		);
	};
}

export const saver = new GuideSaver();
