import React, { useState } from "react";
import "./CTemplates.css";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import CoverCard from "../CoverCard/CoverCard";
import penIcon from "../../../../assets/svgs/screenshot/penIcon";
import { Tooltip } from "@chakra-ui/react";

import Icon from "../../../Icon/Icon";
import { CoverOption, CoverType, Id } from "@giga-user-fern/api/types/api";
import {
	selectBackgroundEdits,
	updateBackgroundEdits,
	updateIntroEdits,
	updateOutroEdits,
} from "../../../../redux/slices/guideSlice";
import {
	setActiveIntroTemplate,
	setActiveOutroTemplate,
} from "../../../../redux/slices/platformUiSlice";
import playIcon from "../../../../assets/svgs/playIcon";
import { useToast } from "@chakra-ui/react";
import { selectSaver } from "../../../../redux/slices/backendSlice";
import downArrowheadIcon from "../../../../assets/svgs/downArrowheadIcon";

type CTemplatesProps = {
	coverType: CoverType;
	onClickNew: () => void;
	onClickEdit: (coverOption: CoverOption) => void;
	onClickEditPremade: (coverOption: CoverOption) => void;
};

const CTemplates: React.FC<CTemplatesProps> = (props) => {
	const dispatch = useAppDispatch();

	const introTemplates = useAppSelector(
		(state) => state.platformDetails.value?.intro,
	);
	const outroTemplates = useAppSelector(
		(state) => state.platformDetails.value?.outro,
	);

	const premadeIntroTemplates = useAppSelector(
		(state) => state.platformDetails.value?.premadeIntros,
	);
	const premadeOutroTemplates = useAppSelector(
		(state) => state.platformDetails.value?.premadeOutros,
	);

	const [showPremade, setShowPremade] = useState(true);

	// const [activeTemplate, setActiveTemplate] = useState<GigaUserApi.Id | undefined>(props.coverType=="intro"?introTemplates?.default: outroTemplates?.default)
	const activeTemplate = useAppSelector((state) =>
		props.coverType === "intro"
			? state.platformUi.value.activeIntroTemplate
			: state.platformUi.value.activeOutroTemplate,
	);

	const introEdits = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.videoEdits?.intro,
	);
	const outroEdits = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.videoEdits?.outro,
	);
	const backgroundEdits = useAppSelector(selectBackgroundEdits);
	const toast = useToast();
	const saver = useAppSelector(selectSaver);

	const onClickTemplate = (template: CoverOption) => {
		if (props.coverType == "intro") {
			dispatch(setActiveIntroTemplate(template.id));
			dispatch(
				updateIntroEdits({
					...introEdits,
					...template.cover,
					text1: introEdits?.text1,
				}),
			);
		} else {
			dispatch(setActiveOutroTemplate(template.id));
			dispatch(
				updateOutroEdits({
					...outroEdits,
					...template.cover,
					text1: outroEdits?.text1,
					text2: outroEdits?.text2,
				}),
			);
		}

		if (template.cover.fixDimensions) {
			dispatch(
				updateBackgroundEdits({
					...backgroundEdits,
					visible: true,
				}),
			);
		}
	};

	const onSetDefaultTemplate = () => {
		if (activeTemplate) {
			const promise = saver.setDefaultCover(props.coverType, activeTemplate);

			toast.promise(promise, {
				success: {
					title: `Set default ${props.coverType.toLowerCase()} `,
					description: "Looks great!",
				},
				error: {
					title: `Error setting default ${props.coverType.toLowerCase()}!`,
					description: "Try again later",
				},
				loading: {
					title: "Working ...",
					description: "Setting default template",
				},
			});
		} else {
			toast({
				title: `No template selected!`,
				description: "Choose a template to set it as default.",
				status: "warning",
				duration: 6000,
				isClosable: true,
				position: "top",
			});
		}
	};

	return (
		<div className="CTemplates-Container">
			<div className="Set-default-container">
				<Tooltip
					placement="right"
					borderRadius={6}
					bg="#303030"
					className="editor-button-tooltip"
					color={"white"}
					label={"All new videos will use this template"}
				>
					<div className="set-default-message" onClick={onSetDefaultTemplate}>
						Set selected as default
					</div>
				</Tooltip>
			</div>

			<p className="my-templates-title">My Templates</p>

			<div className="CTemplate">
				<div className="NewTemplate TemplatePreview" onClick={props.onClickNew}>
					+ New
				</div>

				<TemplateList
					templates={
						props.coverType === "intro"
							? introTemplates?.options
							: outroTemplates?.options
					}
					activeTemplate={activeTemplate}
					coverType={props.coverType}
					onClickTemplate={onClickTemplate}
					onClickEditTemplate={props.onClickEdit}
				/>
			</div>

			{/* <div className="premade-templates-title">
				<p>Premade templates</p>
				<Icon
					style={{
						rotate: !showPremade ? "90deg" : "-90deg",
					}}
					className="downArrow"
					onClick={() => setShowPremade(!showPremade)}
				>
					{downArrowheadIcon("#BFC7D4")}
				</Icon>
			</div>

			{
				<div
					style={{ display: !showPremade ? "none" : undefined }}
					className="CTemplate"
				>
					{
						<TemplateList
							templates={
								props.coverType === "intro"
									? premadeIntroTemplates
									: premadeOutroTemplates
							}
							activeTemplate={activeTemplate}
							coverType={props.coverType}
							onClickTemplate={onClickTemplate}
							onClickEditTemplate={props.onClickEditPremade}
						/>
					}
				</div>
			} */}
		</div>
	);
};
export default CTemplates;

type TemplateListProps = {
	activeTemplate: Id | null;
	coverType: CoverType;
	templates: CoverOption[] | undefined;
	onClickTemplate: (template: CoverOption) => void;
	onClickEditTemplate: (template: CoverOption) => void;
};

const TemplateList = (props: TemplateListProps) => {

	return (
		<>
			{props.templates?.map((template) => (
				<div
					key={template.id}
					data-template-id={template.id}
					className={`TemplatePreview CoverCardContainer ${props.activeTemplate === template.id ? "active" : "inactive"}`}
					onClick={() => {
						props.onClickTemplate(template);
					}}
				>
					{template.cover.type !== "video" ? (
						<CoverCard
							coverType={props.coverType}
							key={template.id}
							cover={template.cover}
						/>
					) : (
						<>
							<video src={template.cover.backgroundSrc}></video>
							<Icon className="playIcon-Template">{playIcon("#d43f8c")}</Icon>
						</>
					)}

					<div
						className="hoverOptions"
						onClick={(e) => {
							props.onClickEditTemplate(template);
							e.stopPropagation();
						}}
					>
						<Icon className="hoverIcon">{penIcon("white")}</Icon>
					</div>
				</div>
			))}
		</>
	);
};
