const eyeClosedIcon = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill={color}
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
		>
			<path d="M2.293,21.707a1,1,0,0,0,1.414,0l3.2-3.2A9.581,9.581,0,0,0,12,20c4.325,0,8.227-3,9.938-7.654a.993.993,0,0,0,0-.692A12.6,12.6,0,0,0,18.7,6.719l3.012-3.012a1,1,0,1,0-1.414-1.414l-3.2,3.2A9.581,9.581,0,0,0,12,4C7.675,4,3.773,7,2.062,11.654a.993.993,0,0,0,0,.692,12.6,12.6,0,0,0,3.243,4.935L2.293,20.293A1,1,0,0,0,2.293,21.707ZM17.266,8.148A10.454,10.454,0,0,1,19.929,12c-1.478,3.657-4.556,6-7.929,6a7.52,7.52,0,0,1-3.632-.954l1.613-1.613A3.947,3.947,0,0,0,12,16a4,4,0,0,0,4-4,3.947,3.947,0,0,0-.567-2.019Zm-7.191,4.363A1.96,1.96,0,0,1,10,12a2,2,0,0,1,2-2,1.96,1.96,0,0,1,.511.075Zm3.85-1.022A1.96,1.96,0,0,1,14,12a2,2,0,0,1-2,2,1.96,1.96,0,0,1-.511-.075ZM4.071,12C5.549,8.343,8.627,6,12,6a7.52,7.52,0,0,1,3.632.954L14.019,8.567A3.947,3.947,0,0,0,12,8a4,4,0,0,0-4,4,3.947,3.947,0,0,0,.567,2.019L6.734,15.852A10.454,10.454,0,0,1,4.071,12Z" />
		</svg>
	);
};
export default eyeClosedIcon;
