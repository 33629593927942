import { createRoutesFromElements, Route } from "react-router-dom";

import { useAppDispatch } from "@gigauser/common/src/redux";
import React from "react";
import PropelAuthProvider from "@gigauser/common/src/auth/PropelAuthProvider";
import { useAuth0 } from "@auth0/auth0-react";

import useGetPath from "@gigauser/common/src/redux/hooks/FileHooks/useGetPath";
import { Provider } from "react-redux";
import store from "@gigauser/common/src/redux/store";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { saver } from "@gigauser/common/src/network/saver";
import EditorPlatform from "../editor/main/EditorPlatform";
import AppInitializer from "../AppInitializer";

const HardRedirect: React.FC = () => {
	window.location.replace("/home");
	return <></>;
};

const routes = createRoutesFromElements(
	<>
		<Route
			path="/home"
			element={
				<PropelAuthProvider>
					<Provider store={store}>
						<AppInitializer page="home" />
					</Provider>
				</PropelAuthProvider>
			}
		/>

		<Route
			path="/welcome/personal-details"
			element={
				<PropelAuthProvider>
					<Provider store={store}>
						<AppInitializer page="welcome/personal-details" />
					</Provider>
				</PropelAuthProvider>
			}
		/>

		<Route
			path="/welcome/create-team"
			element={
				<PropelAuthProvider>
					<Provider store={store}>
						<AppInitializer page="welcome/create-team" />
					</Provider>
				</PropelAuthProvider>
			}
		/>
		<Route
			path="/welcome/join-team"
			element={
				<PropelAuthProvider>
					<Provider store={store}>
						<AppInitializer page="welcome/join-team" />
					</Provider>
				</PropelAuthProvider>
			}
		/>
		<Route
			path="/folders/:objectID"
			element={
				<PropelAuthProvider>
					<Provider store={store}>
						<AppInitializer page="home" />
					</Provider>
				</PropelAuthProvider>
			}
		/>

		<Route
			path="/guide/:objectID"
			element={
				<PropelAuthProvider>
					<Provider store={store}>
						<AppInitializer page="editor" />
					</Provider>
				</PropelAuthProvider>
			}
		/>

		<Route path="*" element={<HardRedirect />} />
	</>,
);

// const getPathFromRoute = async () => {
//     // Here, we need to fetch the current folder and call the API to get the full path.
// 	// This happens by fetching parentID objects for the folder.
// 	const urlSegments = window.location.pathname.split('/')
// 	if (!urlSegments.includes('folders')) {
// 		throw Error
// 	}
// 	const objectID = urlSegments.at(-1)
// 	let newPath : Collection[]= []

// 	if (objectID==='Folder_library'){
// 		newPath=  [rootCollection]
// 	}

// 	else if(objectID){
// 		// Here, you have to do the API call (wrapped perhaps)

//         logger.debug("getPath incoming!")

//         try{
//             const backendGetPath = useGetPath()
//             await backendGetPath(objectID)
//         }
//         catch(e){
//             logger.error("error with backend GetPath: ", e)
//         }

//         ///TODO: Figure out this code.
//         const sections = false

//         if(sections) {
//             logger.debug("getPath sections: ", sections)
//             newPath = sections
//         }
//         else{
//             logger.debug("no sections: ")
//         }

// 	}
// 	// const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
// 	// await sleep(500)
// 	return newPath as [Collection, ...Collection[]]
// };

export { routes };
export function CatchBoundary() {}
