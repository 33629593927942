import rocketIcon from "@gigauser/common/src/assets/svgs/rocketIcon";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import CuttonGroup from "@gigauser/common/src/ui/buttons/CuttonGroup/CuttonGroup";
import React, { useState } from "react";
import "./ConfirmPublishPopup.css";
import { useAppDispatch } from "@gigauser/common/src/redux";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay";
import unpublishIcon from "@gigauser/common/src/assets/svgs/unpublishIcon";
import { Collection } from "@giga-user-fern/api/types/api/resources/collections";
import Breadcrumbs from "@gigauser/common/src/ui/tabs/BreadcrumbsReadonly/Breadcrumbs";
import { useToast } from "@chakra-ui/react";
import useBackendBusy from "@gigauser/common/src/redux/hooks/FileHooks/useBackendBusy";
import { usePublishGuide } from "@gigauser/common/src/redux/hooks/FileHooks/usePublishGuide";

type ConfirmPublishPopupProps = {
	source: "helpcenter" | "embed" | "viewlink";
	isPrivate: boolean;
	hasPrivateParent?: boolean;
	articleVisible?: boolean;
	videoVisible?: boolean;
	unpublish?: boolean;
	path?: [Collection, ...Collection[]];
	guideUrlPath?: string
};

const ConfirmPublishPopup: React.FC<ConfirmPublishPopupProps> = (props) => {
	var message = "This will also publish the latest changes to your ";
	const dispatch = useAppDispatch();
	const isBackendBusy = useBackendBusy();

	const toast = useToast();

	const [isPublishing, setIsPublishing] = useState<boolean>(false);
	const publishGuide = usePublishGuide();

	if (props.source == "helpcenter") {
		if (props.unpublish)
			message = "This will unpublish the guide from your help center.";
		else message += "view-only link and video embeds.";

		if (props.hasPrivateParent) {
			message =
				"This guide is inside a private folder and cannot be published.";
		}
	} else if (props.isPrivate) {
		if (props.source == "embed") {
			message += "view-only link.";
		} else {
			message += "video embeds.";
		}
	} else {
		if (props.source == "embed") {
			message += "help center and view-only link.";
		} else {
			message += "help center and video embeds.";
		}
	}

	const onSubmitPublish = async () => {
		if (isBackendBusy) {
			toast({
				title: `${isBackendBusy} is in progress!`,
				description: "Please wait for it to finish and then try again.",
				status: "warning",
				duration: 4000,
				isClosable: true,
				position: "top",
			});
			return false;
		}

		setIsPublishing(true);
		publishGuide(props);
	};

	return (
		<div className="ConfirmPublishPopup">
			<div className="ConfirmParagraph">{message}</div>

			{props.path && props.hasPrivateParent && (
				<>
					<div className="BreadcrumbsConfirmPublishPopup">
						<Breadcrumbs segments={props.path} />
					</div>
					<div className="ConfirmParagraph">
						Make all parent folders public to publish to your help center.
					</div>
				</>
			)}

			<CuttonGroup>
				<Cutton
					leftIcon={
						props.unpublish ? unpublishIcon("white") : rocketIcon("white")
					}
					onClick={onSubmitPublish}
					isLoading={isPublishing}
					disabled={isPublishing || props.hasPrivateParent}
					loadingText="Working ..."
				>
					{props.unpublish ? "Unpublish" : "Publish"}
				</Cutton>

				<Cutton
					rank="secondary"
					onClick={() => {
						dispatch(closeOverlay());
					}}
				>
					Cancel
				</Cutton>
			</CuttonGroup>
		</div>
	);
};

export default ConfirmPublishPopup;
