import React, { useContext } from "react";
import folderIcon from "@gigauser/common/src/assets/legacy_assets/icons/folder_color.svg";
import pdfIcon from "@gigauser/common/src/assets/legacy_assets/icons/pdfIcon.svg";
import "./SearchResultElement.css";
import { SearchResult } from "../../SearchBox";
import useCollectionNavigate from "@gigauser/common/src/redux/hooks/FileHooks/useCollectionNavigate";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import { useAppDispatch } from "@gigauser/common/src/redux";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { closeFullScreenOverlay } from "@gigauser/common/src/layouts/FullScreenOverlay";
import logger from "@gigauser/common/src/utils/logger";

type SearchResultElementProps = {
	searchResult: SearchResult;
};

const SearchResultElement: React.FC<SearchResultElementProps> = (props) => {
	const file = props.searchResult.searchable;
	const fileType = file.item.type;
	const dispatch = useAppDispatch();

	const env = useContext(EnvironmentContext);
	const navigate = useCollectionNavigate(env);

	let fileIcon: string = folderIcon;
	if (fileType == "Collection") {
		fileIcon = folderIcon;
	} else if (fileType == "Guide") {
		fileIcon = pdfIcon;
	}

	logger.debug("searchResult: ", props.searchResult);

	const onClick = () => {
		if (fileType === "Collection") {
			logger.debug("navigating to: ", file);
			navigate(file.item.entry);
		} else if (fileType === "Guide") {
			// openReading(props.searchResult.objectUser.objectID)
			window.open("/guide/" + file.item.entry.id + "", "_blank");
		}
		dispatch(closeOverlay());
		dispatch(closeFullScreenOverlay());
	};

	const { device } = useWindowSize();

	return (
		<div
			className={`searchResultElement-container ${device}`}
			onClick={onClick}
		>
			<div className="file-info">
				<div className="fileIcon">
					<img style={{ height: "36px" }} src={fileIcon} alt="" />
				</div>

				<div className={`fileHeading ${device}`}>
					<div className="fileName">{props.searchResult.highlightedName}</div>

					{/* {fileType == "Folder" ? <div className='fileDescription'>{(file as Folder).description}</div> : null} */}
				</div>

				<div className={`fileLocation ${device}`}>
					{file.description || "None"}
				</div>
			</div>
		</div>
	);
};
export default SearchResultElement;
