import React from "react";
import "./SubscribePopup.css";
import { User, UserInOrg } from "@giga-user-fern/api/types/api";

type SubscribePopupProps = {
	admins?: UserInOrg[];
	trial?: boolean;
};

const SubscribePopup: React.FC<SubscribePopupProps> = (props) => {
	return (
		<div className="SubscribePopup PreUpdate">
			{props.trial ? <h1>Your trial has ended!</h1> : <h1>Your subscription has expired!</h1>}

			<p>
				To keep using Clueso without interruption, contact us on your Clueso
				support channel or mail us at
				<a href="mailto:hello@clueso.io" className="email-pin dark">
					hello@clueso.io
				</a>
				.
			</p>

			{props.admins?.length ? (
				props.admins.length > 1 ? (
					<>
						<p>Alternatively, contact one of your team admins:</p>
						{props.admins.map((admin, index) => {
							return (
								<li key={index}>
									<a className="email-pin dark" href={`mailto:${admin.email}`}>
										{admin.email}
									</a>
								</li>
							);
						})}
					</>
				) : (
					<p>
						Alternatively, contact your team admin:
						<a
							className="email-pin dark"
							href={`mailto:${props.admins[0].email}`}
						>
							{props.admins[0].email}
						</a>
					</p>
				)
			) : null}
		</div>
	);
};

export default SubscribePopup;
