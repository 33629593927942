const versionsIcon = (color = "#000000") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M12 7.07694V12L15.0769 15.0769"
				stroke={color}
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2.56966 5.68691L1.6466 5.69154C1.64913 6.19771 2.05884 6.60742 2.56502 6.60997L2.56966 5.68691ZM5.69801 6.62571C6.2078 6.62827 6.62315 6.21708 6.62571 5.70729C6.62827 5.19749 6.21708 4.78214 5.70729 4.77958L5.69801 6.62571ZM3.47698 2.54928C3.47441 2.03948 3.05908 1.62828 2.54928 1.63084C2.03948 1.63342 1.62828 2.04876 1.63086 2.55855L3.47698 2.54928ZM1.84615 12C1.84615 11.4902 1.43287 11.0769 0.923077 11.0769C0.41328 11.0769 0 11.4902 0 12H1.84615ZM18.0006 22.3941C18.442 22.1388 18.5927 21.5742 18.3375 21.1328C18.0822 20.6916 17.5175 20.5407 17.0763 20.7961L18.0006 22.3941ZM20.7961 17.0763C20.5407 17.5175 20.6916 18.0822 21.1328 18.3375C21.5742 18.5927 22.1388 18.442 22.3941 18.0006L20.7961 17.0763ZM3.55733 3.47232C3.19505 3.831 3.19214 4.41546 3.55083 4.77774C3.90951 5.14001 4.49396 5.14292 4.85623 4.78423L3.55733 3.47232ZM20.4459 3.55429C15.7441 -1.1475 8.14624 -1.1951 3.47557 3.47557L4.781 4.781C8.71835 0.843631 15.1473 0.866511 19.1406 4.85972L20.4459 3.55429ZM3.47557 3.47557L1.91695 5.03419L3.22238 6.33962L4.781 4.781L3.47557 3.47557ZM2.56502 6.60997L5.69801 6.62571L5.70729 4.77958L2.5743 4.76384L2.56502 6.60997ZM3.49273 5.68226L3.47698 2.54928L1.63086 2.55855L1.6466 5.69154L3.49273 5.68226ZM12 1.84615C17.6078 1.84615 22.1538 6.39218 22.1538 12H24C24 5.37258 18.6274 0 12 0V1.84615ZM12 22.1538C6.39218 22.1538 1.84615 17.6078 1.84615 12H0C0 18.6274 5.37258 24 12 24V22.1538ZM17.0763 20.7961C15.5838 21.6594 13.851 22.1538 12 22.1538V24C14.1842 24 16.2347 23.4156 18.0006 22.3941L17.0763 20.7961ZM22.1538 12C22.1538 13.851 21.6594 15.5838 20.7961 17.0763L22.3941 18.0006C23.4156 16.2347 24 14.1842 24 12H22.1538ZM4.85623 4.78423C6.69178 2.96688 9.2141 1.84615 12 1.84615V0C8.70821 0 5.72447 1.32666 3.55733 3.47232L4.85623 4.78423Z"
				fill={color}
			/>
		</svg>
	);
};
export default versionsIcon;
