import { useState } from "react"
import "./HelpCenterConfig.css"
import HelpCenterConfigEditor from "./HelpCenterConfig"
import { HostingPage } from "./HostingPage"
import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin"
const TabSelector = ({currentTab, setTab, availableTabs}:{currentTab:string,setTab: React.Dispatch<string>, availableTabs: {label:string,id:string}[]})=>{
  return <div className="helpCenterTabs">{availableTabs.map((tab)=><div onClick={()=>setTab(tab.id)} className={tab.id===currentTab?"helpCenterTabs-activeTab":"helpCenterTabs-inActiveTab"} ><span>{tab.label}</span></div> ) }</div>
}
const HelpCenterSettings = ()=>{
  const [subTab, setSubTab] = useState<string>("customizations")
  return <div className="helpCenterContainer">
  <TabSelector currentTab={subTab} setTab={setSubTab} availableTabs={[{id:"customizations",label:"Appearance"},{id:"viewers",label:"Access"}]} />
  {
    subTab==="customizations" && <HelpCenterConfigEditor/>
  }
  {
    subTab === "viewers" && <HostingPage/>
  }
  </div>
}
export default HelpCenterSettings