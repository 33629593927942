import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { selectGuide } from "@gigauser/common/src/redux/slices/guideSlice";
import {
	GifExtensionMetadata,
	ImageExtensionMetadata,
	SpecialImageCoordinates,
} from "@gigauser/common/src/components/formats/RichText/extensions/SpecialImageExtension";
import { TextEditorType } from "@gigauser/common/src/components/formats/RichText/TextEditor";
import ConvertToGif from "./ConvertToGif";
import gifIcon from "@gigauser/common/src/assets/svgs/gifIcon";
import { setDisableClickoutOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import React from "react";
import { ConvertToGifFunction } from "@gigauser/common/src/components/formats/RichText/components/EditScreenshot/useEditScreenshot";
// import { background } from '@chakra-ui/react';

const useConvertToGif: (type: TextEditorType) => ConvertToGifFunction = (
	type: TextEditorType,
) => {
	const dispatch = useAppDispatch();

	if (type == "helpCenter") {
		return () => {};
	}

	return async (metadata: ImageExtensionMetadata, insertGifInNotebook) => {
		dispatch(
			openOverlay({
				heading: {
					icon: gifIcon("white"),
					title: "Convert to Gif",
					data: "",
				},

				component: (
					<ConvertToGif
						metadata={metadata}
						insertGifInNotebook={insertGifInNotebook}
					/>
				),
			}),
		);
		dispatch(setDisableClickoutOverlay(true));

		return;
	};
};

export default useConvertToGif;
