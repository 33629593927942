import React, { useEffect } from "react";
import { useState } from "react";
import pdfIcon from "@gigauser/common/src/assets/legacy_assets/icons/pdfIcon.svg";
import logger from "@gigauser/common/src/utils/logger";
import ReadIcon from "@gigauser/common/src/assets/svgs/readIcon";
import { Icon } from "@chakra-ui/react";

type MoveReadingProps = {
	onClick: (completedCallback: () => void) => void;
	name: string;
	disabled?: boolean;
	// setDisable: React.Dispatch<React.SetStateAction<boolean>>
};

const MoveReading: React.FC<MoveReadingProps> = ({
	name,
	onClick,
	disabled,
}) => {
	const [loading, setLoading] = useState<boolean>(false);

	return (
		<div
			className={`move-file ${disabled || loading ? "disable" : ""}`}
			onClick={() => {
				logger.debug("LOADING", loading);
				if (!disabled && !loading) {
					setLoading(true);
					onClick(() => {
						logger.debug("SETTING LOADING FALSE");
						setLoading(false);
					});
				}
			}}
		>
			<div className="move-file-name">
				<Icon className="file-icon">{ReadIcon("#afb3b0")}</Icon>
				<div className="file-name">{name}</div>
			</div>
			{/* {isHovering ? <img className='move-right-arrow' src={right_arrow}></img> : null} */}
		</div>
	);
};
export default MoveReading;
