import transcriptIcon from "@gigauser/common/src/assets/svgs/videoeditor/transcriptIcon";
import React from "react";
import "./VideoCustomizer.css";
import BackgroundCustomizer from "./customizations/BackgroundCustomizer/BackgroundCustomizer";
import OutroCustomizer from "./customizations/OutroCustomizer/OutroCustomizer";
import IntroCustomizer from "./customizations/IntroCustomizer/IntroCustomizer";
import ZoomCustomizer from "./customizations/ZoomCustomizer/ZoomCustomizer";
import { CloseButton, Flex } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	setActiveElement,
	setCustomizerPage,
} from "@gigauser/common/src/redux/slices/platformUiSlice";
import ElementsCustomizer from "./customizations/ElementsCustomizer/ElementsCustomizer";
import MusicCustomizer from "./customizations/MusicCustomizer/MusicCustomizer";

type VideoCustomizerProps = {};

const VideoCustomizer: React.FC<VideoCustomizerProps> = () => {
	const pageDict = {
		Voiceover: <IntroCustomizer />,
		Intro: <IntroCustomizer />,
		Outro: <OutroCustomizer />,
		Background: <BackgroundCustomizer />,
		Zooms: <ZoomCustomizer />,
		Elements: <ElementsCustomizer />,
		Music: <MusicCustomizer />,
	};

	const dispatch = useAppDispatch();
	const customizerPage = useAppSelector(
		(state) => state.platformUi.value.customizerPage,
	);

	if (!customizerPage) return null;

	return (
		<div className="VideoCustomizer">
			<div className="VideoEditor-contents">
				{/* <BackgroundCustomizer /> */}
				{pageDict[customizerPage]}
			</div>
		</div>
	);
};
export default VideoCustomizer;
