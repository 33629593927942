import React, { useRef, useState } from "react";
import fuzzysort from "fuzzysort";
import searchIcon from "@gigauser/common/src/assets/svgs/searchIcon";
import Icon from "../../Icon/Icon";
import "./CSearch.css";

type CSearchProps = {
	searchableItems: any[];
	searchKey?: string;
	handleResults: (results: any[]) => void;
	placeholder?: string;
};

const CSearch: React.FC<CSearchProps> = (props) => {
	const debounceSearchTimer = useRef<any>(null);

	const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false);

	const [searchString, setSearchString] = useState<string>("");

	const runSearch = (s: string) => {
		if (s) {
			const fuzzyResults = fuzzysort.go(s, props.searchableItems, {
				key: props.searchKey ?? "name",
			});
			props.handleResults(fuzzyResults.map((res) => res.obj));
			// setListedLanguages(fuzzyResults.map((res) => res.obj));
		} else {
			props.handleResults(props.searchableItems);
			// setListedLanguages(allLanguages);
		}
	};

	const onChangeSearch = (e: any) => {

		if (debounceSearchTimer.current !== undefined) {
			clearTimeout(debounceSearchTimer.current);
		}

		setSearchString(e.target.value);

		debounceSearchTimer.current = setTimeout(() => {
			runSearch(e.target.value);
		}, 300);
	};

	return (
		<div className={`CSearchBox  ${isSearchFocused ? "focused" : "blurred"}`}>
			<Icon className="searchIcon">{searchIcon()}</Icon>
			<input
				onChange={onChangeSearch}
				placeholder={props.placeholder ?? "Search ..."}
				onFocus={() => setIsSearchFocused(true)}
				onBlur={() => setIsSearchFocused(false)}
			></input>
		</div>
	);
};
export default CSearch;
