import React, { useState, useContext } from "react";
import CToggle from "@gigauser/common/src/ui/video/components/CToggle/CToggle";
import CSection from "../../layout/CSection/CSection";
import backgroundIcon from "@gigauser/common/src/assets/svgs/videoeditor/backgroundIcon";
import textIcon from "@gigauser/common/src/assets/svgs/videoeditor/textIcon";
import "./OutroCustomizer.css";
import { Flex, Input } from "@chakra-ui/react";
import CSlider from "@gigauser/common/src/ui/video/components/CSlider/CSlider";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectBackgroundEdits,
	selectIntroEdits,
	selectOutroEdits,
	updateBackgroundEdits,
	updateOutroEdits,
} from "@gigauser/common/src/redux/slices/guideSlice";
import clockIcon from "@gigauser/common/src/assets/svgs/clockIcon";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import templateIcon from "@gigauser/common/src/assets/svgs/videoeditor/templateIcon";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import TemplateCreator from "../../../TemplateCreator/TemplateCreator";
import CTemplates from "@gigauser/common/src/ui/video/components/CTemplates/CTemplates";
import { Cover, CoverOption } from "@giga-user-fern/api/types/api";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";
import { setActiveOutroTemplate } from "@gigauser/common/src/redux/slices/platformUiSlice";
import { GigaUserApi } from "@giga-user-fern/api";
import { v4 } from "uuid";

type OutroCustomizerProps = {};

const OutroCustomizer: React.FC<OutroCustomizerProps> = () => {
	const dispatch = useAppDispatch();
	const outroState = useAppSelector(selectOutroEdits);
	const introState = useAppSelector(selectIntroEdits);
	const backgroundState = useAppSelector(selectBackgroundEdits);
	const cp = useContext(CanvasPlayerContext);

	const { outro, intro } = cp.videoEdits;

	const onHideOutro = (value: boolean) => {
		dispatch(
			updateOutroEdits({
				...outroState,
				visible: !value,
			}),
		);

		const standardizeDims =
			(introState.visible && introState.fixDimensions) ||
			(!value && outroState.fixDimensions);
		if (!value && (standardizeDims === undefined || standardizeDims)) {
			dispatch(
				updateBackgroundEdits({
					...backgroundState,
					visible: true,
				}),
			);
		}

		dispatch(setActiveOutroTemplate(GigaUserApi.Id("")));
	};

	const onUpdateOutro: (object: {
		text1?: string;
		text2?: string;
		logoSize?: number;
		showLogo?: boolean;
		duration?: number;
	}) => void = (updates) => {
		const { text1, text2, logoSize, showLogo, duration } = updates;

		dispatch(
			updateOutroEdits({
				...outroState,
				text1: text1 !== undefined ? text1 : outroState.text1,
				text2: text2 !== undefined ? text2 : outroState.text2,
				logoSize: logoSize || outroState.logoSize,
				showLogo: showLogo !== undefined ? showLogo : outroState.showLogo,
				duration: duration || outroState.duration,
			}),
		);
	};

	const onChangeInput = (e: any) => {
		if (e.target.id === "line1") {
			onUpdateOutro({ text1: e.target.value });
		} else {
			onUpdateOutro({ text2: e.target.value });
		}
	};

	const hideOutro = !outroState.visible;
	const hideLogo = !outroState.showLogo;

	const setLogoSize = (value: number) => onUpdateOutro({ logoSize: value });
	const setHideLogo = (value: boolean) => onUpdateOutro({ showLogo: !value });
	const setOutroDuration = (value: number) =>
		onUpdateOutro({ duration: value });

	const openOutroCreator = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: templateIcon("white"),
					title: "Create Outro Template",
					data: "",
				},
				component: <TemplateCreator coverType="outro" operation="create" />,
			}),
		);
	};

	const onUpdateTemplate = (coverOption: CoverOption) => {
		dispatch(
			openOverlay({
				heading: {
					icon: templateIcon("white"),
					title: "Update Outro Template",
					data: "",
				},
				component: (
					<TemplateCreator
						coverType="outro"
						operation="edit"
						coverOption={coverOption}
					/>
				),
			}),
		);
	};

	const onUpdatePremadeTemplate = (coverOption: CoverOption) => {
		const coverOptionCopy = { ...coverOption };
		coverOptionCopy.id = GigaUserApi.Id(v4());

		const onPremadeCreate = (createdCoverOption: CoverOption) => {
			dispatch(setActiveOutroTemplate(createdCoverOption.id));
			dispatch(
				updateOutroEdits({
					...outroState,
					...createdCoverOption.cover,
				}),
			);
		};

		dispatch(
			openOverlay({
				heading: {
					icon: templateIcon("white"),
					title: `Update outro Template`,
					data: "",
				},
				component: (
					<TemplateCreator
						coverType="outro"
						operation="create"
						coverOption={coverOptionCopy}
						onCreate={onPremadeCreate}
					/>
				),
			}),
		);
	};

	const outroForceResize =
		outro?.fixDimensions &&
		intro?.fixDimensions &&
		(outro.naturalHeight !== intro.naturalHeight ||
			outro.naturalWidth !== intro.naturalWidth);

	return (
		<div className="OutroCustomizer">
			{outroForceResize && (
				<CSection>
					<Flex marginBottom={"1.5rem"} className="outroforceresize">
						<IBox size="s">
							Outro forced to resize since dimensions don't match intro.
						</IBox>
					</Flex>
				</CSection>
			)}

			<CSection>
				<CToggle
					label="Hide Outro"
					color="pink"
					value={hideOutro}
					setterFunction={onHideOutro}
				/>
			</CSection>

			<>
				<CSection disabled={hideOutro} label="Template" icon={templateIcon}>
					<CTemplates
						coverType="outro"
						onClickNew={openOutroCreator}
						onClickEdit={onUpdateTemplate}
						onClickEditPremade={onUpdatePremadeTemplate}
					/>
				</CSection>

				{!outroState.hideText && outroState.type !== "video" && (
					<>
						<CSection disabled={hideOutro} label="Text" icon={textIcon}>
							<div className="Outro-TextLine">
								<div className="C-label">Line 1</div>
								<Input
									id="line1"
									value={outroState.text1}
									onChange={onChangeInput}
									placeholder="For more help visit ..."
								/>
							</div>

							<div className="Outro-TextLine">
								<div className="C-label">Line 2</div>
								<Input
									id="line2"
									value={outroState.text2 || ""}
									onChange={onChangeInput}
									placeholder="help.myapp.com"
								/>
							</div>
						</CSection>
					</>
				)}

				<CSection
					disabled={hideOutro || outroState.type == "video"}
					label="Timing"
					icon={clockIcon}
				>
					<CSlider
						disabled={hideOutro || outroState.type == "video"}
						value={outroState.duration || 1.5}
						min={1}
						max={6}
						setValue={setOutroDuration}
						label="Duration"
						unit="s"
					/>
				</CSection>
			</>
		</div>
	);
};
export default OutroCustomizer;
