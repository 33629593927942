import { GigaUserApi } from "@giga-user-fern/api";
import {
	GuidePlatformStatus,
	GuidePreview,
	Voice,
} from "../../../core/types/guide";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import logger from "../../../utils/logger";
import {
	defaultVoiceEdits,
	setCurrentVersions,
	setExport,
	setExportingVideo,
	setGeneratedSrc,
	setGeneratedTimestamps,
	setGuide,
	setGuidePlatformStatus,
	setLoadingGeneratedVideo,
	setOriginalTimestamps,
	setPlainDoc,
	setPublishingGuide,
	setSubtitles,
	setTranscriptEdited,
	setUnsavedChanges,
	setVideoEdits,
	setVideoTranscript,
} from "../../slices/guideSlice";
import { VideoMetadata } from "@giga-user-fern/api/types/api/resources/guides";
import { showErrorMessagePopup } from "../../../layouts/MessagePopup/messagePopupSlice";
import useSaveGuide from "./useSaveGuide";
import { selectVoice, setVoice } from "../../slices/guideSlice";
import { setShowVideoExportPopup } from "../../slices/processingSlice";
import { selectSaver } from "../../slices/backendSlice";
import useShowAsyncError from "./useShowAsyncError";
import React from "react";
import { store } from "../..";
import { useToast } from "@chakra-ui/react";
import { sanitizedBackgroundEdits } from "../../../utils/videoEditsSanitizer";
import orgSetupCanvas from "../../../core/canvas/orgSetupCanvas";

const useFetchGuidePreviewAndCheck: () => (
	prevStatus?: GuidePlatformStatus,
	callback?: () => void,
) => Promise<void> = () => {
	/**
	 * Used for these processes:
	 * 1. publishing
	 * 2. voiceover generation
	 * These two processes cannot happen at the same time. Only one can be true at a time.
	 */

	const dispatch = useAppDispatch();
	const showError = useShowAsyncError();
	const saver = useAppSelector(selectSaver);
	const toast = useToast();

	const fetchGuidePreviewAndCheck = async (
		prevStatus?: GuidePlatformStatus,
		callback?: () => void,
	) => {
		let generatingVoiceover;
		let publishingGuide;
		let exportingVideo;
		let aiEnhancingArticle;
		let aiEnhancingVideo;
		let trimmingVideo;
		let autoUpdatingGuide;
		let creatingGuide;
		const status = store.getState().guide.value.status;
		var url = new URL(window.location.href);
		var urlSplit = url.pathname.split("/");
		var guideId = urlSplit[urlSplit.length - 1];
		const statusResp = await saver.getGuidePlatformStatus(
			GigaUserApi.Id(guideId),
		);

		if (statusResp.ok) {
			generatingVoiceover = statusResp.body.generatingVoiceover;
			publishingGuide = statusResp.body.publishingGuide;
			exportingVideo = statusResp.body.exportingVideo;
			aiEnhancingArticle = statusResp.body.aiEnhancingArticle;
			aiEnhancingVideo = statusResp.body.aiEnhancingVideo;
			autoUpdatingGuide = statusResp.body.autoUpdatingGuide;
			trimmingVideo = statusResp.body.trimmingVideo;
			creatingGuide = statusResp.body.creatingGuide;
			if (
				generatingVoiceover ||
				publishingGuide ||
				exportingVideo ||
				aiEnhancingArticle ||
				aiEnhancingVideo ||
				trimmingVideo ||
				autoUpdatingGuide ||
				creatingGuide
			) {
				/**
				 * still generating video or publishing guide
				 */
				setTimeout(() => fetchGuidePreviewAndCheck(statusResp.body), 3000);
			} else {
				/**
				 * none of the async processes is currently running. All are completed.
				 * */
				// console.log("video is done generating!",  loadedGuidePreview)
				const guideData = await saver.fetchGuideData(GigaUserApi.Id(guideId));

                const loadedGuidePreview = await saver.fetchGuidePreview(
					GigaUserApi.Id(guideId),
				);

				const newGeneratedSrc = guideData?.video.generatedSrc;
				const newVoice = guideData?.video.videoEdits?.voice;

				if (newGeneratedSrc) dispatch(setGeneratedSrc(newGeneratedSrc));

				dispatch(
					setCurrentVersions({
						saved: loadedGuidePreview?.versionSaved,
						published: loadedGuidePreview?.versionPublished,
					}),
				);

				// Creating guide
				if (status?.creatingGuide && !creatingGuide) {
					if (loadedGuidePreview && guideData) {
						const copyGuideData = { ...guideData };
						const setup = orgSetupCanvas[saver.organization?.id || ""];
						if (
							setup &&
							setup.introDuration &&
							setup.outroDuration &&
							copyGuideData.video.videoEdits?.intro?.duration &&
							copyGuideData.video.videoEdits?.outro?.duration
						) {
							copyGuideData.video.videoEdits.intro.duration =
								setup.introDuration;
							copyGuideData.video.videoEdits.outro.duration =
								setup.outroDuration;
						}

						if (copyGuideData.video.videoEdits?.background) {
							copyGuideData.video.videoEdits.background =
								sanitizedBackgroundEdits(
									copyGuideData.video.videoEdits.background,
									copyGuideData.video.videoEdits.version,
								);
						}

						if (copyGuideData.video.videoEdits)
							copyGuideData.video.videoEdits.version = "may012024";

						dispatch(
							setGuide({
								id: GigaUserApi.Id(guideId),
								guidePreview: loadedGuidePreview,
								guideData: copyGuideData,
							}),
						);
						document.title = loadedGuidePreview?.header.name;
					}
				}

				if (guideData?.video.export) {
					dispatch(setExport(guideData?.video.export));
				}

				//AI Rewrite article
				if (
					guideData?.plainDoc &&
					status?.aiEnhancingArticle &&
					!aiEnhancingArticle
				) {
					dispatch(setPlainDoc(guideData?.plainDoc));
				}

				//AI Rewrite video
				if (
					guideData?.video.generated?.transcript &&
					status?.aiEnhancingVideo &&
					!aiEnhancingVideo
				) {
					dispatch(setVideoTranscript(guideData?.video.generated?.transcript));
				}

				//Trimming
				if (guideData?.plainDoc && status?.trimmingVideo && !trimmingVideo) {
					dispatch(setPlainDoc(guideData?.plainDoc));
				}

				if (
					guideData?.video.generated?.transcript &&
					status?.trimmingVideo &&
					!trimmingVideo
				) {
					dispatch(setVideoTranscript(guideData?.video.generated?.transcript));
				}
				if (
					guideData?.video.videoEdits &&
					((status?.generatingVoiceover && !generatingVoiceover) ||
						(status?.trimmingVideo && !trimmingVideo))
				) {

                    dispatch(setVideoEdits(guideData?.video.videoEdits));
					if (guideData.video.generated) {
						dispatch(
							setOriginalTimestamps(
								guideData?.video.generated?.originalTimestamps ?? [],
							),
						);
						dispatch(
							setGeneratedTimestamps(
								guideData?.video.generated?.generatedTimestamps ?? [],
							),
						);
						dispatch(setSubtitles(guideData?.video.generated?.subtitles ?? ""));
						dispatch(
							setVideoTranscript(guideData?.video.generated?.transcript),
						);
					}
				}

				dispatch(setUnsavedChanges(false));

				dispatch(setVoice(newVoice || defaultVoiceEdits));

				// setVideoTime(0);

				if (callback) {
					callback();
				}
			}
			dispatch(setGuidePlatformStatus(statusResp.body));
			if (statusResp.body.operationError)
				showError(statusResp.body.operationError);
		} else {
			toast({
				title: `Please refresh your page.`,
				description: "Network error. Are you connected to the internet?",
				status: "error",
				duration: null,
				isClosable: false,
				position: "top",
			});
		}
	};

	return fetchGuidePreviewAndCheck;
};

export default useFetchGuidePreviewAndCheck;
