import { Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./LoadingTip.css";

type LoadingTipProps = {
	message?: string;
};

const LoadingTip: React.FC<LoadingTipProps> = (props) => {
	type LoadingTip = {
		text: string;
		img: string;
		helpLink?: string;
		weight: number; //from 1 to 10
	};

	const loadingTips: LoadingTip[] = [
		{
			text: "Click in the bottom timeline to add a zoom effect",
			img: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/web_assets/new_zoom.gif",
			weight: 1,
			helpLink: "https://help.clueso.io",
		},
		// {
		//     text: "Empty lines in the script will create pauses in the voiceover",
		//     img: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/web_assets/pauses.gif",
		//     weight: 6,
		//     helpLink: "https://help.clueso.io"
		// },
		{
			text: "To sync voice and video, add screenshots in the script",
			img: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/web_assets/ss_in_ts.gif",
			weight: 4,
			helpLink: "https://help.clueso.io",
		},
		{
			text: "Screenshots in the script enforce sync between voice and video",
			img: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/web_assets/ss_in_ts.gif",
			weight: 4,
			helpLink: "https://help.clueso.io",
		},
		{
			text: "You can upload any recording to get an article & AI video",
			img: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/web_assets/upload_video.gif",
			weight: 1,
			helpLink: "https://help.clueso.io",
		},
		// {
		//     text: "Click here to download your original screen recording",
		//     img: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/web_assets/og_rec.png",
		//     weight: 1,
		//     helpLink: "https://help.clueso.io"
		// },
		// {
		//     text: "Use version history to revert to any previous version",
		//     img: "https://clueso-public-assets.s3.ap-south-1.amazonaws.com/web_assets/version_history.gif",
		//     weight: 1,
		//     helpLink: "https://help.clueso.io"
		// }
	];

	const selectTip = () => {
		const totalWeight = loadingTips.reduce(
			(total, tip) => total + tip.weight,
			0,
		);
		let randomNum = Math.random() * totalWeight;

		for (let i = 0; i < loadingTips.length; i++) {
			if (randomNum < loadingTips[i].weight) {
				return i;
			}
			randomNum -= loadingTips[i].weight;
		}

		return 1;
	};

	const [selectedIndex, setSelectedIndex] = useState<number>(selectTip());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setSelectedIndex(selectTip());
		}, 15000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const selectedTip = loadingTips[selectedIndex];

	return (
		<div className="LoadingTip">
			{props.message ? (
				<Flex align={"center"}>
					<Spinner color="#d43f8c" />
					<h1>{props.message}</h1>
				</Flex>
			) : null}

			{selectedIndex !== null ? (
				<div className="ProTip">
					<a
						className="tip-title"
						href={undefined}
						target="_blank"
						rel="noopener noreferrer"
					>
						💡 Pro tip #{selectedIndex + 1}
					</a>
					<div className="tip-text">{selectedTip.text}</div>
					<img src={selectedTip.img} className="Protip-img" alt="Pro tip"></img>
				</div>
			) : null}
		</div>
	);
};
export default LoadingTip;
