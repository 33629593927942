import { useEffect, useState } from "react";
import AdminList from "./AdminList";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
import { HostingDetails } from "@giga-user-fern/api/types/api";
import { saver } from "@gigauser/common/src/network/saver";
import { useAppDispatch } from "@gigauser/common/src/redux";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import CreateUserOverlay from "./CreateUserOverlay";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { showErrorMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import usersIcon from "@gigauser/common/src/assets/icons/users.svg";
import CToggle from "@gigauser/common/src/ui/video/components/CToggle/CToggle";

export const HostingPage = () => {
	const [loading, setLoading] = useState(true);
	const [details, setDetails] = useState<HostingDetails | null>(null);
	const [users, setUsers] = useState<string[]>([]);
	const [disabled, setDisabled] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	useEffect(() => {
		saver
			.getHostingDetails()
			.then((hostingDetailsResp) => {
				if (hostingDetailsResp.ok) {
					const actualDetails = hostingDetailsResp.body;
					setDetails(actualDetails);
					if (actualDetails.type === "email_login")
						setUsers(actualDetails.allowedEmails ?? []);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log("OSTING FETCH FAIled");
				console.error(err);
				setLoading(false);
			});
	}, []);
	const saveChanges = async (x: HostingDetails) => {
		const resp = await saver.setHostingDetails(x);
		if (!resp.ok) {
			dispatch(
				showErrorMessagePopup(
					"Operation failed! Please contact Clueso support.",
				),
			);
		}
	};
	if (loading) {
		return <GridLoader center />;
	} else {
		return (
			<>
				<h2
					style={{
						fontSize: "x-large",
						padding: "20px 5px",
					}}
				>
					Manage Hosting
				</h2>
				<div style={{ width: "50rem", margin: "0 auto" }}>
					{!saver.organization?.customLogin && (
						<p>Your help center is public!</p>
					)}
					{details?.type !== "email_login" && (
						<p>
							The ability to manage your hosting is coming soon to the platform.
							Please reach out to Clueso for support
						</p>
					)}
					{details?.type == "email_login" && (
						<>
							<p>
								Manage who can view your help center and ad-hoc sharing links
								here
							</p>
							<br></br>
							<CToggle
								disabled={disabled}
								label={`Allow Google sign-in for anyone with a ${saver.organization?.domain} email address`}
								value={details.enableDomainLogin}
								setterFunction={(x) => {
									setDetails({ ...details, enableDomainLogin: x });
									saveChanges({ ...details, enableDomainLogin: x });
								}}
							></CToggle>

							<AdminList
								//@ts-ignore
								self={saver?.user}
								users={users.map((x) => {
									return { email: x, role: "viewer" };
								})}
								deleteUser={async (email: string) => {
									const newUsers = users.filter((x) => x !== email);
									const newHosting = details;
									newHosting.allowedEmails = newUsers;
									setDisabled(true);
									const resp = await saver.setHostingDetails(newHosting);
									setDisabled(false);
									if (resp) {
										setUsers(users.filter((x) => x !== email));
										setDetails(newHosting);
									} else {
										dispatch(showErrorMessagePopup("Couldn't delete user."));
									}
								}}
								createUser={() => {
									dispatch(
										openOverlay({
											heading: {
												title: "Create User",
												icon: <Icon>{<img src={usersIcon}></img>}</Icon>,
											},
											component: (
												<CreateUserOverlay
													complete={async ({ email }) => {
														const newUsers = users.concat([email]).sort();
														const newHosting = details;
														newHosting.allowedEmails = newUsers;
														setDisabled(true);
														const x = await saver.setHostingDetails(newHosting);
														setDisabled(false);
														if (x.ok) {
															setUsers(newUsers);
															setDetails(newHosting);
														} else {
														}
													}}
												/>
											),
										}),
									);
								}}
							/>
						</>
					)}
				</div>
			</>
		);
	}
};
