export default (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="800px"
		height="800px"
		viewBox="0 0 16 16"
		fill="#000000"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.5 2H7.71l-.85-.85L6.51 1h-5l-.5.5v11l.5.5H7v-1H1.99V6h4.49l.35-.15.86-.86H14v1.5l-.001.51h1.011V2.5L14.5 2zm-.51 2h-6.5l-.35.15-.86.86H2v-3h4.29l.85.85.36.15H14l-.01.99zM13 16h-1v-3H9v-1h3V9h1v3h3v1h-3v3z"
		/>
	</svg>
);
