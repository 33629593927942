import React, { useEffect, useRef, useState } from "react";
import CanvasPlayerContext, {
	HardsetCurrentTimeOptions,
} from "./CanvasPlayerContext";
import {
	calculateMediaDuration,
} from "./canvasUtils/videoUtils";
import { Organization } from "../types/guide";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getPlaybackVolume } from "./canvasUtils/volumeUtils";
import logger from "../../utils/logger";
import {
	VideoClip,
	VideoSource,
} from "@giga-user-fern/api/types/api/resources/guides";
import { getFont } from "../../utils/fontsUtils";
import { selectLogos } from "../../redux/slices/platformDetailsSlice";
import { GigaUserApi } from "@giga-user-fern/api";
import { sourcesAreEqual } from "./canvasUtils/canvasUtils";

/**
 * Terminology:
 *      video: The final rendered/exported video
 *      screenclip: The screenshare present in the video ( could be original or generated video)
 */

type CanvasProviderProps = {
	children: React.ReactNode;
	videoSrc: string;
	originalSrc?: string;
	videoEdits: VideoEdits;
	organization: Organization | null;
	initTime?: number; // This is for setting the initial time of the video. This is used when the video is loaded from a specific time.
	// IMPORTANT: This parameter is only valid for the clip. The time is not adjusted for the intro and outro.
	//TODO: Fix this. null is not acceptable.
	onError?: () => void;
};

type CanvasStateRef = {
	currentTime: number;
	paused: boolean;
	initTime: number;
};

export type FrontendVideoSource = VideoSource & {
	ref: React.MutableRefObject<HTMLVideoElement | null>;
	loaded: boolean;
};

const CanvasProvider: React.FC<CanvasProviderProps> = (props) => {
	type Handler = () => void;

	const log = (a: string) => {
		// console.log(a);
	};

	const introVidRef = useRef<HTMLVideoElement | null>(null);
	const outroVidRef = useRef<HTMLVideoElement | null>(null);
	const introImgRef = useRef<HTMLImageElement>(new Image());
	const outroImgRef = useRef<HTMLImageElement>(new Image());
	const introLogoRef = useRef<HTMLImageElement>(new Image());
	const outroLogoRef = useRef<HTMLImageElement>(new Image());

	const vidRef = useRef<HTMLVideoElement | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	const audioRef = useRef<HTMLAudioElement | null>(null);
	const { videoEdits } = props;
	const { intro, outro } = videoEdits;

	const [loading, setLoading] = useState(true);

	const [introClipLoaded, setIntroClipLoaded] = useState(false);
	const [outroClipLoaded, setOutroClipLoaded] = useState(false);
	const [screenClipLoaded, setScreenClipLoaded] = useState(false);
	const [introImgLoaded, setIntroImgLoaded] = useState(false);
	const [outroImgLoaded, setOutroImgLoaded] = useState(false);
	const [introLogoLoaded, setIntroLogoLoaded] = useState(false);
	const [outroLogoLoaded, setOutroLogoLoaded] = useState(false);
	const [loadedAllFonts, setLoadedAllFonts] = useState(false);

	const [clips, _setClips] = useState<VideoClip[] | undefined>(undefined);
	const [sources, _setSources] = useState<FrontendVideoSource[] | undefined>(
		undefined,
	);

	const loadedIntroClip = intro?.type == "video" ? introClipLoaded : true;
	const loadedIntroImg =
		intro && intro.type !== "video" ? introImgLoaded : true;
	const loadedIntroLogo =
		intro && intro.type !== "video" && intro?.logo ? introLogoLoaded : true;
	const loadedOutroClip =
		outro && outro.type == "video" ? outroClipLoaded : true;
	const loadedOutroImg =
		outro && outro.type !== "video" ? outroImgLoaded : true;
	const loadedOutroLogo =
		outro && outro.type !== "video" && outro?.logo ? outroLogoLoaded : true;

	var loadedAllSources = true;

	if (sources) {
		for (let source of sources) {
			if (source.loaded) {
				loadedAllSources = loadedAllSources && true;
			} else {
				const hasMatchingClip =
					clips && clips.some((clip) => clip.srcId === source.id);
				if (hasMatchingClip) {
					loadedAllSources = loadedAllSources && false;
				} else {
					loadedAllSources = loadedAllSources && true;
				}
			}
		}
	}

	// console.log(
	// 	"loading status: ",
	// 	loadedIntroClip,
	// 	loadedIntroImg,
	// 	loadedIntroLogo,
	// 	loadedOutroClip,
	// 	loadedOutroImg,
	// 	loadedOutroLogo,
	// 	screenClipLoaded,
	// 	loadedAllFonts,
	// 	loadedAllSources,
	// );

	var loadedAllVideos =
		loadedIntroClip &&
		loadedIntroImg &&
		loadedIntroLogo &&
		loadedOutroClip &&
		loadedOutroImg &&
		loadedOutroLogo &&
		screenClipLoaded &&
		loadedAllFonts &&
		loadedAllSources;

	const [videoDuration, setVideoDuration] = useState<number>(0);
	const [currentTime, _setCurrentTime] = useState<number>(0);
	const [_hardUpdateTimelinePending, _setHardUpdateTimelinePending] =
		useState(false);
	const [_timelineInitPending, _setTimelineInitPending] = useState(false);
	const dispatch = useAppDispatch();

	const currentTimeRef = useRef<number>(0);

	const [paused, _setPaused] = useState(true);
	const pausedRef = useRef<boolean>(false);

	let introPlayoutInterval: ReturnType<typeof setInterval>;
	let introPlayoutTimer: ReturnType<typeof setTimeout>;

	let outroPlayoutInterval: ReturnType<typeof setInterval>;
	let outroPlayoutTimer: ReturnType<typeof setTimeout>;

	const activeClipIndexRef = useRef<number>(0);
	const [activeClipIndex, _setActiveClipIndex] = useState<number>(0);

	const logos = useAppSelector(selectLogos);

	//Some functions like the renderVideoToCanvas animation don't detect state changes and need ref. So maintaining the same copy as a ref.

	const [showFullScreen, setShowFullScreen] = useState(false);

	const loadAllFonts = async () => {
		var fonts = videoEdits.elements?.map((element) => {
			return element.textdata?.font;
		});

		if (!fonts) fonts = [];

		if (intro?.font) fonts.push(intro.font);
		if (outro?.font) fonts.push(outro.font);

		//Load the default fonts.
		await getFont(false, "Inter");
		await getFont(false, "League Spartan");

		//Load the custom fonts.
		for (let font of fonts) {
			await getFont(false, font);
		}

		const allFonts = document.fonts;

		// This will store the promises for each font load
		let loadPromises: Promise<FontFace | null>[] = [];

		// Loop through each FontFace object
		allFonts.forEach((font) => {
			// Each font can be loaded by calling its load method, which returns a promise
			let loadPromise = font.load().catch((error) => {
				console.error("Failed to load font:", font.family, error);
				return null; // Return null in case of an error to not break the Promise.all
			});
			loadPromises.push(loadPromise);
		});

		// Wait for all fonts to be loaded
		Promise.all(loadPromises).then((results) => {
			// Filter out any null results due to failed loads
			results = results.filter((result) => result !== null);

			if (results.length > 0) {
				console.log("All fonts loaded successfully.");
			} else {
				console.log("No fonts were loaded.");
			}

			setLoadedAllFonts(true);
		});

		// setLoadedAllFonts(true)
	};

	const setClips = (ref: VideoClip[]) => {
		// remove videos with duration less than 1/24 of a second
		const _clips = ref.filter((clip) => clip.endTime - clip.startTime >= 1 / 24);
		_setClips(_clips);
	}

	const onLoadSource = (e: any, sourceId: GigaUserApi.Id) => {
		const videoTarget = e.target;

		if (!sources) {
			console.error("returning prematurely from sources!");
			return;
		}

		if (e.target.readyState == 4) {
			const _sources = sources.map((source) => {
				if (source.id == sourceId)
					return {
						...source,
						loaded: true,
					};
				else return source;
			});

			_setSources(_sources);
		}
	};

	introImgRef.current.onload = () => {
		setIntroImgLoaded(true);
	};

	introImgRef.current.onerror = () => {
		setIntroImgLoaded(true);
	};

	introLogoRef.current.onload = () => {
		setIntroLogoLoaded(true);
	};

	introLogoRef.current.onerror = () => {
		setIntroLogoLoaded(true);
	};

	outroImgRef.current.onload = () => {
		setOutroImgLoaded(true);
	};

	outroImgRef.current.onerror = () => {
		setOutroImgLoaded(true);
	};

	outroLogoRef.current.onload = () => {
		setOutroLogoLoaded(true);
	};

	outroLogoRef.current.onerror = () => {
		setOutroLogoLoaded(true);
	};

	const setPaused = (value: boolean) => {
		/**
		 * used to set paused state and paused ref together to ensure consistency
		 */

		_setPaused(value);
		pausedRef.current = value;
	};

	const setCurrentTime = (currentTime: number) => {
		_setCurrentTime(currentTime);
		currentTimeRef.current = currentTime;

		//logic to play outro
		const outroStartTime = getScreenclipEndTime();
		if (currentTime >= outroStartTime && !paused) {
			playoutOutro(currentTime);
		}
	};

	const setActiveClipIndex = (index: number) => {
		_setActiveClipIndex(index);
		activeClipIndexRef.current = index;
	};

	const updateProgress = (event: any) => {
		const video = event.target;
		if (video && video.buffered.length > 0) {
			// Calculate the percentage of the video that has been buffered
			const bufferedEnd = video.buffered.end(video.buffered.length - 1);
			const duration = video.duration;
			const progress = (bufferedEnd / duration) * 100;
		}
	};

	const incrementCurrentTime = (increment: number) => {
		_setCurrentTime((prevCurrentTime) => prevCurrentTime + increment);
		currentTimeRef.current = currentTimeRef.current + increment;
	};

	const playoutIntro = (currTime: number) => {
		/**
		 * This function plays out the remainder of the intro from the current time
		 * Note, none of the intro rendering happens here. This is just timer controls
		 * @param currTime: the current time. Do not use the currentTime state here!
		 */

		const currentTime = currTime;

		setPaused(false);
		vidRef.current?.pause();
		const videoStartTime = getScreenclipStartTime();

		const timeToVideo = (videoStartTime - currentTime) * 1000;

		const incrementTimeDuringIntro = () => {
			if (currentTimeRef.current > videoStartTime || pausedRef.current) {
				clearInterval(introPlayoutInterval);
				clearTimeout(introPlayoutTimer);
			} else {
				incrementCurrentTime(30 / 1000);
			}
		};

		introPlayoutInterval = setInterval(() => {
			incrementTimeDuringIntro();
		}, 30);

		introPlayoutTimer = setTimeout(() => {
			clearInterval(introPlayoutInterval);
			vidRef.current?.play();

			if (introVidRef.current) {
				introVidRef.current.pause();
			}
		}, timeToVideo);

		if (intro?.type == "video" && introVidRef.current) {
			introVidRef.current.currentTime = currentTime;
			introVidRef.current.play();
		}
	};

	const playoutOutro = (currTime: number) => {
		/**
		 * Similar documentation as playoutIntro. Check above.
		 */

		logger.debug("playoutOutro");

		const currentTime = currTime;
		setPaused(false);
		vidRef.current?.pause();
		const outroEndTime = getVideoDuration();
		const outroStartTime = getScreenclipEndTime();

		if (outro?.type == "video" && outroVidRef.current) {
			outroVidRef.current.currentTime = currentTime - outroStartTime;
			outroVidRef.current.play();
		}

		logger.debug("timings: ", outroStartTime, outroEndTime);

		const timeToEnd = (outroEndTime - currentTime) * 1000;

		const incrementTimeDuringOutro = () => {
			if (
				currentTimeRef.current < outroStartTime ||
				currentTimeRef.current >= outroEndTime ||
				pausedRef.current
			) {
				//What's this for?

				logger.debug("incrementTimeDuringOutro ender");
				setPaused(true);
				audioRef.current?.pause();
				outroVidRef.current?.pause();
				clearInterval(outroPlayoutInterval);
				clearTimeout(outroPlayoutTimer);
			} else {
				incrementCurrentTime(30 / 1000);
			}
		};

		outroPlayoutInterval = setInterval(() => {
			incrementTimeDuringOutro();
		}, 30);

		outroPlayoutTimer = setTimeout(() => {
			logger.debug("done with outro!");

			clearInterval(outroPlayoutInterval);
			setPaused(true);
			audioRef.current?.pause();
			outroVidRef.current?.pause();
		}, timeToEnd);
	};

	const setPlaybackRate = (rate: number) => {
		if (!vidRef.current) return;
		else {
			vidRef.current.playbackRate = rate;
		}
	};

	const timelineTimeIsInGivenClip = (
		timelineTime: number,
		clipIndex: number,
	) => {
		if (!clips || clips.length == 0) return false;

		let elapsed = 0;
		var foundIndex = 0;

		for (let i = 0; i < clips.length; i++) {
			const clip = clips[i];
			const duration = clip.endTime - clip.startTime;
			elapsed += duration;

			if (timelineTime <= elapsed) {
				foundIndex = i;
				break;
			}
		}

		return foundIndex == clipIndex;
	};

	const initPlayHandler = () => {
		if (!vidRef.current) return;

		const videoStartTime = getScreenclipStartTime();
		const videoEndTime = getScreenclipEndTime();
		const endTime = getVideoDuration();
		setPaused(false);

		if (Math.abs(currentTime - endTime) < 0.05) {
			hardsetCurrentTime(0, true);
			playoutIntro(0);
			if (audioRef.current && audioRef.current.duration) {
				audioRef.current.currentTime = currentTime % audioRef.current.duration;
				audioRef.current.play();
			}
			return;
		}

		if (currentTime < videoStartTime) {
			playoutIntro(currentTime);
		} else if (currentTime >= videoEndTime) {
			playoutOutro(currentTime);
		} else {
			//given the current timeline time, let us set the active clip

			if (clips && clips.length > 0) {
				for (var i = 0; i < clips.length; i++) {
					let clip = clips[i];
					if (timelineTimeIsInGivenClip(currentTimeRef.current, i)) {
						//this is the active clip
						setActiveClipIndex(i);
						const s = getSourceRef(clip.srcId);
						s.current?.play();
						break;
					}
				}
			} else {
				vidRef.current?.play();
			}
		}

		if (audioRef.current && audioRef.current.duration) {
			audioRef.current.currentTime = currentTime % audioRef.current.duration;
			audioRef.current.volume = getPlaybackVolume(
				videoEdits.background?.audio?.volume,
			);
			audioRef.current.play();
		}
	};

	const initPauseHandler = () => {
		//pause video in case it's playing

		pauseAllSources();

		if (introVidRef.current) {
			introVidRef.current.pause();
		}

		if (outroVidRef.current) {
			outroVidRef.current.pause();
		}

		if (audioRef.current) {
			audioRef.current.pause();
		}

		setPaused(true);
	};

	const setSources = (initSources: VideoSource[]) => {
		//TODO: Here, setSources shold happen only once at the beginning. Why are we seeing existing sources.

		const _sources = initSources?.map((source) => {
			if (source.id.includes("clip_")) {
				const existingSource = sources?.find((s) => s.id === source.id);

				const newFrontendSource: FrontendVideoSource = {
					id: source.id,
					loaded: existingSource?.loaded ?? false,
					ref: React.createRef<HTMLVideoElement | null>(),
					src: source.src,
				};

				return newFrontendSource;
			}
			return null;
		});
		const newSources = _sources.filter(
			(s) => s !== null,
		) as FrontendVideoSource[];

		_setSources(newSources);
	};

	const addNewSource = (id: string, presignedGet: string) => {
		const newFrontendSource: FrontendVideoSource = {
			id: GigaUserApi.Id(id),
			loaded: false,
			ref: React.createRef<HTMLVideoElement | null>(),
			src: presignedGet,
		};

		const _sources = [...(sources || []), newFrontendSource];

		_setSources(_sources);

		return newFrontendSource;
	};

	const getSourceRef = (srcId?: GigaUserApi.Id) => {
		if (srcId && srcId.toString().includes("clip_")) {
			const source = sources?.find((s) => s.id == srcId);
			if (source) return source.ref;
			else return vidRef;
		}
		return vidRef;
	};

	const onSourceEnded = (_source?: FrontendVideoSource) => {
		if (!(clips && clips.length > 0 && sources)) return;

		// var source: FrontendVideoSource

		// if(_source) source = _source
		// else{
		//     source = sources.find(s => !s.id.includes("clip_")) as FrontendVideoSource
		// }

		if (activeClipIndexRef.current < clips.length - 1) {
			//there are more clips to be played
			jumpToNextClip();
		} else {
			//nothing more to do. Let's pause
			initPauseHandler();
		}
	};

	const jumpToNextClip: () => Promise<boolean> = async () => {
		//we are not playing the correct clip! Some clip got skipped.
		// await pauseAllSources(false)

		var jumped = false;

		if (!(clips && clips.length > 0)) return jumped;

		const currentSource = clips[activeClipIndexRef.current].srcId;

		if (activeClipIndexRef.current >= clips.length - 1) {
			//we are in the last clip. Stop the video
			initPauseHandler();
			// fin()
			return jumped;
		} else {
			//jump to the next clip
			jumped = true;

			const nextClipIndex = activeClipIndexRef.current + 1;
			const nextClip = clips[nextClipIndex];
			const currentClip = clips[activeClipIndexRef.current];
			const nextClipRef = getSourceRef(nextClip.srcId);

			log(`nextClip: ${nextClip}`);

			if (
				!sourcesAreEqual(
					nextClip.srcId,
					clips[activeClipIndexRef.current].srcId,
				)
			) {
				log(`next source is different!`);
				await getSourceRef(currentSource).current?.pause();
			}

			if (nextClipRef.current) {
				if (nextClip.startTime !== currentClip.endTime) {
					nextClipRef.current.currentTime = nextClip.startTime + 0.01;
				} else {
					//the times are equal, proceed to set times.
					jumped = false;
				}

				setActiveClipIndex(activeClipIndexRef.current + 1);
				if (nextClipRef.current.paused) nextClipRef.current.play();
			}
		}

		return jumped;
	};

	const onTimeUpdate = async (srcId?: GigaUserApi.Id) => {
		/**
		 * This function is used to set currentTime as the screenclip plays.
		 * @param srcId: To be passed if the time is updating for a clip that is not the original recording
		 *
		 */

		if (loading || !vidRef.current || pausedRef.current) {
			//no need to do anything here if the video is paused.
			return;
		}

		var playingSourceRef = getSourceRef(srcId);
		const videoStartTime = getScreenclipStartTime();
		const playingSourceTime = playingSourceRef.current?.currentTime;

		log(
			`------------------------- onTimeUpdate ${playingSourceTime}  -------------------------`,
		);

		const setTimelineTimes = () => {
			if (playingSourceTime) {
				const convertedTime = videoToTimelineTime(playingSourceTime, srcId);
				setCurrentTime(convertedTime + videoStartTime);
			}

			if (audioRef.current) {
				if (audioRef.current.src !== videoEdits.background?.audio?.src) {
					audioRef.current.src = videoEdits.background?.audio?.src ?? "";
					if (playingSourceTime)
						audioRef.current.currentTime =
							currentTime % audioRef.current.duration;
				}
				audioRef.current.volume = getPlaybackVolume(
					videoEdits.background?.audio?.volume,
				);
			}
		};

		var jumped = false;

		if (clips && clips.length > 0) {
			if (!playingSourceRef.current || !playingSourceTime) {
				//error
				return;
			}

			const timelineTimeOfPlayingClip = videoToTimelineTime(
				playingSourceTime,
				srcId,
			);

			log(
				`timelinetime(${playingSourceTime}, ${srcId}) = ${timelineTimeOfPlayingClip} `,
			);

			//set the current timeline time

			const currentSource = clips[activeClipIndexRef.current].srcId;
			const currentSourceRef = getSourceRef(srcId).current;

			if (
				currentSourceRef?.paused &&
				currentSourceRef.currentTime < currentSourceRef.duration
			) {
				log(`arrived from a paused source ${currentSource}`);
				log("-------------------");
				//If the onTimeUpdate is coming from a source that is paused (this can happen because actual pausing is async),
				//then we want to return UNLESS the source is finishing up.

				//This logic is required to run if we are switching from one source to the next AND
				//the current source is not finishing up.
				// if (
				// 	activeClipIndexRef.current + 1 < clips.length &&
				// 	!sourcesAreEqual(
				// 		clips[activeClipIndexRef.current].srcId,
				// 		clips[activeClipIndexRef.current + 1].srcId,
				// 	)
				// ) {
				return;
				// }
			}

			if (
				!timelineTimeIsInGivenClip(
					timelineTimeOfPlayingClip,
					activeClipIndexRef.current,
				) ||
				playingSourceTime >= clips[activeClipIndexRef.current].endTime
			) {
				log("needToJump!");

				//we are not playing the correct clip! Some clip got skipped.
				// await pauseAllSources(false)

				jumped = await jumpToNextClip();
				return;
			}
		}

		if (!jumped) setTimelineTimes();
	};

	// const [playHandler, setPlayHandler] = useState<Handler>(() => initPlayHandler)
	// const [pauseHandler, setPauseHandler] = useState<Handler>(() => initPauseHandler)

	const initVideoClips = async () => {
		var introDuration = 0;
		var outroDuration = 0;
		var screenclipDuration = 0;

		const finishLoading = (screenclip_duration: any) => {
			if (typeof screenclip_duration == "number") {
				screenclipDuration = screenclip_duration;
				setVideoDuration(screenclip_duration);
				setCurrentTime(0);
				setLoading(false);
			} else {
				console.error("duration is not a number!");
			}
		};

		if (vidRef.current) {
			calculateMediaDuration(vidRef.current, (duration) => {
				finishLoading(duration);
			});
		}
	};

	useEffect(() => {
		/**
		 * Load all the video clips here.
		 */
		initVideoClips();
	}, [vidRef.current, props.videoSrc]);

	useEffect(() => {
		setIntroImgLoaded(false);
		setIntroClipLoaded(false);
		setIntroLogoLoaded(false);

		const defaultSrc = `https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${props.organization?.id}/video_assets/Bookend.png`;

		if (intro && intro?.type !== "video") {
			if (intro.backgroundSrc) introImgRef.current.src = intro.backgroundSrc;
			else introImgRef.current.src = defaultSrc;

			if (intro.logo) {
				const introLogoSrc = logos.find((l) => l.id === intro?.logo)?.src;

				if (introLogoSrc) {
					introLogoRef.current.src = introLogoSrc;
				} else if (!introLogoRef.current.src) {
					setIntroLogoLoaded(true);
				}
			}
		}
	}, [intro?.backgroundSrc, intro?.type, intro?.logo]);

	useEffect(() => {
		setOutroImgLoaded(false);
		setOutroClipLoaded(false);

		const defaultSrc = `https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${props.organization?.id}/video_assets/Bookend.png`;

		if (outro && outro?.type !== "video") {
			if (outro?.backgroundSrc) outroImgRef.current.src = outro.backgroundSrc;
			else outroImgRef.current.src = defaultSrc;
			if (outro.logo) {
				const outroLogoSrc = logos.find((l) => l.id === outro?.logo)?.src;

				if (outroLogoSrc) {
					outroLogoRef.current.src = outroLogoSrc;
				} else if (!outroLogoRef.current.src) setOutroLogoLoaded(true);
			}
		}
	}, [outro?.backgroundSrc, outro?.type, outro?.logo]);

	useEffect(() => {
		loadAllFonts();
	}, []);

	useEffect(() => {
		const aci = computeActiveClipIndex(currentTime, true);
	}, [clips]);

	const timelineToVideoTime: (time: number) => {
		time: number;
		sourceId?: GigaUserApi.Id;
	} = (time: number) => {
		/**
		 * @param time is the time in the timeline (do not count intro and outro)
		 * time is the time wrt frontend clips.
		 * @returns time wrt backend clips. i.e: the time in the actual video .
		 */

		if (clips && clips.length > 0) {
			//there are some clips, we need to compute video time accordingly
			var elapsedTime = 0;

			// sortedClips.sort((a, b) => a.startTime - b.startTime)

			var i = 0;
			while (i < clips.length) {
				const currentClip = clips[i];
				const clipDuration = currentClip.endTime - currentClip.startTime;
				if (clipDuration + elapsedTime >= time) {
					const f = {
						time: currentClip.startTime + (time - elapsedTime),
						sourceId: currentClip.srcId,
					};
					return f;
				}

				elapsedTime += clipDuration;
				i++;
			}
		}

		//there are no clips
		return {
			time,
		};
	};

	const videoToTimelineTime: (
		t_v: number,
		srcId?: GigaUserApi.Id,
		clips?: VideoClip[],
	) => number = (t_v, srcId, overwriteClips) => {
		/**
		 * @param t_v is the time in the video (clip). (do not count intro and outro)
		 * time is the time wrt backend clips.
		 * @srcId is the source Id we are looking at
		 * @param clips if you want to pass your own clips instead of read from the real clips.
		 * @returns time wrt frontend clips.
		 */

		var _clips;
		if (overwriteClips) {
			_clips = [...overwriteClips];
		} else {
			_clips = [...(clips || [])];
		}

		if (_clips && _clips.length > 0) {
			var elapsedTime = 0; //elapsed time on the frontend clips timeline

			// sortedClips.sort((a, b) => a.startTime - b.startTime)

			for (let clip of _clips) {
				if (
					t_v >= clip.startTime &&
					t_v <= clip.endTime &&
					sourcesAreEqual(clip.srcId, srcId)
				) {
					const t_t = elapsedTime + t_v - clip.startTime;
					return t_t;
				} else {
					elapsedTime += clip.endTime - clip.startTime;
				}
			}
		}

		//no clips
		return t_v;
	};

	const pauseAllSources = async (setState: boolean = true) => {
		if (setState) vidRef.current?.pause();
		if (sources) {
			for (let source of sources) {
				await source.ref.current?.pause();
			}
		}
	};

	const computeActiveClipIndex = (time: number, set: boolean = false) => {
		const videoTime = timelineToVideoTime(time);

		const activeIndex = clips?.findIndex((clip, index) => {
			if (
				sourcesAreEqual(clip.srcId, videoTime.sourceId) &&
				timelineTimeIsInGivenClip(time, index)
			) {
				return true;
			}
		});

		if (set) {
			if (typeof activeIndex == "number" && activeIndex >= 0)
				setActiveClipIndex(activeIndex);
		}

		return activeIndex;
	};

	const hardsetCurrentTime = (
		time: number,
		hard?: boolean,
		options?: HardsetCurrentTimeOptions,
	) => {
		/**
		 * @param time: timelineTime
		 */

		const videoStartTime = getScreenclipStartTime();
		const videoEndTime = getScreenclipEndTime();
		const endTime = getVideoDuration();
		if (!vidRef.current) return; // Return early if the video is not available

		// prevent time from going to negative
		if (time < 0) {
			time = 0;
		}

		//prevent time from becoming bigger than video length
		if (time > endTime) {
			time = endTime;
		}

		if (time < videoStartTime) {
			//we are navigating somewhere in the intro
			setCurrentTime(time);
			pauseAllSources();

			vidRef.current.currentTime = 0; //whats the use of this?

			if (!paused) {
				playoutIntro(time);
			}

			if (intro?.type == "video" && introVidRef.current) {
				introVidRef.current.currentTime = time;
			}
		} else if (time > videoEndTime) {
			//we are navigating somewhere in the outro
			setCurrentTime(time);
			pauseAllSources();
			vidRef.current.currentTime = 0;
			if (!paused) {
				playoutOutro(time);
			}

			if (outro?.type == "video" && outroVidRef.current) {
				outroVidRef.current.currentTime = time - videoEndTime;
			}
		} else {
			//we are navigating in the screenclip

			const t_dash = time - videoStartTime;

			const videoTime = timelineToVideoTime(t_dash);

			var source;
			if (videoTime.sourceId && videoTime.sourceId.includes("clip_")) {
				const sourceId = videoTime.sourceId;
				source = sources?.find((s) => s.id == sourceId);
			}

			if (source?.ref.current) {
				source.ref.current.currentTime = videoTime.time;
			} else vidRef.current.currentTime = videoTime.time;

			if (pausedRef.current) {
				//the video is paused. Let's set the active clip index
				const activeIndex = computeActiveClipIndex(t_dash);

				if (
					typeof activeIndex == "number" &&
					activeIndex >= 0 &&
					!options?.skipSettingActiveClipIndex
				) {
					setActiveClipIndex(activeIndex);
				}
			}

			if (options?.waitTillSeek) {
				if (source) {
					source.ref.current?.addEventListener(
						"seeked",
						() => setCurrentTime(time),
						{ once: true },
					);
				} else {
					vidRef.current.addEventListener(
						"seeked",
						() => setCurrentTime(time),
						{ once: true },
					);
				}
			} else {
				setCurrentTime(time);
			}
		}

		if (audioRef.current) {
			audioRef.current.currentTime = time % audioRef.current.duration;
		}

		if (hard) {
			_setHardUpdateTimelinePending(true);
		}
	};

	const toggleFullScreen = () => {
		setShowFullScreen(!showFullScreen);
	};

	const onErrorOut = (e: any) => {
		// alert("Please refresh the page!")
		props.onError?.();
		// console.error("video errored out!: ", props.videoSrc, e)
	};

	//#region TIMINGS

	const getScreenclipStartTime: () => number = () => {
		/**
		 * Gets the timestamp in the video at which the screenclip starts
		 * (replaces getVideoStartTime)
		 */

		//TODO: start time for a clip might vary based on clips before?

		const edits = props.videoEdits;

		if (!edits) return 0;

		if (edits.intro && edits.intro.visible) {
			var introDuration: number = 0;

			introDuration = edits.intro.duration;

			return introDuration - (edits.intro.intersectionDuration || 0);
		} else {
			return 0;
		}
	};

	const getScreenclipEndTime: () => number = () => {
		/**
		 * Gets the timestamp in the video at which the screenclip ends
		 * (replaces getVideoEndTime)
		 */

		const edits = props.videoEdits;

		const screenclipStartTime = getScreenclipStartTime();
		const screenclipDuration = getScreenclipsDuration();

		if (!vidRef.current?.duration) return 0;
		if (!edits) return vidRef.current.duration;

		return screenclipStartTime + screenclipDuration;
	};

	const getScreenclipsDuration: () => number = () => {
		var totTime = 0;

		if (!vidRef.current?.duration) {
			//do nothing here
			return 0;
		}

		if (clips && clips.length > 0) {
			const totalClipsTime = clips.reduce(
				(acc, clip) => acc + clip.endTime - clip.startTime,
				0,
			);
			totTime = totalClipsTime;
		} else {
			totTime = vidRef.current.duration;
		}

		return totTime;
	};

	const getVideoDuration: () => number = () => {
		/**
		 * gets the total duration of the video (intro + screenclip + outro - any intersection times)
		 * (replaces getTotalDuration)
		 */

		const edits = props.videoEdits;

		const screenclipStartTime = getScreenclipStartTime();

		var totTime = 0;
		if (!vidRef.current?.duration) {
			//do nothing here
			return 0;
		} else {
			totTime = screenclipStartTime + vidRef.current.duration;

			const totalClipsTime = getScreenclipsDuration();
			totTime = screenclipStartTime + totalClipsTime;

			if (edits.outro && edits.outro.visible) {
				totTime =
					totTime +
					edits.outro.duration -
					(edits.outro.intersectionDuration || 0);
			}
		}

		return totTime;
	};

	const getAdjustedTime: (screenclipTime: number) => number = (
		screenclipTime,
	) => {
		/**
		 * Takes in a timestamp of the screenclip and returns the corresponding time in the final video
		 */

		const screenclipStartTime = getScreenclipStartTime();

		return screenclipStartTime + screenclipTime;
	};

	const getUnadjustedTime: (time: number) => number = (time) => {
		/**
		 * Takes in a time of the final video (including intro and outro)
		 * returns time of screenclip
		 */

		const screenclipStartTime = getScreenclipStartTime();
		return time - screenclipStartTime;
	};

	useEffect(() => {
		if (props.initTime && !loading && vidRef.current) {
			vidRef.current.currentTime = props.initTime;
			const time = props.initTime;
			vidRef.current.addEventListener(
				"seeked",
				async () => {
					if (vidRef.current) {
						console.log("seeked!", time);
						// Since the video is hidden, the render canvas here
						// fails to run since video is not repainted (it is not loaded)
						// as it is hidden. So, we play the video for a moment and pause it
						// This is unreliable and often fails if not for the await.
						// https://stackoverflow.com/questions/9770247/html5-video-timeupdate-event-not-firing
						// https://developer.chrome.com/blog/play-request-was-interrupted
						vidRef.current.muted = true;
						await vidRef.current?.play();
						vidRef.current?.pause();
						vidRef.current.muted = false;
						setCurrentTime(time);
						_setTimelineInitPending(true);
					}
				},
				{ once: true },
			);
		}
	}, [props.initTime, loading]);
	//#endregion

	return (
		<CanvasPlayerContext.Provider
			value={{
				//edits
				videoEdits: props.videoEdits,
				videoSrc: props.videoSrc,
				vidRef: vidRef,
				canvasRef: canvasRef,
				organization: props.organization,

				introVidRef: introVidRef,
				outroVidRef: outroVidRef,
				introImgRef: introImgRef,
				outroImgRef: outroImgRef,
				introLogoRef: introLogoRef,
				outroLogoRef: outroLogoRef,
				clips: clips,
				sources: sources,

				//state variables
				loading: !loadedAllVideos,
				currentTime: currentTime,
				currentTimeRef: currentTimeRef,
				activeClipIndex: activeClipIndex,
				activeClipIndexRef: activeClipIndexRef,
				videoDuration: videoDuration,
				paused: paused,
				pausedRef: pausedRef,
				fullscreen: showFullScreen,
				_hardUpdateTimelinePending: _hardUpdateTimelinePending,
				_timelineInitPending: _timelineInitPending,
				setPlaybackRate: setPlaybackRate,
				setClips: setClips,
				addNewSource: addNewSource,
				setSources: setSources,
				setActiveClipIndex: setActiveClipIndex,

				//controller functions
				play: initPlayHandler,
				pause: initPauseHandler,
				hardsetCurrentTime: hardsetCurrentTime,
				toggleFullscreen: toggleFullScreen,
				_setHardUpdateTimelinePending: _setHardUpdateTimelinePending,
				_setTimelineInitPending: _setTimelineInitPending,

				//timings
				getScreenclipStartTime: getScreenclipStartTime,
				getScreenclipEndTime: getScreenclipEndTime,
				getVideoDuration: getVideoDuration,
				getAdjustedTime: getAdjustedTime,
				getUnadjustedTime: getUnadjustedTime,
				timelineToVideoTime: timelineToVideoTime,
				videoToTimelineTime: videoToTimelineTime,

				//customizers
				// appendCallback: () => {}
			}}
		>
			<>
				{props.children}

				{/* TOTO : Probably make this a map of all video clips. */}

				<video
					ref={vidRef}
					preload="metadata"
					onError={onErrorOut}
					controls={false}
					className="hidden-video videoPlayer-screen"
					onTimeUpdate={() => {
						onTimeUpdate();
					}}
					onPlay={() => {
						"playing the main source again!";
					}}
					crossOrigin="anonymous"
					src={props.videoSrc}
					onEnded={() => {
						// onSourceEnded();
						if (!videoEdits.outro?.visible && audioRef.current) {
							audioRef.current.pause();
						}
					}}
					onCanPlayThrough={() => {
						if (vidRef.current?.readyState == 4) setScreenClipLoaded(true);
					}}

					// onPlay={startRendering}
				/>

				{sources?.map((source, index) => {
					if (source.id.includes("clip_")) {
						//All sources to have id clip_ in it'
						return (
							<video
								key={source.id}
								src={source.src}
								ref={source.ref}
								preload="metadata"
								className="hidden-video videoPlayer-screen"
								crossOrigin="anonymous"
								onTimeUpdate={() => {
									onTimeUpdate(source.id);
								}}
								onCanPlayThrough={(e: any) => {
									onLoadSource(e, source.id);
								}}
							/>
						);
					}
				})}

				{intro?.type == "video" && (
					<video
						ref={introVidRef}
						preload="metadata"
						onError={onErrorOut}
						controls={false}
						className="hidden-video videoPlayer-intro"
						src={intro?.backgroundSrc}
						onCanPlayThrough={() => {
							if (introVidRef.current?.readyState == 4)
								setIntroClipLoaded(true);
						}}
					/>
				)}

				{outro?.type == "video" ? (
					<video
						ref={outroVidRef}
						preload="metadata"
						onError={onErrorOut}
						controls={false}
						className=" hidden-video videoPlayer-outro"
						src={outro.backgroundSrc}
						onEnded={() => {
							if (audioRef.current) {
								audioRef.current.pause();
							}
						}}
						onCanPlayThrough={() => {
							if (outroVidRef.current?.readyState == 4)
								setOutroClipLoaded(true);
						}}
					></video>
				) : null}

				{props.videoEdits.background?.audio?.visible &&
					props.videoEdits.background.audio.src && (
						<audio
							style={{ display: "none" }}
							ref={audioRef}
							preload="metadata"
							controls={false}
							className="VideoPlayer-audio-track"
							crossOrigin="anonymous"
							src={props.videoEdits.background?.audio.src}
							loop={true}
						></audio>
					)}
			</>
		</CanvasPlayerContext.Provider>
	);
};
export default CanvasProvider;
