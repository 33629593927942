const textIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M7.5 8.4C7.5 7.90295 7.90295 7.5 8.4 7.5H15.6C16.097 7.5 16.5 7.90295 16.5 8.4C16.5 8.89705 16.097 9.3 15.6 9.3H12.9V15.6C12.9 16.097 12.497 16.5 12 16.5C11.503 16.5 11.1 16.097 11.1 15.6V9.3H8.4C7.90295 9.3 7.5 8.89705 7.5 8.4Z"
				fill={color}
			/>
			<path
				d="M2.4 4.8C3.72548 4.8 4.8 3.72548 4.8 2.4C4.8 1.07452 3.72548 0 2.4 0C1.07452 0 0 1.07452 0 2.4C0 3.72548 1.07452 4.8 2.4 4.8Z"
				fill={color}
			/>
			<path
				d="M2.4 24C3.72548 24 4.8 22.9255 4.8 21.6C4.8 20.2745 3.72548 19.2 2.4 19.2C1.07452 19.2 0 20.2745 0 21.6C0 22.9255 1.07452 24 2.4 24Z"
				fill={color}
			/>
			<path
				d="M24 2.4C24 3.72548 22.9255 4.8 21.6 4.8C20.2744 4.8 19.2 3.72548 19.2 2.4C19.2 1.07452 20.2744 0 21.6 0C22.9255 0 24 1.07452 24 2.4Z"
				fill={color}
			/>
			<path
				d="M21.6 24C22.9255 24 24 22.9255 24 21.6C24 20.2745 22.9255 19.2 21.6 19.2C20.2744 19.2 19.2 20.2745 19.2 21.6C19.2 22.9255 20.2744 24 21.6 24Z"
				fill={color}
			/>
			<g opacity="0.5">
				<path
					d="M3.3 4.62555C3.02207 4.73805 2.71828 4.80001 2.4 4.80001C2.08172 4.80001 1.77793 4.73805 1.5 4.62555V19.3745C1.77793 19.2619 2.08172 19.2 2.4 19.2C2.71828 19.2 3.02207 19.2619 3.3 19.3745V4.62555Z"
					fill={color}
				/>
				<path
					d="M4.62549 3.3H19.3744C19.2619 3.02207 19.1999 2.71828 19.1999 2.4C19.1999 2.08172 19.2619 1.77793 19.3744 1.5H4.62549C4.73799 1.77793 4.79994 2.08172 4.79994 2.4C4.79994 2.71828 4.73799 3.02207 4.62549 3.3Z"
					fill={color}
				/>
				<path
					d="M20.7 4.62555C20.9779 4.73805 21.2817 4.80001 21.6 4.80001C21.9182 4.80001 22.222 4.73805 22.5 4.62555V19.3745C22.222 19.2619 21.9182 19.2 21.6 19.2C21.2817 19.2 20.9779 19.2619 20.7 19.3745V4.62555Z"
					fill={color}
				/>
				<path
					d="M19.3744 20.7H4.62549C4.73799 20.9779 4.79994 21.2818 4.79994 21.6C4.79994 21.9182 4.73799 22.2221 4.62549 22.5H19.3744C19.2619 22.2221 19.1999 21.9182 19.1999 21.6C19.1999 21.2818 19.2619 20.9779 19.3744 20.7Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};
export default textIcon;
