import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import { useAppSelector } from "@gigauser/common/src/redux";
import {
	redoVideoEdits,
	undoVideoEdits,
} from "@gigauser/common/src/redux/slices/guideSlice";
import { ActiveFormat } from "@gigauser/common/src/redux/slices/platformUiSlice";
import { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";

export function isInTypeableArea(activeFormat: ActiveFormat) {
	const activeElement = document.activeElement;
	const typingElements = ["INPUT", "TEXTAREA"];
	const isTypableArea =
		activeElement?.classList.contains("ProseMirror") ||
		typingElements.includes(activeElement?.tagName ?? "") ||
		activeElement?.getAttribute("contenteditable") ||
		activeFormat === "Article";
	return isTypableArea;
}

export const usePlayPauseListener = () => {
	const cp = useContext(CanvasPlayerContext);
	const activeFormat = useAppSelector(
		(state) => state.platformUi.value.activeFormat,
	);
	const listener = (e: KeyboardEvent) => {
		// to remove the spacebar toggle if an area where typing is possible is focused
		if (isInTypeableArea(activeFormat)) return;

		//check whether the keypress is of space key
		if (e.code === "Space") {
			if (cp.paused) {
				cp.play();
			} else {
				cp.pause();
			}
		}
	};

	return listener;
};

export const useSeekVideoListener = () => {
	const cp = useContext(CanvasPlayerContext);
	const activeFormat = useAppSelector(
		(state) => state.platformUi.value.activeFormat,
	);

	const listener = (event: KeyboardEvent) => {
		// to remove the spacebar toggle if an area where typing is possible is focused
		if (isInTypeableArea(activeFormat)) return;

		// pause the video if its playing
		if (!cp.paused) {
			cp.pause();
		}

		if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
			event.preventDefault();
			if (event.key === "ArrowLeft") {
				cp.hardsetCurrentTime(cp.currentTime - 1 / 24, true);
			} else if (event.key === "ArrowRight") {
				cp.hardsetCurrentTime(cp.currentTime + 1 / 24, true);
			}
		}
	};

	return listener;
};

export const useUndoRedoListener = (
	undoFunction: () => void,
	redoFunction: () => void,
) => {
	const cp = useContext(CanvasPlayerContext);
	const activeFormat = useAppSelector(
		(state) => state.platformUi.value.activeFormat,
	);
	const isMac = useMemo(() => {
		return window.navigator.userAgent.toLowerCase().indexOf("mac") !== -1;
	}, []);

	const listener = (event: KeyboardEvent) => {
		// to remove the shortcut if an area where typing is possible is focused or video is playing
		if (isInTypeableArea(activeFormat) || !cp.paused) return;

		const modifierKey = isMac ? event.metaKey : event.ctrlKey;

		if (modifierKey && event.key === "z") {
			event.preventDefault();
			if (event.shiftKey) {
				redoFunction();
			} else {
				undoFunction();
			}
		}
	};

	return listener;
};
