import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { Device } from "@gigauser/common/src/types/devices";
import { IconMenu } from "@gigauser/common/src/types/menus";
import vertical_dots from "@gigauser/common/src/assets/legacy_assets/icons/vertical_dots.svg";
import thunderbolt from "@gigauser/common/src/assets/legacy_assets/icons/thunderbolt.svg";
import search from "@gigauser/common/src/assets/legacy_assets/icons/search.svg";
import sharing from "@gigauser/common/src/assets/legacy_assets/icons/sharing.svg";
import edit from "@gigauser/common/src/assets/legacy_assets/icons/edit.svg";

import "./TopbarButtons.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import { openFullScreenOverlay } from "@gigauser/common/src/layouts/FullScreenOverlay";
import MarkAsRead from "@gigauser/common/src/assets/legacy_assets/icons/mark_as_read.svg";
import SearchInput from "../../laptop/Searchbar/SearchBox/SearchInput/SearchInput";
import SearchResults from "../../laptop/Searchbar/SearchBox/SearchResults/SearchResults";
import SearchBox, {
	SearchResult,
} from "../../laptop/Searchbar/SearchBox/SearchBox";

import fuzzysort from "fuzzysort";

import EditItemDetails from "../../../../../../features/files/EditItemDetails/EditItemDetails";
import {
	selectCollectionPath,
	editCollectionInPath,
} from "@gigauser/common/src/redux/slices/collectionPathSlice";
import Icon from "@gigauser/common/src/ui/icons/HoverTipIcon/Icon";
import { Collection } from "@gigauser/common/src/core/types/collections";
import logger from "@gigauser/common/src/utils/logger";

type TopbarButtonsProps = {};

const getAllQuery = /* GraphQL */ `
query MyQuery {
    getAll{
      parentID
      permission
      name
      objectID
    }
}
`;

const TopbarButtons: React.FC<TopbarButtonsProps> = () => {
	const dispatch = useAppDispatch();

	const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

	const filesToSearch = useRef();

	const collectionPath = useAppSelector(selectCollectionPath);
	const currFolder = collectionPath.segments.at(-1) as Collection;
	const previousFolder = collectionPath.segments.at(-2) as Collection;

	const runSearch = (searchString: string) => {
		logger.debug("filesToSearch: ", filesToSearch);

		if (!filesToSearch.current) return;

		const fuzzyResults = fuzzysort.go(searchString, filesToSearch.current, {
			key: "name",
		});

		const searchResults = fuzzyResults.map((res) => {
			return {
				highlightedName: fuzzysort.highlight(res, (m, i) => (
					<span className="bolden" key={i}>
						{m}
					</span>
				)),
				// highlightedName: fuzzysort.highlight(res) as string,
				objectUser: res.obj,
			};
		});

		logger.debug("searchResults: ", searchResults);

		// const fileResults: ObjectUser[] = results.map(res => res.obj as ObjectUser)

		// const test = results.map(result =>fuzzysort.highlight(result))
		// logger.debug("highlighted: ", test)

		// logger.debug("results: ", results)
		// setSearchResults(searchResults)
		// return fileResults;
	};

	useEffect(() => {
		// (API.graphql({query: getAllQuery}) as any)
		// .then((resp:any) =>{
		//     logger.debug("got all response: ", resp)
		//     filesToSearch.current = resp.data.getAll
		// })
		// .catch((err:any)=>{
		//     logger.error("couldn't getall: ", err)
		// })
	}, []);

	const editDetailsCallback = (file: Collection) => {
		logger.debug("editDetailsCallback   !", file);
		const { name, description } = file;
		const fileCopy = { ...file };
		fileCopy.name = name;
		fileCopy.description = description;

		// dispatch(editFolderInPath(fileCopy))
	};

	const openEditFolderOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
		event?.preventDefault();
		logger.debug("Need to open overlay");
		// dispatch(openOverlay({
		//     heading: {
		//         icon: edit,
		//         title: "Edit",
		//         data: "" ,
		//     },
		//     component: <EditItemDetails file={currFolder} editDetailsCallback={editDetailsCallback}/>
		// }))
	};

	const openSearchFullScreenOverlay = (
		event: React.MouseEvent<HTMLDivElement>,
	) => {
		event?.preventDefault();
		logger.debug("Need to open overlay");
		logger.debug(
			"Search Results in openSearchFullScreenOverlay: ",
			searchResults,
		);
		dispatch(
			openFullScreenOverlay({
				heading: {
					title: "Search macha",
				},
				component: <SearchBox />,
			}),
		);
	};

	const deviceType = useWindowSize().device;

	const topbarButtons: IconMenu = [
		{
			icon: vertical_dots,
			exclusiveTo: "mobile",
			handler: openEditFolderOverlay,
		},
		{
			icon: sharing,
			exclusiveTo: "mobile",
			handler: () => {},
		},
		{
			icon: search,
			exclusiveTo: "mobile",
			handler: openSearchFullScreenOverlay,
		},
	];

	if (previousFolder == undefined) {
		topbarButtons.splice(0, 2);
	}

	return (
		<div className={`TopbarButtons ${deviceType}`}>
			{topbarButtons.map((item) => {
				if (item.exclusiveTo && item.exclusiveTo !== deviceType) return null;
				else {
					return (
						<Icon
							className="TopbarButtons-icon"
							hoverTip={item.hoverTip}
							src={item.icon}
							onClick={(e) => item.handler(e)}
						/>
					);
				}
			})}
		</div>
	);
};

export default TopbarButtons;
