import React, { useEffect, useState } from "react";
import "./Bin.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import GuidesTable from "@gigauser/common/src/components/guides/GuidesTable/GuidesTable";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import logger from "@gigauser/common/src/utils/logger";
import trash from "@gigauser/common/src/assets/icons/trash.svg";
import SectionScroller from "@gigauser/common/src/layouts/SectionScroller/SectionScroller";
import { setDeletedGuidesFrontend } from "@gigauser/common/src/redux/slices/collectionDataSlice";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import RestorePopup from "../../features/files/RestorePopup/RestorePopup";

type BinProps = {};

const Bin: React.FC<BinProps> = () => {
	const [loading, setLoading] = useState(true);

	const saver = useAppSelector(selectSaver);
	const dispatch = useAppDispatch();

	const initDeletedGuides = async () => {
		const res = await saver.fetchAllGuidePreviews(false);
		const deletedGuides = res.filter((gp) => gp.deleted === true).sort((a,b)=>Number(b.deleteAt?.getTime())-Number(a.deleteAt?.getTime()));
		dispatch(setDeletedGuidesFrontend(deletedGuides));
		setLoading(false);
	};

	useEffect(() => {
		initDeletedGuides();
	}, []);

	const deletedGuides = useAppSelector(
		(state) => state.collectionData.allDeletedGuides,
	);

	const onClickGuide = (guide: GuidePreview) => {
		window.open("/guide/" + guide.id + "", "_blank");
		// alert("Please use the chrome extension to open guides. Opening on this platform is coming soon! ")
	};

	const onRestoreGuide = (guide: GuidePreview) => {
		logger.debug("onRestoreGuide: ", guide);

		dispatch(
			openOverlay({
				heading: {
					icon: undefined,
					title: "Restore guide :",
					data: guide.header.name,
				},
				component: <RestorePopup type="Guide" entry={guide} />,
			}),
		);
	};

	return (
		<div className="Bin">
			{loading ? (
				<p>Loading ...</p>
			) : (
				<SectionScroller
					sections={[
						{
							icon: trash,
							header: "Deleted Projects",
							contents: (
								<GuidesTable
									type="Guide"
									entries={deletedGuides}
									onDelete={() => {}}
									onClick={onClickGuide}
									showFileContextMenu={() => {}}
									onRestore={onRestoreGuide}
								/>
							),
						},
					]}
				/>
			)}
		</div>
	);
};
export default Bin;
