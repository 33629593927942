import React from "react";

type TimelineContextValue = {
	getXCoord: (clientX: number) => number;
	setTimelineTimeByPosition: (offsetX: number) => void;
	timeToPosition: (time: number) => number;
	positionToTime: (position: number) => number;
};

const TimelineContext = React.createContext<TimelineContextValue>({
	getXCoord: (clientX: number) => 0,
	setTimelineTimeByPosition: (x) => {},
	timeToPosition: (time: number) => 0,
	positionToTime: (position: number) => 0,
});

export default TimelineContext;
