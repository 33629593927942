import React, { useContext } from "react";
import "./Files.css";
import folder from "@gigauser/common/src/assets/legacy_assets/icons/folder_color.svg";
import pages from "@gigauser/common/src/assets/legacy_assets/icons/papers_color.svg";
import notebooks from "@gigauser/common/src/assets/legacy_assets/icons/notebook_color.svg";
import GuidesTable from "@gigauser/common/src/components/guides/GuidesTable/GuidesTable";

// import {foldersData, readingData, writingData} from './data'
import SectionScroller from "@gigauser/common/src/layouts/SectionScroller/SectionScroller";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import useFilesData from "./useFilesData";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";

import { openReading, openWriting } from "../../../../features/files/openFile";
import empty_folder from "@gigauser/common/src/assets/legacy_assets/images/empty-folder.png";
import empty_shared from "@gigauser/common/src/assets/legacy_assets/images/empty_shared.png";

import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import useCollectionNavigate from "@gigauser/common/src/redux/hooks/FileHooks/useCollectionNavigate";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import { GigaUserApi } from "@giga-user-fern/api";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import DeletePopup from "../../features/files/DeletePopup/DeletePopup";
import trash from "@gigauser/common/src/assets/icons/trash.svg";
import useItemContextMenu from "../../../../features/files/useItemContextMenu";
import useReorderItem from "@gigauser/common/src/redux/hooks/FileHooks/useReorderItem";
import logger from "@gigauser/common/src/utils/logger";

type FilesProps = {};

const Files: React.FC<FilesProps> = () => {
	const env = useContext(EnvironmentContext);

	const navigate = useCollectionNavigate(env);
	const dispatch = useAppDispatch();
	const backendReorderItem = useReorderItem();

	const onClickCollection = (collection: Collection) => {
		logger.debug("collection clicked: ", collection);
		navigate(collection);
	};

	const onClickGuide = (guide: GuidePreview) => {
		logger.debug("got guide: ", guide);
		window.open("/guide/" + guide.id + "", "_blank");
		// alert("Please use the chrome extension to open guides. Opening on this platform is coming soon! ")
	};

	const onDeleteGuide = (guide: GuidePreview) => {
		logger.debug("onDeleteGuide: ", guide);

		dispatch(
			openOverlay({
				heading: {
					icon: trash,
					title: "Delete project ",
					data: guide.header.name,
				},
				component: <DeletePopup type="Guide" entry={guide} />,
			}),
		);
	};

	const onDeleteCollection = (c: Collection) => {
		dispatch(
			openOverlay({
				heading: {
					icon: trash,
					title: "Delete collection ",
					data: c.name,
				},
				component: <DeletePopup type="Collection" entry={c} />,
			}),
		);
	};

	const onReorderGuide = (sourceIndex: number, destinationIndex: number) => {
		const succeedingGuide = guidesData[sourceIndex];
		var precedingGuide: undefined | GuidePreview;

		if (destinationIndex > 0) {
			precedingGuide = guidesData[destinationIndex - 1];
		}

		if (precedingGuide) {
			backendReorderItem({
				type: "Guide",
				succeedingItem: succeedingGuide,
				precedingItem: precedingGuide,
			});
		} else {
			backendReorderItem({ type: "Guide", succeedingItem: succeedingGuide });
		}
	};

	const onReorderCollection = (
		sourceIndex: number,
		destinationIndex: number,
	) => {
		const succeedingCollection = collectionsData[sourceIndex];
		var precedingCollection: undefined | Collection;

		if (destinationIndex > 0) {
			precedingCollection = collectionsData[destinationIndex - 1];
		}

		if (precedingCollection) {
			backendReorderItem({
				type: "Collection",
				succeedingItem: succeedingCollection,
				precedingItem: precedingCollection,
			});
		} else {
			backendReorderItem({
				type: "Collection",
				succeedingItem: succeedingCollection,
			});
		}
	};

	const showFileContextMenu = useItemContextMenu();

	const { collectionsData, guidesData, loading } = useFilesData(env);

	const currentCollection = useAppSelector(
		(state) => state.collectionData.currentCollectionData.currentCollection,
	);

	const pinnedGuides = guidesData.filter(
		(guidePreview) => guidePreview.isPinned,
	);

	const pinnedSection = (
		<GuidesTable
			type="Guide"
			entries={pinnedGuides}
			onClick={onClickGuide}
			onDelete={onDeleteGuide}
			showFileContextMenu={showFileContextMenu}
		/>
	);
	const collectionSection = (
		<GuidesTable
			type="Collection"
			entries={collectionsData}
			onClick={onClickCollection}
			onDelete={onDeleteCollection}
			showFileContextMenu={showFileContextMenu}
		/>
	);
	const guideSection = (
		<GuidesTable
			type="Guide"
			entries={guidesData.filter((guidePreview) => !guidePreview.isPinned)}
			onClick={onClickGuide}
			onDelete={onDeleteGuide}
			showFileContextMenu={showFileContextMenu}
		/>
	);

	const device = useWindowSize().device;

	const isEmpty = !(collectionSection || guideSection);

	var sections = [
		{
			icon: folder,
			header: "Folders",
			contents: collectionSection,
		},
		{
			icon: pages,
			header: "Projects",
			contents: guideSection,
		},
	];

	if (pinnedGuides.length) {
		sections = [
			{
				icon: pages,
				header: "Pinned Project",
				contents: pinnedSection,
			},
			...sections,
		];
	}

	return (
		<div className={`Files ${device} ${isEmpty ? "empty" : ""} `}>
			{loading && "Loading ..."}
			{!loading && <SectionScroller sections={sections} />}
			{!loading && isEmpty ? (
				<img
					src={
						currentCollection.id === "Folder_shared"
							? empty_shared
							: empty_folder
					}
					className="empty-folder"
				></img>
			) : null}
		</div>
	);
};

export default Files;
