const outroIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 32 32"
			fill="none"
		>
			<rect
				x="5"
				y="9"
				width="26"
				height="20"
				rx="2"
				stroke={color}
				stroke-width="1.75"
			/>
			<path
				d="M26 9.13514V6C26 4.89543 25.1046 4 24 4H3C1.89543 4 1 4.89543 1 6V21C1 22.1046 1.89543 23 3 23H5.16667"
				stroke={color}
				stroke-width="1.75"
			/>
			<circle cx="19" cy="15" r="2.4" stroke={color} stroke-width="1.2" />
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M5.53033 19.0303L9.9526 14.608L17.1219 23.1216C17.549 23.6287 18.2977 23.7142 18.8281 23.3164L23.9687 19.4609L30.5119 25.0694L31.4881 23.9305L24.7926 18.1915C24.3478 17.8102 23.6978 17.7891 23.2291 18.1406L18.1171 21.9746L10.9245 13.4335C10.4527 12.8732 9.60246 12.8368 9.08452 13.3548L4.5 17.9393V17.9999L5.53033 19.0303Z"
				fill={color}
			/>
		</svg>
	);
};
export default outroIcon;
