import { useToast } from "@chakra-ui/react";
import copyIcon from "@gigauser/common/src/assets/svgs/copyIcon";
import externalLinkIcon from "@gigauser/common/src/assets/svgs/externalLinkIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import React, { useState } from "react";
import "./LinkCopy.css";

type LinkCopyProps = {};

export const LinkCopy: React.FC<{ link: string }> = (props) => {
	const [linkCopied, setLinkCopied] = useState<"embed" | "link" | null>(null); //true if the id was copied to clipboard
	const toast = useToast();

	const onCopy = () => {
		navigator.clipboard
			.writeText(`${props.link}`)
			.then(() => {
				// setLinkCopied("link")
				// setTimeout(() =>{
				//     setLinkCopied(null)
				// }, 3000)

				toast({
					title: `Link copied to clipboard.`,
					status: "success",
					duration: 3000,
					position: "bottom",
					isClosable: true,
				});
			})
			.catch((error) => {
				alert("Error copying to clipboard!");
			});

		// e.preventDefault()
		// e.stopPropagation()
	};

	const onCopyEmbedCode = (e: any) => {
		const embedLink = props.link
			.replace("guide", "embed")
			.replace("share", "embed");

		const embedCode = `
        
        <div style="position: relative; padding-bottom: 207.50000000000003%; height: 0;">
            <iframe src="${embedLink}" frameborder="0" 
            webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe></div>

        `;

		navigator.clipboard
			.writeText(embedCode)
			.then(() => {
				setLinkCopied("embed");
				setTimeout(() => {
					setLinkCopied(null);
				}, 3000);
			})
			.catch((error) => {
				alert("Error copying to clipboard!");
			});

		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<div className="link-copy">
			<div className="link-copy-input" onClick={onCopy}>
				<div className="link-copy-input-text">{props.link}</div>
			</div>
			<Icon
				className="link-copy-icon"
				hoverTip="Open link"
				onClick={() => {
					window.open(props.link, "_blank");
				}}
			>
				{externalLinkIcon("#bfc7d4")}
			</Icon>
			{linkCopied ? (
				<div
					className={`gigauser-id-copiedPopup guide-settings-copied-popup gigauser-copied-${linkCopied}`}
				>
					Copied!
				</div>
			) : null}
			{/* <Cutton size='s' leftIcon={copyIcon("white")} onClick={onCopy}> Copy</Cutton> */}
			<Icon className="link-copy-icon" hoverTip="Copy link" onClick={onCopy}>
				{copyIcon("#bfc7d4")}
			</Icon>
			{/* <Icon className='link-copy-icon' hoverTip='Copy embed code' onClick={onCopyEmbedCode}>{embedIcon("#bfc7d4")}</Icon> */}
		</div>
	);
};
