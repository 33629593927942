import React, { useState } from "react";
import VideoEditorPlatform from "./VideoEditorPlatform";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectGuidePreview,
	selectVideo,
	selectVideoEdits,
} from "@gigauser/common/src/redux/slices/guideSlice";
import CanvasProvider from "@gigauser/common/src/core/canvas/CanvasProvider";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import useRefreshVideo from "@gigauser/common/src/redux/hooks/FileHooks/useRefreshVideo";

type VideoEditorMasterProps = {
	visible: boolean;
};

const VideoEditorMaster: React.FC<VideoEditorMasterProps> = (props) => {
	/**
	 * This component's sole purpose is to decide between AiMode or not for the video.
	 *
	 * TODO: After we move the speed up rendering logic to frontend from backend, we can get rid of this component.
	 *       Because the videoSrc will not change between AiMode and non AiMode.
	 */

	//guide related data
	const video = useAppSelector(selectVideo);
	const refreshVideo = useRefreshVideo();
	//Video src setting
	const generatedVideo = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.generatedSrc,
	);
	const generatedVideoExists = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.generated,
	);
	const videoEdits = useAppSelector(selectVideoEdits);

	const saver = useAppSelector(selectSaver);

	const isAiVideoPossible = generatedVideoExists ? true : false;

	const [AiMode, setAiMode] = useState(isAiVideoPossible ? true : false);

	if (!video) {
		return <div>Waiting for video in VideoEditorMaster ...</div>;
	}

	const originalSrc = video.originalSrc as string;
	var aiSrc = generatedVideo || originalSrc;

	const videoSrc: string = AiMode ? aiSrc : originalSrc;

	return (
		<CanvasProvider
			videoSrc={videoSrc}
			videoEdits={videoEdits as VideoEdits}
			organization={saver.organization}
			onError={refreshVideo}
		>
			<div
				className="VideoEditorMaster"
				style={{ display: props.visible ? "block" : "none" }}
			>
				<VideoEditorPlatform setAiMode={setAiMode} AiMode={AiMode} />
			</div>
		</CanvasProvider>
	);
};

export default VideoEditorMaster;
