import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
	BrowserRouter,
	RouterProvider,
	createBrowserRouter,
	useNavigate,
} from "react-router-dom";
import { routes } from "./pages/routes";

import Auth0ProviderWithNavigate from "@gigauser/common/src/auth/Auth0ProviderWithNavigate";
import { rootCollection } from "@gigauser/common/src/types/files";
import { Collection } from "@giga-user-fern/api/types/api/resources/collections";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import { setBranding } from "@gigauser/common/src/core/utils/styleUtils";
import logger from "@gigauser/common/src/utils/logger";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { ConfigProvider, theme, Button, Card } from "antd";

// This is to initialise the path from the URL
// getPathFromRoute().then((newPath) => {
//     store.dispatch(updateCollectionPath(newPath));
// }).catch(()=>{});
import * as Sentry from "@sentry/react";
import { initializeAnalytics } from "@gigauser/common/src/core/analytics/analytics";
import customTheme from "./ChakraTheme";

if (process.env.NODE_ENV !== "development") {
	Sentry.init({
		dsn: "https://b57df54d59199bbe3e0c991ac3ab56df@o4506073118867456.ingest.sentry.io/4506073288081408",
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ["localhost", /^https:\/\/api\.clueso\.io\//],
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
	});
}

const { defaultAlgorithm, darkAlgorithm } = theme;

logger.setLevel(process.env.NODE_ENV === "development" ? "debug" : "error");

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter(routes);

setBranding("#d43f8c");

root.render(
	<React.StrictMode>
		{/* <Auth0ProviderWithNavigate> */}
		<EnvironmentContext.Provider
			value={{
				name: "platform",
				navigator: useNavigate,
			}}
		>
			<ChakraProvider theme={customTheme}>
				<ConfigProvider theme={{ algorithm: darkAlgorithm }}>
					<ColorModeScript initialColorMode="dark" />
					<RouterProvider router={router} />
				</ConfigProvider>
			</ChakraProvider>
		</EnvironmentContext.Provider>
		{/* </Auth0ProviderWithNavigate> */}
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
