export const uploadIcon = (color: string) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.41513 1.87044C9.30855 1.75389 9.15795 1.6875 9 1.6875C8.84205 1.6875 8.69145 1.75389 8.58488 1.87044L5.58486 5.15169C5.37524 5.38096 5.39117 5.73676 5.62044 5.94639C5.84973 6.15602 6.20552 6.14009 6.41514 5.91081L8.4375 3.69885V12C8.4375 12.3106 8.68935 12.5625 9 12.5625C9.31065 12.5625 9.5625 12.3106 9.5625 12V3.69885L11.5849 5.91081C11.7945 6.14009 12.1503 6.15602 12.3796 5.94639C12.6089 5.73676 12.6248 5.38096 12.4151 5.15169L9.41513 1.87044Z"
				fill={color}
			/>
			<path
				d="M2.8125 11.25C2.8125 10.9393 2.56067 10.6875 2.25 10.6875C1.93934 10.6875 1.6875 10.9393 1.6875 11.25V11.2912C1.68749 12.3169 1.68747 13.1436 1.77489 13.7938C1.86566 14.4689 2.05982 15.0373 2.51126 15.4887C2.9627 15.9402 3.53111 16.1344 4.20619 16.2251C4.85642 16.3125 5.68316 16.3125 6.70885 16.3125H11.2912C12.3169 16.3125 13.1436 16.3125 13.7938 16.2251C14.4689 16.1344 15.0373 15.9402 15.4888 15.4887C15.9402 15.0373 16.1344 14.4689 16.2251 13.7938C16.3125 13.1436 16.3125 12.3169 16.3125 11.2912V11.25C16.3125 10.9393 16.0606 10.6875 15.75 10.6875C15.4394 10.6875 15.1875 10.9393 15.1875 11.25C15.1875 12.3265 15.1863 13.0774 15.1102 13.6439C15.0361 14.1943 14.9008 14.4856 14.6932 14.6932C14.4857 14.9008 14.1943 15.0361 13.6439 15.1102C13.0774 15.1863 12.3266 15.1875 11.25 15.1875H6.75C5.67344 15.1875 4.9226 15.1863 4.35609 15.1102C3.80576 15.0361 3.51433 14.9008 3.30676 14.6932C3.09919 14.4856 2.96385 14.1943 2.88986 13.6439C2.81369 13.0774 2.8125 12.3265 2.8125 11.25Z"
				fill={color}
			/>
		</svg>
	);
};
