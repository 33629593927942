import { useContext } from "react";
import { CMenuContext } from "@gigauser/common/src/contexts";
import { Item } from "@gigauser/common/src/types/files";
import edit from "@gigauser/common/src/assets/legacy_assets/icons/edit.svg";
import pin from "@gigauser/common/src/assets/legacy_assets/icons/pin.svg";

import move from "@gigauser/common/src/assets/legacy_assets/icons/move.svg";
import trash from "@gigauser/common/src/assets/legacy_assets/icons/trash.svg";
import eye from "@gigauser/common/src/assets/icons/eye.svg";
import eye_closed from "@gigauser/common/src/assets/icons/eye_closed.svg";

import { useAppDispatch } from "@gigauser/common/src/redux";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import EditItemDetails from "./EditItemDetails/EditItemDetails";

import FileExplorer from "../../pages/home/features/FileExplorer/FileExplorer";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import DeletePopup from "../../pages/home/features/files/DeletePopup/DeletePopup";
import logger from "@gigauser/common/src/utils/logger";
import { useEditItem } from "@gigauser/common/src/redux/hooks/FileHooks";
import usePinGuide from "@gigauser/common/src/redux/hooks/FileHooks/usePinGuide";
import useSetCollectionVisibility from "@gigauser/common/src/redux/hooks/FileHooks/useSetCollectionVisibility";
import { saver } from "@gigauser/common/src/network/saver";
import { addGuideToCollection } from "@gigauser/common/src/redux/slices/collectionDataSlice";
import {
	showLoadingMessagePopup,
	showMessagePopup,
} from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import copy from "@gigauser/common/src/assets/icons/copy.svg";

const useItemContextMenu: () => (file: Item, X: number, Y: number) => void =
	() => {
		const cMenuContext = useContext(CMenuContext);
		const { showContextMenu } = cMenuContext;
		const dispatch = useAppDispatch();
		const pinGuide = usePinGuide();
		const setCollectionVisibility = useSetCollectionVisibility();

		return (file: Item, X: number, Y: number) => {
			logger.debug("contextMenu!");

			const fileType = file.type;

			var name: string;

			if (fileType === "Collection") {
				name = file.entry.name;
			} else if (fileType === "Guide") {
				name = file.entry.header.name;
			}

			showContextMenu({
				show: true,
				menuArray: [
					{
						icon: edit,
						label: `Edit title & description`,
						handler: () => {
							dispatch(
								openOverlay({
									heading: {
										icon: edit,
										title: `Edit ${fileType}`,
										data: name,
									},
									component: <EditItemDetails file={file} />,
								}),
							);
						},
					},
					...(fileType === "Collection"
						? [
								{
									icon: file.entry.private ? eye : eye_closed,
									label: `Make ${file.entry.private ? "public" : "private"} `,
									handler: () => {
										setCollectionVisibility(file.entry, !file.entry.private);
									},
								},
							]
						: []),
					...(fileType === "Guide"
						? [
								{
									icon: copy,
									label: "Duplicate",
									handler: async () => {
										dispatch(showLoadingMessagePopup());
										const resp = await saver.duplicateGuide(file.entry.id);
										if (resp.ok) {
											dispatch(addGuideToCollection(resp.body));
											dispatch(showMessagePopup("Duplication Complete!"));
											window.open("/guide/" + resp.body.id);
										} else {
											return;
										}
									},
								},
							]
						: []),
					...(fileType === "Guide"
						? [
								{
									icon: pin,
									label: file.entry.isPinned ? "Unpin" : "Pin as preview",
									handler: () => {
										pinGuide(file.entry.isPinned ? false : true, file.entry);
									},
								},
							]
						: []),

					{
						icon: move,
						label: "Move",
						handler: () => {
							dispatch(
								openOverlay({
									heading: {
										icon: move,
										title: "Move",
										data: name,
									},
									component: (
										<FileExplorer
											action="move"
											item={file}
											// object={
											//     {objectData: file, fileType:fileType} as DocumentInfoProps
											// }
											// disabledItems={fileType==="Writing"?["Writing"]:["Reading", "Writing"]}
										/>
									),
								}),
							);
						},
					},

					{ type: "separator" },
					{
						icon: trash,
						type: "danger",
						label: "Delete",
						handler: () => {
							dispatch(
								openOverlay({
									heading: {
										icon: trash,
										title: `Delete ${fileType} `,
										data: name,
									},
									component: <DeletePopup {...file} />,
								}),
							);
						},
					},
				],
				X: X,
				Y: Y,
			});
		};
	};

export default useItemContextMenu;
