import React from "react";
import plus from "@gigauser/common/src/assets/legacy_assets/icons/plus.svg";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import { useAppDispatch } from "@gigauser/common/src/redux";
import NewButtonOverlay from "./NewButtonOverlay/NewButtonOverlay";

import "./NewButton.mobile.css";
import logger from "@gigauser/common/src/utils/logger";

type NewButtonProps = {
	openCreateFolderOverlay: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const NewButton: React.FC<NewButtonProps> = ({ openCreateFolderOverlay }) => {
	const dispatch = useAppDispatch();

	const openNewButtonOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
		event?.preventDefault();
		logger.debug("Need to open overlay");
		dispatch(
			openOverlay({
				heading: undefined,
				component: <NewButtonOverlay />,
			}),
		);
	};

	return (
		<div className="NewButton mobile" onClick={openNewButtonOverlay}>
			<img src={plus} className="NewButton-icon"></img>
		</div>
	);
};
export default NewButton;
