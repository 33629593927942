import React, { useEffect } from "react";
import { closeOverlay, OverlayComponent } from "../overlaySlice";
import close from "../../../assets/icons/close.svg";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import "./Overlay.laptop.css";
import Icon from "../../../ui/Icon/Icon";
import Cutton from "../../../ui/buttons/Cutton/Cutton";
import tickIconCircled from "../../../assets/svgs/tickIconCircled";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";

type OverlayLaptopProps = {
	overlay: OverlayComponent;
};

const OverlayLaptop: React.FC<OverlayLaptopProps> = ({ overlay }) => {
	const dispatch = useAppDispatch();

	const { disableClose } = useAppSelector((state) => state.overlay.value);

	return (
		<div className={`OverlayLaptop`}>
			<div className="Overlay-container">
				{overlay?.heading ? (
					<div
						className={`Overlay-heading ${overlay.heading.hideSeperator ? "overlay-heading-no-border" : ""}`}
					>
						<div className="Overlay-heading-left-part">
							{typeof overlay.heading.icon === "string" ? (
								<img
									src={overlay.heading?.icon}
									className="Overlay-heading-icon"
								></img>
							) : (
								<Icon className="Overlay-heading-icon">
									{overlay.heading.icon}
								</Icon>
							)}
							<div className="Overlay-heading-title">
								<b>{overlay.heading.title}</b> {overlay.heading.data}
							</div>
						</div>
						<img
							onClick={() => {
								if (!disableClose) dispatch(closeOverlay());
							}}
							className={`Overlay-close-icon ${disableClose ? "disabled" : "enabled"}`}
							src={close}
						></img>
					</div>
				) : null}

				<div className="Overlay-contents">
					{overlay?.heading ? null : (
						<img
							onClick={() => {
								if (!disableClose) dispatch(closeOverlay());
							}}
							className={`Overlay-close-icon ${disableClose ? "disabled" : "enabled"}`}
							src={close}
						></img>
					)}
					{overlay?.component}
				</div>
			</div>
		</div>
	);
};
export default OverlayLaptop;
