import React from "react";
import { Organization } from "../../core/types/guide";
import "./ProfilePreview.css";

type ProfilePreviewProps = {
	organization: Organization;
	active: boolean;
	onClick: () => void;
};

const ProfilePreview: React.FC<ProfilePreviewProps> = ({
	organization,
	active,
	onClick,
}) => {
	if (!organization) {
		return <></>;
	}
	const nameSplit = organization.name;
	const initials = nameSplit[0];

	return (
		<div
			className={`gigauser-ProfilePreview ${active ? "active" : ""}`}
			onClick={onClick}
		>
			<div className="ProfilePreview-dp">{initials}</div>
			<div className="ProfilePreview-account">
				<div className="account-details">
					<div className="name">{organization.name}</div>
					<div className="email">{organization.domain}</div>
				</div>
			</div>
		</div>
	);
};
export default ProfilePreview;
