import React from "react";
import "./CAlign.css";
import leftAlign from "../../../../assets/svgs/text-editor-toolbar/left-align";
import centerAlign from "../../../../assets/svgs/text-editor-toolbar/center-align";
import rightAlign from "../../../../assets/svgs/text-editor-toolbar/right-align";
import Icon from "../../../Icon/Icon";
import { TextAlignment } from "@giga-user-fern/api/types/api";

type CAlignProps = {
	label: string;
	value: TextAlignment;
	setterFunction: (value: TextAlignment) => void;
	disabled?: boolean;

	disabledOptions?: TextAlignment[];
};

const CAlign: React.FC<CAlignProps> = (props) => {
	const iconColor = "#c2c1c1";

	const alignmentOptions: {
		label: TextAlignment;
		icon: JSX.Element;
		disabled?: boolean;
	}[] = [
		{
			label: "left",
			icon: leftAlign(iconColor),
			disabled: props.disabledOptions?.includes("left"),
		},
		{
			label: "center",
			icon: centerAlign(iconColor),
			disabled: props.disabledOptions?.includes("center"),
		},
		{
			label: "right",
			icon: rightAlign(iconColor),
			disabled: props.disabledOptions?.includes("right"),
		},
	];

	return (
		<div className="CAlign CToggle">
			<div>
				<div className="C-label CToggle-label">{props.label}</div>
			</div>

			<div className="texteditor-options-container texteditor-alignment">
				{alignmentOptions.map((align) => {
					return (
						<Icon
							onClick={
								align.disabled
									? () => {}
									: () => {
											props.setterFunction(align.label);
										}
							}
							className={`align-icon ${props.value === align.label ? "active" : "inactive"} ${align.disabled ? "disabled" : "enabled"}`}
						>
							{align.icon}
						</Icon>
					);
				})}
			</div>
		</div>
	);
};
export default CAlign;
