import React from "react";
import {
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Spinner,
} from "@chakra-ui/react";
import "./ZoomSlider.css";
import Icon from "../../Icon/Icon";
import zoomoutIcon from "../../../assets/svgs/screenshot/zoomoutIcon";
import zoominIcon from "../../../assets/svgs/screenshot/zoominIcon";

type ZoomSliderProps = {
	timelineSliderValue: number;
	setTimelineSliderValue: (number: number) => void;
};

const ZoomSlider: React.FC<ZoomSliderProps> = (props) => {
	return (
		<div
			className="VideoPlayer-slider"
			style={
				{
					// display: cp.videoDuration > 30?"block":"none"
				}
			}
		>
			<Icon className="VideoPlayer-zoomIcon">{zoomoutIcon("#c2c1c1")}</Icon>

			<Slider
				focusThumbOnChange={false}
				value={props.timelineSliderValue}
				onChange={props.setTimelineSliderValue}
				className="VPSlider-Slider"
				min={0}
				max={100}
				step={0.05}
			>
				<SliderTrack className="VPSlider-track">
					<SliderFilledTrack className="VPSlider-filledtrack" />
				</SliderTrack>
				<SliderThumb className="VPSlider-thumb" fontSize="sm" boxSize="12px" />
			</Slider>

			<Icon className="VideoPlayer-zoomIcon">{zoominIcon("#c2c1c1")}</Icon>
		</div>
	);
};
export default ZoomSlider;
