import React, { useEffect, useRef, useState } from "react";
import "./TranslateGuide.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";

import {
	closeMessagePopup,
	showErrorMessagePopup,
	showLoadingMessagePopup,
} from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import translateIcon from "@gigauser/common/src/assets/svgs/videoeditor/translateIcon";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";
import { Language } from "@giga-user-fern/api/types/api";
import { getFlag } from "@gigauser/common/src/utils/voices/voiceUtils";
import premiumIcon from "@gigauser/common/src/assets/svgs/premiumIcon";
import { closeModal } from "@gigauser/common/src/layouts/CModal/modalSlice";
import CSearch from "@gigauser/common/src/ui/inputs/CSearch/CSearch";

type TranslateGuideProps = {};

const TranslateGuide: React.FC<TranslateGuideProps> = () => {
	const dispatch = useAppDispatch();
	const id = useAppSelector((state) => state.guide.value.guide?.id);
	const saver = useAppSelector(selectSaver);
	const [translating, setTranslating] = useState(false);

	const [allLanguages, setAllLanguages] = useState<Language[]>([]);
	const [listedLanguages, setListedLanguages] = useState<Language[]>([]);

	const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(
		null,
	);

	var availableLanguageStrings =
		useAppSelector(
			(state) => state.platformDetails.value?.translatableLanguages,
		) || [];

	var availableLanguages: Language[] = availableLanguageStrings
		.map((lang: string) => {
			return allLanguages.find((language) => language.name === lang);
		})
		.filter(Boolean) as Language[];

	var unavailableLanguages: Language[] = allLanguages.filter(
		(lang: Language) => {
			return !availableLanguages.includes(lang);
		},
	);

	// availableLanguages = availableLanguages.sort((a, b) =>
	// 	a.name.localeCompare(b.name),
	// );
	// unavailableLanguages = unavailableLanguages.sort((a, b) =>
	// 	a.name.localeCompare(b.name),
	// );

	const sortedLanguages = listedLanguages.sort((a, b) =>
		a.name.localeCompare(b.name),
	);

	const initAllLanguages = async () => {
		const allLanguages = await saver.getAllLanguages();
		setAllLanguages(allLanguages);
		setListedLanguages(allLanguages);
	};

	useEffect(() => {
		initAllLanguages();
	}, []);

	const onTranslate = async () => {
		if (!id || !selectedLanguage) return;

		setTranslating(true);

		dispatch(showLoadingMessagePopup("Translating..."));

		saver.autoTranslateGuide(id, selectedLanguage).then((resp) => {
			if (resp.ok) {
				window.open("/guide/" + resp.body, "_blank");
				dispatch(closeMessagePopup());
			} else {
				dispatch(
					showErrorMessagePopup("Translate failed. Please try again later."),
				);
			}
		});

		dispatch(closeModal());

		// setShowMoreOptions(false)
	};

	return (
		<div className="TranslateGuide gigauser-dark">
			{/* <div className='TranslateTitle'>
                Translate your video and article across {allLanguages.length} languages
            </div> */}

			<div className="TG-help">
				Translate your video and article to any language below:
			</div>

			<CSearch
				placeholder="Search for a language ..."
				searchableItems={allLanguages}
				handleResults={setListedLanguages}
			/>

			<div className="Languages">
				{sortedLanguages.length ? (
					<>
						{sortedLanguages.map((lang: Language, index: number) => {
							const isAvailable = availableLanguageStrings.includes(lang.name);

							return (
								<div
									key={index}
									className={`LanguageItem ${isAvailable ? "available" : "unavailable"} ${selectedLanguage?.languageId == lang.languageId ? "active" : "inactive"}`}
									onClick={
										isAvailable
											? () => {
													setSelectedLanguage(lang);
												}
											: undefined
									}
								>
									<Icon className="flag">{getFlag(lang.languageId).flag}</Icon>
									<div className="LanguageName">{lang.name}</div>
									{!isAvailable && (
										<Icon className="premiumIcon">{premiumIcon()}</Icon>
									)}
								</div>
							);
						})}
					</>
				) : (
					<div className="NoResults">No Results</div>
				)}
			</div>

			<div className="TG-footer">
				<div className="TG-footer-msg">
					{unavailableLanguages.length ? (
						<>
							<span>Your plan does not include 👑 languages.</span>
							<a className="TG-footer-contact" href="mailto:hello@clueso.io">
								Contact us
							</a>
						</>
					) : null}
				</div>
				<div className="Translate-buttons">
					<Cutton rank="secondary" onClick={() => dispatch(closeModal())}>
						Cancel
					</Cutton>

					<Cutton
						disabled={!selectedLanguage}
						leftIcon={translateIcon("white")}
						onClick={onTranslate}
						loadingText="Translating..."
						isLoading={translating}
					>
						Translate
					</Cutton>
				</div>
			</div>
		</div>
	);
};
export default TranslateGuide;
