import React, { useState } from "react";
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuItemOption,
	MenuGroup,
	MenuOptionGroup,
	MenuDivider,
	Button,
} from "@chakra-ui/react";
import "./CDropdown.css";

import downArrowheadIcon from "../../../assets/svgs/downArrowheadIcon";

import { DisplayMenuItem } from "../../menus/Menu/Menu";
import Clickout from "../../../layouts/Clickout/Clickout";

type CDropdownProps = {
	leftIcon?: React.ReactElement;
	menu: DisplayMenuItem[];
	activeLabel?: string;

	position?: "top" | "bottom";
};

const CDropdown: React.FC<CDropdownProps> = (props) => {
	const [position, setPosition] = useState<"top" | "bottom">(
		props.position || "top",
	);

	const [showDropdown, setShowDropdown] = useState(false);

	const activeMenuItem = props.menu.find((m) => m.label === props.activeLabel);
	const activeMenuItemLabel = activeMenuItem
		? activeMenuItem.label
		: props.menu[0].label;

	return (
		<div className="CDropdown">
			<div className="CDropdown-Menu">
				<div
					className="CDropdown-MenuButton"
					onClick={() => {
						setShowDropdown(true);
					}}
					// as={Button} leftIcon={props.leftIcon} rightIcon={downArrowheadIcon("white")}
				>
					{activeMenuItemLabel}
					{downArrowheadIcon("white")}
				</div>

				{showDropdown ? (
					<Clickout
						closeFunction={() => {
							setShowDropdown(false);
						}}
					>
						<div
							className={`CDropdown-MenuList CDropdown-pos-${props.position}`}
						>
							{props.menu.map((m) => (
								<div
									className={`CDropdown-MenuItem `}
									onClick={(e: any) => {
										m.handler?.(e);
										setShowDropdown(false);
									}}
								>
									{" "}
									{m.label}
								</div>
							))}
						</div>
					</Clickout>
				) : null}
			</div>
		</div>
	);
};
export default CDropdown;
