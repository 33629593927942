import { Item } from "@gigauser/common/src/types/files";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	showErrorMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import { Collection } from "@giga-user-fern/api/types/api/resources/collections";
import {
	reorderCollectionsFrontend,
	reorderGuidesFrontend,
} from "@gigauser/common/src/redux/slices/collectionDataSlice";
import logger from "../../../utils/logger";
import { selectSaver } from "../../slices/backendSlice";

type useReorderItemParams =
	| {
			type: "Collection";
			succeedingItem: Collection;
			precedingItem?: Collection;
	  }
	| {
			type: "Guide";
			succeedingItem: GuidePreview;
			precedingItem?: GuidePreview;
	  };

const useReorderItem: () => (items: useReorderItemParams) => Promise<boolean> =
	() => {
		const dispatch = useAppDispatch();
		const saver = useAppSelector(selectSaver);

		return async (items: useReorderItemParams) => {
			const { precedingItem, succeedingItem, type } = items;

			try {
				dispatch(showLoadingMessagePopup());

				if (type == "Collection") {
					const resp = saver.reorderCollection(succeedingItem, precedingItem);

					dispatch(
						reorderCollectionsFrontend({
							succeedingCollection: succeedingItem,
							precedingCollection: precedingItem,
						}),
					);
				} else {
					// console.log("------------useReorderItem-----------")
					// console.log("preceeding item: ", precedingItem?.guide)
					// console.log("suceeding item: ", succeedingItem.guide)
					// console.log("-------------------------------------")

					const resp = saver.reorderGuide(succeedingItem, precedingItem);

					dispatch(
						reorderGuidesFrontend({
							succeedingGuide: succeedingItem,
							precedingGuide: precedingItem,
						}),
					);
				}

				dispatch(showMessagePopup("Reordered 1 item successfully."));
				return true;
			} catch (e) {
				logger.error("Error while reordering item: ", e);
				dispatch(showErrorMessagePopup("Error while reordering item."));
				return false;
			}
		};
	};

export default useReorderItem;
