import React from "react";
import { formatTime } from "../../../../core/canvas/canvasUtils/videoUtils";
import "./FormattedPlayTime.css";

type FormattedPlayTimeProps = {
	currentTime: number;
	totalTime: number;
};

const FormattedPlayTime: React.FC<FormattedPlayTimeProps> = (props) => {
	const formattedCurrentTime = formatTime(props.currentTime);

	const formattedTime = formatTime(props.totalTime);

	return (
		<div className="VideoPlayer-cut-time">
			{/* <Icon style={{cursor: "default"}} className="clock-icon">{clockIcon("#bfc7d4")}</Icon> */}
			<div className="VideoPlayer-runtime">
				<span className=" currTime mins">{formattedCurrentTime.minutes}</span>:
				<span className=" currTime secs">{formattedCurrentTime.seconds}</span>:
				<span className=" currTime ms">
					{formattedCurrentTime.milliseconds}
				</span>
			</div>
			/<div className="cut-time-text">{formattedTime.fullString}</div>
		</div>
	);
};
export default FormattedPlayTime;
