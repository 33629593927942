import { Background } from "@giga-user-fern/api/types/api";
import { Version } from "@giga-user-fern/api/types/api/resources/video";

export const sanitizedBackgroundEdits: (
	backgroundEdits: Background,
	version: Version | undefined,
) => Background = (backgroundEdits, version) => {
	/**
	 * Reads the background edits saved in backend, and returns a new background edits object with correct values
	 */

	const sanitizedBackgroundEdits = { ...backgroundEdits };

	if (version == "oct162023" || !version) {
		sanitizedBackgroundEdits.padding = 8;
		sanitizedBackgroundEdits.borderRadius = 0;
	}

	return sanitizedBackgroundEdits;
};
