import HeaderLaptop from "./laptop/Header.laptop";
import HeaderMobile from "./mobile/Header.mobile";

import { Device } from "@gigauser/common/src/types/devices";
import React from "react";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";

export type HeaderProps = {
	showTitle?: boolean;
};

const Header: React.FC<HeaderProps> = (props) => {
	const deviceType = useWindowSize().device;

	if (deviceType === "laptop")
		return <HeaderLaptop showTitle={props.showTitle} />;
	else return <HeaderMobile showTitle={props.showTitle} />;
};

export default Header;
